import { Toolbar, Tooltip, Fab, createStyles, lighten, makeStyles, Theme, Grid, Divider } from '@material-ui/core';
import SellIcon from '@material-ui/icons/AttachMoneyOutlined';
import ReserveIcon from '@material-ui/icons/LockRounded';
import ReleaseIcon from '@material-ui/icons/LockOpenRounded';
import CopyIcon from '@material-ui/icons/DescriptionOutlined';
import ClearAllIcon from '@material-ui/icons/ClearAllOutlined';
import ExtendReservationIcon from '@material-ui/icons/Schedule';
import clsx from 'clsx';
import React from 'react';
import Config from 'shared/constants/Config';

interface EnhancedTableToolbarProps {
	transactions: string[];
	numSelected: number;
	onClickSell?: () => void;
	onClickReserve: () => void;
	onClickExtendReservation: () => void;
	onClickRelease: () => void;
	onClickRemoveAllAreas: () => void;
	onClickCopySA1?: () => void;
}

//toolbar component (above table header) containing buy/release/hold buttons
const SelectedSa1CdarAreaListToolbar = (props: EnhancedTableToolbarProps) => {
	const classes = toolbarStyles();
	const { numSelected } = props;

	return (
		<>
			<Toolbar
				className={clsx(classes.root, {
					[classes.highlight]: numSelected > 0
				})}
			>
				<Grid container>
					<Grid item xs alignItems="center" container>
						<Grid container justify="space-evenly" alignItems="center">
							{props.transactions.includes('sell') && (
								<Tooltip title="Sell">
									<span>
										<Fab
											size="small"
											disabled={numSelected === 0}
											className={classes.sellBtn}
											onClick={props.onClickSell}
										>
											<SellIcon />
										</Fab>
									</span>
								</Tooltip>
							)}
							{props.transactions.includes('reserve') && (
								<Tooltip title="Reserve">
									<span>
										<Fab
											size="small"
											disabled={numSelected === 0}
											className={classes.reserveBtn}
											onClick={props.onClickReserve}
										>
											<ReserveIcon />
										</Fab>
									</span>
								</Tooltip>
							)}
							{props.transactions.includes('extend_reservation') && (
								<Tooltip title="Extend Reservation">
									<span>
										<Fab
											disabled={numSelected === 0}
											size="small"
											onClick={props.onClickExtendReservation}
											className={classes.extendReserveBtn}
										>
											<ExtendReservationIcon />
										</Fab>
									</span>
								</Tooltip>
							)}
							{props.transactions.includes('release') && (
								<Tooltip title="Release">
									<span>
										<Fab
											disabled={numSelected === 0}
											size="small"
											className={classes.releaseBtn}
											onClick={props.onClickRelease}
										>
											<ReleaseIcon />
										</Fab>
									</span>
								</Tooltip>
							)}
							{props.transactions.includes('copySA1') && (
								<Tooltip title="Copy">
									<span>
										<Fab
											size="small"
											disabled={numSelected === 0}
											onClick={props.onClickCopySA1}
											className={classes.clearAllBtn}
										>
											<CopyIcon />
										</Fab>
									</span>
								</Tooltip>
							)}
							<Tooltip title="Clear all">
								<span>
									<Fab size="small" onClick={props.onClickRemoveAllAreas} className={classes.clearAllBtn}>
										<ClearAllIcon />
									</Fab>
								</span>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
			<Divider />
		</>
	);
};

const toolbarStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingLeft: 0,
			paddingRight: theme.spacing(1)
		},
		highlight:
			theme.palette.type === 'light'
				? {
						color: theme.palette.secondary.main,
						backgroundColor: '#fff'
				  }
				: {
						color: theme.palette.text.primary,
						backgroundColor: theme.palette.secondary.dark
				  },
		title: {
			flex: '1 1 100%'
		},
		sellBtn: {
			backgroundColor: Config.MapColors.sales.sold,
			color: '#fff',
			fontSize: 20,
			height: 35,
			width: 35
		},
		reserveBtn: {
			backgroundColor: Config.MapColors.sales.reserved,
			color: '#fff',
			fontSize: 20,
			height: 35,
			width: 35
		},
		releaseBtn: {
			backgroundColor: '#E00000',
			color: '#fff',
			fontSize: 20,
			height: 35,
			width: 35
		},
		clearAllBtn: {
			backgroundColor: '#767676',
			color: '#fff',
			fontSize: 20,
			height: 35,
			width: 35
		},
		extendReserveBtn: {
			backgroundColor: '#009A44',
			color: '#fff',
			fontSize: 20,
			height: 35,
			width: 35
		}
	})
);

export default SelectedSa1CdarAreaListToolbar;
