import React, { useEffect } from 'react';
import { Grid, Typography, Fab, Tooltip } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import {
	ISa1PerformanceReportApiParams,
	ITableFilterParam,
	ISa1StatusReportResult,
	ISa1PerformanceFilterParams,
	ISa1AutoComplete
} from '../Report';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CommonApiService from 'shared/services/CommonApiService';
import ReportFilter from '../ReportFilter';
import ReportResult from './Sa1StatusReportResult';
import EmptyScreen from 'shared/components/EmptyScreen';
import UtilityService from 'shared/services/UtilityService';
import LinearProgress from 'shared/components/LoadingLinear';
interface IMatchParams {
	id: string;
}
type IProps = RouteComponentProps<IMatchParams>;

const Sa1StatusReport: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const [filterParams, setFilterParams] = React.useState<ISa1PerformanceReportApiParams | null>(null);
	const [tableFilterParams, setTableFilterParams] = React.useState<ITableFilterParam | null>(null);
	const [filterURLParams, setFilterURLParams] = React.useState<ISa1PerformanceFilterParams | null>(null);
	const [tableFilterURLParams, setTableFilterURLParams] = React.useState<ITableFilterParam | null>(null);
	const [records, setRecords] = React.useState<ISa1StatusReportResult[]>([]);
	const [totalRecords, setTotalRecords] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isShowExportBtn, setIsShowExportBtn] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string | null>(null);

	useEffect(() => {
		const filterParams = fetchParamsFromURL();
		if (Object.keys(filterParams).length !== 0 && filterParams.constructor === Object) {
			onClickApplyFilter(filterParams, 'fromUrl');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchParamsFromURL = () => {
		const filterParams = getFilterParamsFromURL();
		setFilterURLParams(filterParams as ISa1PerformanceFilterParams);
		const tableParams = getTableFilterParamsFromURL();
		setTableFilterURLParams(tableParams as ITableFilterParam);
		return {
			...filterParams,
			...tableParams
		};
	};

	const getFilterParamsFromURL = () => {
		const urlParams = UtilityService.queryParse(props.history.location.search);
		return {
			...(urlParams['sa1s'] && { sa1s: (urlParams['sa1s'] as string).split(',') }),
			...(urlParams['agency_id'] && { agencyId: Number(urlParams['agency_id']) }),
			...(urlParams['agency_name'] && { agencyName: urlParams['agency_name'] as string }),
			...(urlParams['state_id'] && { stateId: Number(urlParams['state_id']) }),
			...(urlParams['status'] && { statusType: urlParams['status'] }),
			...(urlParams['sales_person_name'] && { salesPersonName: urlParams['sales_person_name'] }),
			...(urlParams['sales_person_id'] && { salesPersonId: Number(urlParams['sales_person_id']) }),
			...(urlParams['prospect_agency_name'] && {
				prospectAgencyName: urlParams['prospect_agency_name'] as string
			})
		};
	};

	const getTableFilterParamsFromURL = () => {
		const urlParams = UtilityService.queryParse(props.history.location.search);
		return {
			...(urlParams['limit'] && { limit: Number(urlParams['limit']) }),
			...(urlParams['offset'] && {
				offset: Number(urlParams['offset'])
			}),
			...(urlParams['column_name'] && {
				column_name: urlParams['column_name'] as string
			}),
			...(urlParams['order_by'] && {
				order_by: urlParams['order_by'] as string
			})
		};
	};

	const onTableFilter = (tableFilterParams: ITableFilterParam) => {
		setTableFilterParams(tableFilterParams);
		const params = {
			...filterParams,
			...tableFilterParams
		};
		setUrl(params as ISa1PerformanceReportApiParams);
		getFilteredRecord(params as ISa1PerformanceReportApiParams);
	};
	const setUrl = (params: ISa1PerformanceReportApiParams) => {
		const urlParams = UtilityService.queryStringify(params);
		props.history.push('/ui/report/sa1-status-report?' + urlParams);
	};

	const getFilteredRecord = async (params: ISa1PerformanceReportApiParams) => {
		try {
			setIsLoading(true);
			setIsShowExportBtn(false);
			const filterParams = { ...params };
			if (filterParams['agency_name']) {
				delete filterParams['agency_name'];
			}
			if (filterParams['state_id'] === 9999) {
				delete filterParams['state_id'];
			}
			if (filterParams['prospect_agency_name']) {
				filterParams['prospect_agency_name'] = decodeURI(filterParams['prospect_agency_name']);
			}
			if (filterParams['status'] === 'none') {
				delete filterParams['status'];
			}
			if (filterParams['sales_person_name']) {
				delete filterParams['sales_person_name'];
			}
			const apiRes: any = await CommonApiService.getSA1StatusRecord(filterParams);

			if (!apiRes?.success) {
				setRecords([]);
				return;
			}
			if (apiRes.data.length > 0) {
				setIsShowExportBtn(true);
			}
			setRecords(apiRes.data);
			setTotalRecords(apiRes.total_count);
		} finally {
			setIsLoading(false);
		}
	};

	const isValidForm = (filterParams: any, type: string) => {
		const { sa1s, agencyId, stateId, prospectAgencyName, statusType, salesPersonId } = filterParams;
		if (type === 'search') {
			if (
				getFilterSa1s(sa1s).length === 0 &&
				!agencyId &&
				!salesPersonId &&
				(!stateId || stateId === 9999) &&
				!prospectAgencyName &&
				(!statusType || statusType === 'none')
			) {
				setError('SA1s or State or Agency or Status or Prospect Agency name or Account manger is required field.');
				return false;
			}
		}
		if (type === 'fromUrl') {
			if (
				(!sa1s || sa1s.length === 0) &&
				!agencyId &&
				!salesPersonId &&
				(!stateId || stateId === 9999) &&
				!prospectAgencyName &&
				(!statusType || statusType === 'none')
			) {
				setError('SA1s or State or Agency or Status or Prospect Agency name or Account manger is required field.');
				return false;
			}
		}
		return true;
	};
	const getApiParams = (filterParams: any, type: string) => {
		const {
			sa1s,
			agencyId,
			agencyName,
			stateId,
			prospectAgencyName,
			limit,
			offset,
			statusType,
			salesPersonId,
			salesPersonName
		} = filterParams;
		if (type === 'search') {
			return {
				...(sa1s && sa1s.length > 0 && { sa1s: getFilterSa1s(sa1s) }),
				...(agencyId && { agency_id: agencyId as number }),
				...(agencyId && { agency_name: agencyName }),
				...(stateId && { state_id: stateId as number }),
				...(prospectAgencyName && { prospect_agency_name: encodeURI(prospectAgencyName as string) }),
				...(statusType && { status: statusType }),
				...(salesPersonId && { sales_person_id: salesPersonId as number }),
				...(salesPersonId && { sales_person_name: salesPersonName }),
				limit,
				offset
			};
		}
		return {
			...(sa1s && sa1s.length > 0 && { sa1s: getFilterSa1sForURL(sa1s) }),
			...(agencyId && { agency_id: agencyId }),
			...(agencyId && { agency_name: agencyName }),
			...(stateId && { state_id: stateId as number }),
			...(prospectAgencyName && { prospect_agency_name: prospectAgencyName as string }),
			...(statusType && { status: statusType }),
			...(salesPersonId && { sales_person_id: salesPersonId as number }),
			...(salesPersonId && { sales_person_name: salesPersonName }),
			limit,
			offset
		};
	};

	const getFilterSa1s = (sa1s: ISa1AutoComplete[]) => {
		return sa1s.map((item: ISa1AutoComplete) => {
			return typeof item.sa1_code === 'string' ? Number(item.sa1_code) : item.sa1_code;
		});
	};
	const getFilterSa1sForURL = (sa1s: string[]) => {
		return sa1s.map((item: string) => {
			return Number(item);
		});
	};
	const getTableFilterForURL = (filterParams: any) => {
		const { column_name, order_by } = filterParams;
		return {
			...(column_name && { column_name }),
			...(order_by && { order_by })
		};
	};
	const onClickApplyFilter = (filterParams: any, type: string) => {
		setError('');
		if (!isValidForm(filterParams, type)) {
			return;
		}
		let filterApiParams = getApiParams({ ...filterParams }, type);
		setFilterParams(filterApiParams);

		if (type === 'search') {
			setUrl(filterApiParams);
			setTableFilterURLParams({
				offset: 0,
				limit: 10,
				order_by: 'asc',
				column_name: ''
			});
		}
		if (type === 'fromUrl') {
			filterApiParams = {
				...filterApiParams,
				...getTableFilterForURL(filterParams)
			};
			setTableFilterParams(getTableFilterForURL(filterParams));
		}
		getFilteredRecord(filterApiParams);
	};
	const onReset = () => {
		setError('');
		setRecords([]);
		setFilterParams(null);
		setIsShowExportBtn(false);
		props.history.push('/ui/report/sa1-status-report');
	};
	const onExport = async () => {
		try {
			const params = {
				...(tableFilterParams && tableFilterParams),
				...filterParams,
				is_export: true
			};
			setIsLoading(true);
			setIsShowExportBtn(false);

			if (params['agency_name']) {
				delete params['agency_name'];
			}
			if (params['state_id'] === 9999) {
				delete params['state_id'];
			}
			if (params['status'] === 'none') {
				delete params['status'];
			}
			if (params['prospect_agency_name']) {
				params['prospect_agency_name'] = decodeURI(params['prospect_agency_name']);
			}
			if (params['sales_person_name']) {
				delete params['sales_person_name'];
			}

			const apiRes: any = await CommonApiService.getSA1StatusRecord(params as any);

			setIsShowExportBtn(true);
			if (!apiRes?.success) {
				return;
			}
			UtilityService.downloadCSVFile(apiRes.uri, `SA1StatusReport${UtilityService.getDateForCSVName()}.csv`);
		} finally {
			setIsLoading(false);
		}
	};
	const getEmptyScreenMsg = () => {
		if (!filterParams || (Object.keys(filterParams).length === 0 && filterParams.constructor === Object)) {
			return 'Select some filters and click on APPLY button to get the data';
		}
		return 'No data available';
	};

	return (
		<Grid direction="column" container style={{ width: '100%', height: '100%' }}>
			<LinearProgress showLoader={isLoading} />
			<Grid item container style={{ padding: 10 }} justify="space-between">
				<Grid item>
					<Typography className="mb10" color="secondary" variant="h6">
						SA1 Status Report
					</Typography>
				</Grid>
				<Grid item>
					<Tooltip title="Download Report in CSV">
						<span>
							<Fab
								size="small"
								disabled={!isShowExportBtn}
								onClick={onExport}
								style={{ backgroundColor: isShowExportBtn ? '#0d8f4e' : '#bdbdbd', color: '#fff', marginRight: 15 }}
							>
								<CloudDownloadOutlinedIcon />
							</Fab>
						</span>
					</Tooltip>
				</Grid>
			</Grid>
			<Grid
				item
				className="pl10 rl10 admin-sales"
				style={{ minHeight: 50, maxHeight: 100, overflow: 'auto', marginBottom: 10 }}
			>
				<ReportFilter
					error={error}
					filters={['sa1', 'state', 'agency', 'prospect_agency', 'status', 'salesPerson']}
					filterUrlParams={filterURLParams}
					onClickApplyFilter={onClickApplyFilter}
					reportType="status"
					onReset={onReset}
				/>
			</Grid>
			<Grid item xs className="pl10 rl10" style={{ marginBottom: 10, overflow: 'auto' }}>
				{records.length > 0 ? (
					<ReportResult
						records={records}
						totalRecord={totalRecords}
						onTableFilter={onTableFilter}
						tableFilterParams={tableFilterURLParams}
					/>
				) : (
					<EmptyScreen emptyText={getEmptyScreenMsg()} />
				)}
			</Grid>
		</Grid>
	);
};

export default Sa1StatusReport;
