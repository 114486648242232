import { Grid, List, ListItem, makeStyles } from '@material-ui/core';
import React from 'react';

interface IAreaListUi {
	key: string; //used for unique key prop in list
	name: string;
}
interface IProps<T> {
	//children: React.ReactNode;
	rowContent: (area: T) => React.ReactNode;
	height?: string;
	areaList: T[];
	xs?: boolean;
	onHoverListStart?: (area: T) => void;
	onHoverListEnd?: (area: T) => void;
	onListItemClick?: (area: T) => void;
}

//const RevAreaList: React.FunctionComponent<IProps<T>> = (props): JSX.Element => {
function RevAreaList<T extends IAreaListUi>(props: IProps<T>) {
	const classes = useStyles();

	const suburbListRowMouseEnter = (area: T) => {
		if (props.onHoverListStart) {
			props.onHoverListStart(area);
		}
	};

	const suburbListRowMouseLeave = (area: T) => {
		if (props.onHoverListEnd) {
			props.onHoverListEnd(area);
		}
	};

	const onListItemClick = (area: T) => {
		if (props.onListItemClick) {
			props.onListItemClick(area);
		}
	};
	return (
		<Grid
			item
			xs={props.xs || undefined}
			className={classes.suburbsListContainer}
			style={{ maxHeight: props.height || '100%' }}
		>
			<List className={classes.suburbsList}>
				{props.areaList.map((area, index) => (
					<ListItem
						dense
						divider
						key={index}
						className={classes.suburbListRow}
						onMouseEnter={() => suburbListRowMouseEnter(area)}
						onMouseLeave={() => suburbListRowMouseLeave(area)}
						onClick={() => onListItemClick(area)}
					>
						<Grid container className="cdar-list">
							{props.rowContent(area)}
							{/** row contents are to be supplied as render prop */}
						</Grid>
					</ListItem>
				))}
			</List>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	suburbsListContainer: {
		marginTop: 8,
		overflowY: 'auto',
		overflowX: 'hidden'
	},
	suburbsList: {
		//maxHeight: '100%',
		backgroundColor: '#efefef',
		paddingTop: 2,
		paddingBottom: 2
	},
	suburbsListItemDeleteBtn: {
		cursor: 'pointer'
	},
	suburbListRow: {
		paddingTop: 0,
		paddingBottom: 0,
		/* border: '1px solid transparent', */
		'&:hover': {
			cursor: 'pointer',
			color: theme.palette.primary.main,
			border: '1px solid black'
		}
	}
}));

export default RevAreaList;
