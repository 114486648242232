import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, Grid, ListItemText, makeStyles, Switch, TextField, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/ClearOutlined';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import RevAreaList from 'shared/components/RevAreaList';
import { ISuburb, ICda } from 'shared/types/Types';
import * as yup from 'yup';

interface IProps {
	initialValues: ICda;
	onClickSave: (cda: ICda) => void;
	onClickRemoveSuburbFromCda: (suburb: ISuburb) => void;
	onClickCancel: () => void;
	onSuburbListItemHoverStart?: (suburb: ISuburb) => void;
	onSuburbListItemHoverEnd?: (suburb: ISuburb) => void;
}

interface IForm {
	name: string;
	description: string;
	provisional_search_radius: number;
	reservation_expiry_in_days?: number;
	automate_provisional_assignment: boolean;
}

const schema = yup.object().shape({
	name: yup.string().required(),
	description: yup.string(),
	/* provisional_search_radius: yup.number().required(), */
	reservation_expiry_in_days: yup
		.number()
		.transform((value, originalValue) => {
			//source - https://github.com/jquense/yup/issues/298
			if (typeof originalValue === 'string' && originalValue === '') {
				return null;
			}
			return value;
		})
		.nullable(),
	automate_provisional_assignment: yup.boolean()
});

const ManageCdaForm: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const { initialValues } = props;
	const suburbRef = React.useRef(initialValues.suburbs);
	const { handleSubmit, errors, control, formState } = useForm({
		defaultValues: {
			...initialValues,
			provisional_search_radius: initialValues.provisional_search_radius || '',
			reservation_expiry_in_days: initialValues.reservation_expiry_in_days || '',
			description: initialValues.description || ''
		},
		resolver: yupResolver(schema)
	});
	const { isDirty } = formState;
	//name attribute is required by RevAreaList
	const suburbList = initialValues.suburbs.map((suburb) => {
		return {
			...suburb,
			name: `${suburb.name} (${suburb.abs_suburb_id})`,
			key: suburb.abs_suburb_id
		};
	});

	const onSubmit = (data: IForm) => {
		const isFormDirty = () => {
			//react-hook-form does not detect dirtiness of form with material ui switch
			//hence this custom logic
			if (data.automate_provisional_assignment !== initialValues.automate_provisional_assignment) {
				return true;
			}

			//suburbs are not a part of react-hook-form state, so we need manual maintenance whenever
			//there are changes in suburbs
			//check 1 - if length is unequal, changes have been made to suburbs
			if (initialValues.suburbs.length !== suburbRef.current.length) {
				return true;
			}

			//check 2 - if length is equal, we need to match them one by one
			for (let i = 0; i < initialValues.suburbs.length; i++) {
				const found = suburbRef.current.find((refSub) => refSub.id === initialValues.suburbs[i].id);
				if (!found) {
					return true;
				}
			}

			return false;
		};

		//submit form only if dirty
		if (isFormDirty() || isDirty) {
			props.onClickSave({
				...initialValues,
				...data
			});
		}
	};

	const suburbListRowMouseEnter = (suburb: ISuburb) => {
		if (props.onSuburbListItemHoverStart) {
			props.onSuburbListItemHoverStart(suburb);
		}
	};

	const suburbListRowMouseLeave = (suburb: ISuburb) => {
		if (props.onSuburbListItemHoverEnd) {
			props.onSuburbListItemHoverEnd(suburb);
		}
	};

	const classes = useStyles();
	return (
		<Grid container item direction="column">
			<form onSubmit={handleSubmit(onSubmit)} className="stretch">
				<Grid container direction="column" className="stretch">
					<Grid item direction="column" container style={{ overflowY: 'auto' }} wrap="nowrap">
						<Controller
							as={
								<TextField
									error={errors.name ? true : false}
									helperText={errors.name ? 'CDA name is required' : ''}
									placeholder="Enter CDA Name"
									label="Name"
								/>
							}
							name="name"
							control={control}
						/>

						<FormControl>
							<Controller
								as={
									<TextField
										style={{ marginTop: 5 }}
										multiline
										rowsMax={4}
										label="Description"
										placeholder="Enter CDA Description"
									/>
								}
								name="description"
								control={control}
							/>
						</FormControl>

						{/* <FormControl>
							<Controller
								as={
									<TextField
										type="number"
										error={errors.provisional_search_radius ? true : false}
										style={{ marginTop: 5 }}
										helperText={errors.provisional_search_radius ? 'Search radius should be a number' : ''}
										label="Priovisional Search Radius"
										placeholder="Enter radius in kilometer"
									/>
								}
								name="provisional_search_radius"
								control={control}
							/>
						</FormControl> */}

						<FormControl>
							<Controller
								as={
									<TextField
										type="number"
										error={errors.reservation_expiry_in_days ? true : false}
										style={{ marginTop: 5 }}
										helperText={errors.reservation_expiry_in_days ? 'Reservation expiry should be a number' : ''}
										label="Override Reservation expiry in days"
										placeholder="Enter max days for which CDAR/SA1 can be reserved"
									/>
								}
								name="reservation_expiry_in_days"
								control={control}
							/>
						</FormControl>

						<Controller
							render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
								<Grid container justify="space-between" className="mt5 mb5">
									<Typography color="secondary" variant="body1">
										Automate provisional 1 assignment
									</Typography>
									<Switch
										color="primary"
										size="small"
										onChange={(e) => {
											onChange(e.target.checked);
										}}
										checked={value}
										inputRef={ref}
										value={value}
									/>
								</Grid>
							)}
							name="automate_provisional_assignment"
							control={control}
						/>
						{suburbList.length > 0 && (
							<>
								<Typography color="secondary" variant="caption">
									Suburbs in {initialValues.name}
								</Typography>

								<RevAreaList
									height="calc(100vh - 500px)"
									areaList={suburbList}
									onHoverListStart={suburbListRowMouseEnter}
									onHoverListEnd={suburbListRowMouseLeave}
									rowContent={(suburb) => {
										return (
											<>
												<ListItemText primary={suburb.name} />
												<ClearIcon
													color="secondary"
													className={classes.suburbsListItemDeleteBtn}
													onClick={() => {
														props.onClickRemoveSuburbFromCda(suburb);
													}}
												/>
											</>
										);
									}}
								/>
							</>
						)}
					</Grid>

					{/** form save and cancel buttons */}
					<Grid>
						<Button type="submit" disableElevation size="small" color="primary">
							SAVE
						</Button>
						<Button disableElevation size="small" onClick={props.onClickCancel}>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	error: {
		color: theme.palette.error.light,
		display: 'inline-block',
		fontSize: 12
	},
	suburbsListContainer: {
		marginTop: 10
	},
	suburbsList: {
		//maxHeight: '100%',
		backgroundColor: '#efefef'
	},
	suburbsListItemDeleteBtn: {
		cursor: 'pointer'
	},
	suburbListRow: {
		'&:hover': {
			color: theme.palette.primary.main,
			border: '1px solid black'
		}
	}
}));

export default ManageCdaForm;
