import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import Config from 'shared/constants/Config';
import chroma from 'chroma-js';
import { IMapColor } from 'shared/types/Types';

interface IProps {
	mapColors: IMapColor;
}

export const AreaSoldBadge: React.FunctionComponent<IProps> = (props) => {
	const [mapColors, SetMapColors] = React.useState<IMapColor>(Config.MapColors);

	useEffect(() => {
		SetMapColors(props.mapColors);
	}, [props.mapColors]);
	return (
		<Grid
			item
			container
			alignItems="center"
			justify="center"
			style={{
				height: 22,
				width: 40,
				borderRadius: 2,
				/* backgroundColor: chroma(mapColors.sales.sold).brighten(4).hex(),
				color: chroma(mapColors.sales.sold).darken().hex(), */
				backgroundColor: mapColors.sales.sold,
				color: mapColors.sales.soldLabel
			}}
		>
			<span style={{ fontWeight: 'bold', fontSize: '0.7rem' }}>SOLD</span>
		</Grid>
	);
};

export const AreaReservedBadge: React.FunctionComponent<IProps> = (props) => {
	const [mapColors, SetMapColors] = React.useState<IMapColor>(Config.MapColors);

	useEffect(() => {
		SetMapColors(props.mapColors);
	}, [props.mapColors]);
	return (
		<Grid
			item
			container
			alignItems="center"
			justify="center"
			style={{
				height: 25,
				minWidth: 65,
				borderRadius: 2,
				backgroundColor: mapColors.sales.reserved,
				color: mapColors.sales.reservedLabel
			}}
		>
			<span style={{ fontWeight: 'bold', fontSize: '0.7rem' }}>RESERVED</span>
		</Grid>
	);
};
