import React, { useEffect } from 'react';
import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ISa1StatusReportResult, ITableFilterParam } from '../Report';
import { AreaReservedBadge, AreaSoldBadge } from 'shared/components/AreaStatusLabels';
import { ERevAreaSaleStatus, IMapColor } from 'shared/types/Types';
/* import UtilityService from 'shared/services/UtilityService'; */
import { format } from 'date-fns';
import CommonApiService from 'shared/services/CommonApiService';
import Config from 'shared/constants/Config';

interface IProps {
	tableFilterParams: ITableFilterParam | null;
	records: ISa1StatusReportResult[];
	totalRecord: number;
	onTableFilter: (filterParams: ITableFilterParam) => void;
}
interface HeadCell {
	id: string;
	label: string;
	is_short: boolean;
}

const headCells: HeadCell[] = [
	{ id: 'sa1', label: 'SA1', is_short: true },
	{ id: 'agency_id', label: 'Agency Id', is_short: false },
	{ id: 'agency_name', label: 'Agency Name', is_short: false },
	{ id: 'prospect_agency', label: 'Prospect Customer', is_short: false },
	{ id: 'status', label: 'Status', is_short: true },
	{ id: 'cdar', label: 'Cdar', is_short: false },
	{ id: 'state', label: 'State', is_short: false },
	{ id: 'sold_reserved_expiry_time', label: 'Expiry Datetime', is_short: false },
	{ id: 'sold_reserved_time', label: 'Datetime', is_short: false }
];

const Sa1StatusReportResult: React.FunctionComponent<IProps> = (props): JSX.Element => {
	type Order = 'asc' | 'desc';

	interface ITableHeaderProps {
		classes: ReturnType<typeof useStyles>;
		onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
		order: Order;
		orderBy: string;
	}

	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			root: {
				width: '100%'
			},
			paper: {
				width: '100%',
				marginBottom: theme.spacing(2)
			},
			table: {
				minWidth: 750
			},
			visuallyHidden: {
				border: 0,
				clip: 'rect(0 0 0 0)',
				height: 1,
				margin: -1,
				overflow: 'hidden',
				padding: 0,
				position: 'absolute',
				top: 20,
				width: 1
			}
		})
	);

	const classes = useStyles();
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<string>('');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [mapColor, setMapColors] = React.useState<IMapColor>(Config.MapColors);
	useEffect(() => {
		setTableFilteredParams();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.tableFilterParams]);
	useEffect(() => {
		getMapColors();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getMapColors = async () => {
		const apiRes: any = await CommonApiService.getSystemSettings();
		if (!apiRes?.success) {
			return;
		}
		setMapColors(apiRes.data[0].json.MapColors);
	};

	const setTableFilteredParams = () => {
		const tableFilterUrlParams = props.tableFilterParams;
		if (
			!tableFilterUrlParams ||
			(Object.keys(tableFilterUrlParams).length === 0 && tableFilterUrlParams.constructor !== Object)
		) {
			return;
		}

		if (tableFilterUrlParams['order_by']) {
			setOrder(tableFilterUrlParams['order_by'] as Order);
		}
		if (tableFilterUrlParams['column_name'] !== null) {
			setOrderBy(tableFilterUrlParams['column_name'] as string);
		}
		if (tableFilterUrlParams['limit']) {
			setRowsPerPage(tableFilterUrlParams['limit']);
		}
		if (tableFilterUrlParams['offset'] !== null && tableFilterUrlParams['limit']) {
			setPage(Number((tableFilterUrlParams['offset'] as number) / tableFilterUrlParams['limit']));
		}
	};

	const TableHeader = (props: ITableHeaderProps) => {
		const { classes, order, orderBy, onRequestSort } = props;
		const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};
		return (
			<TableHead>
				<TableRow>
					{headCells.map((headCell, index) =>
						headCell.is_short ? (
							<TableCell key={index} align="center" sortDirection={orderBy === headCell.id ? order : false}>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<span className={classes.visuallyHidden}>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</span>
									) : null}
								</TableSortLabel>
							</TableCell>
						) : (
							<TableCell align="center" key={index}>
								{headCell.label}
							</TableCell>
						)
					)}
				</TableRow>
			</TableHead>
		);
	};

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
		props.onTableFilter({
			...(property && { column_name: property }),
			...(property && { order_by: isAsc ? 'desc' : 'asc' }),
			offset: page === 0 ? 0 : rowsPerPage * page,
			limit: rowsPerPage
		});
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		props.onTableFilter({
			...(orderBy && { column_name: orderBy }),
			...(orderBy && { order_by: order }),
			offset: newPage === 0 ? 0 : rowsPerPage * newPage,
			limit: rowsPerPage
		});
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		props.onTableFilter({
			...(orderBy && { column_name: orderBy }),
			...(orderBy && { order_by: order }),
			offset: 0,
			limit: parseInt(event.target.value, 10)
		});
	};

	const renderStatusColumnName = (status: null | string) => {
		return (
			<Grid container alignItems="center" justify="center">
				<Grid item>
					{status === ERevAreaSaleStatus.Sold && <AreaSoldBadge mapColors={mapColor} />}
					{status === ERevAreaSaleStatus.Reserved && <AreaReservedBadge mapColors={mapColor} />}
					{!status && 'Available'}
				</Grid>
			</Grid>
		);
	};

	const renderDateTime = (dateTime: null | string) => {
		if (!dateTime) {
			return '-';
		}
		//return UtilityService.UTCDateToDate(dateTime, 'PPpp', 'UTC');
		return format(new Date(dateTime), 'dd/MM/yyyy, hh:mm a');
	};

	return (
		<Paper className="paper-container" style={{ padding: 10 }}>
			<TableContainer>
				<Table className={classes.table} aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
					<TableHeader classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
					<TableBody>
						{props.records.map((record, index) => {
							return (
								<TableRow hover tabIndex={-1} key={index}>
									<TableCell align="center">{record.sa1_code !== null ? record.sa1_code : '-'}</TableCell>
									<TableCell align="center">{record.agency_id !== null ? record.agency_id : '-'}</TableCell>
									<TableCell align="center">{record.agency_name !== null ? record.agency_name : '-'}</TableCell>
									<TableCell align="center">{record.prospect_agency !== null ? record.prospect_agency : '-'}</TableCell>
									<TableCell align="center">{renderStatusColumnName(record.status)}</TableCell>
									<TableCell align="center">{record.cdar_name}</TableCell>
									<TableCell align="center">{record.state}</TableCell>
									<TableCell align="center">{renderDateTime(record.expiry_datetime)}</TableCell>
									<TableCell align="center">{renderDateTime(record.status_datetime)}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{props.totalRecord && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={props.totalRecord}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
};

export default Sa1StatusReportResult;
