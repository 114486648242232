import React, { Component } from 'react';
import mapboxgl, { LngLatLike, PointLike } from 'mapbox-gl';
import Config from 'shared/constants/Config';
import ConfirmModalWithMessage from 'shared/components/ConfirmModalWithMessage';
import { find as lodashFind } from 'lodash';
import {
	ELoadingIndicatorStatus,
	IMapboxMouseMoveEvent,
	TSa1MapFeatureState,
	TCdarMapFeatureState,
	TAusStateMapFeatureState,
	IMapColor,
	ISa1GeoJsonFeature,
	ITooltipContent,
	ERevAreaSaleStatus,
	ICdarGeoJsonFeature,
	IStateGeoJsonFeature,
	ICdarWithAgency,
	ISalesAreaListItem,
	ESalesAreaListItemType,
	ICdar,
	IAgency,
	IAustraliaState,
	IGeoBound,
	EMapServiceJobStatus,
	IMapLegend,
	ERevAreaSaleType,
	IAreaSaleSubmissionError,
	ISaleVerificationModalSubmit,
	ISalesTransactionRequestBody,
	ISalesMapSearchSelectedItem,
	ENotificationTypes,
	ISa1,
	IProspectiveCustomer,
	IMapSystemSetting,
	ITransactionArea,
	ISalesPerson,
	ISa1CdarByAgency,
	ISa1ByAgency,
	ICdarByAgency,
	ELoggedInUserRole,
	IProspectiveCustomerOption,
	ILoggedInUser,
	ELocalStorageKeys,
	ISalesAreaSearch,
	ISa1Detail,
	ISuburbDetail
} from 'shared/types/Types';
import { LocalStorageService } from 'shared/services/LocalStorageService';
import { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab';
import UtilityService from 'shared/services/UtilityService';
import CommonApiService from 'shared/services/CommonApiService';
import CdarService from 'shared/services/CdarService';
import { NotificationContext, INotificationContext } from 'shared/providers/NotificationProvider';
/* import chroma from 'chroma-js'; */
import { LoggedInUserContext, ILoggedInUserContext } from 'shared/providers/LoggedInUserProvider';

enum ESalesModals {
	SubmissionErrorModal = 'submission-error-modal',
	SubmissionVerificationModal = 'submission-Verification-modal',
	AgencyCdaSelectionModal = 'agency-cda-selection-modal',
	ProspectiveCustomerSelectModal = 'prospective-customer-selection-modal',
	ReservationSelectModal = 'reservation-selection-modal',
	None = 'none'
}
enum EMapLayers {
	StateFill = 'state-fill',
	StateOutline = 'state-outline',
	StateHoverOutline = 'state-hover-fill',
	CdarFill = 'cdar-fill',
	CdarOutline = 'cdar-outline',
	CdarHoverOutline = 'cdar-hover-outline',
	CdarLabel = 'cdar-label',
	RoyaltyCdarFillPattern = 'royalty-cdar-fill-pattern',
	Sa1Fill = 'sa1-fill',
	Sa1Outline = 'sa1-outline',
	Sa1HoverOutline = 'sa1-hover-outline',
	Sa1Label = 'sa1-label'
}
enum ESa1SalesLoaders {
	ShowAgencyAutocompleteLoader = 'show-agency-autocomplete-loader',
	ShowSalesPersonAutocompleteLoader = 'show-sales-person-autocomplete-loader',
	SalesTransactionSubmitLoader = 'sales-transaction-submit-loader',
	ShowPropspectiveCustomerAutocompleteLoader = 'show-propspective -customer-autocomplete-loader',
	ShowSa1SuburbAutoCompleteLoader = 'show-sa1-suburb-autocomplete-loader'
}
enum EMapZoomLevels {
	Country = 'country',
	State = 'state'
}

type ESa1LoadingIndicatorsStatus = ELoadingIndicatorStatus | ESa1SalesLoaders;

interface IState {
	loadingIndicator: ESa1LoadingIndicatorsStatus;

	mapColors: IMapColor;
	isShowMap: boolean;
	cdar: ICdarWithAgency[];
	salesAreaList: ISalesAreaListItem[];
	selectedAusStateId: number | null;
	agencies: IAgency[];
	prospectiveCustomerOptions: IProspectiveCustomerOption[];
	salesPersons: ISalesPerson[];
	selectedAgency: IAgency | null;
	autocomplete: {
		agency: string;
		salesPerson: string;
		prospectiveCustomer: string;
		sa1Suburb: string;
	};
	ausStates: IAustraliaState[];
	onGoingAreaSaleActionType: ERevAreaSaleType | null;
	activeModal: ESalesModals;
	submissionErrors: IAreaSaleSubmissionError[];
	areasEligibleForSubmission: ISalesAreaListItem[];
	prospectiveCustomers: IProspectiveCustomer[];
	reservations: IProspectiveCustomer[];
	mapSystems: IMapSystemSetting | null;
	isModalLoading: boolean;
	isShowConfirmModal: boolean;
	tmpStateId: number | null;
	sa1SuburbOptions: ISalesAreaSearch[];
}

interface IProps {
	LoggedInUserContextValue: ILoggedInUserContext;
	NotificationContextValue: INotificationContext;
}

const AdminSalesCompo = function (ComposedComponent: any) {
	class WrapperHoc extends Component<IProps, IState> {
		constructor(props: IProps) {
			super(props);
			this.getAgencyBySearchText = UtilityService.debounce(this.getAgencyBySearchText);
			this.getProspectiveCustomerBySearchText = UtilityService.debounce(this.getProspectiveCustomerBySearchText);
			this.getSa1AndSuburbByText = UtilityService.debounce(this.getSa1AndSuburbByText);
			this.onMapHoverStart = UtilityService.debounce(this.onMapHoverStart, 25);
			this.onMapHoverEnd = UtilityService.debounce(this.onMapHoverEnd, 25);
		}
		state: Readonly<IState> = {
			loadingIndicator: ELoadingIndicatorStatus.None,
			mapColors: Config.MapColors,
			isShowMap: false,
			cdar: [],
			salesAreaList: [],
			selectedAusStateId: null,
			agencies: [],
			prospectiveCustomerOptions: [],
			salesPersons: [],
			selectedAgency: null,
			autocomplete: {
				agency: '',
				salesPerson: '',
				prospectiveCustomer: '',
				sa1Suburb: ''
			},
			ausStates: [],
			onGoingAreaSaleActionType: null,
			activeModal: ESalesModals.None,
			submissionErrors: [],
			areasEligibleForSubmission: [],
			prospectiveCustomers: [],
			reservations: [],
			mapSystems: null,
			isModalLoading: false,
			isShowConfirmModal: false,
			tmpStateId: null,
			sa1SuburbOptions: []
		};
		private map!: mapboxgl.Map;
		private currentZoomLevel = EMapZoomLevels.Country;
		private hoverState = {
			/* cda: 0, */
			cdar: 0,
			sa1: 0,
			state: 0
		};

		private popup = new mapboxgl.Popup({
			closeButton: false,
			closeOnClick: false,
			offset: 15
		});

		async componentDidMount() {
			this.removeLastMapSyncJobIdKeyFromLocalStorage();
			this.getInProgressMapServiceSyncJob();
			await this.getSystemMapColors();
			await this.getAustralianStates();
			this.clearLocalStorageValue();
		}
		render() {
			return (
				<>
					<ComposedComponent
						isShowMap={this.state.isShowMap}
						onMapLoadComplete={this.onMapLoadComplete}
						loadingIndicator={this.state.loadingIndicator}
						getMapLegend={this.getMapLegend}
						salesAreaList={this.state.salesAreaList}
						onClickRemoveSelectedAreaListItem={this.onClickRemoveSelectedAreaListItem}
						onClickSelectedAreaListItem={this.onClickSelectedAreaListItem}
						onClickDeSelectAllSalesAreas={this.onClickDeSelectAllSalesAreas}
						onClickExtendReservation={this.onClickExtendReservation}
						onClickReserveArea={this.onClickReserveArea}
						onClickReleaseArea={this.onClickReleaseArea}
						onClickRemoveAllSelectedAreaListItems={this.onClickRemoveAllSelectedAreaListItems}
						onAreaListItemMouseOver={this.onAreaListItemMouseOver}
						onAreaListItemMouseOut={this.onAreaListItemMouseOut}
						onClickSelectAllSalesAreas={this.onClickSelectAllSalesAreas}
						submissionErrors={this.state.submissionErrors}
						activeModal={this.state.activeModal}
						onClickSellArea={this.onClickSellArea}
						onClickCopySA1={this.onClickCopySA1}
						areasEligibleForSubmission={this.state.areasEligibleForSubmission}
						onGoingAreaSaleActionType={this.state.onGoingAreaSaleActionType}
						onClickCancelSubmission={this.onClickCancelSubmission}
						onClickSubmitSaleAction={this.onClickSubmitSaleAction}
						onCloseSubmissionErrorsModal={this.onCloseSubmissionErrorsModal}
						onChangeAusStateDropdown={this.onChangeAusStateDropdown}
						selectedAusStateId={this.state.selectedAusStateId}
						autocomplete={this.state.autocomplete}
						onChangeAgencyAutocompleteInput={this.onChangeAgencyAutocompleteInput}
						onChangeSalesPersonAutocompleteInput={this.onChangeSalesPersonAutocompleteInput}
						onChangeProspectiveCustomerAutocompleteInput={this.onChangeProspectiveCustomerAutocompleteInput}
						agencies={this.state.agencies}
						prospectiveCustomers={this.state.prospectiveCustomers}
						ausStates={this.state.ausStates}
						prospectiveCustomerOptions={this.state.prospectiveCustomerOptions}
						getProspectiveCustomers={this.getProspectiveCustomers}
						mapSystemsConfig={this.state.mapSystems}
						mapColors={this.state.mapColors}
						viewReservations={this.viewReservations}
						reservations={this.state.reservations}
						getSa1ByTransactionIdForSalesPerson={this.getSa1ByTransactionIdForSalesPerson}
						salesPersons={this.state.salesPersons}
						onChangeSalesPersonAutocomplete={this.onChangeSalesPersonAutocomplete}
						onChangeAgencyAutocomplete={this.onChangeAgencyAutocomplete}
						isModalLoading={this.state.isModalLoading}
						getTotalSa1sFromList={this.getTotalSa1sFromList}
						getTotalSelectedSa1sFromList={this.getTotalSelectedSa1sFromList}
						sa1SuburbOptions={this.state.sa1SuburbOptions}
						onChangeSa1SuburbAutocompleteInput={this.onChangeSa1SuburbAutocompleteInput}
						onChangeSa1SuburbAutocomplete={this.onChangeSa1SuburbAutocomplete}
					/>
					{this.state.isShowConfirmModal && (
						<ConfirmModalWithMessage
							onClickCancel={this.onCancelConfirmModal}
							onClickConfirm={this.onConfirmModal}
							message={'Are you sure you want to change state ? You will loss your selected area.'}
						/>
					)}
				</>
			);
		}

		removeLastMapSyncJobIdKeyFromLocalStorage = () => {
			const lastMapSyncJobIdInLs = LocalStorageService.getState<{ id: number } | undefined>(
				ELocalStorageKeys.LastMapSyncJobId
			);
			if (lastMapSyncJobIdInLs) {
				LocalStorageService.removeKey(ELocalStorageKeys.LastMapSyncJobId);
			}
		};

		/* Remove isShowCustomerOnSaleScreen key from localstorage for admin role */
		clearLocalStorageValue = () => {
			const loggedInUser = this.props.LoggedInUserContextValue.loggedInUser;
			if (
				loggedInUser?.role === ELoggedInUserRole.Administrator &&
				localStorage.getItem('isShowCustomerOnSaleScreen')
			) {
				localStorage.removeItem('isShowCustomerOnSaleScreen');
			}
		};
		onCancelConfirmModal = () => {
			this.setState({
				isShowConfirmModal: false,
				tmpStateId: null
			});
		};

		onConfirmModal = () => {
			this.setState({
				isShowConfirmModal: false,
				tmpStateId: null
			});
			this.onSelectStateConfirm(this.state.tmpStateId as number);
		};

		getSa1ByTransactionIdForSalesPerson = async (transactionId: number) => {
			try {
				const transactionArea: ITransactionArea[] = await CommonApiService.getTransactionedAreaByTransactionId(
					transactionId
				);
				this.setState({
					activeModal: ESalesModals.None
				});
				this.updateMapBySelectedTransaction(transactionArea);
			} finally {
				this.setState({
					isModalLoading: false
				});
			}
		};

		getTotalSa1sFromList = (): number => {
			const selectedAreaList = this.state.salesAreaList;
			if (selectedAreaList.length === 0) {
				return 0;
			}
			let sa1Counter = 0;
			selectedAreaList.forEach((area: ISalesAreaListItem) => {
				if (area.type === ESalesAreaListItemType.Sa1) {
					sa1Counter += 1;
				}
			});
			return sa1Counter;
		};
		getTotalSelectedSa1sFromList = (): number => {
			const selectedAreaList = this.state.salesAreaList;
			if (selectedAreaList.length === 0) {
				return 0;
			}
			let selectedSa1Counter = 0;
			selectedAreaList.forEach((area: ISalesAreaListItem) => {
				if (area.type === ESalesAreaListItemType.Sa1 && area.selected) {
					selectedSa1Counter += 1;
				}
			});
			return selectedSa1Counter;
		};

		updateMapBySelectedTransaction = async (transactionArea: ITransactionArea[]) => {
			const stateId = transactionArea[0].state_id;
			try {
				await this.updateMapAfterStateSelect(stateId);
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.ShowNavBarLoader,
					salesAreaList: []
				});
				const selectedArea: ISalesAreaListItem[] = [];
				const transactionAreaBounds: IGeoBound[] = [];
				transactionArea.forEach((area: ITransactionArea) => {
					if (area.sa1_id) {
						const selectedSa1 = this.getSalesAreaListItemFromSa1(area.sa1_id);
						this.updateSa1MapFeatureState(area.sa1_id, { isSelected: true, isSold: false, isReserved: false });
						selectedArea.push(selectedSa1);
						transactionAreaBounds.push(JSON.parse(area.bounds));
					}
					if (area.cdar_id) {
						const cdar = this.state.cdar.find((cdar) => cdar.cdar_id === area.cdar_id);
						if (!cdar) {
							throw new Error('Could not find cdar record of clicked cdar feature.');
						}
						const selectedCdar = this.getSalesAreaListItemFromCdar(cdar);
						this.updateCdarMapFeatureState(area.cdar_id, { isSelected: true, isSold: false, isReserved: false });
						selectedArea.push(selectedCdar);
						transactionAreaBounds.push(JSON.parse(area.bounds));
					}
				});
				if (transactionAreaBounds.length > 0) {
					this.fitMapToArea(transactionAreaBounds);
				}

				this.setState({
					salesAreaList: selectedArea
				});
			} finally {
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.None
				});
			}
		};
		fitMapToSa1Bounds = (sa1Bounds: IGeoBound) => {
			const bbox = UtilityService.getMapBoxBoundingBox(sa1Bounds);
			this.map.fitBounds(bbox);
		};

		viewReservations = async (userId: number) => {
			try {
				this.setState({
					activeModal: ESalesModals.ReservationSelectModal,
					isModalLoading: true,
					agencies: [],
					prospectiveCustomerOptions: [],
					sa1SuburbOptions: [],
					autocomplete: {
						...this.state.autocomplete,
						agency: '',
						prospectiveCustomer: '',
						sa1Suburb: ''
					}
				});
				const reservations: any = await CommonApiService.getMyReservations(userId);
				this.setState({
					reservations
				});
			} finally {
				this.setState({
					isModalLoading: false
				});
			}
		};

		getProspectiveCustomers = async (prospectCustomer: string) => {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.ShowNavBarLoader
			});
			const prospectiveCustomers: IProspectiveCustomer[] = await CommonApiService.getProspectiveCustomers({
				search_text: prospectCustomer
			});
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
			if (!prospectiveCustomers) {
				return;
			}
			this.setState({
				activeModal: ESalesModals.ProspectiveCustomerSelectModal,
				prospectiveCustomers,
				agencies: [],
				salesPersons: [],
				sa1SuburbOptions: [],
				autocomplete: {
					...this.state.autocomplete,
					agency: '',
					salesPerson: '',
					sa1Suburb: ''
				}
			});
		};

		onChangeAusStateDropdown = (ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
			const stateId = ev.target.value as number;
			this.setState({
				selectedAgency: null
			});
			this.selectState(stateId);
		};

		onChangeProspectiveCustomerAutocompleteInput = (
			event: React.ChangeEvent<Record<string, unknown>>,
			value: string,
			reason: AutocompleteInputChangeReason
		) => {
			this.setState({
				autocomplete: {
					...this.state.autocomplete,
					prospectiveCustomer: value
				}
			});
			if (reason === 'reset' && !this.state.autocomplete.prospectiveCustomer) {
				this.setState({
					autocomplete: {
						...this.state.autocomplete,
						prospectiveCustomer: ''
					}
				});
			}
			//autocomplete does not kick in till atleast 3 letters are entered by user
			if (reason !== 'input' || value.trim().length < 3) {
				return;
			}
			this.getProspectiveCustomerBySearchText(value);
		};
		getProspectiveCustomerBySearchText = async (searchText: string) => {
			try {
				this.setState({
					loadingIndicator: ESa1SalesLoaders.ShowPropspectiveCustomerAutocompleteLoader
				});
				const res = await CommonApiService.getProspectiveCustomerBySearchText({ searchText });
				const prospectiveCustomerOptions = res;

				this.setState({
					prospectiveCustomerOptions
				});
			} catch (err) {
				console.log('🚀 ~ file: Sa1Sales.tsx ~ line 394 ~ Sa1Sales ~ getProspectiveCustomerBySearchText= ~ err', err);
			} finally {
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.None
				});
			}
			return;
		};

		onChangeSa1SuburbAutocompleteInput = (
			event: React.ChangeEvent<Record<string, unknown>>,
			value: string,
			reason: AutocompleteInputChangeReason
		) => {
			this.setState({
				autocomplete: {
					...this.state.autocomplete,
					sa1Suburb: value
				}
			});
			if (reason === 'reset' && !this.state.autocomplete.sa1Suburb) {
				this.setState({
					autocomplete: {
						...this.state.autocomplete,
						sa1Suburb: ''
					}
				});
			}
			//autocomplete does not kick in till atleast 3 letters are entered by user
			if (reason !== 'input' || value.trim().length < 3) {
				return;
			}
			this.getSa1AndSuburbByText(value);
		};

		getSa1AndSuburbByText = async (value: string) => {
			try {
				this.setState({
					loadingIndicator: ESa1SalesLoaders.ShowSa1SuburbAutoCompleteLoader
				});
				const res = await CommonApiService.searchSa1AndSuburbByText(value);
				this.setState({
					sa1SuburbOptions: res
				});
			} catch (error) {
				console.log(
					'🚀 ~ file: AdminSalesWrapperHoc.tsx ~ line 519 ~ AdminSalesWrapperHoc ~ getSa1AndSuburbByText= ~ err',
					error
				);
			} finally {
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.None
				});
			}
		};

		onChangeAgencyAutocompleteInput = (
			event: React.ChangeEvent<Record<string, unknown>>,
			value: string,
			reason: AutocompleteInputChangeReason
		) => {
			this.setState({
				autocomplete: {
					...this.state.autocomplete,
					agency: value
				}
			});
			if (reason === 'reset' && !this.state.autocomplete.agency) {
				this.setState({
					autocomplete: {
						...this.state.autocomplete,
						agency: ''
					}
				});
			}
			//autocomplete does not kick in till atleast 3 letters are entered by user
			if (reason !== 'input' || value.trim().length < 3) {
				return;
			}
			this.getAgencyBySearchText(value);
		};
		getAgencyBySearchText = async (searchText: string) => {
			try {
				this.setState({
					loadingIndicator: ESa1SalesLoaders.ShowAgencyAutocompleteLoader
				});
				const res = await CommonApiService.getAgencyBySearchText({ searchText });
				const agencies = res;
				this.setState({
					agencies
				});
			} catch (err) {
				console.log(
					'🚀 ~ file: AdminSalesWrapperHoc.tsx ~ line 259 ~ AdminSalesWrapperHoc ~ getAgencyBySearchText= ~ err',
					err
				);
			} finally {
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.None
				});
			}
			return;
		};

		onChangeSa1SuburbAutocomplete = async (
			ev: unknown,
			value: ISalesAreaSearch | string | null,
			reason: AutocompleteChangeReason
		) => {
			if (!value) {
				return;
			}
			const choosenOption = value as ISalesAreaSearch;
			if (!choosenOption.suburb && !choosenOption.sa1_code) {
				throw new Error('Selected item contains neither SA1 nor Suburb info. Either of them is required.');
			}

			//need to clean up existing sa1/royalty cdar map selections after selecting a new sa1
			this.onClickRemoveAllSelectedAreaListItems();

			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.ShowNavBarLoader
			});
			let optionDetail: ISa1Detail | ISuburbDetail;
			try {
				this.setState({
					autocomplete: {
						...this.state.autocomplete,
						salesPerson: '',
						prospectiveCustomer: '',
						agency: ''
					},
					prospectiveCustomerOptions: [],
					salesPersons: [],
					agencies: [],
					salesAreaList: []
				});
				if (choosenOption.suburb) {
					optionDetail = await CommonApiService.getSuburbById(choosenOption.id);
				} else if (choosenOption.sa1_code) {
					//sa1
					optionDetail = await CommonApiService.getSa1BySa1Code(choosenOption.sa1_code);
				}
				const selectedOption = {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					state_id: optionDetail!.state_id,
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					cda_id: optionDetail!.cda_id,
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					bounds: optionDetail!.bounds
				};
				if (selectedOption.state_id !== this.state.selectedAusStateId) {
					//state does not match
					await this.updateMapAfterStateSelect(selectedOption.state_id);
				}

				if (selectedOption.bounds) {
					this.fitMapToBoundsWithPadding(selectedOption.bounds, 0);
				}
			} catch (error) {
				throw new Error(error);
			} finally {
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.None
				});
			}
		};

		onChangeAgencyAutocomplete = async (ev: unknown, value: IAgency | null, reason: AutocompleteChangeReason) => {
			if (!value) {
				return;
			}
			try {
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.ShowNavBarLoader
				});
				const result: ISa1CdarByAgency = await CommonApiService.getSa1CdarByAgency(value.agency_id);
				if (!result) {
					return;
				}
				if (result.cdar.length === 0 && result.sa1s.length === 0) {
					return;
				}
				const selectedArea: ISalesAreaListItem[] = [];
				this.setState({
					autocomplete: {
						...this.state.autocomplete,
						salesPerson: '',
						prospectiveCustomer: '',
						sa1Suburb: ''
					},
					prospectiveCustomerOptions: [],
					salesPersons: [],
					sa1SuburbOptions: []
				});
				await this.updateMapAfterStateSelect(result.sa1s[0].state_id);

				if (result.cdar.length > 0) {
					result.cdar.forEach((SelCdar: ICdarByAgency) => {
						const cdar = this.state.cdar.find((cdar) => cdar.cdar_id === SelCdar.cdar_id);
						if (!cdar) {
							throw new Error('Could not find cdar record of clicked cdar feature.');
						}
						const selectedCdar = this.getSalesAreaListItemFromCdar(cdar);
						selectedCdar.selected = false;
						selectedArea.push(selectedCdar);
						this.updateCdarMapFeatureState(SelCdar.cdar_id, { isSelected: true, isSold: false, isReserved: false });
					});
				}
				if (result.sa1s.length > 0) {
					result.sa1s.forEach((sa1: ISa1ByAgency) => {
						const selectedSa1 = this.getSalesAreaListItemFromSa1(sa1.sa1_id);
						selectedSa1.selected = false;
						selectedArea.push(selectedSa1);
						this.updateSa1MapFeatureState(sa1.sa1_id, { isSelected: true, isSold: false, isReserved: false });
					});
				}
				this.fitMapToAgencyAreaBounds(result);
				this.setState({
					salesAreaList: selectedArea
				});
			} catch (error) {
				throw new Error(error);
			} finally {
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.None
				});
			}
		};

		fitMapToAgencyAreaBounds = (result: ISa1CdarByAgency) => {
			const bounds: IGeoBound[] = [];
			if (result.cdar.length > 0) {
				result.cdar.forEach((cdar) => {
					bounds.push(JSON.parse(cdar.bounds));
				});
			}
			if (result.sa1s.length > 0) {
				result.sa1s.forEach((sa1s) => {
					bounds.push(JSON.parse(sa1s.bounds));
				});
			}
			if (bounds.length !== 0) {
				this.fitMapToArea(bounds);
			}
		};

		fitMapToArea = (areaBounds: IGeoBound[]) => {
			const bounds: IGeoBound[] = [];
			areaBounds.forEach((bound: IGeoBound) => {
				if (bound.north && bound.south && bound.east && bound.west) {
					bounds.push(bound);
				}
			});
			if (bounds.length === 0) {
				return;
			}
			const northBound = [...bounds].reduce(function (prev, curr) {
				return prev.north > curr.north ? prev : curr;
			});
			const southBound = [...bounds].reduce(function (prev, curr) {
				return prev.south < curr.south ? prev : curr;
			});
			const eastBound = [...bounds].reduce(function (prev, curr) {
				return prev.east > curr.east ? prev : curr;
			});
			const westBound = [...bounds].reduce(function (prev, curr) {
				return prev.west < curr.west ? prev : curr;
			});
			this.fitMapToSa1Bounds({
				north: northBound.north,
				south: southBound.south,
				east: eastBound.east,
				west: westBound.west
			});
		};
		onChangeSalesPersonAutocompleteInput = (
			event: React.ChangeEvent<Record<string, unknown>>,
			value: string,
			reason: AutocompleteInputChangeReason
		) => {
			this.setState({
				autocomplete: {
					...this.state.autocomplete,
					salesPerson: value
				}
			});
			if (reason === 'reset' && !this.state.autocomplete.salesPerson) {
				this.setState({
					autocomplete: {
						...this.state.autocomplete,
						salesPerson: ''
					}
				});
			}
			//autocomplete does not kick in till atleast 3 letters are entered by user
			if (reason !== 'input' || value.trim().length < 3) {
				return;
			}
			this.getSalesPersonBySearchText(value);
		};
		getSalesPersonBySearchText = async (searchText: string) => {
			try {
				this.setState({
					loadingIndicator: ESa1SalesLoaders.ShowSalesPersonAutocompleteLoader
				});
				const res = await CommonApiService.getSalesPersonBySearchText({ searchText });
				const salesPersons = res;

				this.setState({
					salesPersons
				});
			} catch (err) {
				console.log(
					'🚀 ~ file: AdminSalesWrapperHoc.tsx ~ line 373 ~ AdminSalesWrapperHoc ~ getSalesPersonBySearchText= ~ err',
					err
				);
			} finally {
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.None
				});
			}
			return;
		};
		onChangeSalesPersonAutocomplete = (ev: unknown, value: ISalesPerson | null, reason: AutocompleteChangeReason) => {
			if (!value) {
				return;
			}
			this.viewReservations(value.id);
		};
		fitMapToBoundsWithPadding = (bounds: IGeoBound, padding = 20) => {
			const mapboxBounds = UtilityService.getMapBoxBoundingBox(bounds);
			//this.map.on('moveend', this.restrictMapToCdaLevel);
			this.map.fitBounds(mapboxBounds, { padding });
		};

		onClickSubmitSaleAction = async (data: ISaleVerificationModalSubmit) => {
			this.submitTransaction(data);
		};

		onCloseSubmissionErrorsModal = () => {
			this.setState({
				activeModal: ESalesModals.None
			});
		};

		submitTransaction = async (data: ISaleVerificationModalSubmit) => {
			const reqPayload = this.createSalesTransactionRequestPayload(data);
			/* const isTransaction = reqPayload.is_transaction;
			delete reqPayload.is_transaction; */
			try {
				let responseRecord: any = null;
				this.setState({
					loadingIndicator: ESa1SalesLoaders.SalesTransactionSubmitLoader
				});

				/**
				 * ideally the endpoint should be different for all 4 action types or same for
				 * 4 with different body params. But that is ideal world.
				 */
				if (this.state.onGoingAreaSaleActionType === ERevAreaSaleType.ExtendReservation) {
					await CommonApiService.submitReserveExtensionTransaction(reqPayload);
				} else if (this.state.onGoingAreaSaleActionType === ERevAreaSaleType.Sell) {
					await CommonApiService.submitSalesSellTransaction(reqPayload);
				} else if (this.state.onGoingAreaSaleActionType === ERevAreaSaleType.Release) {
					await CommonApiService.submitSalesReleaseTransaction(reqPayload);
				} else {
					responseRecord = await CommonApiService.submitSalesReserveTransaction(reqPayload);
				}
				let updatedSalesAreaList = this.getSelectedAreasWithUpdatedSaleStatus();
				const updatedCdar = this.getCdarWithUpdatedSaleStatus();

				if (
					responseRecord &&
					responseRecord.success &&
					this.state.onGoingAreaSaleActionType === ERevAreaSaleType.Reserve
				) {
					updatedSalesAreaList = this.getSelectedAreasWithUpdatedReservationExpiry(
						responseRecord.t_reservation_expiry_at,
						updatedSalesAreaList
					);
				}

				this.showSuccessToaster(
					this.state.onGoingAreaSaleActionType as ERevAreaSaleType,
					reqPayload as ISalesTransactionRequestBody
				);

				this.setState(
					{
						onGoingAreaSaleActionType: null,
						/* salesAreaList: updatedSalesAreaList, */
						salesAreaList: [],
						cdar: updatedCdar
					},
					() => this.showTransactionStatusLayersOnMap()
				);

				this.setState(
					{
						loadingIndicator: ELoadingIndicatorStatus.None,
						activeModal: ESalesModals.None
					},
					() => {
						this.refreshMapArea();
					}
				);
			} catch (error) {
				//throw new Error(error);
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.None
				});
			}
		};

		refreshMapArea = async () => {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.ShowFullAreaLoader
			});
			await this.getCdarSa1s(this.state.selectedAusStateId as number);
			this.showRoyaltyCdarByCdaOnMap();
			this.showSa1WithinNonRoyaltyCdarOnMap();
			this.showTransactionStatusLayersOnMap();
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		};

		getCdarSa1s = async (stateId: number) => {
			try {
				const res = await CdarService.getCdarSa1sByStateId(stateId);
				let cdar = res as ICdarWithAgency[];

				//sa1sAdded and sa1sRemoved are required client side for sa1 map updates. They are not
				//sent in response model. Hence to make sure both arrays are initialized correctly, we setup up
				//default values here
				cdar = cdar.map((cdar) => {
					return { ...cdar, sa1sAdded: [], sa1sRemoved: [] };
				});
				this.setState({
					cdar
				});
			} catch (err) {
				console.error(err);
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.None
				});
			}
			return;
		};

		showSuccessToaster = (transactionType: ERevAreaSaleType, selectedArea: any) => {
			let toasterMsg: string;
			const transactionTypeString = this.getTransactionTypeForToaster(transactionType);
			if (selectedArea.cdar_id) {
				const cdar = this.state.cdar.find((cdar) => cdar.cdar_id === selectedArea.cdar_id);
				if (!cdar) {
					throw new Error('Unable to find cdar to which the SA1 belongs');
				}
				toasterMsg = `${cdar.name} Cdar ${transactionTypeString} successfully`;
			} else {
				toasterMsg = `${selectedArea.sa1s.length} Sa1(s) ${transactionTypeString} successfully`;
			}
			this.props.NotificationContextValue.addNotification({
				message: toasterMsg,
				type: ENotificationTypes.Success
			});
		};

		getTransactionTypeForToaster = (transactionType: ERevAreaSaleType) => {
			if (transactionType === ERevAreaSaleType.Release) {
				return 'Released';
			} else if (transactionType === ERevAreaSaleType.ExtendReservation) {
				return 'Reservation extended';
			} else if (transactionType === ERevAreaSaleType.Reserve) {
				return 'Reserved';
			}
			return 'Sold';
		};

		//Responsible for updating ReservationExpiry for areas that are being displayed in list and were selected
		//for submitting transaction and for display ReservationExpiry in case of Extend Reservation transaction
		//does not actually update the state, but returns the new array with updated values
		getSelectedAreasWithUpdatedReservationExpiry = (
			reservationExpiryAt: string,
			updatedSalesAreaList: ISalesAreaListItem[]
		) => {
			[...updatedSalesAreaList].forEach((area) => {
				area.reservationExpiry = reservationExpiryAt;
			});
			return updatedSalesAreaList;
		};

		//responsible for updating status for areas that are being displayed in list and were selected
		//for submitting transaction
		//does not actually update the state, but returns the new array with updated values
		getSelectedAreasWithUpdatedSaleStatus = () => {
			const saleType = this.state.onGoingAreaSaleActionType;
			if (!saleType) {
				throw new Error('Cannot find retrieve sale action type');
			}
			const areas = this.state.salesAreaList.map((a) => {
				//break reference pointer to prevent wierd errors
				return { ...a };
			});

			areas.forEach((area) => {
				if (area.selected) {
					area.status = this.getAreaSaleStatusFromSaleType(saleType);
				}
				area.selected = false;
			});

			return areas;
		};

		getAreaSaleStatusFromSaleType = (saleType: ERevAreaSaleType): ERevAreaSaleStatus | null => {
			let status = null;
			if (saleType === ERevAreaSaleType.Sell) {
				status = ERevAreaSaleStatus.Sold;
			} else if (saleType === ERevAreaSaleType.Reserve || saleType === ERevAreaSaleType.ExtendReservation) {
				status = ERevAreaSaleStatus.Reserved;
			}
			return status;
		};

		//updates sale status of cdar (royalty + non royalty)
		getCdarWithUpdatedSaleStatus = () => {
			const saleType = this.state.onGoingAreaSaleActionType;
			if (!saleType) {
				throw new Error('Cannot find retrieve sale action type');
			}
			const royaltyCdar = this.state.salesAreaList.filter((area) => (area.type = ESalesAreaListItemType.Cdar));
			const nonRoyaltySa1s = this.state.salesAreaList.filter((area) => (area.type = ESalesAreaListItemType.Sa1));

			//loop over all items in sa1 sales list and update those whose sale status have been changed
			//entire royalty cdar or individual sa1 in non royalty cdar might have been changed
			const cdars = this.state.cdar.map((cdar) => {
				const newCdar = { ...cdar };

				//check if cdar requires status update (this will be royalty cdar)
				const requiresUpdate = lodashFind(royaltyCdar, { id: newCdar.cdar_id });
				if (requiresUpdate) {
					newCdar.status = this.getAreaSaleStatusFromSaleType(saleType);
				}

				//figure out if cdar has sa1 whose sale status has changed (i.e. this should be a non royalty cdar)
				const hasCdarSa1StatusChanged = lodashFind(nonRoyaltySa1s, { cdarId: newCdar.cdar_id });
				if (hasCdarSa1StatusChanged) {
					//cdar has 1 or more sa1 that require status updates
					const newSa1s = newCdar.sa1s.map((sa1) => {
						const newSa1 = { ...sa1 };
						//check if this sa1 requires updates and return as is if sa1 does not require status update
						const hasSa1StatusChanged = lodashFind(nonRoyaltySa1s, { id: sa1.sa1_code });

						if (hasSa1StatusChanged) {
							newSa1.status = this.getAreaSaleStatusFromSaleType(saleType);
						}

						return newSa1;
					});

					newCdar.sa1s = newSa1s;
				}

				return newCdar;
			});

			return cdars;
		};

		/**
		 * This function assumes that all validations w.r.t data that is being sent for submission are already
		 * done here and that data is valid. It just does the job of preparing the request payload in format
		 * required by the API
		 */
		createSalesTransactionRequestPayload = (data: ISaleVerificationModalSubmit): ISalesTransactionRequestBody => {
			if (!this.state.onGoingAreaSaleActionType) {
				throw new Error('Could not determine transaction action type when generating request payload');
			}

			const { agencyId, note, reserveExtendDays, prospect_agency } = data;
			const areasToSubmit = this.state.areasEligibleForSubmission;
			const areAreasRoyaltyCdars = areasToSubmit.find((area) => area.type === ESalesAreaListItemType.Cdar);
			const areAreasNonRoyaltySa1s = areasToSubmit.find((area) => area.type === ESalesAreaListItemType.Sa1);
			const requestPayload: { [key: string]: any } = {
				status: this.state.onGoingAreaSaleActionType,
				agency_id: agencyId,
				note: note,
				/* is_pin_cdar: pinCdar, */
				no_of_days: reserveExtendDays,
				prospect_agency
				/* is_transaction: areasToSubmit[0].transaction_id ? true : false */
			};

			if (areAreasRoyaltyCdars) {
				requestPayload.cdar_id = areAreasRoyaltyCdars.id;
			} else if (areAreasNonRoyaltySa1s) {
				requestPayload.sa1s = areasToSubmit.map((area) => area.id);
			}

			return requestPayload as ISalesTransactionRequestBody;
		};
		onClickCancelSubmission = () => {
			this.setState({
				activeModal: ESalesModals.None
			});
		};

		onAreaListItemMouseOver = (area: ISalesAreaListItem) => {
			if (area.type === ESalesAreaListItemType.Sa1) {
				this.updateSa1MapFeatureState(area.id, { hover: true });
			} else if (area.type === ESalesAreaListItemType.Cdar) {
				this.updateCdarMapFeatureState(area.id, { hover: true });
			}
		};

		onAreaListItemMouseOut = (area: ISalesAreaListItem) => {
			if (area.type === ESalesAreaListItemType.Sa1) {
				this.updateSa1MapFeatureState(area.id, { hover: false });
			} else if (area.type === ESalesAreaListItemType.Cdar) {
				this.updateCdarMapFeatureState(area.id, { hover: false });
			}
		};
		onClickSelectAllSalesAreas = () => {
			const newSalesAreaList = this.state.salesAreaList.map((area) => {
				return { ...area, selected: true };
			});
			this.setState({
				salesAreaList: newSalesAreaList
			});
		};

		onClickRemoveAllSelectedAreaListItems = () => {
			// eslint-disable-next-line array-callback-return
			this.state.salesAreaList.map((area) => {
				if (area.type === ESalesAreaListItemType.Sa1) {
					this.updateSa1MapFeatureState(area.id, { isSelected: false });
					this.showTransactionStatusLayersOnMapForSa1(area.id);
				} else if (area.type === ESalesAreaListItemType.Cdar) {
					this.updateCdarMapFeatureState(area.id, { isSelected: false });
					this.showTransactionStatusLayersOnMapForRoyaltyCdar(area.id);
				}
			});

			this.setState({
				salesAreaList: []
			});
		};
		showTransactionStatusLayersOnMapForRoyaltyCdar = (cdarId: number) => {
			const cdar = this.state.cdar.find((cdar) => cdar.cdar_id === cdarId);
			if (!cdar) {
				throw new Error('Unable to find cdar to which the SA1 belongs');
			}

			if (cdar.status === ERevAreaSaleStatus.Sold) {
				this.updateCdarMapFeatureState(cdarId, { isSold: true });
			} else if (cdar.status === ERevAreaSaleStatus.Reserved) {
				this.updateCdarMapFeatureState(cdarId, { isReserved: true });
			}
		};
		showTransactionStatusLayersOnMapForSa1 = (sa1Code: number) => {
			const cdar = lodashFind(this.state.cdar, { sa1s: [{ sa1_code: sa1Code }] });
			if (!cdar) {
				throw new Error('Unable to find cdar to which the SA1 belongs');
			}
			const sa1Details = lodashFind(cdar?.sa1s, { sa1_code: sa1Code });
			if (!sa1Details) {
				throw new Error('Unable to find sa1 details');
			}
			if (sa1Details.status === ERevAreaSaleStatus.Sold) {
				this.updateSa1MapFeatureState(sa1Code, { isSold: true });
			} else if (sa1Details.status === ERevAreaSaleStatus.Reserved) {
				this.updateSa1MapFeatureState(sa1Code, { isReserved: true });
			}
		};

		getAreasEligibleForSubmitAction = (action: ERevAreaSaleType) => {
			const aresSelectedForSubmit = this.state.salesAreaList.filter((area) => area.selected);
			return aresSelectedForSubmit;
		};
		getAreasNonEligibleForSubmitAction = (action: ERevAreaSaleType) => {
			const aresSelectedForSubmit = this.state.salesAreaList.filter((area) => area.selected);
			let eligibleAreas: ISalesAreaListItem[] = [];

			if (action === ERevAreaSaleType.Sell) {
				eligibleAreas = aresSelectedForSubmit.filter((a) => a.status === ERevAreaSaleStatus.Reserved);
			}

			if (action === ERevAreaSaleType.Reserve) {
				eligibleAreas = aresSelectedForSubmit.filter((a) => a.status === ERevAreaSaleStatus.Sold);
			}

			if (action === ERevAreaSaleType.Release) {
				//when status=null that means it is available, so we check for truthy status value
				eligibleAreas = aresSelectedForSubmit.filter((a) => !a.status);
			}

			if (action === ERevAreaSaleType.ExtendReservation) {
				//when status=null that means it is available, so we check for truthy status value
				eligibleAreas = aresSelectedForSubmit.filter((a) => a.status !== ERevAreaSaleStatus.Reserved);
			}

			return eligibleAreas;
		};

		onClickReleaseArea = () => {
			const nonEligibleAreas = this.getAreasNonEligibleForSubmitAction(ERevAreaSaleType.Release);
			if (nonEligibleAreas.length !== 0) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message: `There are areas that are non eligible for "${ERevAreaSaleType.Release.toUpperCase()}" transaction from the list of areas that you have selected. Please change the areas selected and try again.`,
							invalidAreas: nonEligibleAreas
						}
					]
				});
				return;
			}
			const eligibleAreas = this.getAreasEligibleForSubmitAction(ERevAreaSaleType.Release);
			if (eligibleAreas.length === 0) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message: `There are no areas that are eligible for "${ERevAreaSaleType.Release.toUpperCase()}" transaction from the list of areas that you have selected. Please change the areas selected and try again.`
						}
					]
				});
				return;
			}

			const areaTypeValidation = this.validateAreaTypeForSubmit(eligibleAreas);
			if (!areaTypeValidation) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message: `The system allows either multiple SA1's  or a Royalty area to be submitted for "${ERevAreaSaleType.Release.toUpperCase()}" transaction but not both. Your current selection contains both of them. Please change the selected areas and try again.`
						}
					]
				});
				return;
			}
			const multipleCdarSelection = this.isMultipleCdarSelected(eligibleAreas);
			if (!multipleCdarSelection) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message: `The system allows single Royalty area to be submitted for "${ERevAreaSaleType.Reserve.toUpperCase()}" transaction but not multiple. Your current selection contains multiple Royalty . Please select only one Royalty and try again.`
						}
					]
				});
				return;
			}

			this.setState({
				onGoingAreaSaleActionType: ERevAreaSaleType.Release,
				activeModal: ESalesModals.SubmissionVerificationModal,
				areasEligibleForSubmission: eligibleAreas
			});
		};
		onClickCopySA1 = () => {
			const selectedAreas = this.state.salesAreaList.filter((area) => area.selected);
			const allSa1s = this.getAllSa1FromSalesAreaSelectedListItem(selectedAreas);
			navigator.clipboard.writeText(allSa1s.join(', ')).then(() =>
				this.props.NotificationContextValue.addNotification({
					message: 'Selected SA1 copied to clipboard.',
					type: ENotificationTypes.Success
				})
			);
		};
		getAllSa1FromSalesAreaSelectedListItem = (selectedAreas: ISalesAreaListItem[]) => {
			const selSA1: number[] = [];
			selectedAreas.forEach((selAreaItem: ISalesAreaListItem) => {
				if (selAreaItem.type === ESalesAreaListItemType.Cdar) {
					const selCdar = this.state.cdar.find((cdar) => selAreaItem.id === cdar.cdar_id);
					if (!selCdar) {
						throw new Error(`Could not find cdar ${selAreaItem.id}`);
					}
					const selSA1s = selCdar.sa1s.map((item: ISa1) => {
						return item.sa1_code;
					});
					selSA1.push(...selSA1s);
				} else {
					selSA1.push(selAreaItem.id);
				}
			});
			return selSA1.flat();
		};
		onClickSellArea = () => {
			const eligibleAreas = this.getAreasEligibleForSubmitAction(ERevAreaSaleType.Sell);

			if (eligibleAreas.length === 0) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message:
								'There are no areas that are eligible for "SELL" transaction from the list of areas that you have selected. Please change the areas selected and try again.'
						}
					]
				});
				return;
			}

			const areaTypeValidation = this.validateAreaTypeForSubmit(eligibleAreas);
			if (!areaTypeValidation) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message:
								"The system allows either multiple SA1's  or a Royalty area to be submitted for 'SELL' transaction but not both. Your current selection contains both of them. Please change the selected areas and try again."
						}
					]
				});
				return;
			}
			const multipleCdarSelection = this.isMultipleCdarSelected(eligibleAreas);
			if (!multipleCdarSelection) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message: `The system allows single Royalty area to be submitted for "${ERevAreaSaleType.Sell.toUpperCase()}" transaction but not multiple. Your current selection contains multiple Royalty . Please select only one Royalty and try again.`
						}
					]
				});
				return;
			}

			this.setState({
				onGoingAreaSaleActionType: ERevAreaSaleType.Sell,
				activeModal: ESalesModals.SubmissionVerificationModal,
				areasEligibleForSubmission: eligibleAreas
			});
		};

		onClickReserveArea = () => {
			const nonEligibleAreas = this.getAreasNonEligibleForSubmitAction(ERevAreaSaleType.Reserve);
			if (nonEligibleAreas.length !== 0) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message: `There are areas that are non eligible for "${ERevAreaSaleType.Reserve.toUpperCase()}" transaction from the list of areas that you have selected. Please change the areas selected and try again.`,
							invalidAreas: nonEligibleAreas
						}
					]
				});
				return;
			}
			const eligibleAreas = this.getAreasEligibleForSubmitAction(ERevAreaSaleType.Reserve);
			if (eligibleAreas.length === 0) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message: `There are no areas that are eligible for "${ERevAreaSaleType.Reserve.toUpperCase()}" transaction from the list of areas that you have selected. Please change the areas selected and try again.`
						}
					]
				});
				return;
			}

			const areaTypeValidation = this.validateAreaTypeForSubmit(eligibleAreas);
			if (!areaTypeValidation) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message: `The system allows either multiple SA1's  or a Royalty area to be submitted for "${ERevAreaSaleType.Reserve.toUpperCase()}" transaction but not both. Your current selection contains both of them. Please change the selected areas and try again.`
						}
					]
				});
				return;
			}
			const multipleCdarSelection = this.isMultipleCdarSelected(eligibleAreas);
			if (!multipleCdarSelection) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message: `The system allows single Royalty area to be submitted for "${ERevAreaSaleType.Reserve.toUpperCase()}" transaction but not multiple. Your current selection contains multiple Royalty . Please select only one Royalty and try again.`
						}
					]
				});
				return;
			}

			this.setState({
				onGoingAreaSaleActionType: ERevAreaSaleType.Reserve,
				activeModal: ESalesModals.SubmissionVerificationModal,
				areasEligibleForSubmission: eligibleAreas
			});
		};

		//as per current rules, we can submit single royalty cdar
		isMultipleCdarSelected = (areas: ISalesAreaListItem[]) => {
			const royaltyCdarCount = areas.filter((a) => a.type === ESalesAreaListItemType.Cdar).length;
			if (royaltyCdarCount > 1) {
				return false;
			} else {
				return true;
			}
		};
		onClickExtendReservation = () => {
			const nonEligibleAreas = this.getAreasNonEligibleForSubmitAction(ERevAreaSaleType.ExtendReservation);
			if (nonEligibleAreas.length !== 0) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message: `The following areas are in-eligible for extending reservation. Please remove then and try again.`,
							invalidAreas: nonEligibleAreas
						}
					]
				});
				return;
			}
			const eligibleAreas = this.getAreasEligibleForSubmitAction(ERevAreaSaleType.ExtendReservation);
			if (eligibleAreas.length === 0) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message: `There are no areas that are eligible for "${ERevAreaSaleType.ExtendReservation.toUpperCase()}" transaction from the list of areas that you have selected. Please change the areas selected and try again.`
						}
					]
				});
				return;
			}

			const areaTypeValidation = this.validateAreaTypeForSubmit(eligibleAreas);
			if (!areaTypeValidation) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message: `The system allows either multiple SA1's  or a Royalty area to be submitted for "${ERevAreaSaleType.Reserve.toUpperCase()}" transaction but not both. Your current selection contains both of them. Please change the selected areas and try again.`
						}
					]
				});
				return;
			}
			const multipleCdarSelection = this.isMultipleCdarSelected(eligibleAreas);
			if (!multipleCdarSelection) {
				this.setState({
					activeModal: ESalesModals.SubmissionErrorModal,
					submissionErrors: [
						{
							message: `The system allows single Royalty area to be submitted for "${ERevAreaSaleType.ExtendReservation.toUpperCase()}" transaction but not multiple. Your current selection contains multiple Royalty . Please select only one Royalty and try again.`
						}
					]
				});
				return;
			}

			this.setState({
				onGoingAreaSaleActionType: ERevAreaSaleType.ExtendReservation,
				activeModal: ESalesModals.SubmissionVerificationModal,
				areasEligibleForSubmission: eligibleAreas
			});
		};

		validateAreaTypeForSubmit = (areas: ISalesAreaListItem[]) => {
			const royaltyCdarCount = areas.filter((a) => a.type === ESalesAreaListItemType.Cdar).length;
			const nonRoyaltySa1Count = areas.filter((a) => a.type === ESalesAreaListItemType.Sa1).length;

			if (royaltyCdarCount > 0 && nonRoyaltySa1Count > 0) {
				return false;
			} else {
				return true;
			}
		};

		onClickRemoveSelectedAreaListItem = (clickedArea: ISalesAreaListItem) => {
			const newSalesAreaList = this.state.salesAreaList.filter((area) => area.id !== clickedArea.id);

			if (clickedArea.type === ESalesAreaListItemType.Sa1) {
				this.updateSa1MapFeatureState(clickedArea.id, { isSelected: false });
				this.showTransactionStatusLayersOnMapForSa1(clickedArea.id);
			} else if (clickedArea.type === ESalesAreaListItemType.Cdar) {
				this.updateCdarMapFeatureState(clickedArea.id, { isSelected: false });
				this.showTransactionStatusLayersOnMapForRoyaltyCdar(clickedArea.id);
			}

			this.setState({
				salesAreaList: newSalesAreaList
			});
		};
		onClickSelectedAreaListItem = (clickedArea: ISalesAreaListItem) => {
			this.toggleSelectAreaListItemSelection(clickedArea);
		};
		onClickDeSelectAllSalesAreas = () => {
			const newSalesAreaList = this.state.salesAreaList.map((area) => {
				return { ...area, selected: false };
			});
			this.setState({
				salesAreaList: newSalesAreaList
			});
		};

		toggleSelectAreaListItemSelection = (clickedArea: ISalesAreaListItem) => {
			const newSalesAreaList = this.state.salesAreaList.map((area) => {
				if (area.id !== clickedArea.id) {
					return area;
				}

				//the only thing that changes on click is the selected state of list item
				return {
					...area,
					selected: !area.selected
				};
			});

			this.setState({
				salesAreaList: newSalesAreaList
			});
		};

		updateMapAfterStateSelect = (stateId: number) => {
			return new Promise<void>((resolve, reject) => {
				const isMapZoom = this.isMapShouldZoom(stateId);
				this.setState(
					{
						selectedAusStateId: stateId,
						salesAreaList: []
					},
					async () => {
						this.navigateMapToStateZoomLevel(isMapZoom);
						await this.getCdarSa1sByState(stateId);
						this.showRoyaltyCdarByCdaOnMap();
						this.showSa1WithinNonRoyaltyCdarOnMap();
						this.showTransactionStatusLayersOnMap();
						resolve();
					}
				);
			});
		};

		selectState = async (stateId: number) => {
			if (
				this.state.selectedAusStateId &&
				stateId !== this.state.selectedAusStateId &&
				this.state.salesAreaList.length > 0
			) {
				this.setState({
					isShowConfirmModal: true,
					tmpStateId: stateId
				});
				return;
			}
			await this.onSelectStateConfirm(stateId);
		};

		isMapShouldZoom = (stateId: number) => {
			if (!this.state.selectedAusStateId) {
				return true;
			}
			return this.state.selectedAusStateId !== stateId ? true : false;
		};

		onSelectStateConfirm = (stateId: number) => {
			return new Promise<void>((resolve, reject) => {
				const isMapZoom = this.isMapShouldZoom(stateId);
				this.setState(
					{
						selectedAusStateId: stateId,
						cdar: [],
						agencies: [],
						prospectiveCustomerOptions: [],
						salesPersons: [],
						selectedAgency: null,
						autocomplete: {
							agency: '',
							salesPerson: '',
							prospectiveCustomer: '',
							sa1Suburb: ''
						},
						salesAreaList: [],
						sa1SuburbOptions: []
					},
					async () => {
						this.navigateMapToStateZoomLevel(isMapZoom);
						await this.getCdarSa1sByState(stateId);
						this.showRoyaltyCdarByCdaOnMap();
						this.showSa1WithinNonRoyaltyCdarOnMap();
						this.showTransactionStatusLayersOnMap();
						//this.showAllCdarOutlineByCdaOnMap();
						resolve();
					}
				);
			});
		};

		showRoyaltyCdarByCdaOnMap = () => {
			let royaltyCdarIds = this.state.cdar.filter((cdar) => cdar.is_royalty).map((cdar) => cdar.cdar_id);

			if (royaltyCdarIds.length === 0) {
				royaltyCdarIds = [0];
			}

			//update layer filter (async operation as it goes to GPU via worker thread)
			this.map.setFilter(EMapLayers.CdarFill, ['match', ['get', 'cdar_id'], royaltyCdarIds, true, false]);
			this.map.setFilter(EMapLayers.CdarLabel, ['match', ['get', 'cdar_id'], royaltyCdarIds, true, false]);
			this.map.setFilter(EMapLayers.RoyaltyCdarFillPattern, ['match', ['get', 'cdar_id'], royaltyCdarIds, true, false]);
			this.map.setFilter(EMapLayers.CdarOutline, ['match', ['get', 'cdar_id'], royaltyCdarIds, true, false]);
		};
		showSa1WithinNonRoyaltyCdarOnMap = () => {
			let sa1Ids = this.state.cdar
				.filter((cdar) => !cdar.is_royalty)
				.flatMap((cdar) => {
					return cdar.sa1s.map((sa1) => sa1.sa1_code);
				});

			if (sa1Ids.length === 0) {
				sa1Ids = [0];
			}

			//update layer filter (async operation as it goes to GPU via worker thread)
			this.map.setFilter(EMapLayers.Sa1Fill, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
			this.map.setFilter(EMapLayers.Sa1Label, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
			this.map.setFilter(EMapLayers.Sa1HoverOutline, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
			this.map.setFilter(EMapLayers.Sa1Outline, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
		};

		showTransactionStatusLayersOnMap = () => {
			const royaltyCdars = this.state.cdar.filter((cdar) => cdar.is_royalty);
			const nonRoyaltySa1s = this.state.cdar
				.filter((cdar) => !cdar.is_royalty)
				.flatMap((cdar) => cdar.sa1s.map((sa1) => sa1));
			const soldCdarIds: number[] = [0];
			const reservedCdarIds: number[] = [0];
			const availableCdarIds: number[] = [0];
			const soldSa1Codes: number[] = [0];
			const reservedSa1Codes: number[] = [0];
			const availableSa1Codes: number[] = [0];

			royaltyCdars.forEach((rCdar) => {
				if (rCdar.status === ERevAreaSaleStatus.Sold) {
					soldCdarIds.push(rCdar.cdar_id);
				} else if (rCdar.status === ERevAreaSaleStatus.Reserved) {
					reservedCdarIds.push(rCdar.cdar_id);
				} else {
					availableCdarIds.push(rCdar.cdar_id);
				}
			});

			nonRoyaltySa1s.forEach((sa1) => {
				if (sa1.status === ERevAreaSaleStatus.Sold) {
					soldSa1Codes.push(sa1.sa1_code);
				} else if (sa1.status === ERevAreaSaleStatus.Reserved) {
					reservedSa1Codes.push(sa1.sa1_code);
				} else {
					availableSa1Codes.push(sa1.sa1_code);
				}
			});

			soldSa1Codes.forEach((sa1Code) => this.updateSa1MapFeatureState(sa1Code, { isSold: true, isSelected: false }));
			reservedSa1Codes.forEach((sa1Code) =>
				this.updateSa1MapFeatureState(sa1Code, { isReserved: true, isSelected: false })
			);
			availableSa1Codes.forEach((sa1Code) =>
				this.updateSa1MapFeatureState(sa1Code, { isReserved: false, isSold: false, isSelected: false })
			);
			soldCdarIds.forEach((cdarId) => this.updateCdarMapFeatureState(cdarId, { isSold: true, isSelected: false }));
			reservedCdarIds.forEach((cdarId) =>
				this.updateCdarMapFeatureState(cdarId, { isReserved: true, isSelected: false })
			);
			availableCdarIds.forEach((cdarId) =>
				this.updateCdarMapFeatureState(cdarId, { isReserved: false, isSold: false, isSelected: false })
			);
		};

		showAllCdarOutlineByCdaOnMap = () => {
			let nonRoyaltyCdarIds = this.state.cdar.map((cdar) => cdar.cdar_id);

			if (nonRoyaltyCdarIds.length === 0) {
				nonRoyaltyCdarIds = [0];
			}

			//update layer filter (async operation as it goes to GPU via worker thread)
			this.map.setFilter(EMapLayers.CdarOutline, ['match', ['get', 'cdar_id'], nonRoyaltyCdarIds, true, false]);
		};

		getCdarSa1sByState = async (stateId: number) => {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.ShowNavBarLoader
			});
			try {
				const res = await CdarService.getCdarSa1sByStateId(stateId);
				let cdar = res as ICdarWithAgency[];

				//sa1sAdded and sa1sRemoved are required client side for sa1 map updates. They are not
				//sent in response model. Hence to make sure both arrays are initialized correctly, we setup up
				//default values here
				cdar = cdar.map((cdar) => {
					return { ...cdar, sa1sAdded: [], sa1sRemoved: [] };
				});
				this.setState({
					cdar,
					loadingIndicator: ELoadingIndicatorStatus.None
				});
			} catch (err) {
				console.error(err);
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.None
				});
			}
			return;
		};

		navigateMapToStateZoomLevel = (isMapZoom = true) => {
			this.hideAllNonRoyaltySa1OnMap();
			this.hideAllRoyaltyCdarOnMap();
			this.hideAllTransactionStatusLayersOnMap();
			this.fitMapToStateBounds(isMapZoom);
			this.currentZoomLevel = EMapZoomLevels.State;
		};
		hideAllNonRoyaltySa1OnMap = () => {
			this.map.setFilter(EMapLayers.Sa1Fill, ['match', ['get', 'sa1_code'], [0], true, false]);
			this.map.setFilter(EMapLayers.Sa1Label, ['match', ['get', 'sa1_code'], [0], true, false]);
			this.map.setFilter(EMapLayers.Sa1HoverOutline, ['match', ['get', 'sa1_code'], [0], true, false]);
			this.map.setFilter(EMapLayers.Sa1Outline, ['match', ['get', 'sa1_code'], [0], true, false]);
		};
		hideAllRoyaltyCdarOnMap = () => {
			this.map.setFilter(EMapLayers.CdarFill, ['match', ['get', 'cdar_id'], [0], true, false]);
			this.map.setFilter(EMapLayers.CdarLabel, ['match', ['get', 'cdar_id'], [0], true, false]);
			this.map.setFilter(EMapLayers.CdarOutline, ['match', ['get', 'cdar_id'], [0], true, false]);
			this.map.setFilter(EMapLayers.RoyaltyCdarFillPattern, ['match', ['get', 'cdar_id'], [0], true, false]);
		};

		hideAllTransactionStatusLayersOnMap = () => {
			const royaltyCdars = this.state.cdar.filter((cdar) => cdar.is_royalty);
			const nonRoyaltySa1s = this.state.cdar
				.filter((cdar) => !cdar.is_royalty)
				.flatMap((cdar) => cdar.sa1s.map((sa1) => sa1));
			const soldCdarIds: number[] = [0];
			const reservedCdarIds: number[] = [0];
			const soldSa1Codes: number[] = [0];
			const reservedSa1Codes: number[] = [0];

			royaltyCdars.forEach((rCdar) => {
				if (rCdar.status === ERevAreaSaleStatus.Sold) {
					soldCdarIds.push(rCdar.cdar_id);
				} else if (rCdar.status === ERevAreaSaleStatus.Reserved) {
					reservedCdarIds.push(rCdar.cdar_id);
				}
			});

			nonRoyaltySa1s.forEach((sa1) => {
				if (sa1.status === ERevAreaSaleStatus.Sold) {
					soldSa1Codes.push(sa1.sa1_code);
				} else if (sa1.status === ERevAreaSaleStatus.Reserved) {
					reservedSa1Codes.push(sa1.sa1_code);
				}
			});

			soldSa1Codes.forEach((sa1Code) => this.updateSa1MapFeatureState(sa1Code, { isSold: false }));
			reservedSa1Codes.forEach((sa1Code) => this.updateSa1MapFeatureState(sa1Code, { isReserved: false }));
			soldCdarIds.forEach((cdarId) => this.updateCdarMapFeatureState(cdarId, { isSold: false }));
			reservedCdarIds.forEach((cdarId) => this.updateCdarMapFeatureState(cdarId, { isReserved: false }));
		};

		fitMapToStateBounds = (isMapZoom: boolean) => {
			/* if (this.map.getZoom() < 5 || !isMapZoom) { */
			if (!isMapZoom) {
				return;
			}
			const state = this.getSelectedState();
			const bbox = UtilityService.getMapBoxBoundingBox(state.bounds as IGeoBound);
			this.map.fitBounds(bbox);
		};

		getSelectedState = () => {
			const state = this.state.ausStates.find((s) => this.state.selectedAusStateId === s.id);
			if (!state) {
				throw new Error('Unable to find state for showing suburbs');
			}
			return state;
		};

		getAustralianStates = async () => {
			try {
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.ShowAreaLoader
				});
				const states = await CommonApiService.getAustraliaStates();
				this.setState({
					ausStates: states
				});
			} finally {
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.None
				});
			}
		};
		getInProgressMapServiceSyncJob = async () => {
			let job = null;
			try {
				const syncJobs = await CommonApiService.getMapServiceSyncJobs();
				job = syncJobs.find((job) => job.status === EMapServiceJobStatus.InProgress);
			} catch (e) {
				console.log('Failed to retrieve map service sync jobs');
			}

			return job;
		};

		getSystemMapColors = async () => {
			const apiRes: any = await CommonApiService.getSystemSettings();
			if (!apiRes?.success) {
				return;
			}
			this.setState({
				mapColors: apiRes.data[0].json.MapColors,
				mapSystems: apiRes.data[1].json.System,
				isShowMap: true
			});
		};
		onMapLoadComplete = (map: mapboxgl.Map) => {
			this.map = map;
			this.addMapSources();
			this.addMapLayers();
			this.registerMapEvents();
		};

		addMapSources = () => {
			this.map.addSource('sa1', {
				type: 'vector',
				url: `mapbox://${Config.MapTilesets.AustraliaSa1.Id}?optimize=true`,
				promoteId: { [Config.MapTilesets.AustraliaSa1.SourceLayer]: 'sa1_code' }
			});

			this.map.addSource('cdar', {
				type: 'vector',
				url: `mapbox://${Config.MapTilesets.AustraliaCdar.Id}?optimize=true`,
				promoteId: { [Config.MapTilesets.AustraliaCdar.SourceLayer]: 'cdar_id' }
			});
			this.map.addSource('state', {
				type: 'vector',
				url: `mapbox://${Config.MapTilesets.AustraliaState.Id}?optimize=true`,
				promoteId: { [Config.MapTilesets.AustraliaState.SourceLayer]: 'state_id' }
			});
		};
		addMapLayers = () => {
			//State
			this.map.addLayer({
				id: EMapLayers.StateFill,
				type: 'fill',
				source: 'state',
				'source-layer': Config.MapTilesets.AustraliaState.SourceLayer,
				/* maxzoom: 5, */
				paint: {
					'fill-color': Config.MapColors.available,
					'fill-outline-color': Config.MapColors.available
				}
			});

			this.map.addLayer(
				{
					id: EMapLayers.StateOutline,
					type: 'line',
					source: 'state',
					/* maxzoom: 5, */
					'source-layer': Config.MapTilesets.AustraliaState.SourceLayer,
					layout: {
						'line-cap': 'square',
						'line-join': 'bevel',
						'line-round-limit': 1.05,
						'line-miter-limit': 2
					},
					paint: {
						'line-color': this.state.mapColors['state']['outline'],
						'line-width': 1
						//'line-width': ['case', ['boolean', ['feature-state', 'clicked'], false], 4, 2],
						/* 'line-dasharray': [2, 1] */
					}
				},
				'road-label'
			);

			this.map.addLayer({
				id: EMapLayers.StateHoverOutline,
				type: 'line',
				source: 'state',
				/* maxzoom: 5, */
				'source-layer': Config.MapTilesets.AustraliaState.SourceLayer,
				layout: {
					'line-cap': 'round',
					'line-join': 'round',
					'line-round-limit': 1.05
				},
				paint: {
					'line-color': this.state.mapColors['state']['outline'],
					'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.5, 0],
					'line-offset': 2,
					'line-width': 3
				}
			});

			//SA1
			this.map.addLayer(
				{
					id: EMapLayers.Sa1Fill,
					type: 'fill',
					source: 'sa1',
					'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
					filter: ['match', ['get', 'sa1_code'], [''], true, false],
					paint: {
						'fill-color': [
							'case',
							['boolean', ['feature-state', 'isSold'], false],
							this.state.mapColors['sales']['sold'],
							['boolean', ['feature-state', 'isReserved'], false],
							this.state.mapColors['sales']['reserved'],
							['boolean', ['feature-state', 'isSelected'], false],
							this.state.mapColors['sales']['selectedArea'],
							Config.MapColors.available
						],
						'fill-opacity': 0.6,
						/* 'fill-outline-color': Config.MapColors.available */
						'fill-outline-color': this.state.mapColors['sales']['sa1Outline']
					}
				},
				'road-label'
			);

			this.map.addLayer(
				{
					id: EMapLayers.Sa1Outline,
					type: 'line',
					source: 'sa1',
					'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
					filter: ['match', ['get', 'sa1_code'], [''], true, false],
					layout: {
						'line-cap': 'square',
						'line-join': 'bevel',
						'line-round-limit': 1.05,
						'line-miter-limit': 2
					},
					paint: {
						'line-color': this.state.mapColors['sales']['sa1Outline'],
						'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 13, 1, 16, 3],
						//'line-width': ['case', ['boolean', ['feature-state', 'clicked'], false], 4, 2],
						'line-dasharray': [2, 1]
					}
				},
				'road-label'
			);

			this.map.addLayer({
				id: EMapLayers.Sa1Label,
				type: 'symbol',
				source: 'sa1',
				minzoom: 14,
				'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
				filter: ['match', ['get', 'sa1_code'], [''], true, false],
				layout: {
					'text-allow-overlap': true,
					'text-padding': 0,
					'text-size': ['interpolate', ['exponential', 0.5], ['zoom'], 14, 14, 17, 30],
					'text-field': ['get', 'sa1_code']
				},
				paint: {
					'text-halo-width': 2,
					'text-halo-color': '#ffffff'
				}
			});

			this.map.addLayer(
				{
					id: EMapLayers.Sa1HoverOutline,
					type: 'line',
					source: 'sa1',
					'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
					layout: {
						'line-cap': 'round',
						'line-join': 'round',
						'line-round-limit': 1.05
					},
					paint: {
						'line-color': this.state.mapColors['sales']['sa1Outline'],
						'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.3, 0],
						'line-offset': 2,
						'line-width': ['interpolate', ['exponential', 1], ['zoom'], 14, 4, 16, 8]
					}
				},
				'road-label'
			);

			//CDAR
			this.map.addLayer(
				{
					id: EMapLayers.CdarFill,
					type: 'fill',
					source: 'cdar',
					'source-layer': Config.MapTilesets.AustraliaCdar.SourceLayer,
					filter: ['match', ['get', 'cdar_id'], [''], true, false],
					paint: {
						'fill-color': [
							'case',
							['boolean', ['feature-state', 'isSelected'], false],
							this.state.mapColors['sales']['selectedArea'],
							['boolean', ['feature-state', 'isSold'], false],
							this.state.mapColors['sales']['sold'],
							['boolean', ['feature-state', 'isReserved'], false],
							this.state.mapColors['sales']['reserved'],
							Config.MapColors.available
						],
						'fill-outline-color': Config.MapColors.available,
						'fill-opacity': 0.6
					}
				},
				'road-label'
			);

			this.map.addLayer(
				{
					id: EMapLayers.CdarHoverOutline,
					type: 'line',
					source: 'cdar',
					'source-layer': Config.MapTilesets.AustraliaCdar.SourceLayer,
					layout: {
						'line-cap': 'round',
						'line-join': 'round',
						'line-round-limit': 1.05
					},
					paint: {
						'line-color': this.state.mapColors['sales']['cdarOutline'],
						'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.3, 0],
						'line-offset': 2,
						'line-width': ['interpolate', ['exponential', 1], ['zoom'], 14, 4, 16, 8]
					}
				},
				'road-label'
			);

			this.map.addLayer({
				id: EMapLayers.CdarLabel,
				type: 'symbol',
				source: 'cdar',
				minzoom: 10,
				'source-layer': Config.MapTilesets.AustraliaCdar.SourceLayer,
				filter: ['match', ['get', 'cdar_id'], [''], true, false],
				layout: {
					'text-field': ['get', 'cdar_name'],
					'text-allow-overlap': true,
					'text-padding': 0,
					'text-size': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 10, 17, 15]
				},
				paint: {
					'text-halo-width': 2,
					'text-halo-color': '#ffffff'
				}
			});

			this.map.addLayer(
				{
					id: EMapLayers.CdarOutline,
					type: 'line',
					source: 'cdar',
					'source-layer': Config.MapTilesets.AustraliaCdar.SourceLayer,
					filter: ['match', ['get', 'cdar_id'], [''], true, false],
					layout: {
						'line-cap': 'square',
						'line-join': 'bevel',
						'line-round-limit': 1.05,
						'line-miter-limit': 2
					},
					paint: {
						'line-color': this.state.mapColors['sales']['cdarOutline'],
						'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 13, 1, 16, 3],
						//'line-width': ['case', ['boolean', ['feature-state', 'clicked'], false], 4, 2],
						'line-dasharray': [2, 1]
					}
				},
				'road-label'
			);

			// Royalty cdar pattern layer
			this.map.loadImage(
				`${process.env.PUBLIC_URL}/map_royalty_cdar.png`,
				(err: unknown, royaltyCdarImage: ImageData) => {
					// Throw an error if something went wrong
					if (err) throw err;

					// Declare the image
					this.map.addImage('royaltyCdar', royaltyCdarImage);
					//this.map.addImage('reserve', reserveImage);

					this.map.addLayer(
						{
							id: EMapLayers.RoyaltyCdarFillPattern,
							type: 'fill',
							source: 'cdar',
							'source-layer': Config.MapTilesets.AustraliaCdar.SourceLayer,
							filter: ['match', ['get', 'cdar_id'], [''], true, false],
							paint: {
								'fill-pattern': 'royaltyCdar',
								//'fill-color': Config.MapColors.sales.sold,
								'fill-opacity': 0.1
							}
						},
						EMapLayers.CdarLabel
					);
				}
			);
		};

		getMapLegend = (): IMapLegend[] => {
			return [
				/* {
					label: 'CDA',
					color: this.state.mapColors['cda']['outline'],
					type: 'boundary'
				}, */
				{
					label: 'SA1',
					color: this.state.mapColors['sales']['cdarOutline'],
					type: 'boundary'
				},
				{
					label: 'SOLD',
					color: this.state.mapColors['sales']['sold'],
					type: 'area'
				},
				{
					type: 'area',
					label: 'RESERVED',
					color: this.state.mapColors['sales']['reserved']
				},
				{
					type: 'area',
					label: 'SELECTED',
					color: this.state.mapColors['sales']['selectedArea']
				},
				{
					type: 'pattern',
					label: 'ROYALTY',
					color: '',
					patternImageUrl: `${process.env.PUBLIC_URL}/map_royalty_cdar.png`,
					opacity: 0.3
				}
			];
		};

		registerMapEvents = () => {
			this.registerMapHoverEvent();
			this.registerMapClickEvent();
			this.registerMapMoveEvents();
		};

		registerMapHoverEvent = () => {
			this.map.on('mousemove', this.onMapHoverStart);

			/**
			 * Mousemove event fires on entire map and its implementation
			 * has logic to clear existing hover outlines and tooltip if it does not find any feature underneath the
			 * mouse
			 *
			 * Essentially mousemove itself clears up the hover styles that it had applied
			 *
			 * In certain cases though mousemove will not be able to do job such as when the map is positioned in such
			 * a way that suburb or CDA boundary is at the edge of right side panel and mouse directly exits the map
			 * view without moving over any other areas. In such cases the hover styles and tooltip will remain
			 *
			 * So for these edge cases we need to listen to mouseleave events and we need listen to each layer
			 * seperately as the callback does not accept array of values
			 */
			this.map.on('mouseleave', EMapLayers.Sa1Fill, this.onMapHoverEnd);
			this.map.on('mouseleave', EMapLayers.CdarFill, this.onMapHoverEnd);
			this.map.on('mouseleave', EMapLayers.StateFill, this.onMapHoverEnd);
		};

		getAreaByTransactionId = async (transactionId: number) => {
			try {
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.ShowNavBarLoader,
					activeModal: ESalesModals.None
				});
				const transactionArea: ITransactionArea[] = await CommonApiService.getTransactionedAreaByTransactionId(
					transactionId
				);
				const selectedSa1s: ISalesAreaListItem[] = [];
				transactionArea.forEach((area: ITransactionArea) => {
					if (area.sa1_id) {
						const selectedSa1 = this.getSalesAreaListItemFromSa1(area.sa1_id);
						this.updateSa1MapFeatureState(area.sa1_id, { isSelected: true, isSold: false, isReserved: false });
						selectedSa1s.push(selectedSa1);
					}
				});
				this.setState({
					salesAreaList: selectedSa1s
				});
			} catch (err) {
				console.log(
					'🚀 ~ file: AdminSalesWrapperHoc.tsx ~ line 1747 ~ AdminSalesWrapperHoc ~ getAreaByTransactionId= ~ err',
					err
				);
			} finally {
				this.setState({
					loadingIndicator: ELoadingIndicatorStatus.None
				});
			}
		};

		registerMapClickEvent = () => {
			this.map.on('click', (e) => {
				if (this.state.loadingIndicator !== ELoadingIndicatorStatus.None) {
					return;
				}
				const bbox: [PointLike, PointLike] = UtilityService.getBoundingboxFromMapMouseEvent(e);

				// set bbox as 5px reactangle area around clicked point
				const features = this.map.queryRenderedFeatures(bbox, {
					layers: [EMapLayers.Sa1Fill, EMapLayers.CdarFill, EMapLayers.StateFill]
				});

				const isClickOnCdar = features.find((f) => f.layer.id === EMapLayers.CdarFill);
				if (isClickOnCdar) {
					if (this.props.LoggedInUserContextValue.loggedInUser?.role === ELoggedInUserRole.Sales) {
						return;
					}
					const cdar = isClickOnCdar as ICdarGeoJsonFeature;
					this.onClickCdarInMap(cdar);
					return;
				}

				const isClickOnSa1 = features.find((f) => f.layer.id === EMapLayers.Sa1Fill);
				if (isClickOnSa1) {
					const sa1 = isClickOnSa1 as ISa1GeoJsonFeature;
					this.onClickSa1InMap(sa1);
					return;
				}

				const isClickOnState = features.find((f) => f.layer.id === EMapLayers.StateFill);
				if (isClickOnState) {
					const state = isClickOnState as IStateGeoJsonFeature;
					//some islands in other territories have not been assigned state id yet
					if (state.properties.state_id) {
						this.onClickStateInMap(state);
						return;
					}
				}
			});
		};

		registerMapMoveEvents = () => {
			this.map.on('movestart', () => {
				this.map.off('mousemove', this.onMapHoverStart);
			});
			this.map.on('moveend', () => {
				this.map.on('mousemove', this.onMapHoverStart);
			});
		};

		onClickCdarInMap = (cdarFeature: ICdarGeoJsonFeature) => {
			this.selectCdar(cdarFeature.properties.cdar_id);
		};
		onClickSa1InMap = (sa1Feature: ISa1GeoJsonFeature) => {
			this.selectSa1(sa1Feature.properties.sa1_code);
		};

		isHaveAccessToState = (stateId: number) => {
			const selectedState = this.state.ausStates.find((state) => state.id === stateId);
			const loggedInUser = this.props.LoggedInUserContextValue.loggedInUser as ILoggedInUser;
			if (loggedInUser.state && loggedInUser.state.toLowerCase() === 'all') {
				return true;
			}
			if (loggedInUser.state && loggedInUser.state.includes(selectedState?.code as string)) {
				return true;
			}
			return false;
		};
		onClickStateInMap = (stateFeature: IStateGeoJsonFeature) => {
			const stateIdFromMapClick = stateFeature.properties.state_id;
			if (
				this.props.LoggedInUserContextValue.loggedInUser?.role === ELoggedInUserRole.Sales &&
				!this.isHaveAccessToState(stateIdFromMapClick)
			) {
				this.props.NotificationContextValue.addNotification({
					message: "You don't have access to this state.",
					type: ENotificationTypes.Error
				});
				return;
			}

			if (!this.state.selectedAusStateId) {
				this.selectState(stateIdFromMapClick);
				return;
			}

			if (this.state.selectedAusStateId !== stateIdFromMapClick) {
				this.selectState(stateIdFromMapClick);
			} else {
				//user selects the same state that he was previously before zooming out and clicking
				// commented by H Gandhi (as allow state click event at any level)
				//this.navigateMapToStateZoomLevel();
			}
		};

		/* removeSelectedAreaOnMapClick = (selectedAreaId: number, areaType: string) => {
			const newSalesAreaList = this.state.salesAreaList.filter((area) => area.id !== selectedAreaId);
			this.setState({
				salesAreaList: newSalesAreaList
			});
			if (areaType === 'cdar') {
				this.updateCdarMapFeatureState(selectedAreaId, { isSelected: false });
				return;
			}
			this.updateSa1MapFeatureState(selectedAreaId, { isSelected: false });
		}; */

		selectCdar = (cdarId: number) => {
			if (this.isAreaTypeExistOnList(ESalesAreaListItemType.Sa1)) {
				return;
			}
			const isCdarAlreadyAddedToList = this.state.salesAreaList.find((area) => area.id === cdarId);
			if (isCdarAlreadyAddedToList) {
				//this.removeSelectedAreaOnMapClick(cdarId, 'cdar');
				return;
			}

			const cdar = this.state.cdar.find((cdar) => cdar.cdar_id === cdarId);
			if (!cdar) {
				throw new Error('Could not find cdar record of clicked cdar feature.');
			}
			//click event is available only for royalty cdar so need to check that.
			//we can straightaway show selected layer on map
			this.updateCdarMapFeatureState(cdarId, { isSelected: true, isSold: false, isReserved: false });
			const salesAreaListItem = this.getSalesAreaListItemFromCdar(cdar);
			this.setState({
				salesAreaList: [...this.state.salesAreaList, salesAreaListItem]
			});
		};
		isAreaTypeExistOnList = (type: string) => {
			const selectedAreaList = this.state.salesAreaList;
			if (selectedAreaList.length === 0) {
				return false;
			}
			let isAreaExist = false;
			selectedAreaList.forEach((area: ISalesAreaListItem) => {
				if (area.type === type) {
					isAreaExist = true;
				}
			});
			return isAreaExist;
		};

		selectSa1 = (sa1Code: number) => {
			if (this.isAreaTypeExistOnList(ESalesAreaListItemType.Cdar)) {
				return;
			}
			const isSa1AlreadyAddedToList = this.state.salesAreaList.find((area) => area.id === sa1Code);
			if (isSa1AlreadyAddedToList) {
				//this.removeSelectedAreaOnMapClick(sa1Code, 'sa1');
				return;
			}
			const salesAreaListItem = this.getSalesAreaListItemFromSa1(sa1Code);
			if (
				this.props.LoggedInUserContextValue.loggedInUser?.role === ELoggedInUserRole.Sales &&
				salesAreaListItem.status === ERevAreaSaleStatus.Sold
			) {
				return;
			}
			const cdar = lodashFind(this.state.cdar, { sa1s: [{ sa1_code: sa1Code }] });
			if (!cdar) {
				throw new Error('Cannot find cdar record from cdar while getting sales area list item data from sa1');
			}
			const sa1 = cdar.sa1s.find((sa1) => sa1.sa1_code === sa1Code);
			if (sa1 && sa1.transaction_id) {
				this.onClickRemoveAllSelectedAreaListItems();
				this.getAreaByTransactionId(sa1.transaction_id);
				return;
			}
			this.updateSa1MapFeatureState(sa1Code, { isSelected: true, isSold: false, isReserved: false });

			this.setState({
				salesAreaList: [...this.state.salesAreaList, salesAreaListItem]
			});
		};

		getSalesAreaListItemFromCdar = (cdar: ICdar): ISalesAreaListItem => {
			return {
				id: cdar.cdar_id,
				name: cdar.name,
				status: cdar.status,
				selected: true,
				type: ESalesAreaListItemType.Cdar,
				reservationExpiry: cdar.t_reservation_expiry_at,
				transaction_id: cdar.transaction_id || null
			};
		};
		getSalesAreaListItemFromSa1 = (sa1Code: number): ISalesAreaListItem => {
			const cdar = lodashFind(this.state.cdar, { sa1s: [{ sa1_code: sa1Code }] });
			if (!cdar) {
				throw new Error(`Cannot find cdar record from cdar while getting sales area list item data for sa1 ${sa1Code}`);
			}
			const sa1 = cdar.sa1s.find((sa1) => sa1.sa1_code === sa1Code);
			if (!sa1) {
				throw new Error(`Cannot find sa1 record from cdar while getting sales area list item data for sa1 ${sa1Code}`);
			}

			return {
				id: sa1Code,
				name: sa1Code.toString(),
				status: sa1?.status,
				selected: true,
				type: ESalesAreaListItemType.Sa1,
				cdarId: cdar.cdar_id,
				reservationExpiry: sa1.t_reservation_expiry_at,
				transaction_id: sa1.transaction_id || null,
				prospect_customer: sa1.agency_name ? sa1.agency_name : null
			};
		};

		onMapHoverStart = (e: IMapboxMouseMoveEvent) => {
			const features = this.map.queryRenderedFeatures(e.point, {
				layers: [EMapLayers.CdarFill, EMapLayers.Sa1Fill, EMapLayers.StateFill]
			});
			this.map.getCanvas().style.cursor = 'pointer';

			if (features.length === 0) {
				this.cleanExistingHoverArea();
				this.popup.remove();
				return;
			}
			/**
			 * IMPORTANT - the sequence in which the hover functions called is important. DO NOT CHANGE without
			 * careful consideration of the impact
			 */
			const isHoverOnSa1 = features.find((f) => f.layer.id === EMapLayers.Sa1Fill);
			if (isHoverOnSa1) {
				const sa1Feature = isHoverOnSa1 as ISa1GeoJsonFeature;
				this.onHoverSa1(sa1Feature, e);
				return;
			}

			const isHoverOnCdar = features.find((f) => f.layer.id === EMapLayers.CdarFill);
			if (isHoverOnCdar) {
				const cdarFeature = isHoverOnCdar as ICdarGeoJsonFeature;
				this.onHoverCdar(cdarFeature, e);
				return;
			}
			const isHoverOnState = features.find((f) => f.layer.id === EMapLayers.StateFill);
			if (isHoverOnState) {
				const stateFeature = isHoverOnState as IStateGeoJsonFeature;
				this.onHoverState(stateFeature, e);
				return;
			}
		};

		onHoverSa1 = (sa1Feature: ISa1GeoJsonFeature, e: IMapboxMouseMoveEvent) => {
			const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];

			//need to update state in feature to remove hover outline
			this.cleanExistingHoverArea();
			this.hoverState.sa1 = sa1Feature.properties.sa1_code;
			this.updateSa1MapFeatureState(this.hoverState.sa1, { hover: true });

			//tooltip logic
			const tooltipContent: ITooltipContent[] = [{ label: 'SA1', value: sa1Feature.properties.sa1_code }];
			const cdar = lodashFind(this.state.cdar, { sa1s: [{ sa1_code: this.hoverState.sa1 }] });
			const sa1 = lodashFind(cdar?.sa1s, { sa1_code: this.hoverState.sa1 });

			if (cdar) {
				let cdarName = cdar.name;
				if (this.props.LoggedInUserContextValue.loggedInUser?.role === ELoggedInUserRole.Administrator) {
					cdarName = `${cdarName} (${cdar.cda_name})`;
				}
				tooltipContent.push({
					label: 'CDAR',
					value: cdarName
				});
			}

			if (sa1) {
				tooltipContent.push(this.getSaleStatusHtmlForTooltip(sa1.status));
				if (sa1.agency_name && this.isShowCustomer()) {
					if (sa1.status === ERevAreaSaleStatus.Sold) {
						tooltipContent.push({ label: 'AGENCY', value: `${sa1.agency_name} (${sa1.agency_id})` });
					} else if (sa1.status === ERevAreaSaleStatus.Reserved) {
						tooltipContent.push({ label: 'PROSPECTIVE CUSTOMER', value: sa1.agency_name });
					}
				}
				if (sa1.status === ERevAreaSaleStatus.Reserved && sa1.t_reservation_expiry_at) {
					tooltipContent.push({
						label: 'RESERVE EXPIRY',
						value: UtilityService.getTimeAndDateWithTimeZone(sa1.t_reservation_expiry_at, 'PP')
					});
				}
			}

			const tooltipHtml = UtilityService.getHtmlTableForTooltip(tooltipContent);

			this.popup.setLngLat(coordinates).setHTML(tooltipHtml).addTo(this.map);
		};

		onHoverCdar = (cdarFeature: ICdarGeoJsonFeature, e: IMapboxMouseMoveEvent) => {
			//need to update state in feature to remove hover outline
			this.cleanExistingHoverArea();
			this.hoverState.cdar = cdarFeature.id;
			this.updateCdarMapFeatureState(this.hoverState.cdar, { hover: true });

			//tooltip logic
			const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];
			const cdarProperties = cdarFeature.properties;
			const cdar = this.state.cdar.find((cdar) => cdar.cdar_id === cdarProperties.cdar_id);

			if (!cdar) {
				throw new Error(`Could not retrieve cdar record for ${cdarProperties.cdar_id}`);
			}
			let cdarName = cdar.name;
			if (this.props.LoggedInUserContextValue.loggedInUser?.role === ELoggedInUserRole.Administrator) {
				cdarName = `${cdarName} (${cdar.cda_name})`;
			}
			const tooltipContent = [{ label: 'CDAR', value: cdarName }];

			if (cdar.is_royalty) {
				tooltipContent.push({ label: 'ROYALTY', value: 'Yes' });
				if (this.props.LoggedInUserContextValue.loggedInUser?.role === ELoggedInUserRole.Sales) {
					tooltipContent.push({
						label: 'STATUS',
						value: 'Unavailable'
					});
				} else {
					tooltipContent.push(this.getSaleStatusHtmlForTooltip(cdar.status));
				}

				if (cdar.status === ERevAreaSaleStatus.Reserved && cdar.t_reservation_expiry_at) {
					tooltipContent.push({
						label: 'RESERVE EXPIRY',
						value: UtilityService.getTimeAndDateWithTimeZone(cdar.t_reservation_expiry_at, 'PP')
					});
				}

				if (cdar.agency_name && this.isShowCustomer()) {
					if (cdar.status === ERevAreaSaleStatus.Sold) {
						tooltipContent.push({ label: 'AGENCY', value: `${cdar.agency_name} (${cdar.agency_id})` });
					} else if (cdar.status === ERevAreaSaleStatus.Reserved) {
						tooltipContent.push({ label: 'PROSPECTIVE CUSTOMER', value: cdar.agency_name });
					}
				}
			} else {
				tooltipContent.push({ label: 'ROYALTY', value: 'No' });
			}

			const tooltipHtml = UtilityService.getHtmlTableForTooltip(tooltipContent);

			this.popup.setLngLat(coordinates).setHTML(tooltipHtml).addTo(this.map);
		};

		isShowCustomer = () => {
			const loggedInUser = this.props.LoggedInUserContextValue.loggedInUser;
			if (loggedInUser?.role === ELoggedInUserRole.Sales && localStorage.getItem('isShowCustomerOnSaleScreen')) {
				const isCustomerOnSaleScreen: boolean = localStorage.getItem('isShowCustomerOnSaleScreen') === 'true';
				return isCustomerOnSaleScreen;
			}
			return true;
		};

		onHoverState = (stateFeature: IStateGeoJsonFeature, e: IMapboxMouseMoveEvent) => {
			//some islands have not been assigned id's in mapbox tilesets hence this condition
			if (!stateFeature.properties.state_id) {
				return;
			}

			const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];

			//need to update state in feature to remove hover outline
			this.cleanExistingHoverArea();
			this.hoverState.state = stateFeature.properties.state_id;

			this.updateStateMapFeatureState(this.hoverState.state, { hover: true });

			//tooltip logic
			const tooltipContent = `${stateFeature.properties.name}`;

			this.popup.setLngLat(coordinates).setHTML(tooltipContent).addTo(this.map);
		};

		getSaleStatusHtmlForTooltip = (status: ERevAreaSaleStatus | null) => {
			if (status === ERevAreaSaleStatus.Sold) {
				return {
					label: 'STATUS',
					value: `<div style="display:inline-block;border-radius:3px;padding:2px 5px;background-color:${this.state.mapColors['sales']['sold']}"><span style="font-weight: bold;color:${this.state.mapColors['sales']['soldLabel']};">SOLD</span></div>`
				};
			} else if (status === ERevAreaSaleStatus.Reserved) {
				return {
					label: 'STATUS',
					value: `<div style="display:inline-block;border-radius:3px;padding:2px 5px;background-color:${this.state.mapColors['sales']['reserved']}"><span style="font-weight: bold;color:${this.state.mapColors['sales']['reservedLabel']};">RESERVED</span></div>`
				};
			} else {
				return {
					label: 'STATUS',
					value: 'Available'
				};
			}
		};

		cleanExistingHoverArea = () => {
			/* if (this.hoverState.cda) {
			this.updateCdaMapFeatureState(this.hoverState.cda, { hover: false });
		} */

			if (this.hoverState.sa1) {
				this.updateSa1MapFeatureState(this.hoverState.sa1, { hover: false });
			}

			if (this.hoverState.cdar) {
				this.updateCdarMapFeatureState(this.hoverState.cdar, { hover: false });
			}

			if (this.hoverState.state) {
				this.updateStateMapFeatureState(this.hoverState.state, { hover: false });
			}
		};

		updateSa1MapFeatureState = (sa1Code: number, options: TSa1MapFeatureState = { clicked: true }) => {
			this.map.setFeatureState(
				{
					source: 'sa1',
					sourceLayer: Config.MapTilesets.AustraliaSa1.SourceLayer,
					id: sa1Code
				},
				options
			);
		};

		updateCdarMapFeatureState = (cdarId: number, options: TCdarMapFeatureState = { clicked: true }) => {
			this.map.setFeatureState(
				{
					source: 'cdar',
					sourceLayer: Config.MapTilesets.AustraliaCdar.SourceLayer,
					id: cdarId
				},
				options
			);
		};

		updateStateMapFeatureState = (stateId: number, options: TAusStateMapFeatureState = { hover: true }) => {
			this.map.setFeatureState(
				{
					source: 'state',
					sourceLayer: Config.MapTilesets.AustraliaState.SourceLayer,
					id: stateId
				},
				options
			);
		};
		onMapHoverEnd = () => {
			/* if (this.hoverState.cda !== 0) {
			this.updateCdaMapFeatureState(this.hoverState.cda, { hover: false });
			this.hoverState.cda = 0;
		} */

			if (this.hoverState.cdar !== 0) {
				this.updateCdarMapFeatureState(this.hoverState.cdar, { hover: false });
				this.hoverState.cdar = 0;
			}

			if (this.hoverState.sa1 !== 0) {
				this.updateSa1MapFeatureState(this.hoverState.sa1, { hover: false });
				this.hoverState.sa1 = 0;
			}

			if (this.hoverState.state !== 0) {
				this.updateStateMapFeatureState(this.hoverState.state, { hover: false });
				this.hoverState.state = 0;
			}

			this.map.getCanvas().style.cursor = '';
			this.popup.remove();
		};
	}

	return () => (
		<NotificationContext.Consumer>
			{(notificationContext) => (
				<LoggedInUserContext.Consumer>
					{(userContext) => (
						<WrapperHoc LoggedInUserContextValue={userContext} NotificationContextValue={notificationContext} />
					)}
				</LoggedInUserContext.Consumer>
			)}
		</NotificationContext.Consumer>
	);
};

export default AdminSalesCompo;
