import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import Config from 'shared/constants/Config';

interface IProps {
	onMapLoadComplete?: (map: mapboxgl.Map) => void;
	onMapMove?: (e: any) => void;
}

interface initMapProps {
	setMap: React.Dispatch<React.SetStateAction<mapboxgl.Map | null>>;
	mapContainer: {
		current: HTMLDivElement | null;
	};
}

const Map: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const [map, setMap] = useState<mapboxgl.Map | null>(null);
	const mapContainer = useRef<HTMLDivElement | null>(null);
	const { onMapLoadComplete, onMapMove } = props;

	useEffect(() => {
		mapboxgl.accessToken = Config.MapboxToken;
		const initializeMap = ({ setMap, mapContainer }: initMapProps) => {
			const map = new mapboxgl.Map({
				container: mapContainer.current as HTMLDivElement,
				style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
				center: [134.306276, -25.987847],
				zoom: 3.5
			});
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: true }));
			map.on('load', () => {
				if (onMapLoadComplete) {
					onMapLoadComplete(map);
				}

				setMap(map);

				map.resize();
			});
			map.on('mousemove', () => {
				if (onMapMove) {
					onMapMove(map);
				}

				setMap(map);

				map.resize();
			});
		};

		if (!map) initializeMap({ setMap, mapContainer });

		return () => {
			//cleanup map resources
			map?.remove();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map, onMapLoadComplete]);

	return (
		<div style={{ height: '100%', width: '100%' }} ref={(el) => (mapContainer.current = el)}>
			{props.children}
		</div>
	);
};

export default Map;
