import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Grid, makeStyles, Typography, Divider } from '@material-ui/core';
import { IAreaSaleSubmissionError } from 'shared/types/Types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: 12
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: 0,
			color: theme.palette.grey[500]
		}
	});
interface IProps {
	errors: IAreaSaleSubmissionError[];
	onClose: () => void;
}

interface DialogTitleProps extends WithStyles<typeof styles> {
	children: React.ReactNode;
	onClose: () => void;
}

const TransacionSubmissionErrors: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const classes = useStyles();

	const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
		const { children, classes, onClose, ...other } = props;
		return (
			<MuiDialogTitle disableTypography className={classes.root} {...other}>
				<Typography variant="h6" className="Modal-title">
					{children}
				</Typography>
				{onClose ? (
					<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
						<CloseIcon />
					</IconButton>
				) : null}
			</MuiDialogTitle>
		);
	});

	return (
		<Dialog open onClose={props.onClose} scroll={'paper'} fullWidth>
			<DialogTitle onClose={props.onClose}>Errors</DialogTitle>
			<Divider />
			<DialogContent className="error-content">
				<Typography variant="body1" color="secondary" style={{ padding: '8px 24px', fontSize: 14 }}>
					There are some errors that need to be fixed before you can proceed ahead with your transaction. Please find
					the details below
				</Typography>
				<Divider style={{ marginLeft: 24, marginRight: 24 }} />
				<div className={classes.dialogContent}>
					{props.errors.map((error, idx) => (
						<div key={idx} className={classes.changeContainer}>
							<Grid container style={{ padding: '8px 24px' }}>
								<Grid item xs={2}>
									<Typography style={{ color: '#404040', fontSize: 14 }} variant="body2">
										#{idx + 1} Error
									</Typography>
								</Grid>
								<Divider />
								<Grid item xs={9} className={classes.errorText}>
									{error.message}
								</Grid>
							</Grid>
							<Divider style={{ marginLeft: 24, marginRight: 24, marginTop: 10 }} />
							<Grid style={{ padding: '8px 24px' }}>
								{error.invalidAreas && (
									<>
										<Typography variant="body1" color="secondary" style={{ fontSize: 14 }}>
											Invalid areas
										</Typography>
										<table className={classes.table}>
											<thead>
												<tr>
													<th className={classes.tableHeader}>Name</th>
													<th className={classes.tableHeader}>Type</th>
												</tr>
											</thead>
											<tbody>
												{error.invalidAreas.map((area) => {
													return (
														<tr key={area.id} className={classes.tableRow}>
															<td className={classes.tableCell}>{area.name}</td>
															<td className={`${classes.tableCell} ${classes.uppercase}`}>{area.type}</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</>
								)}
							</Grid>
						</div>
					))}
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" className="cancel-btn" onClick={props.onClose}>
					CLOSE
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const useStyles = makeStyles((theme) => ({
	dialogContent: {
		minHeight: 200,
		position: 'relative',
		padding: 2
	},
	changeContainer: {
		/* border: '1px solid black', */
		marginBottom: 15
	},
	skeleton: {
		marginBottom: 5
	},
	table: {
		borderCollapse: 'collapse',
		marginTop: 10
	},
	tableHeader: {
		border: '1px solid #ccc',
		padding: 8
	},
	tableCell: {
		border: '1px solid #ccc',
		padding: 8
	},
	tableRow: {
		'&hover': {
			background: '#eee'
		}
	},
	uppercase: {
		textTransform: 'uppercase'
	},
	errorText: {
		color: '#E00000',
		fontSize: 13
	}
}));

export default TransacionSubmissionErrors;
