import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab/Autocomplete';
import { CircularProgress, FormHelperText, Grid, Typography, InputLabel, OutlinedInput } from '@material-ui/core';
import {
	ERevAreaSaleType,
	ESalesAreaListItemType,
	IAgency,
	ISalesAreaListItem,
	ISaleVerificationModalSubmit,
	IMapSystemSetting
} from 'shared/types/Types';
import ConfirmModalWithContent from 'shared/components/ConfirmModalWithContent';
import UtilityService from 'shared/services/UtilityService';
import CommonApiService from 'shared/services/CommonApiService';
import NewIcon from '@material-ui/icons/FiberNewOutlined';

const useStyles = makeStyles({
	table: {
		borderCollapse: 'collapse',
		marginTop: 10,
		width: '100%'
	},
	tableHeader: {
		border: '1px solid #ccc',
		padding: 6,
		fontSize: '0.700rem'
	},
	tableCell: {
		border: '1px solid #ccc',
		padding: '5px 10px',
		fontSize: '0.750rem',
		textAlign: 'center'
	},
	tableRow: {
		'&hover': {
			background: '#eee'
		}
	},
	uppercase: {
		textTransform: 'uppercase'
	}
});

interface IProps {
	saleType: ERevAreaSaleType;
	areas: ISalesAreaListItem[];
	onClickCancelSubmission: () => void;

	onClickSubmitSaleAction: (data: ISaleVerificationModalSubmit) => void;
	isSubmitting?: boolean;
	mapSystemsConfig: IMapSystemSetting | null;
}

const areaType: any = {
	sell: 'sold',
	reserve: 'reserved',
	reserve_extend: 'reserved extended',
	release: 'released'
};

const TransactionModal: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const classes = useStyles();
	const [agencies, setAgencies] = React.useState<IAgency[]>([]);
	const [selAgency, setSelAgency] = React.useState<IAgency | null>(null);
	/* const [pinCdar, setPinCdar] = React.useState(false); */
	const [reservationExtensionDays, setReservationExtensionDays] = React.useState<number | undefined>(undefined);
	const [reservationExtensionDayError, setReservationExtensionDayError] = React.useState({
		isError: false,
		errorMessage: ''
	});
	const saleNotesRef = React.useRef<HTMLInputElement>(null);
	const saleProspectCustomerRef = React.useRef<HTMLInputElement>(null);
	const [agencyAutocompleteInputValue, setAgencyAutocompleteInputValue] = React.useState('');
	const [agencyValidationError, setAgencyValidationError] = React.useState({ isError: false, errorMessage: '' });
	const [reservationProspectiveCustomerError, setReservationProspectiveCustomerError] = React.useState({
		isError: false,
		errorMessage: ''
	});
	const [isLoading, setIsLoading] = React.useState(false);

	const isAreaToSubmitRoyalty = () => {
		//presence of cdar is indication that the transaction is for royalty area
		//as we allow either sa1s or royalty cdars in a single transaction
		const royaltyCdar = props.areas.find((area) => area.type === ESalesAreaListItemType.Cdar);
		if (royaltyCdar) {
			return true;
		}

		return false;
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getAgencyBySearchText = React.useCallback(
		UtilityService.debounce(async (searchText: string) => {
			try {
				const isAreaRoyalty = isAreaToSubmitRoyalty();
				const queryParams = {
					searchText,
					...(isAreaRoyalty && { royalty: true })
				};

				const res = await CommonApiService.getAgencyBySearchText(queryParams);
				setAgencies(res);
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		}),
		[]
	);

	const onChangeAgencyAutocompleteInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		setAgencyAutocompleteInputValue(value);
		if (reason !== 'input') {
			return;
		}

		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (value.trim().length < 3) {
			return;
		}

		setIsLoading(true);
		getAgencyBySearchText(value);
	};

	const onChangeAgencyAutocomplete = (ev: unknown, value: IAgency | null, reason: AutocompleteChangeReason) => {
		if (!value) {
			return;
		}
		setAgencyValidationError({
			isError: false,
			errorMessage: ''
		});
		setSelAgency(value);
	};

	/* const onChangePinCdarCheckbox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		setPinCdar(event.target.checked);
	}; */

	const isValidationPassing = () => {
		let returnValue = true;
		if (
			props.saleType === ERevAreaSaleType.ExtendReservation &&
			(!reservationExtensionDays || reservationExtensionDays < 1)
		) {
			setReservationExtensionDayError({
				isError: true,
				errorMessage: 'Number of days to extend reservation must be valid value'
			});
			returnValue = false;
		}
		if (
			props.saleType === ERevAreaSaleType.ExtendReservation &&
			reservationExtensionDays &&
			props.mapSystemsConfig &&
			reservationExtensionDays > props.mapSystemsConfig?.max_reservation_extension_period
		) {
			setReservationExtensionDayError({
				isError: true,
				errorMessage: `You can extend a reservation to a maximum of ${props.mapSystemsConfig?.max_reservation_extension_period} days`
			});
			returnValue = false;
		}

		if (props.saleType === ERevAreaSaleType.Sell && !selAgency) {
			setAgencyValidationError({
				isError: true,
				errorMessage: 'Please select a agency to submit'
			});
			returnValue = false;
		}

		if (props.saleType === ERevAreaSaleType.Reserve) {
			if (!saleProspectCustomerRef.current?.value && !props.areas[0].transaction_id) {
				setReservationProspectiveCustomerError({
					isError: true,
					errorMessage: 'Please enter Prospective Customer'
				});
				returnValue = false;
			}

			const prospectiveCustomer = saleProspectCustomerRef.current?.value as string;

			if (returnValue && (prospectiveCustomer?.length < 3 || prospectiveCustomer?.length > 255)) {
				setReservationProspectiveCustomerError({
					isError: true,
					errorMessage: 'Prospective Customer length should not be less then 3 OR greater then 255.'
				});
				returnValue = false;
			}
		}

		return returnValue;
	};

	const onClickSubmit = () => {
		const isValid = isValidationPassing();
		if (!isValid) {
			return;
		}
		const submitPayload = getPayloadForSubmission();
		props.onClickSubmitSaleAction(submitPayload);
	};

	const getPayloadForSubmission = (): ISaleVerificationModalSubmit => {
		if (props.saleType === ERevAreaSaleType.Sell) {
			if (!selAgency) {
				throw new Error('Agency selection is required for payload generation');
			}
			return {
				agencyId: selAgency.agency_id,
				/* pinCdar: pinCdar, */
				note: saleNotesRef.current?.value
			};
		} else if (props.saleType === ERevAreaSaleType.Reserve) {
			if (!saleProspectCustomerRef.current?.value && !props.areas[0].transaction_id) {
				throw new Error('Prospective Customer selection is required for payload generation');
			}
			return {
				prospect_agency: props.areas[0].transaction_id
					? (props.areas[0].prospect_customer as string)
					: (saleProspectCustomerRef.current?.value as string),
				note: saleNotesRef.current?.value
			};
		} else if (props.saleType === ERevAreaSaleType.Release) {
			return {
				note: saleNotesRef.current?.value
			};
		} else {
			return {
				reserveExtendDays: reservationExtensionDays,
				note: saleNotesRef.current?.value
			};
		}
	};

	const getNewReservationExpiryDateTime = (currentExpiryDateTime: string) => {
		if (!reservationExtensionDays || reservationExtensionDays < 0) {
			return null;
		}

		const newDate = new Date(currentExpiryDateTime);
		newDate.setDate(newDate.getDate() + reservationExtensionDays);
		return UtilityService.getTimeAndDateWithTimeZone(newDate.toISOString(), 'PP');
	};

	const getReservationExpiryDateTime = (expiryTime: string | undefined) => {
		if (expiryTime) {
			return UtilityService.getTimeAndDateWithTimeZone(expiryTime, 'PP');
		}
		if (props.areas[0].transaction_id && props.areas[0].reservationExpiry) {
			return UtilityService.getTimeAndDateWithTimeZone(props.areas[0].reservationExpiry, 'PP');
		}
		if (!props.mapSystemsConfig || props.mapSystemsConfig.default_reservation_period < 0) {
			return null;
		}
		const newDate = new Date();
		newDate.setDate(newDate.getDate() + props.mapSystemsConfig.default_reservation_period);
		return UtilityService.getTimeAndDateWithTimeZone(newDate.toISOString(), 'PP');
	};

	const getTitle = () => {
		if (props.saleType === ERevAreaSaleType.Release) {
			return 'Confirm Release';
		} else if (props.saleType === ERevAreaSaleType.ExtendReservation) {
			return 'Confirm Reservation extension';
		} else if (props.saleType === ERevAreaSaleType.Sell) {
			return `Select Agency for SELL action`;
		} else if (props.saleType === ERevAreaSaleType.Reserve) {
			return `Reserve`;
		}
	};

	const onChangeReservationExtensionDays = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.value) {
			setReservationExtensionDays(undefined);
			return;
		}
		setReservationExtensionDays(parseInt(event.target.value));
	};

	const isTransactionedArea = (): boolean => {
		const selectedAreaList = props.areas;
		let transactionArea = 0;
		selectedAreaList.forEach((area: ISalesAreaListItem) => {
			if (area.transaction_id) {
				transactionArea += 1;
			}
		});
		return transactionArea > 0 ? true : false;
	};

	const isNewArea = (area: ISalesAreaListItem) => {
		// check selected area are fresh transaction
		if (!isTransactionedArea()) {
			return null;
		}
		if (area.transaction_id) {
			return null;
		}
		return <NewIcon style={{ color: 'red', marginTop: '-5px' }} />;
	};

	const getAreaType = (area: ISalesAreaListItem) => {
		// check selected area are fresh transaction
		if (!isTransactionedArea()) {
			return `To be ${areaType[props.saleType]}`;
		}
		if (area.status && area.status === areaType[props.saleType]) {
			return area.status;
		}
		if (props.saleType === 'reserve_extend') {
			return 'Extend Reservation';
		}
		return `To be ${areaType[props.saleType]}`;
	};

	const renderProspectiveCustomer = () => {
		return (
			<InputLabel className="modal-label" style={{ marginBottom: 15 }}>
				Prospective Customer : <span style={{ fontWeight: 600 }}>{props.areas[0].prospect_customer}</span>
			</InputLabel>
		);
	};
	const renderProspectiveCustomerComponent = () => {
		if (props.areas[0].transaction_id) {
			return renderProspectiveCustomer();
		}
		return (
			<>
				<InputLabel className="modal-label">Prospective Customer</InputLabel>
				<OutlinedInput
					inputRef={saleProspectCustomerRef}
					style={{ marginTop: 5 }}
					fullWidth
					placeholder="Enter the Prospective Customer to reserve."
					error={reservationProspectiveCustomerError.isError}
					labelWidth={0}
					className="modal-textfield"
				/>
				{reservationProspectiveCustomerError.isError && (
					<FormHelperText style={{ color: '#ff1744' }}>
						{reservationProspectiveCustomerError.errorMessage}
					</FormHelperText>
				)}
			</>
		);
	};

	return (
		<ConfirmModalWithContent
			cancelBtnText="Cancel"
			title={getTitle()}
			onClickCancel={props.onClickCancelSubmission}
			onClickConfirm={onClickSubmit}
			isLoading={props.isSubmitting}
		>
			<Grid className={'stretch'}>
				<>
					{props.saleType === ERevAreaSaleType.Sell && (
						<>
							<InputLabel className="modal-label">Search Agency</InputLabel>
							<Autocomplete
								inputValue={agencyAutocompleteInputValue}
								onInputChange={onChangeAgencyAutocompleteInput}
								size="small"
								className="autoComplete"
								onChange={onChangeAgencyAutocomplete}
								options={agencies}
								getOptionLabel={(option) => `(${option.agency_id}) ${option.name}`}
								renderInput={(params) => (
									<TextField
										{...params}
										/* label="Search by Agency" */
										variant="outlined"
										error={agencyValidationError.isError}
										placeholder="Start typing to get search suggestions"
										helperText={agencyValidationError.isError && agencyValidationError.errorMessage}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{isLoading ? <CircularProgress color="secondary" size={20} /> : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											)
										}}
									/>
								)}
							/>
						</>
					)}
					{props.saleType === ERevAreaSaleType.ExtendReservation && renderProspectiveCustomer()}

					{props.saleType === ERevAreaSaleType.ExtendReservation && (
						<>
							<InputLabel className="modal-label">Extend reservation by days</InputLabel>
							<OutlinedInput
								value={reservationExtensionDays}
								onChange={onChangeReservationExtensionDays}
								style={{ marginTop: 5 }}
								type="number"
								fullWidth
								error={reservationExtensionDayError.isError}
								placeholder="Enter the number of days by which the reservation is to be extended"
								labelWidth={0}
								className="modal-textfield"
							/>
							{reservationExtensionDayError.isError && (
								<FormHelperText style={{ color: '#ff1744' }}>
									{reservationExtensionDayError.errorMessage}
								</FormHelperText>
							)}
						</>
					)}
					{props.saleType === ERevAreaSaleType.Reserve && renderProspectiveCustomerComponent()}
					<InputLabel className="modal-label">Notes</InputLabel>
					<OutlinedInput
						inputRef={saleNotesRef}
						style={{ marginTop: 5 }}
						fullWidth
						placeholder="Enter notes related to this action here"
						multiline
						rowsMax={4}
						className="modal-textfield"
						labelWidth={0}
					/>

					{/* props.saleType === ERevAreaSaleType.Sell && (
						<FormControl>
							<FormControlLabel
								control={
									<Checkbox color="primary" checked={pinCdar} onChange={onChangePinCdarCheckbox} name="pinCdar" />
								}
								label="Pin CDAR ?"
							/>
							<FormHelperText>Pinned CDAR's will not be considered for rotation</FormHelperText>
						</FormControl>
					) */}
				</>

				<Typography
					style={{ marginTop: 20, fontSize: 12, color: '#404040', lineHeight: '17px', fontFamily: 'Open Sans' }}
					variant="body2"
					color="secondary"
				>
					The action will be applied to following areas:
				</Typography>
				<table className={classes.table}>
					<thead>
						<tr className="table-header-row">
							<th className={`${classes.tableHeader} border-top border-left border-right-none`}>Area Name/Code</th>
							<th className={`${classes.tableHeader} border-top border-left-none border-right-none`}>Type</th>
							{props.saleType === ERevAreaSaleType.ExtendReservation && (
								<>
									<th className={`${classes.tableHeader} border-top border-left-none border-right-none`}>
										Current Expiry
									</th>
									<th className={`${classes.tableHeader} border-top border-left-none border-right-none`}>New Expiry</th>
								</>
							)}
							{props.saleType === ERevAreaSaleType.Reserve && (
								<th className={`${classes.tableHeader} border-top border-left-none border-right-none`}>Expiry </th>
							)}
							<th className={`${classes.tableHeader} border-top border-left-none border-right`}>Action</th>
						</tr>
					</thead>
					<tbody>
						{props.areas.map((area) => {
							return (
								<tr key={area.id} className={classes.tableRow}>
									<td className={`${classes.tableCell} border-top border-left border-right-none`}>
										<Grid container justify="center">
											{area.name}
											<span style={{ marginRight: 10, width: 24 }}>{isNewArea(area)}</span>
										</Grid>
									</td>
									<td
										className={`${classes.tableCell} ${classes.uppercase} border-top border-left-none border-right-none`}
									>
										{area.type}
									</td>
									{props.saleType === ERevAreaSaleType.ExtendReservation && (
										<>
											<td className={`${classes.tableCell} border-top border-left-none border-right-none`}>
												{area.reservationExpiry &&
													UtilityService.getTimeAndDateWithTimeZone(area.reservationExpiry, 'PP')}
											</td>
											<td className={`${classes.tableCell} border-top border-left-none border-right-none`}>
												{area.reservationExpiry && getNewReservationExpiryDateTime(area.reservationExpiry)}
											</td>
										</>
									)}
									{props.saleType === ERevAreaSaleType.Reserve && (
										<td className={`${classes.tableCell} border-top border-left-none border-right-none`}>
											{getReservationExpiryDateTime(area.reservationExpiry)}
										</td>
									)}
									<td className={`${classes.tableCell} ${classes.uppercase} border-top border-left-none border-right`}>
										{getAreaType(area)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</Grid>
		</ConfirmModalWithContent>
	);
};

export default TransactionModal;
