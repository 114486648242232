import React from 'react';
import { Typography, Grid } from '@material-ui/core';
interface IProps {
	type?: string;
}

const FullAreaLoadingSpinner: React.SFC<IProps> = (props): JSX.Element => {
	return (
		<Grid
			justify="center"
			alignItems="center"
			container
			className={props.type === 'dialog' ? 'LoadingDialogContainer' : 'LoadingContainer'}
		>
			<Grid item direction="column" justify="center" container alignItems="center">
				<svg className="spinner" viewBox="0 0 50 50">
					<circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
				</svg>
				<Typography variant="subtitle2" className="LoadingText">
					Loading . . .
				</Typography>
			</Grid>
		</Grid>
	);
};
export default FullAreaLoadingSpinner;
