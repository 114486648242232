import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, makeStyles } from '@material-ui/core';
import OutlineIcon from '@material-ui/icons/CropDinOutlined';

interface IProps {
	items: {
		type: string;
		label: string;
		color: string;
		patternImageUrl?: string;
		opacity?: number;
	}[];
	legendSpacing?: number;
}

const MapLegend: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const classes = useStyles();
	return (
		<Grid container justify="flex-start" alignItems="center" className={classes.legendContainer}>
			<Typography variant="button" color="secondary">
				LEGEND
			</Typography>
			{props.items.map((i, index) => {
				return (
					<Grid item key={index} style={{ marginRight: props.legendSpacing || 20 }}>
						<Grid container justify="center" alignItems="center">
							{i.type === 'boundary' && (
								<>
									<OutlineIcon
										style={{
											color: i.color,
											marginLeft: 10,
											opacity: i.opacity || 1
										}}
									/>
									<span className={classes.label}>{i.label}</span>
								</>
							)}
							{i.type === 'area' && (
								<>
									<div
										className={classes.boundary}
										style={{ backgroundColor: i.color || 'rgba(255, 111, 89, 0.4)', opacity: i.opacity || 1 }}
									/>
									<span className={classes.label}>{i.label}</span>
								</>
							)}
							{i.type === 'pattern' && (
								<>
									<div
										className={classes.boundary}
										style={{
											border: '2px solid grey',
											//backgroundImage: 'url(https://sa1.stgz.view.com.au/map_royalty_cdar.png)',
											backgroundImage: `url(${i.patternImageUrl})`,
											backgroundRepeat: 'repeat',
											backgroundSize: 10,
											opacity: i.opacity || 1
										}}
									/>
									<span className={classes.label}>{i.label}</span>
								</>
							)}
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	);
};

const useStyles = makeStyles(() => ({
	legendContainer: {
		height: 35,
		padding: 5,
		width: '100%',
		backgroundColor: '#fff'
	},
	boundary: {
		marginLeft: 10,
		height: 20,
		width: 20
	},
	label: {
		fontSize: '0.75rem',
		marginLeft: 5
	}
}));

export default MapLegend;
