import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import CdaService from 'shared/services/CdaService';
import { IAustraliaState, IGetCdaChangelogHttpResponse } from 'shared/types/Types';
import { differenceBy as lodashDifferenceBy } from 'lodash';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface IProps {
	ausState: IAustraliaState;
	onClose: () => void;
}

interface IChange {
	name: string;
	fieldsUpdated: string[];
	suburbs: {
		oldCount: number;
		newCount: number;
		added: string[];
		removed: string[];
	};
	isDeleted: boolean;
	isNew: boolean;
}

const CdaChangesToSyncModal: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const [changes, setChanges] = React.useState<IChange[]>([]);
	const [isLoading, setIsLoading] = React.useState(false);

	const processChangelog = (data: IGetCdaChangelogHttpResponse) => {
		const changeset: IChange[] = [];

		data.tmpCda.forEach((tmpCda) => {
			const tmpChangeSet: IChange = {
				isNew: false,
				name: tmpCda.name,
				isDeleted: false,
				fieldsUpdated: [],
				suburbs: {
					oldCount: 0,
					newCount: 0,
					added: [],
					removed: []
				}
			};
			const masterCda = data.cda.find((mCda) => mCda.cda_id === tmpCda.cda_id);

			if (!masterCda) {
				tmpChangeSet.isNew = true;
				tmpChangeSet.suburbs.added = tmpCda.suburbs.map((s) => s.name);
			} else if (tmpCda.is_deleted) {
				tmpChangeSet.isDeleted = true;
			} else {
				if (masterCda.name !== tmpCda.name) {
					tmpChangeSet.fieldsUpdated = ['Name'];
				}

				if (masterCda.description !== tmpCda.description) {
					tmpChangeSet.fieldsUpdated = [...tmpChangeSet.fieldsUpdated, 'Description'];
				}

				if (masterCda.provisional_search_radius !== tmpCda.provisional_search_radius) {
					tmpChangeSet.fieldsUpdated = [...tmpChangeSet.fieldsUpdated, 'Provisional Search Radius'];
				}

				if (masterCda.reservation_expiry_in_days !== tmpCda.reservation_expiry_in_days) {
					tmpChangeSet.fieldsUpdated = [...tmpChangeSet.fieldsUpdated, 'Reservation Expiry in Days'];
				}

				if (masterCda.automate_provisional_assignment !== tmpCda.automate_provisional_assignment) {
					tmpChangeSet.fieldsUpdated = [...tmpChangeSet.fieldsUpdated, 'Automate Provisional Assignment'];
				}

				const suburbsRemoved = lodashDifferenceBy(masterCda.suburbs, tmpCda.suburbs, 'id');
				const suburbsAdded = lodashDifferenceBy(tmpCda.suburbs, masterCda.suburbs, 'id');

				tmpChangeSet.suburbs = {
					oldCount: masterCda.suburbs.length,
					newCount: tmpCda.suburbs.length,
					added: suburbsAdded.map((s) => s.name),
					removed: suburbsRemoved.map((s) => s.name)
				};
				//cannot recall why did we have this line in first place, have let it remain for time being
				//can remove in future
				//tmpChangeSet.fieldsUpdated = [...tmpChangeSet.fieldsUpdated, 'Automate Provisional Assignment'];
			}

			changeset.push(tmpChangeSet);
		});
		setChanges(changeset);
	};

	React.useEffect(() => {
		const getChangelog = async () => {
			setIsLoading(true);
			try {
				const res = await CdaService.getCdaChangeLog({ state_id: props.ausState.id });
				processChangelog(res);
			} finally {
				setIsLoading(false);
			}
		};

		getChangelog();
	}, [props.ausState]);

	const classes = useStyles();

	return (
		<Dialog open onClose={props.onClose} scroll={'paper'} fullWidth>
			<DialogTitle>Changelog for {props.ausState.name}</DialogTitle>
			<DialogContent dividers>
				<div className={classes.dialogContent}>
					{isLoading && (
						<>
							<Skeleton className={classes.skeleton} variant="rect" width={'100%'} height={118} animation="wave" />
							<Skeleton className={classes.skeleton} variant="rect" width={'100%'} height={118} animation="wave" />
							<Skeleton className={classes.skeleton} variant="rect" width={'100%'} height={118} animation="wave" />
						</>
					)}

					{!isLoading &&
						changes.map((change, idx) => (
							<div key={idx} className={classes.changeContainer}>
								<Grid container>
									<Grid item xs={3}>
										<Typography color="secondary" variant="body2">
											CDA
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant="body2">{change.name}</Typography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={3}>
										<Typography color="secondary" variant="body2">
											Is New ?
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant="body2">{change.isNew ? 'YES' : 'NO'}</Typography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={3}>
										<Typography color="secondary" variant="body2">
											Is Deleted ?
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant="body2">{change.isDeleted ? 'YES' : 'NO'}</Typography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={3}>
										<Typography color="secondary" variant="body2">
											Fields updated
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant="body2">
											{change.fieldsUpdated.length > 0 ? change.fieldsUpdated.map((f) => f).join(', ') : 'N/A'}
										</Typography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={3}>
										<Typography color="secondary" variant="body2">
											Suburbs added
										</Typography>
									</Grid>
									<Grid item xs={9}>
										{change.suburbs.added.length > 0 ? change.suburbs.added.map((f) => f).join(', ') : 'N/A'}
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={3}>
										<Typography color="secondary" variant="body2">
											Suburbs removed
										</Typography>
									</Grid>
									<Grid item xs={9}>
										{/* <Typography variant="body2">{change.suburbs?.removed.map((f) => f).join(', ')}</Typography> */}
										<Typography variant="body2">
											{change.suburbs.removed.length > 0 ? change.suburbs.removed.map((f) => f).join(', ') : 'N/A'}
										</Typography>
									</Grid>
								</Grid>
							</div>
						))}
					{!isLoading && changes.length === 0 && (
						<Typography variant="h5">No changes have been recorded for {props.ausState.name} at the moment</Typography>
					)}
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const useStyles = makeStyles(() => ({
	dialogContent: {
		minHeight: 200,
		position: 'relative',
		padding: 2
	},
	changeContainer: {
		border: '1px solid black',
		marginBottom: 5,
		padding: 5
	},
	skeleton: {
		marginBottom: 5
	}
}));

export default CdaChangesToSyncModal;
