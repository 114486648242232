import React from 'react';
import { makeStyles } from '@material-ui/core';

interface IProps {
	styles?: React.CSSProperties;
}

const AbsolutePositionContainer: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const classes = useStyles();
	return (
		<div style={props.styles} className={classes.container}>
			{props.children}
		</div>
	);
};

const useStyles = makeStyles(() => ({
	container: {
		position: 'absolute',
		zIndex: 10
	}
}));

export default AbsolutePositionContainer;
