import {
	Button,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	LinearProgress,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
	withStyles
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Alert, Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab';
import { forEach, find as lodashFind } from 'lodash';
import mapboxgl, { LngLatLike, PointLike } from 'mapbox-gl';
import React, { Component } from 'react';
import Split from 'react-split';
import AreaLoaderContainer from 'shared/components/AreaLoaderContainer';
import ConfirmModalWithMessage from 'shared/components/ConfirmModalWithMessage';
import InfoModal from 'shared/components/InfoModal';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import Map from 'shared/components/Map';
import MapLegend from 'shared/components/MapLegend';
import MapSyncInProgressMessage from 'shared/components/MapSyncInProgressMessage';
import RevAreaList from 'shared/components/RevAreaList';
import ToggleContent from 'shared/components/ToggleContent';
import Config from 'shared/constants/Config';
import { NotificationContext } from 'shared/providers/NotificationProvider';
import CdarService from 'shared/services/CdarService';
import CdaService from 'shared/services/CdaService';
import CommonApiService from 'shared/services/CommonApiService';
import UtilityService from 'shared/services/UtilityService';
import {
	ELoadingIndicatorStatus,
	EMapServiceJobStatus,
	ENotificationTypes,
	IAustraliaState,
	ICda,
	ICdaGeoJsonFeature,
	ICdar,
	ICdarGeoJsonFeature,
	IGeoBound,
	IMapboxMouseMoveEvent,
	IMapSyncJob,
	ISa1,
	ISa1GeoJsonFeature,
	ISa1GeoJsonFeatureProperties,
	TCdaMapFeatureState,
	TCdarMapFeatureState,
	TSa1MapFeatureState,
	TAusStateMapFeatureState,
	IStateGeoJsonFeature,
	IMapColor,
	ERevAreaSaleStatus
} from 'shared/types/Types';
import CdarInfoView from './CdarInfoView';
import CdarChangesToSyncModal from './CdarChangesToSyncModal';
import ManageCdarForm from './ManageCdarForm';
import AddSa1Modal from './AddSa1Modal';

interface IState {
	ausStates: IAustraliaState[];
	selectedAusStateId: number | null;
	cda: ICda[];
	selectedCdaId: number | null;
	cdaAutocompleteInputValue: string;
	cdar: ICdar[];
	cdarForm: ICdar;
	cdarSelectedOnMap: ICdar | null;
	activityMode: EActivityMode;
	availableSa1: ISa1[];
	loadingIndicator: ELoadingIndicatorStatus;
	isCdaDirtyForState: boolean;
	mapServiceSync: {
		inProgress: boolean;
		inProgressJob: IMapSyncJob | null;
	};
	sa1sAddedToCdar: ISa1[]; //for easy updating of map when editing/creating cda
	sa1sRemovedFromCdar: ISa1[]; //for easy updating of map when editing/creating cda
	mapColors: IMapColor;
	isShowMap: boolean;
	showAddSa1Modal: boolean;
}
enum EActivityMode {
	NewCdar = 'new-cdar',
	ChangeCdar = 'change-cdar',
	ViewCdar = 'view-cdar',
	NoActivity = ''
}
enum EMapLayers {
	StateFill = 'state-fill',
	StateOutline = 'state-outline',
	StateHoverOutline = 'state-hover-fill',
	CdaFill = 'cda-fill',
	CdaOutline = 'cda-outline',
	CdaHoverOutline = 'cda-hover-fill',
	MasterCdarFill = 'master-cdar-fill',
	MasterCdarOutline = 'master-cdar-outline',
	MasterCdarHoverOutline = 'master-cdar-hover-outline',
	TmpCdarFill = 'tmp-cdar-fill',
	TmpCdarOutline = 'tmp-cdar-outline',
	MasterCdarSa1Fill = 'master-cdar-sa1-fill',
	MasterCdarSa1Outline = 'master-cdar-sa1-outline',
	AvailableSa1Fill = 'available-sa1-fill',
	AvailableSa1Outline = 'available-sa1-outline'
}

class ManageCdar extends Component<unknown, IState> {
	constructor(props: unknown) {
		super(props);
		this.onMapHoverStart = UtilityService.debounce(this.onMapHoverStart, 25);
		this.onMapHoverEnd = UtilityService.debounce(this.onMapHoverEnd, 25);
	}
	state: Readonly<IState> = {
		cda: [],
		selectedCdaId: null,
		cdaAutocompleteInputValue: '',
		ausStates: [],
		selectedAusStateId: null,
		cdar: [],
		cdarSelectedOnMap: null,
		availableSa1: [],
		activityMode: EActivityMode.NoActivity, //refers to 3 possible state in UI i.e. change cda, new cda or view cda
		cdarForm: {
			tmp_cdar_id: 0,
			cdar_id: 0,
			name: '',
			sa1s: [],
			cda_id: 0,
			cda_name: '',
			is_provisional: false,
			is_royalty: false,
			description: '',
			status: null
		},
		loadingIndicator: ELoadingIndicatorStatus.None,
		isCdaDirtyForState: false,
		mapServiceSync: {
			inProgress: false,
			inProgressJob: null
		},
		sa1sAddedToCdar: [],
		sa1sRemovedFromCdar: [],
		mapColors: Config.MapColors,
		isShowMap: false,
		showAddSa1Modal: false
	};
	private map!: mapboxgl.Map;
	private start!: mapboxgl.Point;
	private current!: mapboxgl.Point;
	private box: any;
	private canvas!: HTMLElement;
	context!: React.ContextType<typeof NotificationContext>;
	private hoverState = {
		cda: 0,
		cdar: 0,
		sa1: 0,
		state: 0
	};
	private popup = new mapboxgl.Popup({
		closeButton: false,
		closeOnClick: false,
		offset: 10
	});

	componentDidMount = async () => {
		const job = await this.getInProgressMapServiceSyncJob();
		if (job) {
			this.activateReadOnlyMode(job);
		}
		await this.getSystemMapColors();
		await this.getAustralianStates();
	};

	render(): JSX.Element {
		return (
			<Grid container direction="column" className="split-container">
				<Grid item xs>
					{this.state.loadingIndicator === ELoadingIndicatorStatus.ShowNavBarLoader && <LinearProgress />}
					<Split
						sizes={[40, 60]}
						minSize={250}
						expandToMin={false}
						gutterSize={10}
						gutterAlign="center"
						snapOffset={30}
						dragInterval={1}
						cursor="col-resize"
						className="split-parent split-horizontal"
						onDragEnd={() => this.map.resize()}
					>
						{/* sidebar */}
						<div className="split-item pl10 pt10">
							<AreaLoaderContainer isLoading={this.state.loadingIndicator === ELoadingIndicatorStatus.ShowAreaLoader}>
								<Grid direction="column" container style={{ height: '100%', width: '100%' }}>
									<Grid xs item container direction="column">
										<Typography className="mb10" color="secondary" variant="h5">
											Manage CDAR
										</Typography>
										<Grid item xs>
											<Paper elevation={0} className="paper-container stretch">
												<Grid container direction="column" className="stretch">
													{/** Filters */}
													<Grid container item alignItems="center" justify="space-between" spacing={2}>
														<Grid item xs>
															{/** State dropdown */}
															<FormControl style={{ width: '100%' }}>
																<InputLabel htmlFor="manage-cda-state-filter">Select a State</InputLabel>
																<Select
																	value={this.state.selectedAusStateId || ''}
																	labelId="manage-cda-state-filter"
																	onChange={this.onChangeAusStateDropdown}
																>
																	{this.state.ausStates.map((ausState) => {
																		return (
																			<MenuItem key={ausState.name} value={ausState.id}>
																				{ausState.name}
																			</MenuItem>
																		);
																	})}
																</Select>
															</FormControl>
														</Grid>
														<Grid item xs>
															{/** CDA Autocomplete */}
															<FormControl style={{ width: '100%' }}>
																<Autocomplete
																	inputValue={this.state.cdaAutocompleteInputValue}
																	onInputChange={this.onChangeCdaAutocompleteInput}
																	size="small"
																	onChange={this.onChangeCdaAutocomplete}
																	options={this.state.cda}
																	getOptionLabel={(option) => option.name}
																	renderInput={(params) => (
																		<TextField {...params} label="Search CDA" variant="standard" />
																	)}
																/>
															</FormControl>
														</Grid>
													</Grid>

													{/**CDAR Info View */}

													{this.renderCdaInfo()}

													{/**CDAR Info View/Form */}
													<div className="w100p mt10">
														<Grid container alignItems="center">
															<Grid item xs>
																<Typography variant="body2" color="secondary">
																	CDAR Info
																</Typography>
															</Grid>
															{this.showChangeCdarBtn() && (
																<Button
																	size="small"
																	disabled={this.isMapServiceSyncInProgress()}
																	color="secondary"
																	variant="outlined"
																	style={{
																		marginRight: 5
																	}}
																	onClick={this.onClickChangeCdar}
																>
																	Edit
																</Button>
															)}
															{this.showNewCdarBtn() && (
																<Button
																	disabled={this.isMapServiceSyncInProgress()}
																	size="small"
																	color="secondary"
																	variant="outlined"
																	onClick={this.onClickNewCdar}
																	style={{
																		marginRight: 5
																	}}
																>
																	NEW
																</Button>
															)}
															{this.showAddSa1Btn() && (
																<Button
																	disabled={this.isMapServiceSyncInProgress()}
																	size="small"
																	color="secondary"
																	variant="outlined"
																	onClick={this.onClickNewSA1}
																	style={{
																		marginRight: 5
																	}}
																>
																	Search SA1
																</Button>
															)}
															{this.showDeleteCdarBtn() && this.renderDeleteCdarButton()}
														</Grid>
													</div>
													<Divider light style={{ margin: '5px 0' }} />
													{!this.state.activityMode && (
														<Alert severity="info">
															Select a state to display layers on map and then select a CDAR within a CDA to view more
															detailed info here
														</Alert>
													)}
													{this.renderCdarInfo()}
													{this.renderCdarForm()}
												</Grid>
											</Paper>
										</Grid>
									</Grid>
									{/** footer */}
									<Grid item>{this.renderSidebarFooter()}</Grid>
								</Grid>
							</AreaLoaderContainer>
							{this.state.isCdaDirtyForState && (
								<InfoModal
									message={`${
										this.getSelectedState().name
									} has modified CDA's which are not yet synced with Map service. They need to be either submitted and synced with map service or they should be deleted before making any modifications to CDAR inside the state.`}
									onClickOkay={() => {
										this.setState({
											isCdaDirtyForState: false
										});
									}}
								/>
							)}
						</div>
						{/* map */}
						<div className="split-item">
							<AreaLoaderContainer isLoading={!this.state.isShowMap}>
								<Grid
									container
									direction="column"
									style={{
										height: 'calc(100vh - 48px)',
										width: '100%'
									}}
								>
									<Grid item xs>
										<Map onMapLoadComplete={this.onMapLoadComplete} onMapMove={this.onMapMove} />
									</Grid>
									<MapLegend items={this.getMapLegend()} />
								</Grid>
							</AreaLoaderContainer>
						</div>
					</Split>
					{this.state.showAddSa1Modal && (
						<AddSa1Modal
							onClickClose={this.onClickCloseModal}
							allSa1s={this.getAvailableSa1s()}
							onClickSubmitSa1={this.onClickSubmitSa1}
						/>
					)}
				</Grid>
			</Grid>
		);
	}

	getAvailableSa1s = () => {
		let availableSa1 = [...this.state.availableSa1, ...this.state.sa1sRemovedFromCdar];
		//suburbs added to cda need to made unavailalbe
		availableSa1 = availableSa1.filter((sa1) => {
			return !this.state.cdarForm.sa1s.find((s) => s.sa1_code === sa1.sa1_code);
		});

		return availableSa1.filter((e, i) => availableSa1.findIndex((a) => a['sa1_code'] === e['sa1_code']) === i);
	};

	onClickSubmitSa1 = (selSa1s: ISa1[]) => {
		const sa1s = [...this.state.cdarForm.sa1s];
		const sa1sAddedToCdar = [...this.state.sa1sAddedToCdar];
		selSa1s.forEach((selSa1: ISa1) => {
			const sa1 = {
				//these id's are dummy values and they will be replaced once
				//we recieve actual values from server
				id: new Date().getTime(),
				sa1_code: selSa1.sa1_code,
				is_dirty: true,
				status: null,
				agency_name: null,
				agency_id: null
			};
			this.addSa1InCdar(sa1);
			sa1s.push(sa1);
			sa1sAddedToCdar.push(sa1);
		});

		this.setState(
			{
				cdarForm: {
					...(this.state.cdarForm as ICdar),
					sa1s: sa1s
				},
				showAddSa1Modal: false,
				sa1sAddedToCdar: sa1sAddedToCdar
			},
			() => {
				this.updateAvailableSa1OnMapFromCdarForm();
				this.updateTmpSa1OnMapFromCdarForm();
			}
		);
	};

	addSa1InCdar = (sa1Val: ISa1) => {
		const sa1 = {
			//these id's are dummy values and they will be replaced once
			//we recieve actual values from server
			id: new Date().getTime(),
			sa1_code: sa1Val.sa1_code,
			is_dirty: true,
			status: null,
			agency_name: null,
			agency_id: null
		};
		//the new sa1 being added may have been earlier removed
		const sa1sRemoved = this.state.sa1sRemovedFromCdar.filter((s) => s.sa1_code !== sa1.sa1_code);
		this.updateSa1MapFeatureState(sa1.sa1_code, { newlyAddedToCdar: true });
		this.setState({
			sa1sRemovedFromCdar: sa1sRemoved
		});
	};

	onClickCloseModal = () => {
		this.setState({
			showAddSa1Modal: false
		});
	};

	getMapLegend = () => {
		return [
			{
				label: 'CDA',
				color: this.state.mapColors['cda']['outline'],
				type: 'boundary'
			},
			{
				label: 'CDAR',
				color: this.state.mapColors['cdar']['masterFill'],
				type: 'area',
				opacity: 0.2
			},
			{
				type: 'area',
				label: 'Selected Tmp CDAR',
				color: this.state.mapColors['cdar']['selected'],
				opacity: 0.4
			},
			{
				label: 'Tmp CDAR',
				type: 'area',
				color: this.state.mapColors['cdar']['tmpFill'],
				opacity: 0.4
			},
			{
				label: 'New SA1 in CDAR',
				type: 'area',
				color: this.state.mapColors['cdar']['newlyAddedToCdar'],
				opacity: 0.7
			},
			{
				label: 'Available SA1',
				color: this.state.mapColors['sa1']['outlineAvailable'],
				type: 'boundary'
			},
			{
				label: 'Assigned SA1',
				color: this.state.mapColors['sa1']['outline'],
				type: 'boundary'
			}
		];
	};

	renderSidebarFooter = () => {
		if (this.state.mapServiceSync.inProgressJob) {
			return <MapSyncInProgressMessage job={this.state.mapServiceSync.inProgressJob} />;
		}

		return (
			<Grid style={{ height: 50 }} container justify="space-around" alignItems="center">
				<ToggleContent
					toggle={(show) => (
						<Button variant="outlined" color="primary" onClick={show}>
							SUBMIT TO MAP SERVICE
						</Button>
					)}
					content={(hide) => (
						<ConfirmModalWithMessage
							isLoading={this.state.loadingIndicator === ELoadingIndicatorStatus.ShowNavBarLoader}
							onClickCancel={hide}
							onClickConfirm={async () => {
								await this.submitToMapService();
								hide();
							}}
							message={'Are you sure you want to submit changes to map service'}
						/>
					)}
				/>
				<ToggleContent
					toggle={(show) => (
						<Button variant="outlined" color="primary" onClick={() => this.onClickShowSyncChanges(show)}>
							View Changelog
						</Button>
					)}
					content={(hide) => (
						<CdarChangesToSyncModal cda={this.state.cda} onClose={hide} ausState={this.getSelectedState()} />
					)}
				/>
			</Grid>
		);
	};

	onClickShowSyncChanges = (showSyncModal: () => void) => {
		if (!this.state.selectedAusStateId) {
			this.context.addNotification({
				message: 'Select a state to view changes that are yet to be synced',
				type: ENotificationTypes.Info
			});
			return;
		}

		showSyncModal();
	};

	activateReadOnlyMode = (job: IMapSyncJob) => {
		this.setState({
			mapServiceSync: {
				inProgress: true,
				inProgressJob: job
			}
		});
	};

	getInProgressMapServiceSyncJob = async () => {
		let job = null;
		try {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.ShowAreaLoader
			});
			const syncJobs = await CommonApiService.getMapServiceSyncJobs();
			job = syncJobs.find((job) => job.status === EMapServiceJobStatus.InProgress);
		} catch (e) {
			throw new Error('Failed to retrieve map service sync jobs');
		} finally {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		}

		return job;
	};
	getAustralianStates = async () => {
		try {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.ShowAreaLoader
			});
			const states = await CommonApiService.getAustraliaStates();
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None,
				ausStates: states
			});
		} catch (err) {
			console.error(err);
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		}
	};
	getSystemMapColors = async () => {
		const apiRes: any = await CommonApiService.getSystemSettings('map_colors');
		if (!apiRes?.success) {
			return;
		}
		this.setState({
			mapColors: apiRes.data[0].json.MapColors,
			isShowMap: true
		});
	};

	onChangeAusStateDropdown = (ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
		const stateId = ev.target.value as number;
		this.selectState(stateId);
	};

	selectState = (stateId: number) => {
		if (this.state.selectedCdaId) {
			//remove selection of existing cda
			this.updateCdaMapFeatureState(this.getSelectedCda().cda_id, { clicked: false });
		}
		//need to clear any existing tmp cdar that are already drawn on map
		this.hideAllTmpCdarInCdaOnMap();
		this.hideAllTmpCdarSa1SelectionsOnMap();
		this.hideAllMasterCdarInCdaOnMap();
		this.hideAllSa1NewlyAddedToCdar();
		this.hideAllSa1InMasterCdarOnMap();

		this.setState(
			{
				selectedAusStateId: stateId,
				activityMode: EActivityMode.NoActivity,
				cda: [],
				cdar: [],
				sa1sAddedToCdar: [],
				sa1sRemovedFromCdar: [],
				cdaAutocompleteInputValue: '',
				selectedCdaId: null
			},
			async () => {
				this.fitMapToStateBounds();
				const isCdaDirty = await this.isCdaDirtyForState();
				if (isCdaDirty) {
					this.setState({
						isCdaDirtyForState: true
					});
					return;
				}
				await this.getCdaByState(stateId);
				this.showCdaByStateOnMap();
			}
		);
	};

	isCdaDirtyForState = async () => {
		let isCdaDirty = false;
		if (!this.state.selectedAusStateId) {
			throw new Error('State selection is required to get dirty cda in a state');
		}
		try {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.ShowNavBarLoader
			});
			const res = await CdaService.getDirtyCda(this.state.selectedAusStateId);
			if (res.length > 0) {
				isCdaDirty = true;
			}
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		} catch (err) {
			console.error(err);
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		}
		return isCdaDirty;
	};

	getCdaByState = async (stateId: number) => {
		try {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.ShowNavBarLoader
			});
			const res = await CdaService.getCda({ stateId, includeTemp: false, includingSuburbs: false });
			const cda = res.cda;

			this.setState({
				cda,
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		} catch (err) {
			console.error(err);
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		}
	};
	onChangeCdaAutocomplete = (ev: unknown, value: ICda | null, reason: AutocompleteChangeReason) => {
		if (reason !== 'select-option') {
			return false;
		}

		if (!value) {
			return;
		}
		const cdaId = value?.cda_id;
		this.selectCda(cdaId);
	};

	onChangeCdaAutocompleteInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		this.setState({
			cdaAutocompleteInputValue: value
		});
	};

	showNewCdarBtn = (): boolean => {
		if (this.state.activityMode === EActivityMode.NoActivity) {
			return true;
		}

		if (this.state.activityMode === EActivityMode.ViewCdar) {
			return true;
		}

		return false;
	};

	showAddSa1Btn = (): boolean => {
		if (this.state.availableSa1.length === 0) {
			return false;
		}
		if (this.state.activityMode === EActivityMode.NewCdar || this.state.activityMode === EActivityMode.ChangeCdar) {
			return true;
		}
		return false;
	};

	showChangeCdarBtn = (): boolean => {
		if (this.state.activityMode === EActivityMode.ViewCdar) {
			return true;
		}

		return false;
	};

	showDeleteCdarBtn = (): boolean => {
		if (this.state.activityMode === EActivityMode.ViewCdar) {
			return true;
		}

		return false;
	};

	renderDeleteCdarButton = () => {
		return (
			<ToggleContent
				toggle={(show) => (
					<DeleteButton disabled={this.isMapServiceSyncInProgress()} size="small" variant="outlined" onClick={show}>
						DELETE
					</DeleteButton>
				)}
				content={(hide) => (
					<ConfirmModalWithMessage
						isLoading={this.state.loadingIndicator === ELoadingIndicatorStatus.ShowNavBarLoader}
						onClickCancel={hide}
						onClickConfirm={async () => {
							await this.deleteCdar();
							hide();
						}}
						message={`Are you sure you want to delete ${this.state.cdarSelectedOnMap?.name} CDAR from ${
							this.getSelectedCda().name
						} CDA`}
					/>
				)}
			/>
		);
	};

	onClickChangeCdar = () => {
		this.setState({
			activityMode: EActivityMode.ChangeCdar
		});
	};

	onClickNewSA1 = () => {
		if (!this.state.selectedCdaId) {
			this.context.addNotification({
				message: 'Please select a CDA to create new CDAR',
				type: ENotificationTypes.Info
			});
			return;
		}
		this.setState({
			showAddSa1Modal: true
		});
	};

	onClickNewCdar = () => {
		if (!this.state.selectedCdaId) {
			this.context.addNotification({
				message: 'Please select a CDA to create new CDAR',
				type: ENotificationTypes.Info
			});
			return;
		}

		const cdarFormInitialValues = this.getInitialCdarFormValues();
		cdarFormInitialValues.cda_id = this.getSelectedCda().cda_id;
		cdarFormInitialValues.cda_name = this.getSelectedCda().name;

		this.hideAllTmpCdarSa1SelectionsOnMap();

		this.setState({
			cdarForm: cdarFormInitialValues,
			cdarSelectedOnMap: null,
			activityMode: EActivityMode.NewCdar
		});
	};

	deleteCdar = async () => {
		if (!this.state.cdarSelectedOnMap) {
			throw new Error('No cdar is selected, cannot perform delete operation');
		}

		if (this.state.cdarSelectedOnMap.is_default) {
			this.context.addNotification({
				message: `ERROR - CDAR: ${this.state.cdarSelectedOnMap.name} is a default CDAR for the CDA. Default CDAR cannot be deleted`,
				type: ENotificationTypes.Error
			});
			return;
		}

		const doesCdarHaveSoldSuburb = this.state.cdarSelectedOnMap.sa1s.find(
			(s) => s.status && this.state.cdarSelectedOnMap?.is_royalty
		);
		if (doesCdarHaveSoldSuburb) {
			this.context.addNotification({
				message: `ERROR - CDAR: ${this.state.cdarSelectedOnMap.name} has Sa1:${doesCdarHaveSoldSuburb.sa1_code} which belongs to royalty area and is sold or reserved.`,
				type: ENotificationTypes.Error
			});
			return;
		}

		try {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.ShowNavBarLoader
			});
			if (this.state.cdarSelectedOnMap.cdar_id !== 0) {
				await CdarService.deleteCdar(this.state.cdarSelectedOnMap.cdar_id);
			} else {
				await CdarService.deleteTmpCdar(this.state.cdarSelectedOnMap.tmp_cdar_id);
			}

			const newCdarArr = this.state.cdar.filter((cdar: ICdar) => {
				//the cdar being deleted can be a brand new cdar which has cdar_id=0
				//and it can belong to a CDA full of brand new cdar's, all with cdar_id=0
				//hence we cannot filter using cda_id all the time.
				if (this.state.cdarSelectedOnMap?.tmp_cdar_id !== 0) {
					return cdar.tmp_cdar_id !== this.state.cdarSelectedOnMap?.tmp_cdar_id;
				} else {
					return cdar.cdar_id !== this.state.cdarSelectedOnMap?.cdar_id;
				}
			});

			this.setState(
				{
					activityMode: EActivityMode.NoActivity,
					cdarForm: this.getInitialCdarFormValues(),
					cdarSelectedOnMap: null,
					cdar: newCdarArr,
					availableSa1: [...this.state.availableSa1, ...this.state.cdarSelectedOnMap.sa1s],
					loadingIndicator: ELoadingIndicatorStatus.None
				},
				() => {
					//refresh map to show changes
					this.hideAllTmpCdarSa1SelectionsOnMap();
					this.showMasterCdarByCdaOnMap();
					this.showTmpCdarByCdaOnMap();
					this.showAvailableSa1ByCdarOnMap();
					this.updateSa1OnMapByMasterCdar();
				}
			);

			this.context.addNotification({
				message: `CDAR deleted successfully`,
				type: ENotificationTypes.Success
			});
		} catch (err) {
			console.error(err);
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		}
	};
	renderSA1Count = (area: ICdar) => {
		if (area.sa1s.length === 0) {
			return (
				<Grid container justify="space-between">
					<span>Available SA1s: NA</span>
					<span>Sold SA1s:NA</span>
					<span>Reserved SA1s: NA</span>
					<span>Total SA1s: NA</span>
				</Grid>
			);
		}
		const { availableSa1Count, soldSa1Count, reservedSa1Count } = this.calculateSa1Count(area.sa1s);
		const totalSa1Count = availableSa1Count + soldSa1Count + reservedSa1Count;
		return (
			<Grid container justify="space-between">
				<span>Available SA1s : {availableSa1Count}</span>
				<span>Sold SA1s: {soldSa1Count}</span>
				<span>Reserved SA1s: {reservedSa1Count}</span>
				<span>Total SA1s: {totalSa1Count}</span>
			</Grid>
		);
	};

	calculateSa1Count = (sa1s: ISa1[]) => {
		let availableSa1Count = 0;
		let soldSa1Count = 0;
		let reservedSa1Count = 0;

		sa1s.forEach((sa1) => {
			if (!sa1.status) {
				availableSa1Count = availableSa1Count + 1;
			}
			if (sa1.status === 'sold') {
				soldSa1Count = soldSa1Count + 1;
			}
			if (sa1.status === 'reserved') {
				reservedSa1Count = reservedSa1Count + 1;
			}
		});
		return {
			availableSa1Count,
			soldSa1Count,
			reservedSa1Count
		};
	};

	renderCdaInfo = () => {
		if (this.state.activityMode === EActivityMode.ChangeCdar || this.state.activityMode === EActivityMode.NewCdar) {
			return;
		}
		if (!this.state.selectedCdaId) {
			return (
				<Alert severity="info">
					Select a CDA either from dropdown or from map to view more information and CDARs contained inside it.
				</Alert>
			);
		}

		if (this.state.loadingIndicator === ELoadingIndicatorStatus.ShowFetchingCdarLoader) {
			return (
				<div className="mt10">
					<LoadingSpinner message="Fetching CDAR's.." />
				</div>
			);
		}

		if (this.state.cdar.length === 0) {
			return <Alert severity="warning">This CDA does not have any CDAR at the moment.</Alert>;
		}
		return (
			<Grid container item direction="column">
				<Typography color="secondary" variant="body2">
					List of CDARs in the selected CDA
				</Typography>
				<Divider light style={{ margin: '5px 0' }} />

				<RevAreaList
					height={'calc(100vh - 460px)'}
					areaList={this.state.cdar.map((cdar) => {
						return { ...cdar, key: cdar.cdar_id.toString() };
					})}
					onHoverListStart={this.onCdarListHoverStart}
					onHoverListEnd={this.onCdarListHoverEnd}
					onListItemClick={this.onClickCdarInList}
					rowContent={(area) => {
						return <ListItemText primary={area.name} secondary={this.renderSA1Count(area)} />;
					}}
				/>
			</Grid>
		);
	};

	onClickCdarInList = (cdar: ICdar) => {
		if (this.state.activityMode === EActivityMode.NewCdar || this.state.activityMode === EActivityMode.ChangeCdar) {
			return;
		}

		if (cdar.cdar_id !== 0 && !cdar.tmp_cdar_id) {
			this.selectMasterCdar(cdar.cdar_id);
		} else {
			this.selectTmpCdar(cdar.tmp_cdar_id);
		}
	};

	renderCdarInfo = () => {
		if (this.state.activityMode !== EActivityMode.ViewCdar || !this.state.cdarSelectedOnMap) {
			return null;
		}
		return (
			<CdarInfoView
				cdar={this.state.cdarSelectedOnMap}
				onSa1ListItemHoverStart={this.onSa1ListHoverStart}
				onSa1ListItemHoverEnd={this.onSa1ListHoverEnd}
				showNotification={this.showNotification}
			/>
		);
	};

	showNotification = (message: string, type: ENotificationTypes) => {
		this.context.addNotification({
			message: message,
			type: type
		});
	};

	renderCdarForm = () => {
		if (this.state.activityMode !== EActivityMode.ChangeCdar && this.state.activityMode !== EActivityMode.NewCdar) {
			return;
		}
		return (
			<ManageCdarForm
				initialValues={this.state.cdarForm}
				onClickCancel={this.onClickCancelCdarForm}
				onClickSave={this.onClickSaveCdarForm}
				onClickRemoveSa1FromCdar={this.onClickRemoveSa1FromCdarForm}
				onSa1ListItemHoverStart={this.onSa1ListHoverStart}
				onSa1ListItemHoverEnd={this.onSa1ListHoverEnd}
			/>
		);
	};

	onClickCancelCdarForm = () => {
		//clicking cancel when creating a new cda should close the form
		if (this.state.activityMode === EActivityMode.NewCdar) {
			this.setState(
				{
					cdarForm: this.getInitialCdarFormValues(),
					cdarSelectedOnMap: null,
					activityMode: EActivityMode.NoActivity,
					sa1sAddedToCdar: [],
					sa1sRemovedFromCdar: []
				},
				() => {
					this.hideAllTmpCdarSa1SelectionsOnMap();
					this.showMasterCdarByCdaOnMap();
					this.showAvailableSa1ByCdarOnMap();
					this.showSa1OnMapByMasterCdar();
					this.showTmpCdarByCdaOnMap();
				}
			);
			return;
		}

		if (!this.state.cdarSelectedOnMap) {
			throw new Error('No cdar selection found on clicking cancel');
		}

		//clicking cancel in edit should just undo the changes but keep the cda selected and in "view" state
		this.setState(
			{
				activityMode: EActivityMode.ViewCdar,
				cdarForm: this.state.cdarSelectedOnMap,
				sa1sAddedToCdar: [],
				sa1sRemovedFromCdar: []
			},
			() => {
				this.hideAllTmpCdarSa1SelectionsOnMap();
				this.showMasterCdarByCdaOnMap();
				this.showAvailableSa1ByCdarOnMap();
				this.showSa1OnMapByMasterCdar();
				this.showTmpCdarByCdaOnMap();
			}
		);
	};

	onClickSaveCdarForm = async (cdar: ICdar) => {
		if (cdar.tmp_cdar_id !== 0) {
			await this.updateCdar(cdar);
		} else {
			await this.createCdar(cdar);
		}
	};

	updateCdar = async (updatedCdar: ICdar) => {
		//let res: ICdar;

		this.setState({
			loadingIndicator: ELoadingIndicatorStatus.ShowNavBarLoader
		});

		try {
			/**
			 * Important - in order to update any cdar in manage cdar module, we need data for that
			 * cdar in tmp table. If user is doing this the first time for any cdar then that record won't be
			 * there. Hence we need to create one
			 *
			 * Essentially in that case we are actually creating a record rather than updating it.
			 * Presence of tmp_cdar_id indicates if cdar has data in tmp table, so if we do not have tmp_cdar_id
			 * then we create cdar in tmp table, if we have value of cdar_id then we can directly update
			 * tmp table
			 */
			/* if (!updatedCdar.tmp_cdar_id) {
				updatedCdar.cda_id = this.getSelectedCda().cda_id;
				res = await CdarService.createTmpCdar(updatedCdar);
				updatedCdar.tmp_cdar_id = res.tmp_cdar_id;
			} else { */
			await CdarService.updateTmpCdar(updatedCdar);
			//}

			let availableSa1 = [...this.state.availableSa1, ...this.state.sa1sRemovedFromCdar];

			//suburbs added to cda need to made unavailalbe
			availableSa1 = availableSa1.filter((suburb) => {
				return !this.state.cdarForm.sa1s.find((s) => s.sa1_code === suburb.sa1_code);
			});

			const newCdarArr = this.state.cdar.map((cdar) => {
				if (cdar.tmp_cdar_id !== updatedCdar.tmp_cdar_id) {
					return cdar;
				}

				return {
					...cdar,
					...updatedCdar
				};
			});

			this.setState(
				{
					activityMode: EActivityMode.ViewCdar,
					cdar: newCdarArr,
					availableSa1,
					cdarForm: this.getInitialCdarFormValues(),
					cdarSelectedOnMap: updatedCdar,
					sa1sAddedToCdar: [],
					sa1sRemovedFromCdar: [],
					loadingIndicator: ELoadingIndicatorStatus.None
				},
				() => {
					//refresh map to show changes
					//this.showSuburbsOnMapByState();
					//this.showCdaOnMapByState();
					this.hideAllSa1NewlyAddedToCdar();
					this.showMasterCdarByCdaOnMap();
					this.showTmpCdarByCdaOnMap();
					this.showAvailableSa1ByCdarOnMap();
					this.updateSa1OnMapByMasterCdar();
					this.selectTmpCdar(updatedCdar.tmp_cdar_id);
				}
			);
			this.context.addNotification({
				message: 'CDAR updated successfully',
				type: ENotificationTypes.Success
			});
		} catch (err) {
			console.error(err);
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		}
	};

	createCdar = async (cdar: ICdar) => {
		try {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.ShowNavBarLoader
			});
			const cdarFromApi = await CdarService.createTmpCdar(cdar);
			cdar.tmp_cdar_id = cdarFromApi.tmp_cdar_id;

			let availableSa1 = [...this.state.availableSa1, ...this.state.sa1sRemovedFromCdar];
			//suburbs added to cdar need to made unavailalbe
			availableSa1 = availableSa1.filter((suburb) => {
				return !this.state.sa1sAddedToCdar.find((s) => s.sa1_code === suburb.sa1_code);
			});

			//making changes to a master cdar will call this function as it needs to create entry into tmp table
			//once api call succeeds, we need to update existing cdar instead of adding new cdar. We check for
			//presence of cdar_id to decide this. cdar_id when creating a new cdar is always 0
			let newCdarArr: ICdar[] = [];
			if (cdar.cdar_id) {
				newCdarArr = this.state.cdar.map((c) => {
					if (cdar.cdar_id !== c.cdar_id) {
						return c;
					}

					return {
						...c,
						...cdar
					};
				});
			} else {
				newCdarArr = [...this.state.cdar, cdar];
			}
			this.setState(
				{
					loadingIndicator: ELoadingIndicatorStatus.None,
					cdar: newCdarArr,
					activityMode: EActivityMode.ViewCdar,
					availableSa1,
					cdarForm: this.getInitialCdarFormValues(),
					cdarSelectedOnMap: cdar,
					sa1sAddedToCdar: [],
					sa1sRemovedFromCdar: []
				},
				() => {
					this.hideAllSa1NewlyAddedToCdar();
					this.showTmpCdarByCdaOnMap();
					this.showMasterCdarByCdaOnMap();
					this.showAvailableSa1ByCdarOnMap();
					this.selectTmpCdar(cdar.tmp_cdar_id);
				}
			);

			this.context.addNotification({
				message: 'CDAR created successfully',
				type: ENotificationTypes.Success
			});
		} catch (err) {
			console.error(err);
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		}
	};
	onClickRemoveSa1FromCdarForm = (sa1: ISa1) => {
		this.deselectSa1(sa1.sa1_code);
	};

	deselectSa1 = async (sa1_code: number) => {
		const sa1ToBeRemoved = this.state.cdarForm.sa1s.find((s) => s.sa1_code === sa1_code);
		if (!sa1ToBeRemoved) {
			throw new Error('Clicked sa1 not found in cdar form sa1 array');
		}

		//suburb cannot be removed if it has sold sa1/cdar
		if (sa1ToBeRemoved && sa1ToBeRemoved.status && this.state.cdarForm.is_royalty) {
			this.context.addNotification({
				message: `Cannot remove this SA1 as it belongs to royalty area which is sold to a customer`,
				type: ENotificationTypes.Error
			});
			return;
		}

		const sa1s = this.state.cdarForm.sa1s.filter((s) => {
			return s.sa1_code !== sa1_code;
		});

		//the new sa1 being removed may have been added earlier
		const sa1sAdded = this.state.sa1sAddedToCdar.filter((s) => s.sa1_code !== sa1ToBeRemoved.sa1_code);

		//in case of tmp cda, the suburb would have fill background to show selected UI
		this.updateSa1MapFeatureState(sa1ToBeRemoved.sa1_code, { clicked: false });

		this.context.addNotification({
			message: `SA1 ${sa1ToBeRemoved.sa1_code} removed from CDAR ${this.state.cdarForm?.name} successfully`,
			type: ENotificationTypes.Info
		});

		this.setState(
			{
				cdarForm: {
					...this.state.cdarForm,
					sa1s: sa1s
				},
				sa1sRemovedFromCdar: [...this.state.sa1sRemovedFromCdar, sa1ToBeRemoved],
				sa1sAddedToCdar: sa1sAdded
			},
			() => {
				this.updateSa1OnMapByMasterCdar();
				this.updateTmpSa1OnMapFromCdarForm();
				this.updateAvailableSa1OnMapFromCdarForm();
			}
		);
	};

	selectSa1 = (sa1FeatureProperties: ISa1GeoJsonFeatureProperties) => {
		const sa1 = {
			//these id's are dummy values and they will be replaced once
			//we recieve actual values from server
			id: new Date().getTime(),
			sa1_code: sa1FeatureProperties.sa1_code,
			is_dirty: true,
			status: null,
			agency_name: null,
			agency_id: null
		};

		//the new sa1 being added may have been earlier removed
		const sa1sRemoved = this.state.sa1sRemovedFromCdar.filter((s) => s.sa1_code !== sa1.sa1_code);

		this.updateSa1MapFeatureState(sa1.sa1_code, { newlyAddedToCdar: true });

		this.setState(
			{
				cdarForm: {
					...(this.state.cdarForm as ICdar),
					sa1s: [...this.state.cdarForm.sa1s, sa1]
				},
				sa1sAddedToCdar: [...this.state.sa1sAddedToCdar, sa1],
				sa1sRemovedFromCdar: sa1sRemoved
			},
			() => {
				this.updateAvailableSa1OnMapFromCdarForm();
				this.updateTmpSa1OnMapFromCdarForm();
			}
		);

		this.context.addNotification({
			message: `SA1 ${sa1.sa1_code} added to CDAR ${this.state.cdarForm?.name} successfully`,
			type: ENotificationTypes.Info
		});

		//this.updateSuburbMapFeatureState(sa1.abs_id);
	};

	showCdaByStateOnMap = () => {
		let masterCdaIds = this.state.cda.map((cda) => cda.cda_id);

		if (masterCdaIds.length === 0) {
			masterCdaIds = [0];
		}

		//update layer filter (async operation as it goes to GPU via worker thread)
		this.map.setFilter(EMapLayers.CdaFill, ['match', ['get', 'cda_id'], masterCdaIds, true, false]);
		this.map.setFilter(EMapLayers.CdaOutline, ['match', ['get', 'cda_id'], masterCdaIds, true, false]);
	};

	showTmpCdarByCdaOnMap = () => {
		let tmpCdarSa1Codes = this.state.cdar
			.filter((cdar) => cdar.tmp_cdar_id)
			.flatMap((cdar) => cdar.sa1s.map((sa1) => sa1.sa1_code));

		if (tmpCdarSa1Codes.length === 0) {
			tmpCdarSa1Codes = [0];
		}

		//update layer filter (async operation as it goes to GPU via worker thread)
		this.map.setFilter(EMapLayers.TmpCdarFill, ['match', ['get', 'sa1_code'], tmpCdarSa1Codes, true, false]);
		this.map.setFilter(EMapLayers.TmpCdarOutline, ['match', ['get', 'sa1_code'], tmpCdarSa1Codes, true, false]);
	};

	showAvailableSa1ByCdarOnMap = () => {
		let availableSa1Codes = this.state.availableSa1.map((sa1) => sa1.sa1_code);

		if (availableSa1Codes.length === 0) {
			availableSa1Codes = [0];
		}

		//update layer filter (async operation as it goes to GPU via worker thread)
		this.map.setFilter(EMapLayers.AvailableSa1Fill, ['match', ['get', 'sa1_code'], availableSa1Codes, true, false]);
		this.map.setFilter(EMapLayers.AvailableSa1Outline, ['match', ['get', 'sa1_code'], availableSa1Codes, true, false]);
	};

	showMasterCdarByCdaOnMap = () => {
		let masterCdarIds = this.state.cdar.filter((cdar) => !cdar.tmp_cdar_id).map((cdar) => cdar.cdar_id);

		if (masterCdarIds.length === 0) {
			masterCdarIds = [0];
		}

		//update layer filter (async operation as it goes to GPU via worker thread)
		this.map.setFilter(EMapLayers.MasterCdarFill, ['match', ['get', 'cdar_id'], masterCdarIds, true, false]);
		this.map.setFilter(EMapLayers.MasterCdarOutline, ['match', ['get', 'cdar_id'], masterCdarIds, true, false]);
	};

	showSa1OnMapByMasterCdar = () => {
		const cdar = this.state.cdar
			.filter((cdar) => !cdar.tmp_cdar_id) //suburbs are to be shown inside master cda only
			.find((cdar) => cdar.cdar_id === this.state.cdarSelectedOnMap?.cdar_id);

		if (!cdar) {
			return;
		}

		let sa1Ids = cdar.sa1s.map((sa1) => sa1.sa1_code);

		if (sa1Ids.length === 0) {
			sa1Ids = [0];
		}

		this.map.setFilter(EMapLayers.MasterCdarSa1Fill, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
		this.map.setFilter(EMapLayers.MasterCdarSa1Outline, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
	};

	hideAllTmpCdarSa1SelectionsOnMap = () => {
		this.state.cdar.forEach((cdar) => {
			cdar.sa1s.forEach((s) => {
				this.updateSa1MapFeatureState(s.sa1_code, { clicked: false });
			});
		});
	};

	hideAllSa1NewlyAddedToCdar = () => {
		this.state.cdar.forEach((cdar) => {
			cdar.sa1s.forEach((s) => {
				this.updateSa1MapFeatureState(s.sa1_code, { newlyAddedToCdar: false });
			});
		});
	};

	/**
	 * Name may be misleading for this function as we actually clear out layers for SA1.
	 * There is nothing such as a temporary CDAR visually, we combine SA1's to visually
	 * show a temp cdar.
	 */
	hideAllTmpCdarInCdaOnMap = () => {
		this.map.setFilter(EMapLayers.TmpCdarFill, ['match', ['get', 'sa1_code'], [0], true, false]);
		this.map.setFilter(EMapLayers.TmpCdarOutline, ['match', ['get', 'sa1_code'], [0], true, false]);
	};

	hideAllMasterCdarInCdaOnMap = () => {
		this.map.setFilter(EMapLayers.MasterCdarFill, ['match', ['get', 'cdar_id'], [0], true, false]);
		this.map.setFilter(EMapLayers.MasterCdarOutline, ['match', ['get', 'cdar_id'], [0], true, false]);
	};

	hideAllSa1InMasterCdarOnMap = () => {
		this.map.setFilter(EMapLayers.MasterCdarSa1Fill, ['match', ['get', 'sa1_code'], [0], true, false]);
		this.map.setFilter(EMapLayers.MasterCdarSa1Outline, ['match', ['get', 'sa1_code'], [0], true, false]);
	};

	showSelectedTmpCdarSa1OnMap = () => {
		this.state.cdarForm.sa1s.forEach((s) => {
			this.updateSa1MapFeatureState(s.sa1_code, { clicked: true });
		});
	};

	/**
	 * this function updates sa1 layer only inside the master cdar when adding/removing sa1
	 * from cdar.
	 * IMPORTANT - it gets sa1 data from cda record in this.state.cdarForm
	 * as that is where the latest data is when user is editing
	 */
	updateSa1OnMapByMasterCdar = () => {
		let sa1Ids: number[] = [];

		/**
		 * Internal suburb boundaries should be rendered only when the cda is master CDA
		 * This function gets called from multiple places and one of them is when CDA is updated
		 * If CDA is updated, then it will no longer be a master CDA and hence we can no longer
		 * show suburb boundaries
		 *
		 * Below if condition checks above scenario
		 */
		if (!this.state.cdarForm.tmp_cdar_id) {
			sa1Ids = this.state.cdarForm.sa1s.map((sa1) => sa1.sa1_code);
		}

		if (sa1Ids.length === 0) {
			//mapbox layer filter does not like empty array without ''
			sa1Ids = [0];
		}

		this.map.setFilter(EMapLayers.MasterCdarSa1Fill, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
		this.map.setFilter(EMapLayers.MasterCdarSa1Outline, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
	};

	updateTmpSa1OnMapFromCdarForm = () => {
		let sa1LayerFeatureIds = this.state.cdar
			//this filter is because for cdar being edited, this.state.cdar array will not have
			//latest value.
			.filter((cdar) => cdar.tmp_cdar_id && this.state.cdarForm.tmp_cdar_id !== cdar.tmp_cdar_id)
			.flatMap((cdar) => cdar.sa1s.map((s) => s.sa1_code));

		//get latest sa1 array for cdar being edited from cdarForm
		const sa1sFromCdaForm = this.state.cdarForm.sa1s.map((s) => s.sa1_code);
		sa1LayerFeatureIds = [...sa1LayerFeatureIds, ...sa1sFromCdaForm];

		if (sa1LayerFeatureIds.length === 0) {
			sa1LayerFeatureIds = [0];
		}

		this.map.setFilter(EMapLayers.TmpCdarFill, ['match', ['get', 'sa1_code'], sa1LayerFeatureIds, true, false]);
		this.map.setFilter(EMapLayers.TmpCdarOutline, ['match', ['get', 'sa1_code'], sa1LayerFeatureIds, true, false]);
	};

	updateAvailableSa1OnMapFromCdarForm = () => {
		let sa1Ids = this.state.availableSa1.map((s) => s.sa1_code);
		const sa1IdsNewlyAddedToCdar = this.state.sa1sAddedToCdar.map((s) => s.sa1_code);
		const sa1IdsNewlyRemovedFromCdar = this.state.sa1sRemovedFromCdar.map((s) => s.sa1_code);

		//suburbs removed are available
		sa1Ids = [...sa1Ids, ...sa1IdsNewlyRemovedFromCdar];

		//suburbs added to cda need to made unavailalbe
		sa1Ids = sa1Ids.filter((id) => {
			return !sa1IdsNewlyAddedToCdar.includes(id);
		});
		sa1Ids = sa1Ids.filter(function (value, index, array) {
			return array.indexOf(value) === index;
		});

		if (sa1Ids.length === 0) {
			sa1Ids = [0];
		}

		this.map.setFilter(EMapLayers.AvailableSa1Fill, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
		this.map.setFilter(EMapLayers.AvailableSa1Outline, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
	};
	getSelectedState = () => {
		const state = this.state.ausStates.find((s) => this.state.selectedAusStateId === s.id);
		if (!state) {
			throw new Error('Unable to find state for showing suburbs');
		}
		return state;
	};

	getSelectedCda = () => {
		const cda = this.state.cda.find((cda) => this.state.selectedCdaId === cda.cda_id);
		if (!cda) {
			throw new Error('Failed to find selected cda');
		}
		return cda;
	};

	getCdarByCda = async () => {
		if (!this.state.selectedCdaId) {
			throw Error('Cda id not found. It is required when fetching Cdar');
		}
		try {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.ShowFetchingCdarLoader
			});
			const res = await CdarService.getCdar({
				cdaId: this.state.selectedCdaId,
				includeTemp: true,
				including_sold_stats: true
			});
			let cdar = res.cdar;

			//sa1sAdded and sa1sRemoved are required client side for sa1 map updates. They are not
			//sent in response model. Hence to make sure both arrays are initialized correctly, we setup up
			//default values here
			cdar = cdar.map((cdar) => {
				return { ...cdar, sa1sAdded: [], sa1sRemoved: [] };
			});
			this.setState({
				cdar,
				availableSa1: res.not_assigned_sa1s,
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		} catch (err) {
			console.error(err);
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		}
		return;
	};

	getInitialCdarFormValues = (): ICdar => {
		return {
			tmp_cdar_id: 0,
			cdar_id: 0,
			name: '',
			sa1s: [],
			cda_id: 0,
			cda_name: '',
			is_provisional: false,
			is_royalty: false,
			description: '',
			status: null
		};
	};

	selectCda = (cdaId: number) => {
		const cda = this.state.cda.find((cda) => cda.cda_id === cdaId);
		if (!cda) {
			throw new Error('Could not find cda record of clicked cda feature.');
		}

		if (cda.bounds && cda.bounds !== '') {
			const bounds = JSON.parse(cda.bounds);
			this.fitMapToBoundsWithPadding(bounds);
		}

		//de-select any previous cda selection if available
		if (this.state.selectedCdaId) {
			//todo
			this.updateCdaMapFeatureState(this.getSelectedCda().cda_id, { clicked: false });
			this.hideAllMasterCdarInCdaOnMap();
			this.hideAllTmpCdarSa1SelectionsOnMap();
			this.hideAllSa1InMasterCdarOnMap();
		}

		this.updateCdaMapFeatureState(cda.cda_id);

		this.setState(
			{
				selectedCdaId: cda.cda_id,
				cdaAutocompleteInputValue: cda.name,
				cdar: [],
				cdarForm: this.getInitialCdarFormValues(),
				cdarSelectedOnMap: null,
				sa1sAddedToCdar: [],
				sa1sRemovedFromCdar: [],
				activityMode: EActivityMode.NoActivity
			},
			async () => {
				await this.getCdarByCda();
				this.showMasterCdarByCdaOnMap();
				this.showAvailableSa1ByCdarOnMap();
				this.showTmpCdarByCdaOnMap();
			}
		);
	};

	selectMasterCdar = (cdarId: number) => {
		const cdar = this.state.cdar.find((cdar) => cdar.cdar_id === cdarId);
		if (!cdar) {
			throw new Error('Could not find master cdar record of clicked cdar feature.');
		}

		if (cdarId === this.state.cdarSelectedOnMap?.cdar_id) {
			return;
		}
		// add property status in feature state
		this.state.cdar.forEach((c) => {
			c.sa1s.forEach((f) => {
				if (f.status === ERevAreaSaleStatus.Sold) {
					this.updateSa1MapFeatureState(f.sa1_code, { status: true });
				}
			});
		});

		//de-select any previous cda selection if available
		if (this.state.cdarSelectedOnMap?.cdar_id) {
			this.updateCdarMapFeatureState(this.state.cdarSelectedOnMap.cdar_id, { clicked: false });
			//this.hideAllMasterCdarInCdaOnMap();
			this.hideAllTmpCdarSa1SelectionsOnMap();
		}

		this.updateCdarMapFeatureState(cdarId);

		this.setState(
			{
				cdarSelectedOnMap: cdar,
				cdarForm: cdar,
				activityMode: EActivityMode.ViewCdar
			},
			() => {
				this.showSa1OnMapByMasterCdar();
			}
		);
	};

	selectTmpCdar = (cdarTmpId: number, sa1Code?: number) => {
		const cdar = this.state.cdar.find((cdar) => cdar.tmp_cdar_id === cdarTmpId);
		if (!cdar) {
			return;
			throw new Error('Failed to get cda of the clicked suburb');
		}

		if (this.state.activityMode === EActivityMode.ChangeCdar && sa1Code) {
			this.deselectSa1(sa1Code);
			return;
		}

		this.setState(
			{
				activityMode: EActivityMode.ViewCdar,
				cdarSelectedOnMap: cdar,
				cdarForm: cdar
			},
			() => {
				this.hideAllTmpCdarSa1SelectionsOnMap();
				this.showSelectedTmpCdarSa1OnMap();
			}
		);
	};

	onSa1ListHoverStart = (sa1: ISa1) => {
		this.updateSa1MapFeatureState(sa1.sa1_code, { hover: true });
	};

	onSa1ListHoverEnd = (sa1: ISa1) => {
		this.updateSa1MapFeatureState(sa1.sa1_code, { hover: false });
	};

	onCdarListHoverStart = (cdar: ICdar) => {
		if (cdar.cdar_id) {
			//master cda hover
			this.updateCdarMapFeatureState(cdar.cdar_id, { hover: true });
		}
	};

	onCdarListHoverEnd = (cdar: ICdar) => {
		if (cdar.cdar_id) {
			//master cda hover
			this.updateCdarMapFeatureState(cdar.cdar_id, { hover: false });
		}
	};

	isMapServiceSyncInProgress = () => {
		return this.state.mapServiceSync.inProgress;
	};

	submitToMapService = async () => {
		if (!this.state.selectedAusStateId) {
			this.context.addNotification({
				message: 'State needs to be selected to sync updates with map service',
				type: ENotificationTypes.Info
			});
			return;
		}

		try {
			this.setState({ loadingIndicator: ELoadingIndicatorStatus.ShowNavBarLoader });
			const syncStatus = await CdarService.submitToMapServiceForSync({ state_id: this.getSelectedState().id });
			if (syncStatus.success) {
				const job = await this.getInProgressMapServiceSyncJob();
				if (job) {
					this.activateReadOnlyMode(job);
				}
				this.context.addNotification({
					type: ENotificationTypes.Success,
					message: 'Job was successfully submitted to sync map service'
				});
			}

			//todo after submit, backend needs to return the submitted job details in response
			//and frontend will then enter read only state
			/* this.setState({
				latestMapServiceSync: syncStatus
			}); */
		} finally {
			this.setState({ loadingIndicator: ELoadingIndicatorStatus.None });
		}
	};

	/********************************* MAP METHODS ********************************/

	onMapLoadComplete = (map: mapboxgl.Map) => {
		this.map = map;
		this.addMapSources();
		this.addMapLayers();
		this.registerMapEvents();
		this.map.boxZoom.disable();
		this.initializeCanvasForDragArea();
	};

	initializeCanvasForDragArea = () => {
		this.canvas = this.map.getCanvasContainer();
		this.canvas.addEventListener('mousedown', this.onMouseDownFromMap, true);
	};

	// Return the xy coordinates of the mouse position
	getMapMousePosition = (e: MouseEvent) => {
		const rect = this.canvas.getBoundingClientRect();
		return new mapboxgl.Point(
			e.clientX - rect.left - this.canvas.clientLeft,
			e.clientY - rect.top - this.canvas.clientTop
		);
	};

	onMouseDownFromMap = (e: MouseEvent) => {
		// Continue the rest of the function if the shiftkey is pressed.
		if (!(e.shiftKey && e.button === 0)) return;

		// Disable default drag zooming when the shift key is held down.
		this.map.dragPan.disable();

		// Call functions for the following events
		document.addEventListener('mousemove', this.onMapMouseMove);
		document.addEventListener('mouseup', this.onMapMouseUp);
		document.addEventListener('keydown', this.onKeyDownFromMap);

		// Capture the first xy coordinates
		this.start = this.getMapMousePosition(e);
	};

	onMapMouseMove = (e: MouseEvent) => {
		// Capture the ongoing xy coordinates
		this.current = this.getMapMousePosition(e);
		// Append the box element if it doesnt exist
		if (!this.box) {
			this.box = document.createElement('div');
			this.box.classList.add('boxdraw');
			this.canvas.appendChild(this.box);
		}

		const minX = Math.min(this.start.x, this.current.x),
			maxX = Math.max(this.start.x, this.current.x),
			minY = Math.min(this.start.y, this.current.y),
			maxY = Math.max(this.start.y, this.current.y);

		// Adjust width and xy position of the box element ongoing
		const pos = 'translate(' + minX + 'px,' + minY + 'px)';
		this.box.style.transform = pos;
		this.box.style.webkitTransform = pos;
		this.box.style.width = maxX - minX + 'px';
		this.box.style.height = maxY - minY + 'px';
	};

	onKeyDownFromMap = (e: KeyboardEvent) => {
		// If the ESC key is pressed
		if (e.keyCode === 27) this.onMapDragFinish();
	};
	onMapMouseUp = (e: MouseEvent) => {
		// Capture xy coordinates
		this.onMapDragFinish([this.start, this.getMapMousePosition(e)]);
	};
	onMapDragFinish = (bbox?: any) => {
		// Remove these events now that onMapDragFinish has been called.
		document.removeEventListener('mousemove', this.onMapMouseMove);
		document.removeEventListener('keydown', this.onKeyDownFromMap);
		document.removeEventListener('mouseup', this.onMapMouseUp);

		if (this.state.activityMode !== EActivityMode.ChangeCdar && this.state.activityMode !== EActivityMode.NewCdar) {
			this.map.dragPan.enable();
			return;
		}
		if (this.box) {
			this.box.parentNode.removeChild(this.box);
			this.box = null;
		}

		// If bbox exists. use this value as the argument for `queryRenderedFeatures`
		if (bbox) {
			const features = this.map.queryRenderedFeatures(bbox, {
				layers: [EMapLayers.AvailableSa1Fill]
			});
			if (features.length === 0) {
				this.map.dragPan.enable();
				return;
			}
			let sa1Count = 0;
			const sa1s: number[] = [];
			let cdarFormSa1s = [...this.state.cdarForm.sa1s];
			let sa1sAddedToCdar = [...this.state.sa1sAddedToCdar];
			const addedSa1IdsInCdar = cdarFormSa1s.map((sa1) => sa1.sa1_code);
			let sa1sRemoved = this.state.sa1sRemovedFromCdar;
			features.forEach((feature) => {
				if (feature.properties) {
					const selSa1: number = feature.properties.sa1_code;
					if (!addedSa1IdsInCdar.includes(selSa1) && !sa1s.includes(selSa1)) {
						sa1Count = sa1Count + 1;
						const sa1 = this.getSa1Info(selSa1);
						cdarFormSa1s = [...cdarFormSa1s, sa1];
						sa1sAddedToCdar = [...sa1sAddedToCdar, sa1];
						sa1sRemoved = this.state.sa1sRemovedFromCdar.filter((s) => s.sa1_code !== selSa1);
						sa1s.push(selSa1);
						this.updateSa1MapFeatureState(selSa1, { newlyAddedToCdar: true });
					}
				}
			});
			this.setState(
				{
					cdarForm: {
						...(this.state.cdarForm as ICdar),
						sa1s: cdarFormSa1s
					},
					sa1sAddedToCdar: sa1sAddedToCdar,
					sa1sRemovedFromCdar: sa1sRemoved
				},
				() => {
					this.updateAvailableSa1OnMapFromCdarForm();
					this.updateTmpSa1OnMapFromCdarForm();
				}
			);

			this.context.addNotification({
				message: `${sa1Count} SA1(s) added to CDAR ${this.state.cdarForm?.name} successfully`,
				type: ENotificationTypes.Info
			});
		}
		this.map.dragPan.enable();
	};

	getSa1Info = (sa1: number) => {
		return {
			id: new Date().getTime(),
			sa1_code: sa1,
			is_dirty: true,
			status: null,
			agency_name: null,
			agency_id: null
		};
	};
	onMapMove = (e: any) => {
		if (!this.map) {
			return;
		}
		const features = this.map.queryRenderedFeatures(e.point, {
			layers: [EMapLayers.AvailableSa1Fill]
		});
		// Change the cursor style as a UI indicator.
		this.map.getCanvas().style.cursor = features.length ? 'pointer' : '';

		/* if (!features.length) {
			this.popup.remove();
			return;
		}  */
	};

	addMapSources = () => {
		this.map.addSource('sa1', {
			type: 'vector',
			url: `mapbox://${Config.MapTilesets.AustraliaSa1.Id}`,
			promoteId: { [Config.MapTilesets.AustraliaSa1.SourceLayer]: 'sa1_code' }
		});
		this.map.addSource('cda', {
			type: 'vector',
			url: `mapbox://${Config.MapTilesets.AustraliaCda.Id}`,
			promoteId: { [Config.MapTilesets.AustraliaCda.SourceLayer]: 'cda_id' }
		});
		this.map.addSource('cdar', {
			type: 'vector',
			url: `mapbox://${Config.MapTilesets.AustraliaCdar.Id}`,
			promoteId: { [Config.MapTilesets.AustraliaCdar.SourceLayer]: 'cdar_id' }
		});
		this.map.addSource('state', {
			type: 'vector',
			url: `mapbox://${Config.MapTilesets.AustraliaState.Id}?optimize=true`,
			promoteId: { [Config.MapTilesets.AustraliaState.SourceLayer]: 'state_id' }
		});
	};

	addMapLayers = () => {
		this.map.addLayer({
			id: EMapLayers.StateFill,
			type: 'fill',
			source: 'state',
			'source-layer': Config.MapTilesets.AustraliaState.SourceLayer,
			maxzoom: 5,
			paint: {
				'fill-color': Config.MapColors.available,
				'fill-outline-color': Config.MapColors.available
			}
		});

		this.map.addLayer(
			{
				id: EMapLayers.StateOutline,
				type: 'line',
				source: 'state',
				maxzoom: 5,
				'source-layer': Config.MapTilesets.AustraliaState.SourceLayer,
				layout: {
					'line-cap': 'square',
					'line-join': 'bevel',
					'line-round-limit': 1.05,
					'line-miter-limit': 2
				},
				paint: {
					'line-color': this.state.mapColors['state']['outline'],
					'line-width': 1
					//'line-width': ['case', ['boolean', ['feature-state', 'clicked'], false], 4, 2],
					//'line-dasharray': [2, 1]
				}
			},
			'road-label'
		);
		this.map.addLayer({
			id: EMapLayers.StateHoverOutline,
			type: 'line',
			source: 'state',
			maxzoom: 5,
			'source-layer': Config.MapTilesets.AustraliaState.SourceLayer,
			layout: {
				'line-cap': 'round',
				'line-join': 'round',
				'line-round-limit': 1.05
			},
			paint: {
				'line-color': this.state.mapColors['state']['outline'],
				'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.5, 0],
				'line-offset': 2,
				'line-width': 3
			}
		});

		this.map.addLayer({
			id: 'sa1-outline',
			type: 'line',
			source: 'sa1',
			'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
			filter: ['match', ['get', 'sa1_code'], [''], true, false],
			paint: {
				'line-color': this.state.mapColors['suburb']['outline']
			}
		});

		this.map.addLayer({
			id: EMapLayers.CdaOutline,
			type: 'line',
			source: 'cda',
			'source-layer': Config.MapTilesets.AustraliaCda.SourceLayer,
			filter: ['match', ['get', 'cda_id'], [''], true, false],
			layout: {
				'line-cap': 'square',
				'line-join': 'bevel',
				'line-round-limit': 1.05,
				'line-miter-limit': 2
			},
			paint: {
				'line-color': this.state.mapColors['cda']['outline'],
				'line-width': ['case', ['boolean', ['feature-state', 'clicked'], false], 4, 2]
			}
		});

		this.map.addLayer({
			id: EMapLayers.CdaFill,
			type: 'fill',
			source: 'cda',
			'source-layer': Config.MapTilesets.AustraliaCda.SourceLayer,
			filter: ['match', ['get', 'cda_id'], [''], true, false],
			paint: {
				//'line-color': Config.MapColors.cda.outline
				'fill-color': Config.MapColors.available,
				'fill-outline-color': Config.MapColors.available
			}
		});

		this.map.addLayer({
			id: EMapLayers.CdaHoverOutline,
			type: 'line',
			source: 'cda',
			'source-layer': Config.MapTilesets.AustraliaCda.SourceLayer,
			layout: {
				'line-cap': 'round',
				'line-join': 'round',
				'line-round-limit': 1.05
			},
			paint: {
				'line-color': this.state.mapColors['cda']['outline'],
				'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.3, 0],
				'line-offset': 2,
				'line-width': 6
			}
		});

		this.map.addLayer(
			{
				id: EMapLayers.AvailableSa1Outline,
				type: 'line',
				source: 'sa1',
				'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
				filter: ['match', ['get', 'sa1_code'], [''], true, false],
				paint: {
					'line-color': this.state.mapColors['sa1']['outlineAvailable'],
					'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 4, 2]
				}
			},
			EMapLayers.CdaOutline
		);

		this.map.addLayer(
			{
				id: EMapLayers.AvailableSa1Fill,
				type: 'fill',
				source: 'sa1',
				'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
				filter: ['match', ['get', 'sa1_code'], [''], true, false],
				paint: {
					'fill-outline-color': Config.MapColors.available,
					'fill-color': Config.MapColors.available
				}
			},
			EMapLayers.CdaOutline
		);

		this.map.addLayer({
			id: EMapLayers.TmpCdarOutline,
			type: 'line',
			source: 'sa1',
			'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
			filter: ['match', ['get', 'sa1_code'], [''], true, false],
			paint: {
				'line-color': this.state.mapColors['sa1']['outline'],
				'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 4, 0]
			}
		});

		this.map.addLayer({
			id: EMapLayers.TmpCdarFill,
			type: 'fill',
			source: 'sa1',
			'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
			filter: ['match', ['get', 'sa1_code'], [''], true, false],
			paint: {
				'fill-outline-color': Config.MapColors.available,
				'fill-color': [
					'case',
					['==', ['feature-state', 'newlyAddedToCdar'], true],
					this.state.mapColors['cdar']['newlyAddedToCdar'],
					['==', ['feature-state', 'clicked'], true],
					this.state.mapColors['cdar']['selected'],
					this.state.mapColors['cdar']['tmpFill']
				],
				'fill-opacity': [
					'case',
					['==', ['feature-state', 'newlyAddedToCdar'], true],
					0.7,
					['==', ['feature-state', 'clicked'], true],
					0.4,
					0.4
				]
			}
		});

		this.map.addLayer(
			{
				id: EMapLayers.MasterCdarOutline,
				type: 'line',
				source: 'cdar',
				'source-layer': Config.MapTilesets.AustraliaCdar.SourceLayer,
				filter: ['match', ['get', 'cdar_id'], [''], true, false],
				layout: {
					'line-cap': 'square',
					'line-join': 'bevel',
					'line-round-limit': 1.05,
					'line-miter-limit': 2
				},
				paint: {
					'line-color': this.state.mapColors['cdar']['outline'],
					'line-width': ['case', ['boolean', ['feature-state', 'clicked'], false], 4, 2]
				}
			},
			EMapLayers.CdaOutline
		);

		this.map.addLayer(
			{
				id: EMapLayers.MasterCdarFill,
				type: 'fill',
				source: 'cdar',
				'source-layer': Config.MapTilesets.AustraliaCdar.SourceLayer,
				filter: ['match', ['get', 'cdar_id'], [''], true, false],
				paint: {
					//'line-color': Config.MapColors.cda.outline
					'fill-color': this.state.mapColors['cdar']['masterFill'],
					'fill-outline-color': Config.MapColors.available,
					'fill-opacity': 0.2
				}
			},
			EMapLayers.CdaOutline
		);

		this.map.addLayer({
			id: EMapLayers.MasterCdarHoverOutline,
			type: 'line',
			source: 'cdar',
			'source-layer': Config.MapTilesets.AustraliaCdar.SourceLayer,
			layout: {
				'line-cap': 'round',
				'line-join': 'round',
				'line-round-limit': 1.05
			},
			paint: {
				'line-color': this.state.mapColors['cdar']['outline'],
				'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.8, 0],
				'line-offset': 2,
				'line-width': ['interpolate', ['exponential', 1], ['zoom'], 14, 4, 16, 8]
			}
		});
		this.map.addLayer({
			id: EMapLayers.MasterCdarSa1Outline,
			type: 'line',
			source: 'sa1',
			'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
			filter: ['match', ['get', 'sa1_code'], [''], true, false],
			paint: {
				'line-color': this.state.mapColors['sa1']['outline'],
				'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 4, 1]
			}
		});

		this.map.addLayer({
			id: EMapLayers.MasterCdarSa1Fill,
			type: 'fill',
			source: 'sa1',
			'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
			filter: ['match', ['get', 'sa1_code'], [''], true, false],
			paint: {
				'fill-outline-color': [
					'case',
					['boolean', ['feature-state', 'status'], false],
					'#002642',
					Config.MapColors.available
				],
				'fill-color': ['case', ['boolean', ['feature-state', 'status'], false], '#ff6400', Config.MapColors.available],
				'fill-opacity': 0.6
			}
		});
	};

	registerMapEvents = () => {
		this.registerMapHoverEvent();
		this.registerMapClickEvent();
		this.registerMapMoveEvents();
	};

	registerMapMoveEvents = () => {
		this.map.on('movestart', () => {
			this.map.off('mousemove', this.onMapHoverStart);
		});
		this.map.on('moveend', () => {
			this.map.on('mousemove', this.onMapHoverStart);
		});
	};

	registerMapClickEvent = () => {
		this.map.on('click', (e) => {
			if (this.state.loadingIndicator !== ELoadingIndicatorStatus.None) {
				return;
			}
			const bbox: [PointLike, PointLike] = UtilityService.getBoundingboxFromMapMouseEvent(e);

			// set bbox as 5px reactangle area around clicked point
			const features = this.map.queryRenderedFeatures(bbox, {
				layers: [
					EMapLayers.CdaFill,
					EMapLayers.MasterCdarFill,
					EMapLayers.AvailableSa1Fill,
					EMapLayers.TmpCdarFill,
					EMapLayers.MasterCdarSa1Fill,
					EMapLayers.StateFill
				]
			});

			//Don't add click event for sold sa1s
			if (features[0].properties && features[0].properties.sa1_code) {
				for (const c of this.state.cdar) {
					const a = c.sa1s.find((f) => features[0].properties && features[0].properties.sa1_code === f.sa1_code);
					if (a?.status === ERevAreaSaleStatus.Sold) {
						return;
					}
				}
			}

			const isClickOnAvailableSa1 = features.find((f) => f.layer.id === EMapLayers.AvailableSa1Fill);
			if (isClickOnAvailableSa1) {
				const sa1 = isClickOnAvailableSa1 as ISa1GeoJsonFeature;
				this.onClickAvailableSa1InMap(sa1);
				return;
			}

			const isClickOnTmpSa1 = features.find((f) => f.layer.id === EMapLayers.TmpCdarFill);
			if (isClickOnTmpSa1) {
				const sa1 = isClickOnTmpSa1 as ISa1GeoJsonFeature;
				this.onClickTmpSa1InMap(sa1);
				return;
			}

			const isClickOnSa1InMasterCdar = features.find((f) => f.layer.id === EMapLayers.MasterCdarSa1Fill);
			if (isClickOnSa1InMasterCdar) {
				const sa1 = isClickOnSa1InMasterCdar as ISa1GeoJsonFeature;
				this.onClickSa1InMasterCdarInMap(sa1);
				return;
			}

			const isClickOnMasterCdar = features.find((f) => f.layer.id === EMapLayers.MasterCdarFill);
			if (isClickOnMasterCdar) {
				const cdar = isClickOnMasterCdar as ICdarGeoJsonFeature;
				this.onClickMasterCdarInMap(cdar);
				return;
			}

			const isClickOnCda = features.find((f) => f.layer.id === EMapLayers.CdaFill);
			if (isClickOnCda) {
				const cda = isClickOnCda as ICdaGeoJsonFeature;
				this.onClickCdaInMap(cda);
			}

			const isClickOnState = features.find((f) => f.layer.id === EMapLayers.StateFill);
			if (isClickOnState) {
				const state = isClickOnState as IStateGeoJsonFeature;
				//some islands in other territories have not been assigned state id yet
				if (state.properties.state_id) {
					this.onClickStateInMap(state);
					return;
				}
			}
		});

		/* this.map.on('click', EMapLayers.MasterCdarFill, (e) => {
			console.log(1234);
		}); */
	};

	onClickStateInMap = (stateFeature: IStateGeoJsonFeature) => {
		const stateIdFromMapClick = stateFeature.properties.state_id;
		if (!this.state.selectedAusStateId) {
			this.selectState(stateIdFromMapClick);
			return;
		}

		if (this.state.selectedAusStateId !== stateIdFromMapClick) {
			this.selectState(stateIdFromMapClick);
		} else {
			//user selects the same state that he was previously before zooming out and clicking
			//this.navigateMapToStateZoomLevel();
		}
	};

	onClickAvailableSa1InMap = (sa1Feature: ISa1GeoJsonFeature) => {
		if (this.state.activityMode !== EActivityMode.ChangeCdar && this.state.activityMode !== EActivityMode.NewCdar) {
			return;
		}
		const sa1 = sa1Feature.properties;

		//if suburb is already selected, de-select it
		/* if (suburbFeature.state.clicked) {
			this.deselectSuburb(suburb.abs_id);
			return;
		} */

		this.selectSa1(sa1);
	};

	onClickSa1InMasterCdarInMap = (sa1Feature: ISa1GeoJsonFeature) => {
		//click on suburb inside a master cda should be actioned only if edit mode is on
		if (this.state.activityMode !== EActivityMode.ChangeCdar) {
			return;
		}

		//clicking on a suburb inside a master cda always means that user is deselecting it
		this.deselectSa1(sa1Feature.properties.sa1_code);
	};

	onClickTmpSa1InMap = (sa1Feature: ISa1GeoJsonFeature) => {
		//when creating new cda, do not allow any action on suburbs that are within some cda
		if (this.state.activityMode === EActivityMode.NewCdar) {
			return;
		}
		const cdar = this.state.cdar.find((cdar) => {
			return cdar.sa1s.find((s) => s.sa1_code === sa1Feature.properties.sa1_code);
		});
		if (!cdar) {
			return;
			throw new Error('Failed to get cda of the clicked suburb');
		}
		this.selectTmpCdar(cdar.tmp_cdar_id as number, sa1Feature.properties.sa1_code);
	};

	onClickMasterCdarInMap = (cdarFeature: ICdarGeoJsonFeature) => {
		if (this.state.activityMode === EActivityMode.NewCdar || this.state.activityMode === EActivityMode.ChangeCdar) {
			return;
		}

		this.selectMasterCdar(cdarFeature.properties.cdar_id);
	};

	onClickCdaInMap = (cdaFeature: ICdaGeoJsonFeature) => {
		if (this.state.activityMode === EActivityMode.NewCdar || this.state.activityMode === EActivityMode.ChangeCdar) {
			return;
		}

		this.selectCda(cdaFeature.properties.cda_id);
	};

	moveMapToCoordinatesWithoutAnimation = (
		coordinates: { latitude: number; longitude: number },
		moveOptions: { zoom: number } = { zoom: 10 }
	) => {
		this.map.jumpTo({
			center: [coordinates.longitude, coordinates.latitude]
		});
		this.map.setZoom(moveOptions.zoom);
	};

	getSa1MapFeatureStateById = (featureId: number) => {
		const sa1FeatureState = this.map.getFeatureState({
			source: 'sa1',
			sourceLayer: Config.MapTilesets.AustraliaSa1.SourceLayer,
			id: featureId
		}) as TSa1MapFeatureState;

		return sa1FeatureState;
	};

	moveMapToCda = () => {
		if (!this.state.selectedCdaId) {
			return;
		}
		/* const coordinates = {
			latitude: this.state.selectedCdaId.center.latitude,
			longitude: this.state.selectedCdaId.center.longitude
		};
		this.moveMapToCoordinatesWithoutAnimation(coordinates, { zoom: 10 }); */
	};

	fitMapToStateBounds = () => {
		const state = this.getSelectedState();
		const bbox = UtilityService.getMapBoxBoundingBox(state.bounds as IGeoBound);
		//this.map.setMaxBounds(null);
		this.map.fitBounds(bbox);
		//this.map.setMaxBounds(bbox);
	};

	registerMapHoverEvent = () => {
		this.map.on('mousemove', this.onMapHoverStart);

		/**
		 * Mousemove event fires on entire map and its implementation
		 * has logic to clear existing hover outlines and tooltip if it does not find any feature underneath the
		 * mouse
		 *
		 * Essentially mousemove itself clears up the hover styles that it had applied
		 *
		 * In certain cases though mousemove will not be able to do job such as when the map is positioned in such
		 * a way that suburb or CDA boundary is at the edge of right side panel and mouse directly exits the map
		 * view without moving over any other areas. In such cases the hover styles and tooltip will remain
		 *
		 * So for these edge cases we need to listen to mouseleave events and we need listen to each layer
		 * seperately as the callback does not accept array of values
		 */
		this.map.on('mouseleave', EMapLayers.CdaFill, this.onMapHoverEnd);
		this.map.on('mouseleave', EMapLayers.StateFill, this.onMapHoverEnd);
	};

	onMapHoverStart = (e: IMapboxMouseMoveEvent) => {
		/* if (!this.state.selectedAusStateId) {
			return;
		} */

		const features = this.map.queryRenderedFeatures(e.point, {
			layers: [
				EMapLayers.CdaFill,
				EMapLayers.AvailableSa1Fill,
				EMapLayers.TmpCdarFill,
				EMapLayers.MasterCdarFill,
				EMapLayers.MasterCdarSa1Fill,
				EMapLayers.StateFill
			]
		});
		this.map.getCanvas().style.cursor = 'pointer';

		if (features.length === 0) {
			this.cleanExistingHoverArea();
			this.popup.remove();
			return;
		}

		const isHoverOnSa1 = features.find(
			(f) =>
				f.layer.id === EMapLayers.MasterCdarSa1Fill ||
				f.layer.id === EMapLayers.AvailableSa1Fill ||
				f.layer.id === EMapLayers.TmpCdarFill
		);
		if (isHoverOnSa1) {
			const sa1Feature = isHoverOnSa1 as ISa1GeoJsonFeature;
			this.onHoverSa1(sa1Feature, e);
			return;
		}

		const isHoverOnMasterCdar = features.find((f) => f.layer.id === EMapLayers.MasterCdarFill);
		if (isHoverOnMasterCdar) {
			const cdarFeature = isHoverOnMasterCdar as ICdarGeoJsonFeature;
			this.onHoverMasterCdar(cdarFeature, e);
			return;
		}

		const isHoverOnCda = features.find((f) => f.layer.id === EMapLayers.CdaFill);
		if (isHoverOnCda) {
			const cdaFeature = isHoverOnCda as ICdaGeoJsonFeature;
			this.onHoverCda(cdaFeature, e);
		}
		const isHoverOnState = features.find((f) => f.layer.id === EMapLayers.StateFill);
		if (isHoverOnState) {
			const stateFeature = isHoverOnState as IStateGeoJsonFeature;
			this.onHoverState(stateFeature, e);
			return;
		}
	};
	onHoverState = (stateFeature: IStateGeoJsonFeature, e: IMapboxMouseMoveEvent) => {
		//some islands have not been assigned id's in mapbox tilesets hence this condition
		if (!stateFeature.properties.state_id) {
			return;
		}

		const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];

		//need to update state in feature to remove hover outline
		this.cleanExistingHoverArea();
		this.hoverState.state = stateFeature.properties.state_id;

		this.updateStateMapFeatureState(this.hoverState.state, { hover: true });

		//tooltip logic
		const tooltipContent = `${stateFeature.properties.name}`;

		this.popup.setLngLat(coordinates).setHTML(tooltipContent).addTo(this.map);
	};

	onHoverMasterCdar = (cdarFeature: ICdarGeoJsonFeature, e: IMapboxMouseMoveEvent) => {
		//need to update state in feature to remove hover outline
		this.cleanExistingHoverArea();
		this.hoverState.cdar = cdarFeature.id;
		this.updateCdarMapFeatureState(this.hoverState.cdar, { hover: true });

		//tooltip logic
		const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];
		const cdarProperties = cdarFeature.properties;
		const cdar = this.state.cdar.find((cdar) => cdar.cdar_id === cdarProperties.cdar_id);

		if (!cdar) {
			throw new Error(`Could not retrieve cdar record for ${cdarProperties.cdar_id}`);
		}

		const tooltipContent = `CDAR: ${cdar.name}<br>CDA: ${this.getSelectedCda().name}`;
		this.popup.setLngLat(coordinates).setHTML(tooltipContent).addTo(this.map);
	};

	onHoverSa1 = (sa1: ISa1GeoJsonFeature, e: IMapboxMouseMoveEvent) => {
		const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];

		//need to update state in feature to remove hover outline
		this.cleanExistingHoverArea();
		this.hoverState.sa1 = sa1.properties.sa1_code;
		this.updateSa1MapFeatureState(this.hoverState.sa1, { hover: true });

		//tooltip logic
		let tooltipContent = `SA1: ${sa1.properties.sa1_code}`;
		const cdar = lodashFind(this.state.cdar, { sa1s: [{ sa1_code: this.hoverState.sa1 }] });
		if (cdar) {
			tooltipContent += `<br>CDAR: ${cdar.name}`;
			//if sa1 belongs to a cdar, then a cda must already be selected
			tooltipContent += `<br>CDA: ${this.getSelectedCda().name}`;
		}

		this.popup.setLngLat(coordinates).setHTML(tooltipContent).addTo(this.map);
	};
	onHoverCda = (cdaFeature: ICdaGeoJsonFeature, e: IMapboxMouseMoveEvent) => {
		//need to update state in feature to remove hover outline
		this.cleanExistingHoverArea();
		this.hoverState.cda = cdaFeature.id;
		this.updateCdaMapFeatureState(this.hoverState.cda, { hover: true });

		//tooltip logic
		const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];
		const cdaProperties = cdaFeature.properties;
		const cda = this.state.cda.find((cda) => cda.cda_id === cdaProperties.cda_id);

		if (!cda) {
			throw new Error(`Could not retrieve cda record for ${cdaProperties.cda_id}`);
		}
		const tooltipContent = `CDA: ${cda.name}`;

		this.popup.setLngLat(coordinates).setHTML(tooltipContent).addTo(this.map);
	};

	onMapHoverEnd = () => {
		if (this.hoverState.cda !== 0) {
			this.map.setFeatureState(
				{
					source: 'cda',
					id: this.hoverState.cda,
					sourceLayer: Config.MapTilesets.AustraliaCda.SourceLayer
				},
				{ hover: false }
			);
			this.hoverState.cda = 0;
		}
		if (this.hoverState.state !== 0) {
			this.updateStateMapFeatureState(this.hoverState.state, { hover: false });
			this.hoverState.state = 0;
		}

		this.map.getCanvas().style.cursor = '';
		this.popup.remove();
	};

	cleanExistingHoverArea = () => {
		if (this.hoverState.cda) {
			this.updateCdaMapFeatureState(this.hoverState.cda, { hover: false });
		}

		if (this.hoverState.sa1) {
			this.updateSa1MapFeatureState(this.hoverState.sa1, { hover: false });
		}

		if (this.hoverState.cdar) {
			this.updateCdarMapFeatureState(this.hoverState.cdar, { hover: false });
		}
		if (this.hoverState.state) {
			this.updateStateMapFeatureState(this.hoverState.state, { hover: false });
		}
	};

	updateCdaMapFeatureState = (cdaId: number, options: TCdaMapFeatureState = { clicked: true }) => {
		this.map.setFeatureState(
			{
				source: 'cda',
				sourceLayer: Config.MapTilesets.AustraliaCda.SourceLayer,
				id: cdaId
			},
			options
		);
	};
	updateStateMapFeatureState = (stateId: number, options: TAusStateMapFeatureState = { hover: true }) => {
		this.map.setFeatureState(
			{
				source: 'state',
				sourceLayer: Config.MapTilesets.AustraliaState.SourceLayer,
				id: stateId
			},
			options
		);
	};

	updateSa1MapFeatureState = (sa1Code: number, options: TSa1MapFeatureState = { clicked: true }) => {
		this.map.setFeatureState(
			{
				source: 'sa1',
				sourceLayer: Config.MapTilesets.AustraliaSa1.SourceLayer,
				id: sa1Code
			},
			options
		);
	};

	updateCdarMapFeatureState = (cdarId: number, options: TCdarMapFeatureState = { clicked: true }) => {
		this.map.setFeatureState(
			{
				source: 'cdar',
				sourceLayer: Config.MapTilesets.AustraliaCdar.SourceLayer,
				id: cdarId
			},
			options
		);
	};

	fitMapToBoundsWithPadding = (bounds: IGeoBound, padding = 20) => {
		const mapboxBounds = UtilityService.getMapBoxBoundingBox(bounds);
		this.map.fitBounds(mapboxBounds, { padding });
	};
}

const DeleteButton = withStyles((theme) => ({
	root: {
		color: red[500],
		'&:hover': {
			backgroundColor: red[700],
			color: theme.palette.getContrastText(red[500])
		}
	},
	outlined: {
		color: red[500]
	}
}))(Button);

ManageCdar.contextType = NotificationContext;
export default ManageCdar;
