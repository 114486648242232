import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import {
	Column,
	RowMouseEventHandlerParams,
	Table,
	TableCellRenderer,
	TableHeaderProps,
	AutoSizer
} from 'react-virtualized';
import { Checkbox, Tooltip } from '@material-ui/core';

declare module '@material-ui/core/styles/withStyles' {
	// Augment the BaseCSSProperties so that we can control jss-rtl
	interface BaseCSSProperties {
		/*
		 * Used to control if the rule-set should be affected by rtl transformation
		 */
		flip?: boolean;
	}
}

const styles = (theme: Theme) =>
	createStyles({
		sizeSmall: {
			padding: 5,
			fontSize: '0.750rem'
		},
		flexContainer: {
			display: 'flex',
			alignItems: 'center',
			boxSizing: 'border-box'
		},
		table: {
			// temporary right-to-left patch, waiting for
			// https://github.com/bvaughn/react-virtualized/issues/454
			'& .ReactVirtualized__Table__headerRow': {
				flip: false,
				paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined
			},
			outline: 0
		},
		tableRow: {
			cursor: 'pointer',
			outline: 0,
			textTransform: 'uppercase'
		},
		tableRowHover: {
			'&:hover': {
				backgroundColor: theme.palette.grey[200]
			}
		},
		tableCell: {
			flex: 1
		},
		noClick: {
			cursor: 'initial'
		}
	});

interface IColumnData {
	dataKey: string;
	label: string;
	numeric?: boolean;
	width: number;
	header?: () => ReactElement;
	content?: (row: any) => ReactElement;
	type?: string;
	headerWidth?: number;
}

interface IRow {
	index: number;
}

interface IMuiVirtualizedTableProps extends WithStyles<typeof styles> {
	columns: IColumnData[];
	headerHeight?: number;
	rowCount: number;
	rowHeight?: number;
	onRowClick?: (row: RowMouseEventHandlerParams) => void;
	rowGetter: (row: IRow) => any;
	onToggleSelectionOfAllItems?: () => void;
	onToggleAllRowsSelectionClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onRowMouseOver?: (row: RowMouseEventHandlerParams) => void;
	onRowMouseOut?: (row: RowMouseEventHandlerParams) => void;
}

class MuiVirtualizedTable extends React.PureComponent<IMuiVirtualizedTableProps> {
	static defaultProps = {
		headerHeight: 35,
		rowHeight: 40
	};

	getRowClassName = ({ index }: IRow) => {
		const { classes, onRowClick } = this.props;

		return clsx(classes.tableRow, classes.flexContainer, {
			[classes.tableRowHover]: index !== -1 && onRowClick != null
		});
	};

	cellRenderer: TableCellRenderer = (props) => {
		const { columns, classes, rowHeight, onRowClick } = this.props;
		const hasType = columns[props.columnIndex].type;
		const hasContent = columns[props.columnIndex].content;
		const cellWidth = columns[props.columnIndex].width;
		const cellLabel = columns[props.columnIndex].label;
		const styleCss =
			hasType && hasType === 'checkbox'
				? { height: rowHeight, width: 50, padding: 0 }
				: { height: rowHeight, padding: 0, width: cellWidth ? cellWidth : 'auto' };

		const renderName = (data: any) => {
			return (
				<Tooltip title={data.name}>
					<span className="ellipsis-text">{data.name}</span>
				</Tooltip>
			);
		};

		return (
			<TableCell
				size="small"
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, classes.sizeSmall, {
					[classes.noClick]: onRowClick == null
				})}
				variant="body"
				style={styleCss}
				align={(props.columnIndex != null && columns[props.columnIndex].numeric) || false ? 'right' : 'left'}
			>
				{hasType && hasType === 'checkbox' && (
					<Checkbox
						size="small"
						color="primary"
						indeterminate={false}
						checked={Boolean(props.cellData)}
						//onChange={this.props.}
					/>
				)}

				{!hasType && hasContent && hasContent(props.rowData)}
				{!hasType && !hasContent && cellLabel && cellLabel === 'Name'
					? renderName(props.rowData)
					: !hasType && !hasContent && props.cellData && props.cellData}
			</TableCell>
		);
	};

	headerRenderer = (props: TableHeaderProps & { columnIndex: number }) => {
		const { headerHeight, columns, classes } = this.props;
		const hasType = columns[props.columnIndex].type;
		const headerWidth = columns[props.columnIndex].headerWidth;
		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, classes.noClick, classes.sizeSmall)}
				variant="head"
				style={{
					height: headerHeight,
					width: headerWidth ? headerWidth : 'auto',
					padding: 0,
					textAlign: 'center',
					fontWeight: 600
				}}
				align={columns[props.columnIndex].numeric || false ? 'right' : 'left'}
			>
				{!hasType && props.label}
				{hasType && hasType === 'checkbox' && (
					<Checkbox
						size="small"
						color="primary"
						indeterminate={false}
						onChange={this.props.onToggleAllRowsSelectionClick}
					/>
				)}
			</TableCell>
		);
	};

	onRowClick = (info: RowMouseEventHandlerParams) => {
		if (this.props.onRowClick) {
			this.props.onRowClick(info);
		}
	};

	onClickToggleSelectionOfAllItems = () => {
		//todo
	};

	render() {
		const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
		return (
			<div style={{ flex: '1 1 auto' }}>
				<AutoSizer>
					{({ height, width }) => (
						<Table
							height={height}
							onRowClick={this.onRowClick}
							width={width}
							rowStyle={{ width: width }}
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							rowHeight={rowHeight!}
							gridStyle={{
								direction: 'inherit'
							}}
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							headerHeight={headerHeight!}
							className={`${classes.table} admin-sale`}
							{...tableProps}
							rowClassName={this.getRowClassName}
							onRowMouseOver={this.props.onRowMouseOver}
							onRowMouseOut={this.props.onRowMouseOut}
						>
							{columns.map(({ dataKey, ...other }, index) => {
								return (
									<Column
										key={dataKey}
										headerRenderer={(headerProps) =>
											this.headerRenderer({
												...headerProps,
												columnIndex: index
											})
										}
										className={`${classes.flexContainer} admin-sales-list-area`}
										cellRenderer={this.cellRenderer}
										dataKey={dataKey}
										{...other}
										width={width}
									/>
								);
							})}
						</Table>
					)}
				</AutoSizer>
			</div>
		);
	}
}

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);
export default VirtualizedTable;
