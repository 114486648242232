import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { IAustraliaState, ICda, IGetCdarChangelogHttpResponse } from 'shared/types/Types';
import { differenceBy as lodashDifferenceBy } from 'lodash';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import CdarService from 'shared/services/CdarService';

interface IProps {
	cda: ICda[];
	ausState: IAustraliaState;
	onClose: () => void;
}

interface IChange {
	name: string;
	cdaName: string;
	fieldsUpdated: string[];
	sa1s: {
		oldCount: number;
		newCount: number;
		added: number[];
		removed: number[];
	};
	isDeleted: boolean;
	isNew: boolean;
}

const CdarChangesToSyncModal: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const [changes, setChanges] = React.useState<IChange[]>([]);
	const [isLoading, setIsLoading] = React.useState(false);

	const processChangelog = (data: IGetCdarChangelogHttpResponse) => {
		const changeset: IChange[] = [];

		data.tmpCdar.forEach((tmpCdar) => {
			const cda = props.cda.find((cda) => cda.cda_id === tmpCdar.cda_id);
			const tmpChangeSet: IChange = {
				isNew: false,
				name: tmpCdar.name,
				cdaName: cda?.name || '',
				isDeleted: false,
				fieldsUpdated: [],
				sa1s: {
					oldCount: 0,
					newCount: 0,
					added: [],
					removed: []
				}
			};
			const masterCdar = data.cdar.find((mCda) => mCda.cdar_id === tmpCdar.cdar_id);

			if (!masterCdar) {
				tmpChangeSet.isNew = true;
				tmpChangeSet.sa1s.added = tmpCdar.sa1s.map((s) => s.sa1_code);
			} else if (tmpCdar.is_deleted) {
				tmpChangeSet.isDeleted = true;
			} else {
				if (masterCdar.name !== tmpCdar.name) {
					tmpChangeSet.fieldsUpdated = ['Name'];
				}

				if (masterCdar.description !== tmpCdar.description) {
					tmpChangeSet.fieldsUpdated = [...tmpChangeSet.fieldsUpdated, 'Description'];
				}

				if (masterCdar.is_provisional !== tmpCdar.is_provisional) {
					tmpChangeSet.fieldsUpdated = [...tmpChangeSet.fieldsUpdated, 'Is Provisional'];
				}

				if (masterCdar.is_royalty !== tmpCdar.is_royalty) {
					tmpChangeSet.fieldsUpdated = [...tmpChangeSet.fieldsUpdated, 'Is Royalty'];
				}

				const sa1sRemoved = lodashDifferenceBy(masterCdar.sa1s, tmpCdar.sa1s, 'sa1_code');
				const sa1sAdded = lodashDifferenceBy(tmpCdar.sa1s, masterCdar.sa1s, 'sa1_code');

				tmpChangeSet.sa1s = {
					oldCount: masterCdar.sa1s.length,
					newCount: tmpCdar.sa1s.length,
					added: sa1sAdded.map((s) => s.sa1_code),
					removed: sa1sRemoved.map((s) => s.sa1_code)
				};
			}

			changeset.push(tmpChangeSet);
		});
		setChanges(changeset);
	};

	React.useEffect(() => {
		const getChangelog = async () => {
			setIsLoading(true);
			try {
				const res = await CdarService.getCdarChangeLog({ state_id: props.ausState.id });
				console.log(res);
				processChangelog(res);
			} finally {
				setIsLoading(false);
			}
		};

		getChangelog();
	}, [props.ausState]);

	const classes = useStyles();

	return (
		<Dialog open onClose={props.onClose} scroll={'paper'} fullWidth>
			<DialogTitle>Changelog for {props.ausState.name}</DialogTitle>
			<DialogContent dividers>
				<div className={classes.dialogContent}>
					{isLoading && (
						<>
							<Skeleton className={classes.skeleton} variant="rect" width={'100%'} height={118} animation="wave" />
							<Skeleton className={classes.skeleton} variant="rect" width={'100%'} height={118} animation="wave" />
							<Skeleton className={classes.skeleton} variant="rect" width={'100%'} height={118} animation="wave" />
						</>
					)}

					{!isLoading &&
						changes.map((change, idx) => (
							<div key={idx} className={classes.changeContainer}>
								<Grid container>
									<Grid item xs={3}>
										<Typography color="secondary" variant="body2">
											CDA
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant="body2">{change.cdaName}</Typography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={3}>
										<Typography color="secondary" variant="body2">
											CDAR
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant="body2">{change.name}</Typography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={3}>
										<Typography color="secondary" variant="body2">
											Is New ?
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant="body2">{change.isNew ? 'YES' : 'NO'}</Typography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={3}>
										<Typography color="secondary" variant="body2">
											Is Deleted ?
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant="body2">{change.isDeleted ? 'YES' : 'NO'}</Typography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={3}>
										<Typography color="secondary" variant="body2">
											Fields updated
										</Typography>
									</Grid>
									<Grid item xs={9}>
										<Typography variant="body2">
											{change.fieldsUpdated.length > 0 ? change.fieldsUpdated.map((f) => f).join(', ') : 'N/A'}
										</Typography>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={3}>
										<Typography color="secondary" variant="body2">
											SA1's added
										</Typography>
									</Grid>
									<Grid item xs={9}>
										{change.sa1s.added.length > 0 ? change.sa1s.added.map((f) => f).join(', ') : 'N/A'}
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={3}>
										<Typography color="secondary" variant="body2">
											SA1's removed
										</Typography>
									</Grid>
									<Grid item xs={9}>
										{/* <Typography variant="body2">{change.sa1s?.removed.map((f) => f).join(', ')}</Typography> */}
										<Typography variant="body2">
											{change.sa1s.removed.length > 0 ? change.sa1s.removed.map((f) => f).join(', ') : 'N/A'}
										</Typography>
									</Grid>
								</Grid>
							</div>
						))}
					{!isLoading && changes.length === 0 && (
						<Typography variant="h5">No changes have been recorded for {props.ausState.name} at the moment</Typography>
					)}
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const useStyles = makeStyles(() => ({
	dialogContent: {
		minHeight: 200,
		position: 'relative',
		padding: 2
	},
	changeContainer: {
		border: '1px solid black',
		marginBottom: 5,
		padding: 5
	},
	skeleton: {
		marginBottom: 5
	}
}));

export default CdarChangesToSyncModal;
