import Auth from 'modules/auth/Auth';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import UseAxiosErrorHandler from 'shared/hooks/UseAxiosErrorHandler';
import LoggedInRoutes from 'shared/components/LoggedInRoutes';
import LoggedInUserProvider from 'shared/providers/LoggedInUserProvider';
import './App.css';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App() {
	const getLoggedInRoute = () => {
		return <LoggedInRoutes />;
	};
	UseAxiosErrorHandler();
	return (
		<>
			<Switch>
				<Route path="/ui">
					<LoggedInUserProvider>{getLoggedInRoute()}</LoggedInUserProvider>
				</Route>
				<Route exact path="/auth" component={Auth}></Route>
				<Redirect to="/auth" />
			</Switch>
		</>
	);
}

export default App;
