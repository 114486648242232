import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#0073CF'
		},
		secondary: {
			main: '#666666'
		},
		error: {
			main: red.A400
		},
		background: {
			default: '#fff'
		}
	},
	typography: {
		fontFamily: 'Open Sans'
	}
});

export default theme;
