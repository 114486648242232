import {
	Button,
	Divider,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	Typography,
	withStyles
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Alert } from '@material-ui/lab';
import mapboxgl, { LngLatLike, PointLike } from 'mapbox-gl';
import CdaInfoView from 'modules/manage-cda/CdaInfoView';
import React, { Component } from 'react';
import Split from 'react-split';
import AreaLoaderContainer from 'shared/components/AreaLoaderContainer';
import ConfirmModalWithMessage from 'shared/components/ConfirmModalWithMessage';
import Map from 'shared/components/Map';
import ToggleContent from 'shared/components/ToggleContent';
import Config from 'shared/constants/Config';
import { NotificationContext } from 'shared/providers/NotificationProvider';
import CdaService from 'shared/services/CdaService';
import CommonApiService from 'shared/services/CommonApiService';
import UtilityService from 'shared/services/UtilityService';
import { find as lodashFind } from 'lodash';
import {
	EMapServiceJobStatus,
	ENotificationTypes,
	IAustraliaState,
	ICda,
	ICdaGeoJsonFeature,
	IGeoBound,
	IMapboxMouseMoveEvent,
	IMapSyncJob,
	ISuburb,
	ISuburbGeoJsonFeature,
	ISuburbGeoJsonFeatureProperties,
	TCdaMapFeatureState,
	IStateGeoJsonFeature,
	TAusStateMapFeatureState,
	IMapColor
} from 'shared/types/Types';
import ManageCdaForm from './ManageCdaForm';
import MapLegend from 'shared/components/MapLegend';
import CdaChangesToSyncModal from './CdaChangesToSyncModal';
import MapSyncInProgressMessage from 'shared/components/MapSyncInProgressMessage';

// mapbox gl js has issues with webpack/babel transpiling. Reference - https://github.com/mapbox/mapbox-gl-js/issues/10173
//eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
//eslint-disable-next-line import/no-webpack-loader-syntax, @typescript-eslint/no-var-requires, @typescript-eslint/ban-ts-comment
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

enum EActivityMode {
	NewCda = 'new-cda',
	ChangeCda = 'change-cda',
	ViewCda = 'view-cda',
	NoActivity = ''
}

enum EMapLayers {
	StateFill = 'state-fill',
	StateOutline = 'state-outline',
	StateHoverOutline = 'state-hover-fill',
	MasterCdaSuburbFill = 'master-cda-suburb-fill',
	MasterCdaSuburbOutline = 'master-cda-suburb-outline',
	TempCdaSuburbFill = 'temp-cda-suburb-fill',
	TempCdaSuburbOutline = 'temp-cda-suburb-outline',
	AvailableSuburbFill = 'available-suburb-fill',
	AvailableSuburbOutline = 'available-suburb-outline',
	MasterCdaFill = 'master-cda-fill',
	MasterCdaOutline = 'master-cda-outline',
	MasterCdaHoverFill = 'master-cda-hover-fill'
}

interface IState {
	ausStates: IAustraliaState[];
	selectedState: string;
	cdaSelectedOnMap: ICda | null;
	activityMode: EActivityMode;
	cdaForm: ICda;
	cda: ICda[];
	availableSuburbs: ISuburb[];
	isLoading: boolean;
	showAreaLoader: boolean;
	mapServiceSync: {
		inProgress: boolean;
		inProgressJob: IMapSyncJob | null;
	};
	suburbsAddedToCda: ISuburb[];
	suburbsRemovedFromCda: ISuburb[];
	isShowMap: boolean;
	mapColors: IMapColor;
}

const mapLegend = [];

//definition for state variables that we store inside mapbox map feature
type TSuburbMapFeatureState = { newlyAddedToCda: boolean } | { clicked: boolean } | { hover: boolean };
class ManageCda extends Component<unknown, IState> {
	state: Readonly<IState> = {
		selectedState: '',
		cdaSelectedOnMap: null,
		activityMode: EActivityMode.NoActivity, //refers to 3 possible state in UI i.e. change cda, new cda or view cda
		cdaForm: {
			cda_id: 0,
			state_id: 0,
			tmp_cda_id: 0,
			name: '',
			suburbs: []
		},
		cda: [],
		isLoading: false,
		ausStates: [],
		showAreaLoader: false,
		availableSuburbs: [],
		mapServiceSync: {
			inProgress: false,
			inProgressJob: null
		},
		suburbsAddedToCda: [],
		suburbsRemovedFromCda: [],
		isShowMap: false,
		mapColors: Config.MapColors
	};
	private popup = new mapboxgl.Popup({
		closeButton: false,
		closeOnClick: false,
		offset: 10
	});
	private map!: mapboxgl.Map;
	context!: React.ContextType<typeof NotificationContext>;
	private hoverState = {
		cda: 0,
		suburb: '',
		state: 0
	};

	constructor(props: unknown) {
		super(props);
		this.onMapHoverStart = UtilityService.debounce(this.onMapHoverStart, 25);
		this.onMapHoverEnd = UtilityService.debounce(this.onMapHoverEnd, 25);
	}

	componentDidMount = async () => {
		const job = await this.getInProgressMapServiceSyncJob();
		if (job) {
			this.activateReadOnlyMode(job);
		}
		await this.getSystemMapColors();
		await this.getAustralianStates();
		//await this.getMapServiceSubmissionStatus();
	};

	render(): JSX.Element {
		return (
			<Grid container direction="column" className="split-container">
				<Grid item xs>
					{this.state.isLoading && <LinearProgress />}
					<Split
						sizes={[40, 60]}
						minSize={250}
						expandToMin={false}
						gutterSize={10}
						gutterAlign="center"
						snapOffset={30}
						dragInterval={1}
						cursor="col-resize"
						className="split-parent split-horizontal"
						onDragEnd={() => this.map.resize()}
					>
						{/* sidebar */}
						<div className="split-item pl10 pt10">
							<AreaLoaderContainer isLoading={this.state.showAreaLoader}>
								<Grid direction="column" container style={{ height: '100%', width: '100%' }}>
									<Grid xs item container direction="column">
										<Typography className="mb10" color="secondary" variant="h5">
											Manage CDA
										</Typography>
										<Grid item xs>
											<Paper elevation={0} className="paper-container stretch">
												<Grid container direction="column" className="stretch">
													{/** State dropdown */}
													<Grid container item alignItems="center">
														<Grid item xs>
															<FormControl style={{ width: '100%' }}>
																<InputLabel htmlFor="manage-cda-state-filter">Select a State</InputLabel>
																<Select
																	value={this.state.selectedState || ''}
																	labelId="manage-cda-state-filter"
																	onChange={this.onChangeAusStateDropdown}
																>
																	{this.state.ausStates.map((state) => {
																		return (
																			<MenuItem key={state.name} value={state.code}>
																				{state.name}
																			</MenuItem>
																		);
																	})}
																</Select>
																<FormHelperText>
																	The map on the right will be restricted to the state selected
																</FormHelperText>
															</FormControl>
														</Grid>
													</Grid>

													{/**CDA Info View/Form */}
													<div className="w100p mt10">
														<Grid container alignItems="center">
															<Grid item xs>
																<Typography variant="body2" color="secondary">
																	CDA Info
																</Typography>
															</Grid>
															{this.showChangeCdaBtn() && (
																<Button
																	size="small"
																	disabled={this.isMapServiceSyncInProgress()}
																	color="secondary"
																	variant="outlined"
																	style={{
																		marginRight: 5
																	}}
																	onClick={this.onClickChangeCda}
																>
																	Edit
																</Button>
															)}
															{this.showNewCdaBtn() && (
																<Button
																	disabled={this.isMapServiceSyncInProgress()}
																	size="small"
																	color="secondary"
																	variant="outlined"
																	onClick={this.onClickNewCda}
																	style={{
																		marginRight: 5
																	}}
																>
																	NEW
																</Button>
															)}
															{this.showDeleteCdaBtn() && this.renderDeleteCdaButton()}
														</Grid>
													</div>
													<Divider light style={{ margin: '5px 0' }} />
													{!this.state.activityMode && (
														<Alert severity="info">
															Select a state to display layers on map and then select CDA to view more detailed info
															here
														</Alert>
													)}
													{this.renderCdaInfo()}
													{this.renderCdaForm()}
												</Grid>
											</Paper>
										</Grid>
									</Grid>
									{/** footer */}
									<Grid item>{this.renderSidebarFooter()}</Grid>
								</Grid>
							</AreaLoaderContainer>
						</div>
						{/* map */}
						<div className="split-item">
							<AreaLoaderContainer isLoading={!this.state.isShowMap}>
								<Grid
									container
									direction="column"
									style={{
										height: 'calc(100vh - 48px)',
										width: '100%'
									}}
								>
									<Grid item xs>
										<Map onMapLoadComplete={this.onMapLoadComplete} />
									</Grid>
									<MapLegend items={this.getMapLegend()} />
								</Grid>
							</AreaLoaderContainer>
						</div>
					</Split>
				</Grid>
			</Grid>
		);
	}
	getMapLegend = () => {
		return [
			{
				type: 'area',
				label: 'Selected Tmp CDA',
				color: this.state.mapColors['suburb']['selected'],
				opacity: 0.4
			},
			{
				label: 'Tmp CDA',
				type: 'area',
				color: this.state.mapColors['cda']['fill'],
				opacity: 0.4
			},
			{
				label: 'New Suburbs in CDA',
				type: 'area',
				color: this.state.mapColors['suburb']['newlyAddedToCda'],
				opacity: 0.7
			},
			{
				label: 'Master CDA',
				color: this.state.mapColors['cda']['outline'],
				type: 'boundary'
			},
			{
				label: 'Available Suburbs',
				color: this.state.mapColors['suburb']['outlineAvailable'],
				type: 'boundary'
			},
			{
				label: 'Assigned Suburbs',
				color: this.state.mapColors['suburb']['outline'],
				type: 'boundary'
			}
		];
	};
	getInitialCdaFormValues = (): ICda => {
		return {
			cda_id: 0,
			state_id: 0,
			tmp_cda_id: 0,
			name: '',
			suburbs: [],
			provisional_search_radius: 10,
			reservation_expiry_in_days: 0,
			description: '',
			automate_provisional_assignment: false
		};
	};

	getAustralianStates = async () => {
		try {
			this.setState({
				showAreaLoader: true
			});
			const states = await CommonApiService.getAustraliaStates();
			this.setState({
				ausStates: states
			});
		} finally {
			this.setState({
				showAreaLoader: false
			});
		}
	};
	getSystemMapColors = async () => {
		const apiRes: any = await CommonApiService.getSystemSettings('map_colors');
		if (!apiRes?.success) {
			return;
		}
		this.setState({
			mapColors: apiRes.data[0].json.MapColors,
			isShowMap: true
		});
	};

	getInProgressMapServiceSyncJob = async () => {
		let job = null;
		try {
			this.setState({
				isLoading: true
			});
			const syncJobs = await CommonApiService.getMapServiceSyncJobs();
			job = syncJobs.find((job) => job.status === EMapServiceJobStatus.InProgress);
		} catch (e) {
			throw new Error('Failed to retrieve map service sync jobs');
		} finally {
			this.setState({
				isLoading: false
			});
		}

		return job;
	};

	activateReadOnlyMode = (job: IMapSyncJob) => {
		this.setState({
			mapServiceSync: {
				inProgress: true,
				inProgressJob: job
			}
		});
	};
	isMapServiceSyncInProgress = () => {
		return this.state.mapServiceSync.inProgress;
	};

	showNewCdaBtn = (): boolean => {
		if (this.state.activityMode === EActivityMode.NoActivity) {
			return true;
		}

		if (this.state.activityMode === EActivityMode.ViewCda) {
			return true;
		}

		return false;
	};

	showChangeCdaBtn = (): boolean => {
		if (this.state.activityMode === EActivityMode.ViewCda) {
			return true;
		}

		return false;
	};

	showDeleteCdaBtn = (): boolean => {
		if (this.state.activityMode === EActivityMode.ViewCda) {
			return true;
		}

		return false;
	};

	renderDeleteCdaButton = () => {
		return (
			<ToggleContent
				toggle={(show) => (
					<DeleteButton disabled={this.isMapServiceSyncInProgress()} size="small" variant="outlined" onClick={show}>
						DELETE
					</DeleteButton>
				)}
				content={(hide) => (
					<ConfirmModalWithMessage
						isLoading={this.state.isLoading}
						onClickCancel={hide}
						onClickConfirm={async () => {
							await this.deleteCda();
							hide();
						}}
						message={`Are you sure you want to delete ${this.state.cdaSelectedOnMap?.name} CDA`}
					/>
				)}
			/>
		);
	};

	onChangeAusStateDropdown = (ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
		const stateCode = ev.target.value as string;
		const state = this.state.ausStates.find((s) => stateCode === s.code);
		if (!state) {
			throw new Error('Could not find state selected in local states array');
		}
		this.selectState(state.id);
	};

	selectState = (stateId: number) => {
		const state = this.state.ausStates.find((s) => stateId === s.id);
		if (!state) {
			throw new Error('Could not find state selected in local states array');
		}
		this.setState(
			{
				selectedState: state.code,
				cdaSelectedOnMap: null,
				activityMode: EActivityMode.NoActivity,
				cda: [],
				cdaForm: this.getInitialCdaFormValues()
			},
			async () => {
				await this.getCdaByState(stateId);
				this.fitMapToBounds();
				//this.showSuburbsOnMapByState();
				//this.showCdaOnMapByState();
				this.showMasterCdaOnMap();
				this.showTmpCdaOnMap();
				this.showAvailableSuburbsOnMap();
			}
		);
	};

	getCdaByState = async (stateId: number) => {
		try {
			this.setState({
				isLoading: true
			});
			const res = await CdaService.getCda({ stateId, includeTemp: true, includingSuburbs: true });
			const unAssignedSuburbs = res.not_assigned_suburbs;

			this.setState({
				cda: res.cda,
				availableSuburbs: unAssignedSuburbs
			});
		} finally {
			this.setState({
				isLoading: false
			});
		}
		return;
	};

	onClickChangeCda = () => {
		this.setState({
			activityMode: EActivityMode.ChangeCda
		});
	};

	onClickNewCda = () => {
		if (!this.state.selectedState) {
			this.context.addNotification({
				message: 'Select a state to create new CDA',
				type: ENotificationTypes.Info
			});
			return;
		}

		const cdaForm = this.getInitialCdaFormValues();
		cdaForm.state_id = this.getSelectedState().id;

		this.hideAllTmpCdaSuburbSelectionsOnMap();

		this.setState({
			cdaForm: cdaForm,
			cdaSelectedOnMap: null,
			activityMode: EActivityMode.NewCda
		});
	};

	onClickShowSyncChanges = (showSyncModal: () => void) => {
		if (!this.state.selectedState) {
			this.context.addNotification({
				message: 'Select a state to view changes that are yet to be synced',
				type: ENotificationTypes.Info
			});
			return;
		}

		showSyncModal();
	};

	renderSidebarFooter = () => {
		if (this.state.mapServiceSync.inProgressJob) {
			return <MapSyncInProgressMessage job={this.state.mapServiceSync.inProgressJob} />;
		}

		return (
			<Grid style={{ height: 50 }} container justify="space-around" alignItems="center">
				<ToggleContent
					toggle={(show) => (
						<Button variant="outlined" color="primary" onClick={show}>
							SUBMIT TO MAP SERVICE
						</Button>
					)}
					content={(hide) => (
						<ConfirmModalWithMessage
							isLoading={this.state.isLoading}
							onClickCancel={hide}
							onClickConfirm={async () => {
								await this.submitToMapService();
								hide();
							}}
							message={'Are you sure you want to submit changes to map service'}
						/>
					)}
				/>
				<ToggleContent
					toggle={(show) => (
						<Button variant="outlined" color="primary" onClick={() => this.onClickShowSyncChanges(show)}>
							View Changelog
						</Button>
					)}
					content={(hide) => <CdaChangesToSyncModal onClose={hide} ausState={this.getSelectedState()} />}
				/>
			</Grid>
		);
	};

	renderCdaInfo = (): JSX.Element | null => {
		if (this.state.activityMode !== 'view-cda') {
			return null;
		}

		if (!this.state.cdaSelectedOnMap) {
			throw new Error('No cda selection found, cannot render cda info');
		}

		return (
			<CdaInfoView
				cda={this.state.cdaSelectedOnMap}
				onSuburbListItemHoverStart={this.onSuburbListHoverStart}
				onSuburbListItemHoverEnd={this.onSuburbListHoverEnd}
			/>
		);
	};

	renderCdaForm = (): JSX.Element | null => {
		if (this.state.activityMode === 'view-cda' || !this.state.activityMode) {
			return null;
		}

		return (
			<ManageCdaForm
				initialValues={this.state.cdaForm}
				onClickCancel={this.onClickCancelCdaForm}
				onClickSave={this.onClickSaveCdaForm}
				onClickRemoveSuburbFromCda={this.onClickRemoveSuburbFromCdaForm}
				onSuburbListItemHoverStart={this.onSuburbListHoverStart}
				onSuburbListItemHoverEnd={this.onSuburbListHoverEnd}
			/>
		);
	};

	onClickSaveCdaForm = async (cda: ICda) => {
		if (cda.tmp_cda_id) {
			await this.updateCda(cda);
		} else {
			await this.createCda(cda);
		}
	};

	createCda = async (cda: ICda) => {
		try {
			this.setState({
				isLoading: true
			});
			const cdaFromApi = await CdaService.createTmpCda(cda);
			cda.tmp_cda_id = cdaFromApi.tmp_cda_id;

			let availableSuburbs = [...this.state.availableSuburbs, ...this.state.suburbsRemovedFromCda];
			//suburbs added to cda need to made unavailalbe
			availableSuburbs = availableSuburbs.filter((suburb) => {
				return !this.state.suburbsAddedToCda.find((s) => s.abs_suburb_id === suburb.abs_suburb_id);
			});

			//making changes to a master cda will call this function as it needs to create entry into tmp table
			//once api call succeeds, we need to update existing cda instead of adding new cda. We check for
			//presence of cda_id to decide this. cda_id when creating a new cda is always 0
			let newCdaArr: ICda[] = [];
			if (cda.cda_id) {
				newCdaArr = this.state.cda.map((c) => {
					if (cda.cda_id !== c.cda_id) {
						return c;
					}

					return {
						...c,
						...cda
					};
				});
			} else {
				newCdaArr = [...this.state.cda, cda];
			}

			this.setState(
				{
					cda: newCdaArr,
					activityMode: EActivityMode.ViewCda,
					availableSuburbs,
					cdaForm: this.getInitialCdaFormValues(),
					cdaSelectedOnMap: cda,
					suburbsRemovedFromCda: [],
					suburbsAddedToCda: []
				},
				() => {
					this.hideAllNewlyAddedSuburbsInCdaOnMap();
					this.showTmpCdaOnMap();
					this.showAvailableSuburbsOnMap();
					this.showMasterCdaOnMap();
					//this is for reselecting the entire tmp cda because we retain selection after saving
					if (cda.suburbs[0].abs_suburb_id) {
						this.selectTmpCda(cda.suburbs[0].abs_suburb_id);
					}
				}
			);
			this.context.addNotification({
				message: 'CDA created successfully',
				type: ENotificationTypes.Success
			});
		} finally {
			this.setState({
				isLoading: false
			});
		}
	};

	updateCda = async (updatedCda: ICda) => {
		//let res: ICda;

		this.setState({
			isLoading: true
		});

		try {
			await CdaService.updateTmpCda(updatedCda);

			let availableSuburbs = [...this.state.availableSuburbs, ...this.state.suburbsRemovedFromCda];

			//suburbs added to cda need to made unavailalbe
			availableSuburbs = availableSuburbs.filter((suburb) => {
				return !this.state.suburbsAddedToCda.find((s) => s.abs_suburb_id === suburb.abs_suburb_id);
			});

			const newCdaArr = this.state.cda.map((cda) => {
				if (cda.tmp_cda_id !== updatedCda.tmp_cda_id) {
					return cda;
				}

				return {
					...cda,
					...updatedCda
				};
			});

			this.setState(
				{
					activityMode: EActivityMode.ViewCda,
					cda: newCdaArr,
					availableSuburbs,
					cdaForm: this.getInitialCdaFormValues(),
					cdaSelectedOnMap: updatedCda,
					suburbsAddedToCda: [],
					suburbsRemovedFromCda: []
				},
				() => {
					//refresh map to show changes
					//this.showSuburbsOnMapByState();
					//this.showCdaOnMapByState();
					this.hideAllNewlyAddedSuburbsInCdaOnMap();
					this.showMasterCdaOnMap();
					this.showTmpCdaOnMap();
					this.showAvailableSuburbsOnMap();
					this.updateSuburbsOnMapByMasterCda();
					//this is for reselecting the entire tmp cda because we retain selection after saving
					if (updatedCda.suburbs[0].abs_suburb_id) {
						this.selectTmpCda(updatedCda.suburbs[0].abs_suburb_id);
					}
				}
			);
			this.context.addNotification({
				message: 'CDA updated successfully',
				type: ENotificationTypes.Success
			});
		} finally {
			this.setState({
				isLoading: false
			});
		}
	};

	deleteCda = async () => {
		if (!this.state.cdaSelectedOnMap) {
			throw new Error('No cda is selected, cannot perform delete operation');
		}

		const doesCdaHaveSoldSuburb = this.state.cdaSelectedOnMap.suburbs.find((s) => !s.is_removable);
		if (doesCdaHaveSoldSuburb) {
			this.context.addNotification({
				message: `ERROR - CDA: ${this.state.cdaSelectedOnMap.name} has suburb:${doesCdaHaveSoldSuburb.name} which has sold/reserved SA1 in royalty areas.`,
				type: ENotificationTypes.Error
			});
			return;
		}

		try {
			this.setState({
				isLoading: true
			});
			if (this.state.cdaSelectedOnMap.cda_id !== 0) {
				await CdaService.deleteCda(this.state.cdaSelectedOnMap.cda_id);
			} else {
				await CdaService.deleteTmpCda(this.state.cdaSelectedOnMap.tmp_cda_id);
			}

			const newCdaArr = this.state.cda.filter((cda: ICda) => {
				//the cda being deleted can be a brand new cda which has cda_id=0
				//and it can belong to a state full of brand new cda's, all with cda_id=0
				//hence we cannot filter using cda_id all the time.
				if (this.state.cdaSelectedOnMap?.tmp_cda_id && this.state.cdaSelectedOnMap?.tmp_cda_id !== 0) {
					return cda.tmp_cda_id !== this.state.cdaSelectedOnMap?.tmp_cda_id;
				} else {
					return cda.cda_id !== this.state.cdaSelectedOnMap?.cda_id;
				}
			});

			this.context.addNotification({
				message: `CDA ${this.state.cdaSelectedOnMap.name} deleted successfully`,
				type: ENotificationTypes.Success
			});

			this.setState(
				{
					activityMode: EActivityMode.NoActivity,
					cdaForm: this.getInitialCdaFormValues(),
					cdaSelectedOnMap: null,
					cda: newCdaArr,
					availableSuburbs: [...this.state.availableSuburbs, ...this.state.cdaSelectedOnMap.suburbs]
				},
				() => {
					//refresh map to show changes
					//this.showSuburbsOnMapByState();
					//this.showCdaOnMapByState();
					this.showMasterCdaOnMap();
					this.showTmpCdaOnMap();
					this.showAvailableSuburbsOnMap();
					this.hideAllTmpCdaSuburbSelectionsOnMap();
					this.updateSuburbsOnMapByMasterCda();
				}
			);
		} finally {
			this.setState({
				isLoading: false
			});
		}
	};

	onClickCancelCdaForm = () => {
		//clicking cancel when creating a new cda should close the form
		if (this.state.activityMode === EActivityMode.NewCda) {
			this.setState(
				{
					cdaForm: this.getInitialCdaFormValues(),
					cdaSelectedOnMap: null,
					activityMode: EActivityMode.NoActivity,
					suburbsAddedToCda: [],
					suburbsRemovedFromCda: []
				},
				() => {
					this.hideAllTmpCdaSuburbSelectionsOnMap();
					this.showMasterCdaOnMap();
					this.showAvailableSuburbsOnMap();
					this.showSuburbsOnMapByMasterCda();
					this.showTmpCdaOnMap();
				}
			);
			return;
		}

		if (!this.state.cdaSelectedOnMap) {
			throw new Error('No cda selection found on clicking cancel');
		}

		//clicking cancel in edit should just undo the changes but keep the cda selected and in "view" state
		this.setState(
			{
				activityMode: EActivityMode.ViewCda,
				cdaForm: this.state.cdaSelectedOnMap,
				suburbsAddedToCda: [],
				suburbsRemovedFromCda: []
			},
			() => {
				this.hideAllTmpCdaSuburbSelectionsOnMap();
				this.showMasterCdaOnMap();
				this.showAvailableSuburbsOnMap();
				this.showSuburbsOnMapByMasterCda();
				this.showTmpCdaOnMap();
			}
		);
	};

	onClickRemoveSuburbFromCdaForm = (suburb: ISuburb) => {
		this.removeSuburbFromCda(suburb.abs_suburb_id);
	};

	removeSuburbFromCda = async (abs_suburb_id: string) => {
		const suburbToBeRemoved = this.state.cdaForm.suburbs.find((s) => s.abs_suburb_id === abs_suburb_id);

		if (!suburbToBeRemoved) {
			throw new Error('Clicked suburb not found in cda form suburbs array');
		}

		//suburb cannot be removed if it has sold sa1/cdar
		if (suburbToBeRemoved && !suburbToBeRemoved.is_removable) {
			this.context.addNotification({
				message: `Cannot remove this suburb as it contains a royalty area which is sold to a customer.`,
				type: ENotificationTypes.Error
			});
			return;
		}

		const suburbs = this.state.cdaForm.suburbs.filter((s) => {
			return s.abs_suburb_id !== abs_suburb_id;
		});

		//the new suburb being removed may have been earlier been added
		const suburbsAdded = this.state.suburbsAddedToCda.filter(
			(s) => s.abs_suburb_id !== suburbToBeRemoved.abs_suburb_id
		);

		//in case of tmp cda, the suburb would have fill background to show selected UI
		this.updateSuburbMapFeatureState(suburbToBeRemoved.abs_suburb_id, { clicked: false });

		this.setState(
			{
				cdaForm: {
					...this.state.cdaForm,
					suburbs: suburbs
				},
				suburbsAddedToCda: suburbsAdded,
				suburbsRemovedFromCda: [...this.state.suburbsRemovedFromCda, suburbToBeRemoved]
			},
			() => {
				//this.updateSuburbsOnMapByMasterCda();
				this.hideAllSuburbsInMasterCda();
				this.updateTmpSuburbsOnMapFromCdaForm();
				this.updateAvailableSuburbsOnMapFromCdaForm();
			}
		);

		this.context.addNotification({
			message: `Suburb ${suburbToBeRemoved.name} removed from CDA ${this.state.cdaForm.name}`,
			type: ENotificationTypes.Info
		});
	};

	addSuburbToCda = (suburbFeatureProperties: ISuburbGeoJsonFeatureProperties) => {
		const suburb = {
			//these id's are dummy values and they will be replaced once
			//we recieve actual values from server
			id: new Date().getTime(),
			name: suburbFeatureProperties.name,
			abs_suburb_id: suburbFeatureProperties.abs_id,
			is_dirty: true,
			is_removable: true
		};

		//the new suburb being added may have been earlier removed
		const suburbsRemoved = this.state.suburbsRemovedFromCda.filter((s) => s.abs_suburb_id !== suburb.abs_suburb_id);

		this.updateSuburbMapFeatureState(suburb.abs_suburb_id, { newlyAddedToCda: true });

		this.setState(
			{
				cdaForm: {
					...(this.state.cdaForm as ICda),
					suburbs: [...this.state.cdaForm.suburbs, suburb]
				},
				suburbsRemovedFromCda: suburbsRemoved,
				suburbsAddedToCda: [...this.state.suburbsAddedToCda, suburb]
			},
			() => {
				this.updateAvailableSuburbsOnMapFromCdaForm();
				this.updateTmpSuburbsOnMapFromCdaForm();
			}
		);

		this.context.addNotification({
			message: `Suburb ${suburb.name} added to CDA ${this.state.cdaForm.name}`,
			type: ENotificationTypes.Info
		});
	};

	submitToMapService = async () => {
		if (!this.state.selectedState) {
			this.context.addNotification({
				message: 'State needs to be selected to sync updates with map service',
				type: ENotificationTypes.Info
			});
			return;
		}

		try {
			this.setState({ isLoading: true });
			const syncStatus = await CdaService.submitToMapServiceForSync({ state_id: this.getSelectedState().id });
			if (syncStatus.success) {
				const job = await this.getInProgressMapServiceSyncJob();
				if (job) {
					this.activateReadOnlyMode(job);
				}
				this.context.addNotification({
					type: ENotificationTypes.Success,
					message: 'Job was successfully submitted to sync map service'
				});
			}

			//todo after submit, backend needs to return the submitted job details in response
			//and frontend will then enter read only state
			/* this.setState({
				latestMapServiceSync: syncStatus
			}); */
		} finally {
			this.setState({ isLoading: false });
		}
	};

	getSelectedState = () => {
		const state = this.state.ausStates.find((s) => this.state.selectedState === s.code);
		if (!state) {
			throw new Error('Unable to find state for showing suburbs');
		}
		return state;
	};

	onSuburbListHoverStart = (suburb: ISuburb) => {
		this.updateSuburbMapFeatureState(suburb.abs_suburb_id, { hover: true });
	};

	onSuburbListHoverEnd = (suburb: ISuburb) => {
		this.updateSuburbMapFeatureState(suburb.abs_suburb_id, { hover: false });
	};

	/***** MAP METHODS *****/

	showMasterCdaOnMap = () => {
		let masterCdaIds = this.state.cda.filter((cda) => !cda.tmp_cda_id).map((cda) => cda.cda_id);

		if (masterCdaIds.length === 0) {
			masterCdaIds = [0];
		}

		//update layer filter (async operation as it goes to GPU via worker thread)
		this.map.setFilter(EMapLayers.MasterCdaOutline, ['match', ['get', 'cda_id'], masterCdaIds, true, false]);
		this.map.setFilter(EMapLayers.MasterCdaFill, ['match', ['get', 'cda_id'], masterCdaIds, true, false]);
	};

	showSuburbsOnMapByMasterCda = () => {
		const cda = this.state.cda
			.filter((cda) => !cda.tmp_cda_id) //suburbs are to be shown inside master cda only
			.find((cda) => cda.cda_id === this.state.cdaSelectedOnMap?.cda_id);

		if (!cda) {
			return;
		}

		let suburbIds = cda.suburbs.map((suburb) => suburb.abs_suburb_id);

		if (suburbIds.length === 0) {
			suburbIds = [''];
		}

		this.map.setFilter(EMapLayers.MasterCdaSuburbOutline, ['match', ['get', 'abs_id'], suburbIds, true, false]);
		this.map.setFilter(EMapLayers.MasterCdaSuburbFill, ['match', ['get', 'abs_id'], suburbIds, true, false]);
	};

	showAvailableSuburbsOnMap = () => {
		let suburbIds = this.state.availableSuburbs.map((s) => s.abs_suburb_id);

		if (suburbIds.length === 0) {
			suburbIds = [''];
		}

		this.map.setFilter(EMapLayers.AvailableSuburbFill, ['match', ['get', 'abs_id'], suburbIds, true, false]);
		this.map.setFilter(EMapLayers.AvailableSuburbOutline, ['match', ['get', 'abs_id'], suburbIds, true, false]);
	};

	/**
	 * this function updates suburbs layer only inside the master cda when adding/removing suburbs
	 * from cda.
	 * IMPORTANT - it gets suburbs data from cda record in this.state.cdaForm
	 * as that is where the latest data is when user is editing
	 */
	updateSuburbsOnMapByMasterCda = () => {
		let suburbIds: string[] = [];

		/**
		 * Internal suburb boundaries should be rendered only when the cda is master CDA
		 * This function gets called from multiple places and one of them is when CDA is updated
		 * If CDA is updated, then it will no longer be a master CDA and hence we can no longer
		 * show suburb boundaries
		 *
		 * Below if condition checks above scenario
		 */
		if (!this.state.cdaForm.tmp_cda_id) {
			suburbIds = this.state.cdaForm.suburbs.map((suburb) => suburb.abs_suburb_id);
		}

		if (suburbIds.length === 0) {
			//mapbox layer filter does not like empty array without ''
			suburbIds = [''];
		}

		this.map.setFilter(EMapLayers.MasterCdaSuburbOutline, ['match', ['get', 'abs_id'], suburbIds, true, false]);
		this.map.setFilter(EMapLayers.MasterCdaSuburbFill, ['match', ['get', 'abs_id'], suburbIds, true, false]);
	};

	updateAvailableSuburbsOnMapFromCdaForm = () => {
		let suburbIds = this.state.availableSuburbs.map((s) => s.abs_suburb_id);
		const suburbsIdsNewlyAddedToCda = this.state.suburbsAddedToCda.map((s) => s.abs_suburb_id);
		const suburbsIdsNewlyRemovedFromCda = this.state.suburbsRemovedFromCda.map((s) => s.abs_suburb_id);

		//suburbs removed are available
		suburbIds = [...suburbIds, ...suburbsIdsNewlyRemovedFromCda];

		//suburbs added to cda need to made unavailalbe
		suburbIds = suburbIds.filter((id) => {
			return !suburbsIdsNewlyAddedToCda.includes(id);
		});

		if (suburbIds.length === 0) {
			suburbIds = [''];
		}

		this.map.setFilter(EMapLayers.AvailableSuburbFill, ['match', ['get', 'abs_id'], suburbIds, true, false]);
		this.map.setFilter(EMapLayers.AvailableSuburbOutline, ['match', ['get', 'abs_id'], suburbIds, true, false]);
	};

	updateTmpSuburbsOnMapFromCdaForm = () => {
		let suburbLayerFeatureIds = this.state.cda
			.filter((cda) => cda.tmp_cda_id && this.state.cdaForm.tmp_cda_id !== cda.tmp_cda_id)
			.flatMap((cda) => cda.suburbs.map((s) => s.abs_suburb_id));

		const suburbsFromCdaForm = this.state.cdaForm.suburbs.map((s) => s.abs_suburb_id);
		suburbLayerFeatureIds = [...suburbLayerFeatureIds, ...suburbsFromCdaForm];

		this.map.setFilter(EMapLayers.TempCdaSuburbFill, ['match', ['get', 'abs_id'], suburbLayerFeatureIds, true, false]);
		this.map.setFilter(EMapLayers.TempCdaSuburbOutline, [
			'match',
			['get', 'abs_id'],
			suburbLayerFeatureIds,
			true,
			false
		]);
	};

	showSelectedTmpCdaSuburbsOnMap = () => {
		this.state.cdaForm.suburbs.forEach((s) => {
			this.updateSuburbMapFeatureState(s.abs_suburb_id, { clicked: true });
		});
	};

	hideAllTmpCdaSuburbSelectionsOnMap = () => {
		this.state.cda.forEach((cda) => {
			cda.suburbs.forEach((s) => {
				this.updateSuburbMapFeatureState(s.abs_suburb_id, { clicked: false });
			});
		});
	};

	hideAllNewlyAddedSuburbsInCdaOnMap = () => {
		this.state.cda.forEach((cda) => {
			cda.suburbs.forEach((s) => {
				this.updateSuburbMapFeatureState(s.abs_suburb_id, { newlyAddedToCda: false });
			});
		});
	};

	hideAllSuburbsInMasterCda = () => {
		this.map.setFilter(EMapLayers.MasterCdaSuburbOutline, ['match', ['get', 'abs_id'], [''], true, false]);
		this.map.setFilter(EMapLayers.MasterCdaSuburbFill, ['match', ['get', 'abs_id'], [''], true, false]);
	};

	showTmpCdaOnMap = () => {
		let suburbLayerFeatureIds = this.state.cda
			.filter((cda) => cda.tmp_cda_id)
			.flatMap((cda) => cda.suburbs.map((s) => s.abs_suburb_id));

		if (suburbLayerFeatureIds.length === 0) {
			suburbLayerFeatureIds = [''];
		}

		//update layer filter (async operation as it goes to GPU via worker thread)
		//if (isCdaOutlineLayerAddedToMap) {
		this.map.setFilter(EMapLayers.TempCdaSuburbFill, ['match', ['get', 'abs_id'], suburbLayerFeatureIds, true, false]);
		this.map.setFilter(EMapLayers.TempCdaSuburbOutline, [
			'match',
			['get', 'abs_id'],
			suburbLayerFeatureIds,
			true,
			false
		]);
	};

	moveMapToCoordinates = (coordinates: { latitude: number; longitude: number }) => {
		this.map.flyTo({
			center: [coordinates.latitude, coordinates.longitude]
		});
	};

	fitMapToBounds = () => {
		const state = this.state.ausStates.find((s) => this.state.selectedState === s.code);
		if (!state) {
			throw new Error('Unable to find state to zoom map');
		}
		const bbox = UtilityService.getMapBoxBoundingBox(state.bounds as IGeoBound);
		//this.map.setMaxBounds(null);
		this.map.fitBounds(bbox);
		//this.map.setMaxBounds(bbox);
	};

	onMapLoadComplete = (map: mapboxgl.Map) => {
		this.map = map;
		this.addMapSources();
		this.addMapLayers();
		this.registerMapEvents();
	};

	addMapSources = () => {
		this.map.addSource('cda', {
			type: 'vector',
			url: `mapbox://${Config.MapTilesets.AustraliaCda.Id}`,
			promoteId: { [Config.MapTilesets.AustraliaCda.SourceLayer]: 'cda_id' }
		});
		this.map.addSource('suburbs', {
			type: 'vector',
			url: `mapbox://${Config.MapTilesets.AustraliaSuburbs.Id}`,
			promoteId: { [Config.MapTilesets.AustraliaSuburbs.SourceLayer]: 'abs_id' }
		});
		this.map.addSource('state', {
			type: 'vector',
			url: `mapbox://${Config.MapTilesets.AustraliaState.Id}?optimize=true`,
			promoteId: { [Config.MapTilesets.AustraliaState.SourceLayer]: 'state_id' }
		});
	};

	addMapLayers = () => {
		this.map.addLayer({
			id: EMapLayers.StateFill,
			type: 'fill',
			source: 'state',
			'source-layer': Config.MapTilesets.AustraliaState.SourceLayer,
			maxzoom: 5,
			paint: {
				'fill-color': Config.MapColors.available,
				'fill-outline-color': Config.MapColors.available
			}
		});

		this.map.addLayer(
			{
				id: EMapLayers.StateOutline,
				type: 'line',
				source: 'state',
				maxzoom: 5,
				'source-layer': Config.MapTilesets.AustraliaState.SourceLayer,
				layout: {
					'line-cap': 'square',
					'line-join': 'bevel',
					'line-round-limit': 1.05,
					'line-miter-limit': 2
				},
				paint: {
					'line-color': this.state.mapColors['state']['outline'],
					'line-width': 1
					//'line-width': ['case', ['boolean', ['feature-state', 'clicked'], false], 4, 2],
					//'line-dasharray': [2, 1]
				}
			},
			'road-label'
		);
		this.map.addLayer({
			id: EMapLayers.StateHoverOutline,
			type: 'line',
			source: 'state',
			maxzoom: 5,
			'source-layer': Config.MapTilesets.AustraliaState.SourceLayer,
			layout: {
				'line-cap': 'round',
				'line-join': 'round',
				'line-round-limit': 1.05
			},
			paint: {
				'line-color': this.state.mapColors['state']['outline'],
				'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.5, 0],
				'line-offset': 2,
				'line-width': 3
			}
		});
		this.map.addLayer({
			id: EMapLayers.MasterCdaSuburbOutline,
			type: 'line',
			source: 'suburbs',
			'source-layer': Config.MapTilesets.AustraliaSuburbs.SourceLayer,
			filter: ['match', ['get', 'abs_id'], [''], true, false],
			paint: {
				'line-color': this.state.mapColors['suburb']['outline'],
				'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 4, 1]
			}
		});

		this.map.addLayer({
			id: EMapLayers.MasterCdaSuburbFill,
			type: 'fill',
			source: 'suburbs',
			'source-layer': Config.MapTilesets.AustraliaSuburbs.SourceLayer,
			filter: ['match', ['get', 'abs_id'], [''], true, false],
			paint: {
				'fill-outline-color': Config.MapColors.available,
				'fill-color': Config.MapColors.available
			}
		});

		this.map.addLayer({
			id: EMapLayers.TempCdaSuburbOutline,
			type: 'line',
			source: 'suburbs',
			'source-layer': Config.MapTilesets.AustraliaSuburbs.SourceLayer,
			filter: ['match', ['get', 'abs_id'], [''], true, false],
			paint: {
				'line-color': this.state.mapColors['suburb']['outline'],
				'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 4, 0]
			}
		});

		this.map.addLayer({
			id: EMapLayers.TempCdaSuburbFill,
			type: 'fill',
			source: 'suburbs',
			'source-layer': Config.MapTilesets.AustraliaSuburbs.SourceLayer,
			filter: ['match', ['get', 'abs_id'], [''], true, false],
			paint: {
				'fill-outline-color': Config.MapColors.available,
				'fill-color': [
					'case',
					['==', ['feature-state', 'newlyAddedToCda'], true],
					this.state.mapColors['suburb']['newlyAddedToCda'],
					['==', ['feature-state', 'clicked'], true],
					this.state.mapColors['suburb']['selected'],
					this.state.mapColors['cda']['fill']
				],
				'fill-opacity': [
					'case',
					['==', ['feature-state', 'newlyAddedToCda'], true],
					0.7,
					['==', ['feature-state', 'clicked'], true],
					0.4,
					0.4
				]
			}
		});

		this.map.addLayer({
			id: EMapLayers.MasterCdaOutline,
			type: 'line',
			source: 'cda',
			'source-layer': Config.MapTilesets.AustraliaCda.SourceLayer,
			filter: ['match', ['get', 'cda_id'], [''], true, false],
			layout: {
				'line-cap': 'square',
				'line-join': 'bevel',
				'line-round-limit': 1.05,
				'line-miter-limit': 2
			},
			paint: {
				'line-color': this.state.mapColors['cda']['outline'],
				'line-width': ['case', ['boolean', ['feature-state', 'clicked'], false], 4, 2]
			}
		});

		this.map.addLayer({
			id: EMapLayers.MasterCdaHoverFill,
			type: 'line',
			source: 'cda',
			'source-layer': Config.MapTilesets.AustraliaCda.SourceLayer,
			layout: {
				'line-cap': 'round',
				'line-join': 'round',
				'line-round-limit': 1.05
			},
			paint: {
				'line-color': this.state.mapColors['cda']['outline'],
				'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.3, 0],
				'line-offset': 2,
				'line-width': 6
			}
		});

		//fill layer is required becuase we need to setup hover events over entire cda
		//and not just the boundaries (which will be the case if we register events using cda-outline layer)
		this.map.addLayer({
			id: EMapLayers.MasterCdaFill,
			type: 'fill',
			source: 'cda',
			'source-layer': Config.MapTilesets.AustraliaCda.SourceLayer,
			filter: ['match', ['get', 'cda_id'], [''], true, false],
			paint: {
				//'line-color': Config.MapColors.cda.outline
				'fill-color': Config.MapColors.available,
				'fill-outline-color': Config.MapColors.available
			}
		});

		this.map.addLayer({
			id: EMapLayers.AvailableSuburbOutline,
			type: 'line',
			source: 'suburbs',
			'source-layer': Config.MapTilesets.AustraliaSuburbs.SourceLayer,
			filter: ['match', ['get', 'abs_id'], [''], true, false],
			paint: {
				'line-color': this.state.mapColors['suburb']['outlineAvailable'],
				'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 4, 2]
			}
		});

		this.map.addLayer({
			id: EMapLayers.AvailableSuburbFill,
			type: 'fill',
			source: 'suburbs',
			'source-layer': Config.MapTilesets.AustraliaSuburbs.SourceLayer,
			filter: ['match', ['get', 'abs_id'], [''], true, false],
			paint: {
				'fill-outline-color': Config.MapColors.available,
				'fill-color': Config.MapColors.available
			}
		});
	};

	registerMapEvents = () => {
		this.registerSuburbClickEvent();
		//this.registerSuburbHoverEvent();
		this.registerMapHoverEvent();
		this.registerMasterCdaClickEvent();
		this.registerMapMoveEvents();
		this.registerStateClickEvent();
	};

	registerMapMoveEvents = () => {
		this.map.on('movestart', () => {
			this.map.off('mousemove', this.onMapHoverStart);
		});
		this.map.on('moveend', () => {
			this.map.on('mousemove', this.onMapHoverStart);
		});
	};

	registerSuburbClickEvent = () => {
		this.map.on('click', EMapLayers.MasterCdaSuburbFill, this.onClickSuburbWithinMasterCda);
		this.map.on('click', EMapLayers.AvailableSuburbFill, this.onClickSuburbWhichAreAvailable);
		this.map.on('click', EMapLayers.TempCdaSuburbFill, this.onClickSuburbWhithinTmpCda);
	};

	onClickSuburbWhithinTmpCda = (e: IMapboxMouseMoveEvent) => {
		//when creating new cda, do not allow any action on suburbs that are within some cda
		if (this.state.activityMode === 'new-cda') {
			return;
		}

		const bbox = UtilityService.getBoundingboxFromMapMouseEvent(e);
		const features = this.map.queryRenderedFeatures(bbox, {
			layers: [EMapLayers.TempCdaSuburbFill]
		}) as ISuburbGeoJsonFeature[];
		const suburbFeature = features[0];

		if (this.state.activityMode === 'change-cda') {
			this.removeSuburbFromCda(suburbFeature.properties.abs_id);
			return;
		}

		this.selectTmpCda(suburbFeature.properties.abs_id);
	};

	selectTmpCda = (suburbAbsId: string) => {
		const cda = this.state.cda.find((cda) => {
			return cda.suburbs.find((s) => s.abs_suburb_id === suburbAbsId);
		});

		if (!cda) {
			throw new Error('Failed to get cda of the clicked suburb');
		}

		this.setState(
			{
				activityMode: EActivityMode.ViewCda,
				cdaSelectedOnMap: cda,
				cdaForm: cda
			},
			() => {
				this.hideAllTmpCdaSuburbSelectionsOnMap();
				this.showSelectedTmpCdaSuburbsOnMap();
			}
		);
	};

	onClickSuburbWhichAreAvailable = (e: IMapboxMouseMoveEvent) => {
		if (this.state.activityMode !== EActivityMode.ChangeCda && this.state.activityMode !== EActivityMode.NewCda) {
			return;
		}

		const bbox = UtilityService.getBoundingboxFromMapMouseEvent(e);
		const features = this.map.queryRenderedFeatures(bbox, {
			layers: [EMapLayers.AvailableSuburbFill]
		}) as ISuburbGeoJsonFeature[];
		const suburbFeature = features[0];
		const suburb = suburbFeature.properties;

		//if suburb is already selected, de-select it
		/* if (suburbFeature.state.clicked) {
			this.removeSuburbFromCda(suburb.abs_id);
			return;
		} */

		this.addSuburbToCda(suburb);
	};

	onClickSuburbWithinMasterCda = (e: IMapboxMouseMoveEvent) => {
		const bbox = UtilityService.getBoundingboxFromMapMouseEvent(e);
		const features = this.map.queryRenderedFeatures(bbox, {
			layers: [EMapLayers.MasterCdaSuburbFill]
		}) as ISuburbGeoJsonFeature[];
		const suburbFeature = features[0];

		//click on suburb inside a master cda should be actioned only if edit mode is on
		if (this.state.activityMode !== EActivityMode.ChangeCda) {
			return;
		}

		//clicking on a suburb inside a master cda always means that user is deselecting it
		this.removeSuburbFromCda(suburbFeature.properties.abs_id);
	};

	registerMapHoverEvent = () => {
		this.map.on('mousemove', this.onMapHoverStart);

		/**
		 * Mousemove event fires on entire map and its implementation
		 * has logic to clear existing hover outlines and tooltip if it does not find any feature underneath the
		 * mouse
		 *
		 * Essentially mousemove itself clears up the hover styles that it had applied
		 *
		 * In certain cases though mousemove will not be able to do job such as when the map is positioned in such
		 * a way that suburb or CDA boundary is at the edge of right side panel and mouse directly exits the map
		 * view without moving over any other areas. In such cases the hover styles and tooltip will remain
		 *
		 * So for these edge cases we need to listen to mouseleave events and we need listen to each layer
		 * seperately as the callback does not accept array of values
		 */
		this.map.on('mouseleave', EMapLayers.MasterCdaFill, this.onMapHoverEnd);
		this.map.on('mouseleave', EMapLayers.AvailableSuburbFill, this.onMapHoverEnd);
		this.map.on('mouseleave', EMapLayers.TempCdaSuburbFill, this.onMapHoverEnd);
		this.map.on('mouseleave', EMapLayers.StateFill, this.onMapHoverEnd);
	};

	onMapHoverStart = (e: IMapboxMouseMoveEvent) => {
		/* if (this.state.selectedState === '') {
			return;
		} */

		const features = this.map.queryRenderedFeatures(e.point, {
			layers: [
				EMapLayers.MasterCdaFill,
				EMapLayers.MasterCdaSuburbFill,
				EMapLayers.TempCdaSuburbFill,
				EMapLayers.AvailableSuburbFill,
				EMapLayers.StateFill
			]
		});
		this.map.getCanvas().style.cursor = 'pointer';

		if (features.length === 0) {
			this.cleanExistingHoverArea();
			this.popup.remove();
			return;
		}

		const isHoverOnSuburb = features.find(
			(f) =>
				f.layer.id === EMapLayers.MasterCdaSuburbFill ||
				f.layer.id === EMapLayers.TempCdaSuburbFill ||
				f.layer.id === EMapLayers.AvailableSuburbFill
		);
		if (isHoverOnSuburb) {
			const suburb = isHoverOnSuburb as ISuburbGeoJsonFeature;
			this.onHoverSuburb(suburb, e);
			return;
		}

		const isHoverOnCda = features.find((f) => f.layer.id === EMapLayers.MasterCdaFill);
		if (isHoverOnCda) {
			const cdaFeature = isHoverOnCda as ICdaGeoJsonFeature;
			this.onHoverCda(cdaFeature, e);
		}
		const isHoverOnState = features.find((f) => f.layer.id === EMapLayers.StateFill);
		if (isHoverOnState) {
			const stateFeature = isHoverOnState as IStateGeoJsonFeature;
			this.onHoverState(stateFeature, e);
			return;
		}
	};
	onHoverState = (stateFeature: IStateGeoJsonFeature, e: IMapboxMouseMoveEvent) => {
		//some islands have not been assigned id's in mapbox tilesets hence this condition
		if (!stateFeature.properties.state_id) {
			return;
		}

		const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];

		//need to update state in feature to remove hover outline
		this.cleanExistingHoverArea();
		this.hoverState.state = stateFeature.properties.state_id;

		this.updateStateMapFeatureState(this.hoverState.state, { hover: true });

		//tooltip logic
		const tooltipContent = `${stateFeature.properties.name}`;

		this.popup.setLngLat(coordinates).setHTML(tooltipContent).addTo(this.map);
	};

	//e is required because we need hover event coordinates to display tooltip
	onHoverSuburb = (suburb: ISuburbGeoJsonFeature, e: IMapboxMouseMoveEvent) => {
		const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];

		//need to update state in feature to remove hover outline
		this.cleanExistingHoverArea();
		this.hoverState.suburb = suburb.properties.abs_id;
		this.updateSuburbMapFeatureState(this.hoverState.suburb, { hover: true });

		//tooltip logic
		let tooltipContent = `Suburb: ${suburb.properties.name}`;
		const cda = lodashFind(this.state.cda, { suburbs: [{ abs_suburb_id: this.hoverState.suburb }] });
		if (cda) {
			tooltipContent += `<br>CDA: ${cda.name}`;
		}

		this.popup.setLngLat(coordinates).setHTML(tooltipContent).addTo(this.map);
	};

	onHoverCda = (cdaFeature: ICdaGeoJsonFeature, e: IMapboxMouseMoveEvent) => {
		//need to update state in feature to remove hover outline
		this.cleanExistingHoverArea();
		this.hoverState.cda = cdaFeature.id;
		this.updateCdaMapFeatureState(this.hoverState.cda, { hover: true });

		//tooltip logic
		const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];
		const cdaProperties = cdaFeature.properties;
		const cda = this.state.cda.find((cda) => cda.cda_id === cdaProperties.cda_id);

		if (!cda) {
			//throw new Error(`Could not retrieve cda record for ${cdaProperties.cda_id}`);
			console.error(`Could not retrieve cda record for ${cdaProperties.cda_id}`);
			return;
		}
		const tooltipContent = `CDA: ${cda.name}`;

		this.popup.setLngLat(coordinates).setHTML(tooltipContent).addTo(this.map);
	};

	onMapHoverEnd = () => {
		if (this.hoverState.cda !== 0) {
			this.map.setFeatureState(
				{
					source: 'cda',
					id: this.hoverState.cda,
					sourceLayer: Config.MapTilesets.AustraliaCda.SourceLayer
				},
				{ hover: false }
			);
			this.hoverState.cda = 0;
		}
		if (this.hoverState.state !== 0) {
			this.updateStateMapFeatureState(this.hoverState.state, { hover: false });
			this.hoverState.state = 0;
		}

		this.map.getCanvas().style.cursor = '';
		this.popup.remove();
	};

	//finds any existing feature that is in hover state and removes
	//all styling and resets state so that a fresh area can be hovered onto
	cleanExistingHoverArea = () => {
		if (this.hoverState.cda) {
			this.updateCdaMapFeatureState(this.hoverState.cda, { hover: false });
		}

		if (this.hoverState.suburb) {
			this.updateSuburbMapFeatureState(this.hoverState.suburb, { hover: false });
		}
		if (this.hoverState.state) {
			this.updateStateMapFeatureState(this.hoverState.state, { hover: false });
		}
	};

	registerMasterCdaClickEvent = () => {
		this.map.on('click', EMapLayers.MasterCdaFill, (e) => {
			// set bbox as 5px reactangle area around clicked point
			/**
			 * Note - all the PointLike type declaration below just to get bbox were done because
			 * typescript was complaining of the type mismatch when calling queryRenderedFeatures
			 */
			const ns: PointLike = [e.point.x - 5, e.point.y - 5];
			const ew: PointLike = [e.point.x + 5, e.point.y + 5];
			const bbox: [PointLike, PointLike] = [ns, ew];

			const features = this.map.queryRenderedFeatures(bbox, {
				layers: [EMapLayers.MasterCdaFill]
			}) as ICdaGeoJsonFeature[];
			const cdaFeature = features[0];

			this.onClickMasterCda(cdaFeature);
		});
	};

	registerStateClickEvent = () => {
		this.map.on('click', EMapLayers.StateFill, (e) => {
			const ns: PointLike = [e.point.x - 5, e.point.y - 5];
			const ew: PointLike = [e.point.x + 5, e.point.y + 5];
			const bbox: [PointLike, PointLike] = [ns, ew];
			const features = this.map.queryRenderedFeatures(bbox, {
				layers: [EMapLayers.StateFill]
			});
			const state = features[0] as IStateGeoJsonFeature;
			if (state.properties.state_id) {
				this.onClickStateInMap(state);
				return;
			}
		});
	};

	onClickMasterCda = (cdaFeature: ICdaGeoJsonFeature) => {
		if (this.state.activityMode === EActivityMode.NewCda || this.state.activityMode === EActivityMode.ChangeCda) {
			return;
		}

		const cda = this.state.cda.find((cda) => cda.cda_id === cdaFeature.properties.cda_id);
		if (!cda) {
			throw new Error('Could not find master cda record of clicked cda feature.');
		}

		if (cda.bounds && cda.bounds !== '') {
			const bounds = JSON.parse(cda.bounds);
			this.fitMapToBoundsWithPadding(bounds);
		}

		//de-select any previous cda selection if available
		if (this.state.cdaSelectedOnMap) {
			this.updateCdaMapFeatureState(this.state.cdaSelectedOnMap.cda_id, { clicked: false });
			this.hideAllTmpCdaSuburbSelectionsOnMap();
		}

		this.updateCdaMapFeatureState(cdaFeature.id);

		this.setState(
			{
				cdaSelectedOnMap: cda,
				cdaForm: cda,
				activityMode: EActivityMode.ViewCda
			},
			() => {
				this.showSuburbsOnMapByMasterCda();
			}
		);
	};
	onClickStateInMap = (stateFeature: IStateGeoJsonFeature) => {
		const stateIdFromMapClick = stateFeature.properties.state_id;
		if (!this.state.selectedState) {
			this.selectState(stateIdFromMapClick);
			return;
		}
		const state = this.state.ausStates.find((s) => stateIdFromMapClick === s.id);
		if (!state) {
			throw new Error('Could not find state selected in local states array');
		}

		if (this.state.selectedState !== state.code) {
			this.selectState(stateIdFromMapClick);
		}
	};

	updateSuburbMapFeatureState = (absSuburbId: string, options: TSuburbMapFeatureState = { clicked: true }) => {
		this.map.setFeatureState(
			{
				source: 'suburbs',
				sourceLayer: Config.MapTilesets.AustraliaSuburbs.SourceLayer,
				id: absSuburbId
			},
			options
		);
	};

	updateCdaMapFeatureState = (cdaId: number, options: TCdaMapFeatureState = { clicked: true }) => {
		this.map.setFeatureState(
			{
				source: 'cda',
				sourceLayer: Config.MapTilesets.AustraliaCda.SourceLayer,
				id: cdaId
			},
			options
		);
	};
	updateStateMapFeatureState = (stateId: number, options: TAusStateMapFeatureState = { hover: true }) => {
		this.map.setFeatureState(
			{
				source: 'state',
				sourceLayer: Config.MapTilesets.AustraliaState.SourceLayer,
				id: stateId
			},
			options
		);
	};

	fitMapToBoundsWithPadding = (bounds: IGeoBound, padding = 20) => {
		const mapboxBounds = UtilityService.getMapBoxBoundingBox(bounds);
		this.map.fitBounds(mapboxBounds, { padding });
	};
}

const DeleteButton = withStyles((theme) => ({
	root: {
		color: red[500],
		'&:hover': {
			backgroundColor: red[700],
			color: theme.palette.getContrastText(red[500])
		}
	},
	outlined: {
		color: red[500]
	}
}))(Button);

ManageCda.contextType = NotificationContext;

export default ManageCda;
