const config = {
	Backend: {
		ApiEndpoint: 'http://localhost:3000/api'
	},
	MapboxToken: 'pk.eyJ1IjoidmluYXluYiIsImEiOiJja3BkbHBrcmcwYTJ5MndudnJnZWhwY24wIn0.YILvCKV8KCu7_tztjVU3eQ',
	MapColors: {
		available: 'rgba(38, 70, 83, 0)',
		cda: {
			fill: '#2E282A',
			outline: '#A41623',
			selected: ''
		},
		cdar: {
			newlyAddedToCdar: '#E98A15',
			tmpFill: '#2E282A',
			outline: '#8400A3',
			selected: '#FF6F59',
			masterFill: '#42033D'
		},
		neighbouringSuburb: {
			fill: '#43AA8B',
			outline: '#002642',
			selected: '#FF6F59'
		},
		suburb: {
			newlyAddedToCda: '#E98A15',
			outline: '#002642',
			outlineAvailable: '#F96900',
			selected: '#FF6F59'
		},
		sa1: {
			outlineAvailable: '#F96900',
			fill: '#43AA8B',
			outline: '#002642',
			selected: '#FF6F59',
			sold: '#FF6400'
		},
		sales: {
			cdarOutline: '#8400A3', //neon blue
			sa1Outline: '#8400A3', //neon blue
			sold: '#FF6400', //brown
			soldLabel: '#FFFFFF',
			reserved: '#3D9A43', //yellow
			reservedLabel: '#FFFFFF',
			selectedArea: '#D9DADC'
		},
		state: {
			outline: '#85877C'
		}
	},
	MapOutline: {
		width: 2
	},
	MapTilesets: {
		AustraliaSuburbs: { Id: 'vinaynb.ckkjfbqr711kh21qq29og94xv-851dx', SourceLayer: 'suburb-sa1' },
		AustraliaSa1: { Id: 'vinaynb.0evtgjck', SourceLayer: 'aus-sa1-5pd9e6' },
		AustraliaCda: { Id: 'vinaynb.stgz_aus_cda', SourceLayer: 'stgz_aus_cda' },
		AustraliaCdar: { Id: 'vinaynb.stgz_aus_cdar', SourceLayer: 'stgz_aus_cdar' },
		AustraliaState: { Id: 'vinaynb.a4vb8rqa', SourceLayer: 'aus_state' }
	},
	MapInitialPosition: {
		coordinates: [134.306276, -25.987847],
		zoom: 4
	}
};
export default config;
