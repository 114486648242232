import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import theme from 'shared/constants/Theme';
import App from './App';
import './Index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import NotificationContentProvider from 'shared/providers/NotificationProvider';

ReactDOM.render(
	//documentation at https://reactjs.org/docs/strict-mode.html
	//strict mode may affect perf as it renders some components twice
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<React.StrictMode>
			<NotificationContentProvider>
				<Router>
					<App />
				</Router>
			</NotificationContentProvider>
		</React.StrictMode>
	</ThemeProvider>,
	document.getElementById('root')
);
