/**
 * Author - Vinay
 * This hook is inspired from - https://stackoverflow.com/questions/59335963/react-hooks-display-global-spinner-using-axios-interceptor
 */

import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useMemo } from 'react';
import { ax } from 'shared/services/HttpService';
import { ENotificationTypes } from 'shared/types/Types';
import UseNotification from './UseNotification';

const UseAxiosErrorHandler = () => {
	const { addNotification } = UseNotification();

	//useMemo is used becase we want to avoid unnecessary re-creation of below functions
	//as that would be an overhead
	const interceptors = useMemo(() => {
		return {
			responseErrorInterceptor: (error: AxiosError) => {
				//do not show notification if requests are cancelled on purpose
				if (axios.isCancel(error)) {
					return Promise.reject(error);
				}
				const notification = {
					message: error.response?.data.message,
					type: ENotificationTypes.Error
				};
				addNotification(notification);
				return Promise.reject(error);
			},
			responseSuccessInterceptor: (value: AxiosResponse) => {
				/**
				 * hideToast=true signals that frontend should show error/notification
				 * on its own individually in the respective component using a custom UI
				 */
				//if (!value.data.success && !value.data.hideToast) {
				if (!value.data.success) {
					const notification = {
						message: value.data.message || 'Something went wrong',
						type: ENotificationTypes.Error
					};
					addNotification(notification);
					return Promise.reject(value);
				}
				return value;
			}
		};
	}, [addNotification]);

	useEffect(() => {
		// add response interceptors
		const resInterceptor = ax.interceptors.response.use(
			interceptors.responseSuccessInterceptor,
			interceptors.responseErrorInterceptor
		);
		return () => {
			// remove all intercepts when done
			ax.interceptors.response.eject(resInterceptor);
		};
	}, [interceptors]);
};

export default UseAxiosErrorHandler;
