import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/ClearOutlined';
import { ERevAreaSaleStatus, ISalesAreaListItem, IMapColor } from 'shared/types/Types';
import { AreaReservedBadge, AreaSoldBadge } from 'shared/components/AreaStatusLabels';
import VirtualizedTable from 'shared/components/VirtualizedTable';
import { RowMouseEventHandlerParams } from 'react-virtualized';
import UtilityService from 'shared/services/UtilityService';

interface IProps {
	mapColors: IMapColor;
	areas: ISalesAreaListItem[];
	onClickArea: (area: ISalesAreaListItem) => void;
	onClickSelectAllAreas: () => void;
	onClickDeSelectAllAreas: () => void;
	onClickRemoveArea: (area: ISalesAreaListItem) => void;
	onAreaMouseOver: (area: ISalesAreaListItem) => void;

	onAreaMouseOut: (area: ISalesAreaListItem) => void;
}

const getTableColumns = (props: IProps) => {
	return [
		{
			label: '',
			type: 'checkbox',
			dataKey: 'selected',
			width: 50
			/* headerWidth: 50 */
		},
		{
			label: 'Name',
			dataKey: 'name',
			width: 70,
			headerWidth: 50
		},
		{
			label: 'Type',
			dataKey: 'type',
			width: 50,
			headerWidth: 50
		},
		{
			label: 'Status',
			dataKey: 'status',
			width: 110,
			headerWidth: 100,
			content: (row: any) => {
				const area = row as ISalesAreaListItem;
				return (
					<Grid
						container
						alignItems="center"
						justify="flex-start"
						className="stretch selectedAreaStatus"
						style={{ paddingLeft: 5 }}
					>
						{area.status === ERevAreaSaleStatus.Sold && <AreaSoldBadge mapColors={props.mapColors} />}
						{area.status === ERevAreaSaleStatus.Reserved && <AreaReservedBadge mapColors={props.mapColors} />}
						{!area.status && 'Available'}
					</Grid>
				);
			}
		},
		{
			label: 'Expiry',
			dataKey: 'reservationExpiry',
			width: 150,
			headerWidth: 70,
			content: (row: any) => {
				const area = row as ISalesAreaListItem;
				return (
					<span style={{ padding: area.reservationExpiry ? 0 : 15, width: '100%', textAlign: 'center' }}>
						{area.reservationExpiry ? UtilityService.getTimeAndDateWithTimeZone(area.reservationExpiry, 'PP') : '-'}
					</span>
				);
			}
		},
		{
			label: 'Action',
			dataKey: '',
			width: 50,
			headerWidth: 50,
			content: (row: any) => {
				const area = row as ISalesAreaListItem;
				return (
					<Grid container alignItems="center" justify="center" className="stretch">
						{!area.status ? (
							<IconButton
								size="small"
								style={{ paddingRight: 30 }}
								onClick={(ev) => {
									ev.stopPropagation();
									props.onClickRemoveArea(area);
								}}
							>
								<ClearIcon color="secondary" style={{ width: 20, height: 20 }} />
							</IconButton>
						) : (
							<span style={{ paddingRight: 15 }}>-</span>
						)}
					</Grid>
				);
			}
		}
	];
};

const SelectedSa1CdarAreaList: React.FunctionComponent<IProps> = (props) => {
	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			props.onClickSelectAllAreas();
			return;
		}
		props.onClickDeSelectAllAreas();
	};

	const handleClick = (ev: RowMouseEventHandlerParams) => {
		const area = ev.rowData as ISalesAreaListItem;
		props.onClickArea(area);
	};

	const onAreaMouseOver = (ev: RowMouseEventHandlerParams) => {
		const area = ev.rowData as ISalesAreaListItem;
		props.onAreaMouseOver(area);
	};

	const onAreaMouseOut = (ev: RowMouseEventHandlerParams) => {
		const area = ev.rowData as ISalesAreaListItem;
		props.onAreaMouseOut(area);
	};

	const areaCount = props.areas.length;

	return (
		<Grid container direction="column" className="stretch">
			{areaCount > 0 && (
				<VirtualizedTable
					onRowClick={handleClick}
					columns={getTableColumns(props)}
					rowCount={areaCount}
					rowGetter={({ index }) => props.areas[index]}
					onToggleAllRowsSelectionClick={handleSelectAllClick}
					onRowMouseOver={onAreaMouseOver}
					onRowMouseOut={onAreaMouseOut}
				/>
			)}
		</Grid>
	);
};
export default SelectedSa1CdarAreaList;
