import React, { useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { ITableFilterParam } from '../Report';
import { IMapSyncJob } from 'shared/types/Types';
import CommonApiService from 'shared/services/CommonApiService';
import ReportResult from './MapServiceSyncReportResult';
import EmptyScreen from 'shared/components/EmptyScreen';
import LinearProgress from 'shared/components/LoadingLinear';
import RefreshedIcon from '@material-ui/icons/CachedOutlined';
interface IMatchParams {
	id: string;
}
type IProps = RouteComponentProps<IMatchParams>;

const MapServiceSyncReport: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const [records, setRecords] = React.useState<IMapSyncJob[]>([]);
	const [tableFilterURLParams, setTableFilterURLParams] = React.useState<ITableFilterParam | null>(null);
	const [totalRecords, setTotalRecords] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	useEffect(() => {
		getMapServiceRecord();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onTableFilter = (tableFilterParams: ITableFilterParam) => {};

	const getMapServiceRecord = async () => {
		try {
			setIsLoading(true);
			const syncJobs = await CommonApiService.getMapServiceSyncJobs();
			if (!syncJobs) {
				setRecords([]);
				return;
			}
			setRecords(syncJobs);
			setTotalRecords(20);
		} finally {
			setIsLoading(false);
		}
	};

	const getEmptyScreenMsg = () => {
		return 'No data available';
	};

	const onRefreshRecord = () => {
		getMapServiceRecord();
	};

	return (
		<Grid direction="column" container style={{ width: '100%', height: '100%' }}>
			<LinearProgress showLoader={isLoading} />
			<Grid item container style={{ padding: 10 }} justify="space-between">
				<Grid item>
					<Typography className="mb10" color="secondary" variant="h6">
						Map Service Sync Report
					</Typography>
				</Grid>
				<Grid item>
					<Button
						color="primary"
						variant="outlined"
						onClick={onRefreshRecord}
						endIcon={<RefreshedIcon />}
						style={{ marginRight: 0, margin: 5 }}
					>
						Refresh
					</Button>
				</Grid>
			</Grid>

			<Grid item xs className="pl10 rl10" style={{ marginBottom: 10, overflow: 'auto' }}>
				{records.length > 0 ? (
					<ReportResult
						records={records}
						totalRecord={totalRecords}
						onTableFilter={onTableFilter}
						tableFilterParams={tableFilterURLParams}
					/>
				) : (
					<EmptyScreen emptyText={getEmptyScreenMsg()} />
				)}
			</Grid>
		</Grid>
	);
};

export default MapServiceSyncReport;
