import axios, { AxiosRequestConfig, Method } from 'axios';
import config from 'shared/constants/Config';

export const ax = axios.create();
const NODE_ENV = process.env.REACT_APP_RUNTIME_ENV as 'development' | 'staging' | 'production' | 'uat';

/* ax.interceptors.request.use((req) => {
	console.log('from request interceptor');

	// Important: request interceptors **must** return the request.
	return req;
});

ax.interceptors.response.use((res) => {
	console.log('from response interceptor');
	// Important: response interceptors **must** return the response.
	return res;
}); */

interface IErrorResponse {
	message: string;
}

interface IErrorData extends IErrorResponse {
	statusText: string;
	success: boolean;
	isDialog?: boolean;
	errContentCode?: string;
	code?: string;
}

function post<T>(url: string, body: T): Promise<unknown> {
	return makeAjaxCall(url, 'POST', { data: body });
}

function get(url: string, queryParams?: { [key: string]: unknown }, otherOptns?: AxiosRequestConfig): Promise<unknown> {
	return makeAjaxCall(url, 'GET', {
		params: queryParams,
		...otherOptns
	});
}

function put<T>(url: string, body: T): Promise<unknown> {
	return makeAjaxCall(url, 'PUT', { data: body });
}

function remove<T>(url: string): Promise<unknown> {
	return makeAjaxCall(url, 'DELETE');
}

function makeAjaxCall(urlSuffix: string, method: Method, options?: { [key: string]: unknown }): Promise<unknown> {
	return new Promise(function (resolve, reject) {
		ax({
			method,
			url: `${config.Backend.ApiEndpoint}/${urlSuffix}`,
			...options,
			withCredentials: ['staging', 'production', 'uat'].includes(NODE_ENV) ? undefined : true
		})
			.then((response) => {
				/* console.log(response.data); */
				resolve(response.data);
			})
			.catch((error) => {
				if (!error.response) {
					reject('Axios cancelled request');
					return;
				}
				if (error.response.status === 401 || error.response.status === 403) {
					window.location.href = '/auth';
				}

				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					//console.log(error.response.data);
					//console.log(error.response.status);
					//console.log(error.response.headers);
					reject(error.response.data);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					//console.log(error.request);
					reject(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					//console.log('Error', error.message);
					reject(error.message);
				}
				//console.log(error.config);
				//console.log(error);
			});
	});
}

const HttpService = { post, put, get, remove };
export default HttpService;
