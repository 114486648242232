import { Grid, ListItemText, Typography, Tooltip, Fab } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/DescriptionOutlined';
import React from 'react';
import RevAreaList from 'shared/components/RevAreaList';
import { ISuburb, IZenuSuburb } from 'shared/types/Types';

interface IProps {
	suburbs: IZenuSuburb[];
	showRegionHeader: boolean;
	showSuburbHeader: boolean;
	onSuburbListItemHoverStart?: (suburb: ISuburb) => void;
	onSuburbListItemHoverEnd?: (suburb: ISuburb) => void;
	onClickCopySuburbs?: () => void;
}

const ViewNeighbouringSuburbsForm: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const { suburbs } = props;

	//name attribute is required by RevAreaList
	const suburbList = suburbs.map((suburb) => {
		return {
			id: suburb.id,
			abs_suburb_id: suburb.abs_suburb_id,
			is_removable: false,
			name: `${suburb.name} (${suburb.postcode}) (${suburb.id})`,
			key: suburb.abs_suburb_id
		};
	});

	const showRegionHeader = props.showRegionHeader;
	const showSuburbHeader = props.showSuburbHeader;

	const suburbListRowMouseEnter = (suburb: any) => {
		if (props.onSuburbListItemHoverStart) {
			props.onSuburbListItemHoverStart(suburb);
		}
	};

	const suburbListRowMouseLeave = (suburb: any) => {
		if (props.onSuburbListItemHoverEnd) {
			props.onSuburbListItemHoverEnd(suburb);
		}
	};

	return (
		<Grid container item direction="column">
			<form className="stretch">
				<Grid container direction="column" className="stretch">
					<Grid item direction="column" container style={{ overflowY: 'auto' }} wrap="nowrap">
						{suburbList.length > 0 && (
							<>
								<Grid container justify="space-between" alignItems="baseline">
									{showRegionHeader && (
										<Typography style={{ margin: '0 10px' }} color="secondary" variant="body1">
											Regions Suburbs List
										</Typography>
									)}
									{showSuburbHeader && (
										<Typography style={{ margin: '0 10px' }} color="secondary" variant="body1">
											Neighbouring Suburbs List
										</Typography>
									)}
									<Tooltip title="Copy" style={{ margin: '0 10px' }}>
										<span>
											<Fab size="small" onClick={props.onClickCopySuburbs}>
												<CopyIcon />
											</Fab>
										</span>
									</Tooltip>
								</Grid>
								<RevAreaList
									height="calc(100vh - 350px)"
									areaList={suburbList}
									onHoverListStart={suburbListRowMouseEnter}
									onHoverListEnd={suburbListRowMouseLeave}
									rowContent={(suburb) => {
										return (
											<>
												<ListItemText primary={suburb.name} />
											</>
										);
									}}
								/>
							</>
						)}
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};

export default ViewNeighbouringSuburbsForm;
