import React from 'react';
import { makeStyles } from '@material-ui/core';
import LoadingSpinner from './LoadingSpinner';

interface IProps {
	type?: string;
	status?: boolean;
}

const AreaLoader: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const classes = useStyles();
	return (
		<>
			{props.status && (
				<div style={{ position: 'relative', height: '100%', width: '100%' }}>
					<div className={props.type === 'dialog' ? classes.loadingDialogContainer : classes.loadingContainer}>
						<LoadingSpinner />
					</div>
				</div>
			)}
		</>
	);
};

AreaLoader.defaultProps = {
	status: false
};

const coreLoadingContainerStyles = {
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	zIndex: 999,
	width: '100%',
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: 'rgba(255,255,255,0.8)'
};

const useStyles = makeStyles(() => ({
	loadingContainer: {
		...coreLoadingContainerStyles,
		position: 'absolute'
	},
	loadingDialogContainer: {
		...coreLoadingContainerStyles,
		position: 'fixed'
	}
}));

export default AreaLoader;
