import React, { useState } from 'react';
import { ILoggedInUser } from 'shared/types/Types';

export interface ILoggedInUserContext {
	loggedInUser: ILoggedInUser | null;
	setLoggedInUser: (user: ILoggedInUser) => void;
	removeLoggedInUser: () => void;
}

export const LoggedInUserContext = React.createContext<ILoggedInUserContext>({
	loggedInUser: null,
	setLoggedInUser: () => {
		console.log('add notification');
	},
	removeLoggedInUser: () => {
		console.log('remove notification');
	}
});

interface IProps {
	children?: React.ReactNode;
}

const LoggedInUserProvider: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const [user, setUser] = useState<ILoggedInUser | null>(null);

	const removeLoggedInUser = () => {
		if (!user) {
			return;
		}
		setUser(null);
	};

	const setLoggedInUser = (loggedInUser: ILoggedInUser) => {
		setUser(loggedInUser);
	};

	const contextValue = {
		loggedInUser: user,
		setLoggedInUser,
		removeLoggedInUser
	};

	return <LoggedInUserContext.Provider value={contextValue}>{props.children}</LoggedInUserContext.Provider>;
};

export default LoggedInUserProvider;
