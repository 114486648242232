import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	Divider,
	Grid,
	Typography,
	TextField,
	DialogContent,
	DialogTitle,
	Dialog,
	Button,
	Popover,
	CircularProgress,
	makeStyles
} from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { IMapColor } from 'shared/types/Types';
import { withStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import reactCSS from 'reactcss';
import { SketchPicker, ColorResult } from 'react-color';

interface IProps {
	mapColors: IMapColor | null;
	onClickClose: () => void;
	onClickSubmitMapColor: (data: IMapColor) => void;
}

enum EColorTypeModals {
	cdaFillModal = 'cdaFill',
	cdaOutlineModal = 'cdaOutline',
	cdaSelectedModal = 'cdaSelected',
	cdarNewlyAddedToCdarModal = 'cdarNewlyAddedToCdar',
	cdarTmpFillModal = 'cdarTmpFill',
	cdarOutlineModal = 'cdarOutline',
	cdarSelectedModal = 'cdarSelected',
	cdarMasterFillModal = 'cdarMasterFill',
	suburbNewlyAddedToCdaModal = 'suburbNewlyAddedToCda',
	suburbOutlineModal = 'suburbOutline',
	suburbOutlineAvailableModal = 'suburbOutlineAvailable',
	suburbSelectedModal = 'suburbSelected',
	sa1OutlineAvailableModal = 'sa1OutlineAvailable',
	sa1FillModal = 'sa1Fill',
	sa1OutlineModal = 'sa1Outline',
	sa1SelectedModal = 'sa1Selected',
	salesCdarOutlineModal = 'salesCdarOutline',
	salesSa1OutlineModal = 'salesSa1Outline',
	salesSoldModal = 'salesSold',
	salesSoldLabelModal = 'salesSoldLabel',
	salesReservedModal = 'salesReserved',
	salesReservedLabelModal = 'salesReservedLabel',
	salesSelectedAreaModal = 'salesSelectedArea',
	stateOutlineModal = 'stateOutline',
	neighbouringSuburbFillModal = 'neighbouringSuburbFill',
	neighbouringSuburbSelectedModal = 'neighbouringSuburbSelected',
	neighbouringSuburbOutlineModal = 'neighbouringSuburbOutline',
	None = 'none'
}

const initialValues = {
	cdaFill: '',
	cdaOutline: '',
	cdaSelected: '',
	cdarNewlyAddedToCdar: '',
	cdarTmpFill: '',
	cdarOutline: '',
	cdarSelected: '',
	cdarMasterFill: '',
	suburbNewlyAddedToCda: '',
	suburbOutline: '',
	suburbOutlineAvailable: '',
	suburbSelected: '',
	sa1OutlineAvailable: '',
	sa1Fill: '',
	sa1Outline: '',
	sa1Selected: '',
	salesCdarOutline: '',
	salesSa1Outline: '',
	salesSold: '',
	salesSoldLabel: '',
	salesReserved: '',
	salesReservedLabel: '',
	salesSelectedArea: '',
	stateOutline: '',
	neighbouringSuburbFill: '',
	neighbouringSuburbSelected: '',
	neighbouringSuburbOutline: ''
};
const colorYupValidation = (name: string) => {
	return yup
		.string()
		.required(`${name} Map color is a required field`)
		.matches(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/, 'Enter valid Hex value');
};
const schema = yup.object().shape({
	cdaFill: colorYupValidation('CDA fill'),
	cdaOutline: colorYupValidation('CDA outline'),
	cdaSelected: colorYupValidation('CDA selected'),
	cdarNewlyAddedToCdar: colorYupValidation('Newly added To CDAR'),
	cdarTmpFill: colorYupValidation('CDAR temp fill'),
	cdarOutline: colorYupValidation('CDAR outline'),
	cdarSelected: colorYupValidation('CDAR selected'),
	cdarMasterFill: colorYupValidation('CDAR Master Fill'),
	suburbNewlyAddedToCda: colorYupValidation('Newly added To CDA'),
	suburbOutline: colorYupValidation('Suburb outline'),
	suburbOutlineAvailable: colorYupValidation('Suburb outline available'),
	suburbSelected: colorYupValidation('Suburb selected'),
	sa1OutlineAvailable: colorYupValidation('SA1 outline available'),
	sa1Fill: colorYupValidation('SA1 fill'),
	sa1Outline: colorYupValidation('SA1 Outline'),
	sa1Selected: colorYupValidation('SA1 Selected'),
	salesCdarOutline: colorYupValidation('Sales CDAR'),
	salesSa1Outline: colorYupValidation('Sales SA1'),
	salesSold: colorYupValidation('Sales sold'),
	salesSoldLabel: colorYupValidation('Sales sold Label'),
	salesReserved: colorYupValidation('Sales Reserved'),
	salesReservedLabel: colorYupValidation('Sales Reserved Label'),
	salesSelectedArea: colorYupValidation('Sales Selected Area'),
	stateOutline: colorYupValidation('State Outline'),
	neighbouringSuburbFill: colorYupValidation('Neighbouring Suburb Fill'),
	neighbouringSuburbSelected: colorYupValidation('Neighbouring Suburb Selected'),
	neighbouringSuburbOutline: colorYupValidation('Neighbouring Suburb Outline')
});

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	}
}));

const ConfigureColorModal: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const getColorObjForForm = (selMapColors: IMapColor) => {
		return {
			cdaFill: selMapColors.cda.fill,
			cdaOutline: selMapColors.cda.outline,
			cdaSelected: selMapColors.cda.selected,
			cdarNewlyAddedToCdar: selMapColors.cdar.newlyAddedToCdar,
			cdarTmpFill: selMapColors.cdar.tmpFill,
			cdarOutline: selMapColors.cdar.outline,
			cdarSelected: selMapColors.cdar.selected,
			cdarMasterFill: selMapColors.cdar.masterFill,
			suburbNewlyAddedToCda: selMapColors.suburb.newlyAddedToCda,
			suburbOutline: selMapColors.suburb.outline,
			suburbOutlineAvailable: selMapColors.suburb.outlineAvailable,
			suburbSelected: selMapColors.suburb.selected,
			sa1OutlineAvailable: selMapColors.sa1.outlineAvailable,
			sa1Fill: selMapColors.sa1.fill,
			sa1Outline: selMapColors.sa1.outline,
			sa1Selected: selMapColors.sa1.selected,
			salesCdarOutline: selMapColors.sales.cdarOutline,
			salesSa1Outline: selMapColors.sales.sa1Outline,
			salesSold: selMapColors.sales.sold,
			salesSoldLabel: selMapColors.sales.soldLabel,
			salesReserved: selMapColors.sales.reserved,
			salesReservedLabel: selMapColors.sales.reservedLabel,
			salesSelectedArea: selMapColors.sales.selectedArea,
			stateOutline: selMapColors.state.outline,
			neighbouringSuburbFill: selMapColors.neighbouringSuburb.fill,
			neighbouringSuburbSelected: selMapColors.neighbouringSuburb.selected,
			neighbouringSuburbOutline: selMapColors.neighbouringSuburb.outline
		};
	};
	const getFormValues = () => {
		return !props.mapColors ? initialValues : getColorObjForForm(props.mapColors);
	};
	const styles = reactCSS({
		default: {
			color: {
				width: '36px',
				height: '14px',
				borderRadius: '2px'
			},
			swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
				marginTop: 18,
				marginLeft: 5
			},
			popover: {
				position: 'absolute',
				zIndex: '2'
			},
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px'
			}
		}
	});
	const { errors, control, formState, getValues, register, handleSubmit, setValue } = useForm({
		defaultValues: { ...getFormValues() },
		resolver: yupResolver(schema)
	});
	const classes = useStyles();
	const [isLoading, setIsLoading] = React.useState(false);
	const [errorsObj, setErrors] = React.useState(errors);
	const [activeModal, setActiveColorModal] = React.useState(EColorTypeModals.None);
	const [selColor, setSelColor] = React.useState<string | undefined>();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const getColorObjForSave = (data: typeof initialValues): IMapColor => {
		return {
			cda: {
				fill: data.cdaFill,
				outline: data.cdaOutline,
				selected: data.cdaSelected
			},
			cdar: {
				newlyAddedToCdar: data.cdarNewlyAddedToCdar,
				tmpFill: data.cdarTmpFill,
				outline: data.cdarOutline,
				selected: data.cdarSelected,
				masterFill: data.cdarMasterFill
			},
			neighbouringSuburb: {
				fill: data.neighbouringSuburbFill,
				selected: data.neighbouringSuburbSelected,
				outline: data.neighbouringSuburbOutline
			},
			suburb: {
				newlyAddedToCda: data.suburbNewlyAddedToCda,
				outline: data.suburbOutline,
				outlineAvailable: data.suburbOutlineAvailable,
				selected: data.suburbSelected
			},
			sa1: {
				outlineAvailable: data.sa1OutlineAvailable,
				fill: data.sa1Fill,
				outline: data.sa1Outline,
				selected: data.sa1Selected
			},
			sales: {
				cdarOutline: data.salesCdarOutline,
				sa1Outline: data.salesSa1Outline,
				sold: data.salesSold,
				soldLabel: data.salesSoldLabel,
				reserved: data.salesReserved,
				reservedLabel: data.salesReservedLabel,
				selectedArea: data.salesSelectedArea
			},
			state: {
				outline: data.stateOutline
			}
		};
	};

	const onClickSubmit = (data: typeof initialValues) => {
		setIsLoading(true);
		const mapColor = getColorObjForSave(data);
		props.onClickSubmitMapColor(mapColor);
	};

	if (JSON.stringify(errors) !== JSON.stringify(errorsObj)) {
		setErrors(errors);
	}

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>, type: string) => {
		setSelColor(getValues()[type as keyof typeof initialValues]);
		setActiveColorModal(EColorTypeModals[`${type}Modal` as keyof typeof EColorTypeModals]);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setActiveColorModal(EColorTypeModals.None);
		setAnchorEl(null);
	};

	const handleChange = (color: ColorResult, field: string) => {
		setValue(field as keyof typeof initialValues, color.hex);
		setSelColor(color.hex);
	};

	const getColor = (field: string) => {
		const selColors = props.mapColors ? getColorObjForForm(props.mapColors) : initialValues;
		return activeModal === EColorTypeModals[`${field}Modal` as keyof typeof EColorTypeModals]
			? selColor
			: getValues()[field as keyof typeof initialValues] || selColors[field as keyof typeof initialValues];
	};

	const renderColorPicker = (field: string) => {
		const color = getColor(field);
		const colorStyleObj = {
			...styles.color,
			...{ background: color as string }
		};
		return (
			<Grid item>
				<div
					style={styles.swatch}
					onClick={(e: any) => {
						handleClick(e, field);
					}}
				>
					<div style={colorStyleObj} />
				</div>
				{activeModal === EColorTypeModals[`${field}Modal` as keyof typeof EColorTypeModals] && (
					<div style={styles.popover as React.CSSProperties}>
						<Popover open anchorEl={anchorEl}>
							<div style={styles.cover as React.CSSProperties} onClick={handleClose} />
							<SketchPicker
								onChange={(color) => {
									handleChange(color, field);
								}}
								color={color as string}
							/>
						</Popover>
					</div>
				)}
			</Grid>
		);
	};

	return (
		<Dialog
			aria-labelledby="customized-dialog-title"
			open={true}
			onClose={props.onClickClose}
			maxWidth="lg"
			className="map-color-setting"
		>
			<DialogTitle>Configure Map Color</DialogTitle>
			<DialogContent dividers>
				<Grid className="stretch">
					<Typography variant="body1" color="secondary">
						CDA Map Colors
					</Typography>
					<Grid container>
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145 }}
										ref={register}
										error={errorsObj.cdaFill ? true : false}
										label="Fill Map color"
										helperText={errorsObj.cdaFill && errorsObj.cdaFill.message}
									/>
								}
								name="cdaFill"
								control={control}
							/>
						</Grid>
						{renderColorPicker('cdaFill')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.cdaOutline ? true : false}
										label="Outline Map color"
										helperText={errorsObj.cdaOutline && errorsObj.cdaOutline.message}
									/>
								}
								name="cdaOutline"
								control={control}
							/>
						</Grid>
						{renderColorPicker('cdaOutline')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.cdaSelected ? true : false}
										label="Selected Map color"
										helperText={errorsObj.cdaSelected && errorsObj.cdaSelected.message}
									/>
								}
								name="cdaSelected"
								control={control}
							/>
						</Grid>
						{renderColorPicker('cdaSelected')}
					</Grid>

					<Divider style={{ marginTop: 15 }} />
					<Typography style={{ marginTop: 20 }} variant="body1" color="secondary">
						CDAR Map Colors
					</Typography>
					<Grid container>
						<Grid item>
							<Controller
								as={
									<TextField
										/* style={{ minWidth: 195 }} */
										ref={register}
										error={errorsObj.cdarNewlyAddedToCdar ? true : false}
										label="Newly added To CDAR"
										helperText={errorsObj.cdarNewlyAddedToCdar && errorsObj.cdarNewlyAddedToCdar.message}
									/>
								}
								name="cdarNewlyAddedToCdar"
								control={control}
							/>
						</Grid>
						{renderColorPicker('cdarNewlyAddedToCdar')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.cdarTmpFill ? true : false}
										label="Temp fill Map color"
										helperText={errorsObj.cdarTmpFill && errorsObj.cdarTmpFill.message}
									/>
								}
								name="cdarTmpFill"
								control={control}
							/>
						</Grid>
						{renderColorPicker('cdarTmpFill')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.cdarOutline ? true : false}
										label="Outline Map color"
										helperText={errorsObj.cdarOutline && errorsObj.cdarOutline.message}
									/>
								}
								name="cdarOutline"
								control={control}
							/>
						</Grid>
						{renderColorPicker('cdarOutline')}

						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.cdarSelected ? true : false}
										label="Selected Map color"
										helperText={errorsObj.cdarSelected && errorsObj.cdarSelected.message}
									/>
								}
								name="cdarSelected"
								control={control}
							/>
						</Grid>
						{renderColorPicker('cdarSelected')}
					</Grid>
					<Grid container>
						<Grid item>
							<Controller
								as={
									<TextField
										ref={register}
										error={errorsObj.cdarMasterFill ? true : false}
										label="Master Fill Map color"
										helperText={errorsObj.cdarMasterFill && errorsObj.cdarMasterFill.message}
									/>
								}
								name="cdarMasterFill"
								control={control}
							/>
						</Grid>
						{renderColorPicker('cdarMasterFill')}
					</Grid>

					<Divider style={{ marginTop: 15 }} />
					<Typography style={{ marginTop: 20 }} variant="body1" color="secondary">
						Suburb Map Colors
					</Typography>
					<Grid container>
						<Grid item>
							<Controller
								as={
									<TextField
										ref={register}
										error={errorsObj.suburbNewlyAddedToCda ? true : false}
										label="Newly added To CDA"
										helperText={errorsObj.suburbNewlyAddedToCda && errorsObj.suburbNewlyAddedToCda.message}
									/>
								}
								name="suburbNewlyAddedToCda"
								control={control}
							/>
						</Grid>
						{renderColorPicker('suburbNewlyAddedToCda')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.suburbOutline ? true : false}
										label="Outline Map color"
										helperText={errorsObj.suburbOutline && errorsObj.suburbOutline.message}
									/>
								}
								name="suburbOutline"
								control={control}
							/>
						</Grid>
						{renderColorPicker('suburbOutline')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.suburbOutlineAvailable ? true : false}
										label="Outline Available"
										helperText={errorsObj.suburbOutlineAvailable && errorsObj.suburbOutlineAvailable.message}
									/>
								}
								name="suburbOutlineAvailable"
								control={control}
							/>
						</Grid>
						{renderColorPicker('suburbOutlineAvailable')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.suburbSelected ? true : false}
										placeholder="Selected Map color"
										label="Selected Map color"
										helperText={errorsObj.suburbSelected && errorsObj.suburbSelected.message}
									/>
								}
								name="suburbSelected"
								control={control}
							/>
						</Grid>
						{renderColorPicker('suburbSelected')}
					</Grid>

					<Divider style={{ marginTop: 15 }} />
					<Typography style={{ marginTop: 20 }} variant="body1" color="secondary">
						SA1 Map Colors
					</Typography>
					<Grid container>
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145 }}
										ref={register}
										error={errorsObj.sa1OutlineAvailable ? true : false}
										label="Outline Available"
										helperText={errorsObj.sa1OutlineAvailable && errorsObj.sa1OutlineAvailable.message}
									/>
								}
								name="sa1OutlineAvailable"
								control={control}
							/>
						</Grid>
						{renderColorPicker('sa1OutlineAvailable')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.sa1Fill ? true : false}
										label="Fill Map color"
										helperText={errorsObj.sa1Fill && errorsObj.sa1Fill.message}
									/>
								}
								name="sa1Fill"
								control={control}
							/>
						</Grid>
						{renderColorPicker('sa1Fill')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.sa1Outline ? true : false}
										label="Outline Map color"
										helperText={errorsObj.sa1Outline && errorsObj.sa1Outline.message}
									/>
								}
								name="sa1Outline"
								control={control}
							/>
						</Grid>
						{renderColorPicker('sa1Outline')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.sa1Selected ? true : false}
										label="Selected Map color"
										helperText={errorsObj.sa1Selected && errorsObj.sa1Selected.message}
									/>
								}
								name="sa1Selected"
								control={control}
							/>
						</Grid>
						{renderColorPicker('sa1Selected')}
					</Grid>

					<Divider style={{ marginTop: 15 }} />
					<Typography style={{ marginTop: 20 }} variant="body1" color="secondary">
						Sales Map Colors
					</Typography>
					<Grid container>
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145 }}
										ref={register}
										error={errorsObj.salesCdarOutline ? true : false}
										label="CDAR Map color"
										helperText={errorsObj.salesCdarOutline && errorsObj.salesCdarOutline.message}
									/>
								}
								name="salesCdarOutline"
								control={control}
							/>
						</Grid>
						{renderColorPicker('salesCdarOutline')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.salesSa1Outline ? true : false}
										label="SA1 Map color"
										helperText={errorsObj.salesSa1Outline && errorsObj.salesSa1Outline.message}
									/>
								}
								name="salesSa1Outline"
								control={control}
							/>
						</Grid>
						{renderColorPicker('salesSa1Outline')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.salesSold ? true : false}
										label="Sold Map color"
										helperText={errorsObj.salesSold && errorsObj.salesSold.message}
									/>
								}
								name="salesSold"
								control={control}
							/>
						</Grid>
						{renderColorPicker('salesSold')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ marginLeft: 30 }}
										ref={register}
										error={errorsObj.salesSoldLabel ? true : false}
										label="Sold Label Map color"
										helperText={errorsObj.salesSoldLabel && errorsObj.salesSoldLabel.message}
									/>
								}
								name="salesSoldLabel"
								control={control}
							/>
						</Grid>
						{renderColorPicker('salesSoldLabel')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.salesReserved ? true : false}
										label="Reserved Map color"
										helperText={errorsObj.salesReserved && errorsObj.salesReserved.message}
									/>
								}
								name="salesReserved"
								control={control}
							/>
						</Grid>
						{renderColorPicker('salesReserved')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145 }}
										ref={register}
										error={errorsObj.salesReservedLabel ? true : false}
										label="Reserved Label"
										helperText={errorsObj.salesReservedLabel && errorsObj.salesReservedLabel.message}
									/>
								}
								name="salesReservedLabel"
								control={control}
							/>
						</Grid>
						{renderColorPicker('salesReservedLabel')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145, marginLeft: 30 }}
										ref={register}
										error={errorsObj.salesSelectedArea ? true : false}
										label="Selected Area"
										helperText={errorsObj.salesSelectedArea && errorsObj.salesSelectedArea.message}
									/>
								}
								name="salesSelectedArea"
								control={control}
							/>
						</Grid>
						{renderColorPicker('salesSelectedArea')}
					</Grid>

					<Divider style={{ marginTop: 15 }} />
					<Typography style={{ marginTop: 20 }} variant="body1" color="secondary">
						State Map Colors
					</Typography>
					<Grid container>
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145 }}
										ref={register}
										error={errorsObj.stateOutline ? true : false}
										label="Outline Map color"
										helperText={errorsObj.stateOutline && errorsObj.stateOutline.message}
									/>
								}
								name="stateOutline"
								control={control}
							/>
						</Grid>
						{renderColorPicker('stateOutline')}
					</Grid>
					<Divider style={{ marginTop: 15 }} />
					<Typography style={{ marginTop: 20 }} variant="body1" color="secondary">
						Neighbouring Suburbs Map Colors
					</Typography>
					<Grid container>
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145 }}
										ref={register}
										error={errorsObj.neighbouringSuburbFill ? true : false}
										label="Suburb Map color"
										helperText={errorsObj.neighbouringSuburbFill && errorsObj.neighbouringSuburbFill.message}
									/>
								}
								name="neighbouringSuburbFill"
								control={control}
							/>
						</Grid>
						{renderColorPicker('neighbouringSuburbFill')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145 }}
										ref={register}
										error={errorsObj.neighbouringSuburbSelected ? true : false}
										label="Selected Suburb Map color"
										helperText={errorsObj.neighbouringSuburbSelected && errorsObj.neighbouringSuburbSelected.message}
									/>
								}
								name="neighbouringSuburbSelected"
								control={control}
							/>
						</Grid>
						{renderColorPicker('neighbouringSuburbSelected')}
						<Grid item>
							<Controller
								as={
									<TextField
										style={{ maxWidth: 145 }}
										ref={register}
										error={errorsObj.neighbouringSuburbOutline ? true : false}
										label="Outline Map color"
										helperText={errorsObj.neighbouringSuburbOutline && errorsObj.neighbouringSuburbOutline.message}
									/>
								}
								name="neighbouringSuburbOutline"
								control={control}
							/>
						</Grid>
						{renderColorPicker('neighbouringSuburbOutline')}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClickClose}>CLOSE</Button>
				<div className={classes.wrapper}>
					<Button
						disabled={isLoading}
						color="primary"
						onClick={handleSubmit((formData) => {
							onClickSubmit(formData);
						})}
					>
						SUBMIT
					</Button>
					{isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default ConfigureColorModal;
