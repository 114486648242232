import React, { useEffect } from 'react';
import 'date-fns';
import {
	Paper,
	Grid,
	TextField,
	CircularProgress,
	FormControl,
	Button,
	MenuItem,
	Select,
	InputLabel,
	Typography
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab';
import CommonApiService from 'shared/services/CommonApiService';
import {
	IAgency,
	IAustraliaState,
	ISalesAreaSearch,
	ISalesPerson,
	IProspectiveCustomerOption
} from 'shared/types/Types';
import {
	ISa1AutoComplete,
	ISa1PerformanceReportApiParams,
	IAgencyAutoComplete,
	ISuburbAutoComplete,
	ISalesPersonFilter
} from './Report';
import UtilityService from 'shared/services/UtilityService';
import { StatusOptions } from 'shared/constants/AppData';
import axios, { CancelTokenSource } from 'axios';

let axiosRequestCancelToken: CancelTokenSource | null = null;

interface IProps {
	error: string | null;
	filterUrlParams: any | null;
	filters: string[];
	reportType: string;
	onClickApplyFilter: (filterParams: ISa1PerformanceReportApiParams, type: string) => void;
	onReset: () => void;
}
enum EAutoCompleteLoader {
	AgencyLoader = 'agency-loader',
	SuburbLoader = 'suburb-loader',
	SA1Loader = 'sa1-loader',
	SalesPersonLoader = 'sales-person',
	ProspectiveCustomerLoader = 'prospective-customer-loader',
	None = 'none'
}

const Sa1ReportFilter: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const [agencyOptions, setAgencyOptions] = React.useState<IAgencyAutoComplete[]>([]);
	const [suburbOptions, setSuburbOptions] = React.useState<ISuburbAutoComplete[]>([]);
	const [agencyId, setAgencyId] = React.useState<IAgencyAutoComplete | null>(null);
	const [suburbId, setSuburbId] = React.useState<ISuburbAutoComplete | null>(null);
	const [sa1Options, setSa1Options] = React.useState<ISa1AutoComplete[]>([]);
	const [sa1s, setSa1s] = React.useState<ISa1AutoComplete[]>([]);
	const [fromDate, setFromDate] = React.useState<Date | null>(null);
	const [ausStates, setAusStates] = React.useState<IAustraliaState[]>([]);
	const [stateId, setStateId] = React.useState<number | null>();
	const [statusType, setStatusType] = React.useState<string | null>();
	const [toDate, setToDate] = React.useState<Date | null>(null);
	const [prospectAgencyName, setProspectAgencyName] = React.useState<string | null>(null);
	const [prospectAgencyOptions, setProspectAgencyOptions] = React.useState<string[]>([]);
	const [salesPersonOptions, setSalesPersonOptions] = React.useState<ISalesPersonFilter[]>([]);
	const [salesPersonId, setSalesPersonId] = React.useState<ISalesPersonFilter | null>(null);
	const [loading, setLoading] = React.useState<EAutoCompleteLoader>(EAutoCompleteLoader.None);

	useEffect(() => {
		const getAustralianStates = async () => {
			try {
				const defaultStateOptions = {
					id: 9999,
					name: 'None',
					code: 'all',
					bounds: {
						east: 9999,
						north: 9999,
						west: 9999,
						south: 9999
					},
					latitude: 9999,
					longitude: 9999
				};
				const states = await CommonApiService.getAustraliaStates();
				states.splice(0, 0, defaultStateOptions);
				setAusStates(states);
			} catch (error) {
				throw new Error(error);
			}
		};

		const setInitFromToDate = () => {
			const todayDate = new Date();
			const fromDate = todayDate.getDate() - 7;
			setFromDate(new Date(todayDate.setDate(fromDate)));
			setToDate(new Date());
		};

		if (props.filters.includes('state')) {
			getAustralianStates();
		}
		setInitFromToDate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		setFilteredParams();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.filterUrlParams]);

	const setFilteredParams = () => {
		const filterUrlParams = props.filterUrlParams;
		if (!filterUrlParams || (Object.keys(filterUrlParams).length === 0 && filterUrlParams.constructor !== Object)) {
			return;
		}
		if (filterUrlParams['fromDate']) {
			setFromDate(new Date(filterUrlParams['fromDate']));
		}
		if (filterUrlParams['toDate']) {
			setToDate(new Date(filterUrlParams['toDate']));
		}
		if (filterUrlParams['sa1s']) {
			setSa1s(getSA1ForAutoComplete(filterUrlParams['sa1s']));
			setSa1Options(getSA1ForAutoComplete(filterUrlParams['sa1s']));
		}
		if (filterUrlParams['stateId']) {
			setStateId(filterUrlParams['stateId']);
		}
		if (filterUrlParams['statusType']) {
			setStatusType(filterUrlParams['statusType']);
		}
		if (filterUrlParams['agencyId'] && filterUrlParams['agencyName']) {
			const agency = getAgencyForAutoComplete(filterUrlParams['agencyId'], decodeURI(filterUrlParams['agencyName']));
			setAgencyId(agency);
			setAgencyOptions([agency]);
		}
		if (filterUrlParams['suburbId'] && filterUrlParams['suburbName']) {
			const suburb = getSuburbForAutoComplete(filterUrlParams['suburbId'], decodeURI(filterUrlParams['suburbName']));
			setSuburbId(suburb);
			setSuburbOptions([suburb]);
		}
		if (filterUrlParams['prospectAgencyName']) {
			const customer = decodeURI(filterUrlParams['prospectAgencyName']);
			setProspectAgencyName(customer);
			setProspectAgencyOptions([customer]);
		}
		if (filterUrlParams['salesPersonId'] && filterUrlParams['salesPersonName']) {
			const salesPerson = getSalesPersonForAutoComplete(
				filterUrlParams['salesPersonId'],
				decodeURI(filterUrlParams['salesPersonName'])
			);
			setSalesPersonId(salesPerson);
			setSalesPersonOptions([salesPerson]);
		}
	};
	const getSalesPersonForAutoComplete = (salesPersonId: number, salesPersonName: string) => {
		return {
			id: salesPersonId,
			name: salesPersonName
		};
	};

	const getSA1ForAutoComplete = (urlSa1s: number[]) => {
		return urlSa1s.map((item: number) => {
			return {
				sa1_code: item
			};
		});
	};

	const getAgencyForAutoComplete = (agencyId: number, agencyName: string) => {
		return {
			agency_id: agencyId,
			agency_name: agencyName
		};
	};
	const getSuburbForAutoComplete = (suburbId: number, suburbName: string) => {
		return {
			suburb_id: suburbId,
			suburb_name: suburbName
		};
	};
	const onChangeProspectAgencyInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		if (reason === 'clear') {
			setProspectAgencyName(null);
			return;
		}
		if (reason !== 'input' || value.trim().length < 3) {
			return;
		}
		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (value.trim().length < 3) {
			return;
		}
		getProspectAgencyBySearchText(value);
	};
	const onChangeSalesPersonAutocompleteInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		if (reason === 'clear') {
			setSalesPersonId(null);
			return;
		}
		if (reason !== 'input' || value.trim().length < 3) {
			return;
		}
		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (value.trim().length < 3) {
			return;
		}
		getSalesPersonBySearchText(value);
	};
	let getProspectAgencyBySearchText = async (searchText: string) => {
		try {
			setLoading(EAutoCompleteLoader.ProspectiveCustomerLoader);
			const res = await CommonApiService.getProspectiveCustomerBySearchText({ searchText });
			const customers = getFormattedProspectiveCustomerRecord(res);
			setProspectAgencyOptions(customers);
		} catch (err) {
			console.log('🚀 ~ file: ReportFilter.tsx ~ line 218 ~ ReportFilter ~ getProspectAgencyBySearchText= ~ err', err);
		} finally {
			setLoading(EAutoCompleteLoader.None);
		}
		return;
	};
	getProspectAgencyBySearchText = UtilityService.debounce(getProspectAgencyBySearchText);
	let getSalesPersonBySearchText = async (searchText: string) => {
		try {
			setLoading(EAutoCompleteLoader.SalesPersonLoader);
			const res = await CommonApiService.getSalesPersonBySearchText({ searchText });
			const salesPersons = getFormattedSalesPersonRecord(res);
			setSalesPersonOptions(salesPersons);
		} catch (err) {
			console.log(
				'🚀 ~ file: Sa1HistoricalReportFilter.tsx ~ line 212 ~ Sa1HistoricalReportFilter ~ getSalesPersonBySearchText= ~ err',
				err
			);
		} finally {
			setLoading(EAutoCompleteLoader.None);
		}
		return;
	};
	getSalesPersonBySearchText = UtilityService.debounce(getSalesPersonBySearchText);

	const onChangeAgencyAutocompleteInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		if (reason === 'clear') {
			setAgencyId(null);
			return;
		}
		if (reason !== 'input' || value.trim().length < 3) {
			return;
		}

		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (value.trim().length < 3) {
			return;
		}
		getAgencyBySearchText(value);
	};
	const onChangeSuburbAutocompleteInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		if (reason === 'clear') {
			setSuburbId(null);
			return;
		}
		if (reason !== 'input' || value.trim().length < 3) {
			return;
		}

		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (value.trim().length < 3) {
			return;
		}
		getSuburbBySearchText(value);
	};
	const onChangeSa1AutocompleteInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		if (reason === 'clear') {
			setSa1s([]);
			return;
		}
		if (reason !== 'input' || value.trim().length < 3) {
			return;
		}
		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (value.trim().length < 3) {
			return;
		}
		getSa1BySearchText(value);
	};
	let getAgencyBySearchText = async (searchText: string) => {
		try {
			setLoading(EAutoCompleteLoader.AgencyLoader);
			const res = await CommonApiService.getAgencyBySearchText({ searchText });
			const agencies = getFormattedAgencyRecord(res);
			setAgencyOptions(agencies);
		} catch (err) {
			console.log('🚀 ~ file: ReportFilter.tsx ~ line 188 ~ ReportFilter ~ getAgencyBySearchText= ~ err', err);
		} finally {
			setLoading(EAutoCompleteLoader.None);
		}
		return;
	};
	getAgencyBySearchText = UtilityService.debounce(getAgencyBySearchText);

	let getSuburbBySearchText = async (searchText: string) => {
		try {
			if (axiosRequestCancelToken) {
				axiosRequestCancelToken.cancel();
			}
			axiosRequestCancelToken = axios.CancelToken.source();
			setLoading(EAutoCompleteLoader.SuburbLoader);
			const res = await CommonApiService.searchSa1AndSuburbByText(searchText, axiosRequestCancelToken.token);
			const suburbs = getFormattedSuburbRecord(res);
			setSuburbOptions(suburbs);
		} catch (err) {
			console.log('🚀 ~ file: ReportFilter.tsx ~ line 207 ~ ReportFilter ~ getSuburbBySearchText= ~ err', err);
		} finally {
			setLoading(EAutoCompleteLoader.None);
		}
		return;
	};
	getSuburbBySearchText = UtilityService.debounce(getSuburbBySearchText);

	const getFormattedProspectiveCustomerRecord = (res: IProspectiveCustomerOption[]) => {
		return res.map((prospectiveCustomer: IProspectiveCustomerOption) => {
			return prospectiveCustomer.prospect_agency;
		});
	};

	const getFormattedSalesPersonRecord = (res: ISalesPerson[]) => {
		return res.map((person: ISalesPerson) => {
			return {
				id: person.id,
				name: `${person.firstname} ${person.lastname}`
			};
		});
	};

	const getFormattedAgencyRecord = (res: IAgency[]) => {
		return res.map((agency: IAgency) => {
			return {
				agency_id: agency.agency_id,
				agency_name: agency.name
			};
		});
	};
	const getFormattedSuburbRecord = (res: ISalesAreaSearch[]) => {
		return res.map((suburb: ISalesAreaSearch) => {
			return {
				suburb_id: suburb.id,
				suburb_name: suburb.text
			};
		});
	};
	let getSa1BySearchText = async (searchText: string) => {
		try {
			setLoading(EAutoCompleteLoader.SA1Loader);
			const res = await CommonApiService.getSa1BySearchText({ searchText });
			const sa1s = res;
			setSa1Options(sa1s);
		} catch (err) {
			console.log('🚀 ~ file: Sa1ReportFilter.tsx ~ line 95 ~ Sa1ReportFilter ~ getSa1BySearchText= ~ err', err);
		} finally {
			setLoading(EAutoCompleteLoader.None);
		}
		return;
	};
	getSa1BySearchText = UtilityService.debounce(getSa1BySearchText);
	const onChangeAgencyAutocomplete = async (
		ev: unknown,
		value: IAgencyAutoComplete | null,
		reason: AutocompleteChangeReason
	) => {
		if (!value) {
			return;
		}
		setAgencyId(value);
	};
	const onChangeSuburbAutocomplete = async (
		ev: unknown,
		value: ISuburbAutoComplete | null,
		reason: AutocompleteChangeReason
	) => {
		if (!value) {
			return;
		}
		setSuburbId(value);
	};

	const onChangeSalesPersonAutoComplete = async (
		ev: unknown,
		value: ISalesPersonFilter | null,
		reason: AutocompleteChangeReason
	) => {
		if (!value) {
			return;
		}
		setSalesPersonId(value);
	};

	const onChangeSa1Autocomplete = async (
		ev: unknown,
		value: ISa1AutoComplete[] | null,
		reason: AutocompleteChangeReason
	) => {
		if (!value) {
			return;
		}
		setSa1s(value);
	};
	const handleDateChange = (date: Date | null, type: string) => {
		if (!date) {
			return;
		}

		if (type === 'fromDate') {
			setFromDate(date);
			return;
		}
		setToDate(date);
	};

	const getSalesPersonNameById = (salesPersonId: number) => {
		if (!salesPersonId || salesPersonOptions.length === 0) {
			return;
		}
		const record = salesPersonOptions.find((salesPerson) => salesPerson.id === salesPersonId);
		if (!record) {
			return;
		}
		return encodeURI(record.name);
	};

	const getAgencyNameById = (agencyId: number) => {
		if (!agencyId || agencyOptions.length === 0) {
			return;
		}
		const record = agencyOptions.find((agency) => agency.agency_id === agencyId);
		if (!record) {
			return;
		}
		return encodeURI(record.agency_name);
	};
	const getSuburbNameById = (suburbId: number) => {
		if (!suburbId || suburbOptions.length === 0) {
			return;
		}
		const record = suburbOptions.find((suburb) => suburb.suburb_id === suburbId);
		if (!record) {
			return;
		}
		return encodeURI(record.suburb_name);
	};

	/* const getApiParamsTypeByReportType = () => {
		return props.reportType === 'performance' ? 'ISa1PerformanceReportApiParams' : 'ISa1StatusReportApiParams';
	} */
	const onClickApplyFilter = () => {
		const filterParams = {
			fromDate: fromDate && new Date(fromDate).getTime(),
			toDate: toDate && new Date(toDate).getTime(),
			sa1s,
			agencyId: agencyId?.agency_id,
			suburbId: suburbId?.suburb_id,
			stateId,
			salesPersonId: salesPersonId?.id,
			salesPersonName: getSalesPersonNameById(salesPersonId?.id as number),
			agencyName: getAgencyNameById(agencyId?.agency_id as number),
			suburbName: getSuburbNameById(suburbId?.suburb_id as number),
			prospectAgencyName,
			statusType,
			limit: 10,
			offset: 0
		};
		props.onClickApplyFilter(filterParams as any, 'search');
	};

	const onClickReset = () => {
		/* setFromDate(null);
		setToDate(null); */
		setSa1s([]);
		setAgencyId(null);
		setSuburbId(null);
		setStateId(null);
		setSalesPersonOptions([]);
		setSuburbOptions([]);
		setStatusType(null);
		setAgencyOptions([]);
		setSa1Options([]);
		setProspectAgencyName(null);
		setProspectAgencyOptions([]);
		setSalesPersonId(null);
		props.onReset();
	};

	const onChangeAusStateDropdown = (ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
		const stateId = ev.target.value as number;
		setStateId(stateId);
	};
	const onChangeStatusType = (ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
		const status = ev.target.value as string;
		setStatusType(status);
	};

	const onChangeProspectiveAgencyComplete = (ev: unknown, value: string | null, reason: AutocompleteChangeReason) => {
		if (!value) {
			setProspectAgencyName(null);
		}
		setProspectAgencyName(value);
	};

	const renderFilters = () => {
		return (
			<>
				<Grid item container alignItems="center" style={{ paddingLeft: 10 }} className="sa1-report admin-sales-sidebar">
					{props.filters.includes('from_date') && (
						<Grid item style={{ width: 115 }} className="mr20 date-filter">
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									variant="inline"
									autoOk
									format="dd/MM/yyyy"
									id="from-date-picker-inline"
									label="From Date"
									value={fromDate}
									onChange={(date: Date | null) => {
										handleDateChange(date, 'fromDate');
									}}
									KeyboardButtonProps={{
										'aria-label': 'change date'
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
					)}
					{props.filters.includes('to_date') && (
						<Grid item style={{ width: 115 }} className="mr20 date-filter">
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<Grid container justify="space-around">
									<KeyboardDatePicker
										variant="inline"
										autoOk
										format="dd/MM/yyyy"
										id="to-date-picker-inline"
										label="To Date"
										value={toDate}
										minDate={fromDate}
										maxDate={new Date()}
										onChange={(date: Date | null) => {
											handleDateChange(date, 'toDate');
										}}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
									/>
								</Grid>
							</MuiPickersUtilsProvider>
						</Grid>
					)}
					{props.filters.includes('sa1') && (
						<Grid item style={{ width: 310 }} className="mr20">
							<FormControl style={{ width: '100%' }}>
								<Autocomplete
									multiple
									value={sa1s}
									onInputChange={onChangeSa1AutocompleteInput}
									size="small"
									onChange={onChangeSa1Autocomplete}
									options={sa1Options}
									getOptionLabel={(option) => `${option.sa1_code}`}
									renderOption={(option) => <Typography style={{ fontSize: '0.80rem' }}>{option.sa1_code}</Typography>}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Search by SA1"
											variant="standard"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{loading === EAutoCompleteLoader.SA1Loader && (
															<CircularProgress color="secondary" size={20} />
														)}
														{params.InputProps.endAdornment}
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
							</FormControl>
						</Grid>
					)}
					{props.filters.includes('state') && (
						<Grid item style={{ width: 170 }} className="mr20">
							<FormControl style={{ width: '100%' }}>
								<InputLabel htmlFor="manage-cda-state-filter">Select a State</InputLabel>
								<Select
									value={stateId || ''}
									labelId="manage-cda-state-filter"
									onChange={onChangeAusStateDropdown}
									className="stateFilter"
								>
									{ausStates.map((ausState) => {
										return (
											<MenuItem key={ausState.name} value={ausState.id} className="stateFilterOptions">
												{ausState.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
					)}
					{props.filters.includes('status') && (
						<Grid item style={{ width: 140 }} className="mr20">
							<FormControl style={{ width: '100%' }}>
								<InputLabel htmlFor="manage-cda-state-filter">Select a Status</InputLabel>
								<Select
									value={statusType || ''}
									labelId="manage-cda-status-filter"
									onChange={onChangeStatusType}
									className="stateFilter"
								>
									{StatusOptions.map((status) => {
										return (
											<MenuItem key={status.name} value={status.value} className="stateFilterOptions">
												{status.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
					)}
					{props.filters.includes('agency') && (
						<Grid item style={{ width: 190 }} className="mr20">
							<FormControl style={{ width: '100%' }}>
								<Autocomplete
									value={agencyId as IAgencyAutoComplete}
									onInputChange={onChangeAgencyAutocompleteInput}
									size="small"
									onChange={onChangeAgencyAutocomplete}
									options={agencyOptions}
									getOptionLabel={(option) => `(${option.agency_id}) ${option.agency_name}`}
									renderOption={(option) => (
										<Typography style={{ fontSize: '0.80rem' }}>
											({option.agency_id}) {option.agency_name}
										</Typography>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Search by Agency"
											variant="standard"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{loading === EAutoCompleteLoader.AgencyLoader && (
															<CircularProgress color="secondary" size={20} />
														)}
														{params.InputProps.endAdornment}
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
							</FormControl>
						</Grid>
					)}
					{props.filters.includes('suburb') && (
						<Grid item style={{ width: 180 }} className="mr20">
							<FormControl style={{ width: '100%' }}>
								<Autocomplete
									value={suburbId as ISuburbAutoComplete}
									onInputChange={onChangeSuburbAutocompleteInput}
									size="small"
									onChange={onChangeSuburbAutocomplete}
									options={suburbOptions}
									getOptionLabel={(option) => `${option.suburb_name}`}
									renderOption={(option) => (
										<Typography style={{ fontSize: '0.80rem' }}>
											({option.suburb_id}) {option.suburb_name}
										</Typography>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Search by Suburb"
											variant="standard"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{loading === EAutoCompleteLoader.SuburbLoader && (
															<CircularProgress color="secondary" size={20} />
														)}
														{params.InputProps.endAdornment}
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
							</FormControl>
						</Grid>
					)}
					{props.filters.includes('salesPerson') && (
						<Grid item style={{ width: 190 }} className="mr20">
							<FormControl style={{ width: '100%' }}>
								<Autocomplete
									value={salesPersonId as ISalesPersonFilter}
									onInputChange={onChangeSalesPersonAutocompleteInput}
									size="small"
									onChange={onChangeSalesPersonAutoComplete}
									options={salesPersonOptions}
									getOptionLabel={(option) => `${option.name}`}
									renderOption={(option) => <Typography style={{ fontSize: '0.80rem' }}>{option.name}</Typography>}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Search by Account Manager"
											variant="standard"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{loading === EAutoCompleteLoader.SalesPersonLoader && (
															<CircularProgress color="secondary" size={20} />
														)}
														{params.InputProps.endAdornment}
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
							</FormControl>
						</Grid>
					)}

					{props.filters.includes('prospect_agency') && (
						<Grid item style={{ width: 160 }} className="mr20">
							<FormControl style={{ width: '100%' }}>
								<Autocomplete
									value={prospectAgencyName as string}
									onInputChange={onChangeProspectAgencyInput}
									size="small"
									onChange={onChangeProspectiveAgencyComplete}
									options={prospectAgencyOptions}
									getOptionLabel={(option) => option}
									renderOption={(option) => <Typography style={{ fontSize: '0.80rem' }}>{option}</Typography>}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Prospective Customer"
											variant="standard"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{loading === EAutoCompleteLoader.ProspectiveCustomerLoader && (
															<CircularProgress color="secondary" size={20} />
														)}
														{params.InputProps.endAdornment}
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
							</FormControl>
						</Grid>
					)}

					<Grid item style={{ marginBottom: 0 }}>
						<Button color="secondary" variant="outlined" onClick={onClickReset}>
							Reset
						</Button>
						<Button
							color="primary"
							variant="outlined"
							onClick={onClickApplyFilter}
							style={{ marginLeft: 10, marginRight: 15 }}
						>
							Apply
						</Button>
					</Grid>
				</Grid>
				{props.error && (
					<Grid>
						<small
							style={{
								color: '#F44336',
								marginTop: 5,
								fontSize: '80%'
							}}
						>
							{`* ${props.error}`}
						</small>
					</Grid>
				)}
			</>
		);
	};

	return (
		<Paper className="paper-container" style={{ padding: 10 }}>
			{renderFilters()}
		</Paper>
	);
};

export default Sa1ReportFilter;
