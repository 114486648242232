import { EventData, MapboxGeoJSONFeature } from 'mapbox-gl';

export enum ELoadingIndicatorStatus {
	ShowNavBarLoader = 'show-nav-bar-loader', //this is for loading strip that appears below top nav bar
	ShowAreaLoader = 'show-area-loader', //this is for circular progress bar that covers a section of page
	ShowFetchingCdarLoader = 'show-fetching-cdar-loader',
	ShowMapLoader = 'show-map-loader',
	ShowFullAreaLoader = 'show-full-area-loader',
	None = 'none'
}
export enum EAusStateShortCode {
	Victoria = 'VIC',
	NewSouthWales = 'NSW',
	Tasmania = 'TAS',
	SouthAustralia = 'SA',
	NorthenTerritory = 'NT',
	Queensland = 'QLD',
	AustralianCapitalTerritory = 'ACT',
	WesternAustralia = 'WA'
}

export interface IGeoBound {
	north: number;
	east: number;
	south: number;
	west: number;
}

export interface ISuburbGeoJsonFeatureProperties {
	abs_id: string;
	postcode: string;
	state: string;
	name: string;
}

export interface ISuburbGeoJsonFeature extends MapboxGeoJSONFeature {
	properties: ISuburbGeoJsonFeatureProperties;
}

export interface ISa1GeoJsonFeatureProperties {
	sa1_code: number;
	sa1_pid: string;
	state_pid: number;
}

export interface ISa1GeoJsonFeature extends MapboxGeoJSONFeature {
	properties: ISa1GeoJsonFeatureProperties;
	id: number;
}

export interface ICdaGeoJsonFeatureProperties {
	cda_id: number;
	lat_lng: string;
	bounds?: string;
}

export interface ICdaGeoJsonFeature extends MapboxGeoJSONFeature {
	properties: ICdaGeoJsonFeatureProperties;
	id: number;
}

export interface ICdarGeoJsonFeatureProperties {
	cda_id: number;
	cdar_id: number;
}

export interface ICdarGeoJsonFeature extends MapboxGeoJSONFeature {
	properties: ICdarGeoJsonFeatureProperties;
	id: number;
}

export interface IStateGeoJsonFeatureProperties {
	name: string;
	state_id: number;
}

export interface IStateGeoJsonFeature extends MapboxGeoJSONFeature {
	properties: IStateGeoJsonFeatureProperties;
	id: number;
}

/**
 * (parameter) e: mapboxgl.MapMouseEvent & {
    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
} & mapboxgl.EventData
 */

export type IMapboxMouseMoveEvent = mapboxgl.MapMouseEvent & {
	features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
} & mapboxgl.EventData;

export interface IMapboxSuburbLayerClickEvent extends mapboxgl.MapMouseEvent, EventData {
	features?: ISuburbGeoJsonFeature[] | undefined;
}

export interface ISuburb {
	id: number;
	/* cda_suburb_id: number;
	cda_id: number; */
	name: string;
	abs_suburb_id: string;
	is_dirty?: boolean; //used for client side state tracking
	is_removable: boolean;
}

export interface ICda {
	cda_id: number;
	tmp_cda_id: number;
	state_id: number;
	name: string;
	description?: string;
	suburbs: ISuburb[];
	automate_provisional_assignment?: boolean;
	provisional_search_radius?: number;
	reservation_expiry_in_days?: number;
	is_dirty?: boolean;
	bounds?: string;
	is_deleted?: boolean;
}

export interface ICdaWithStateName extends ICda {
	state_name: string;
}

export interface ICdaWithStats extends ICda {
	sa1_stats: {
		sold: number;
		reserved: number;
		available: number;
	};
	cdar_stats?: {
		sold: number;
		reserved: number;
		available: number;
	};
}

export interface ICdarWithAgency extends ICdar {
	agency_id: null | number;
	agency_name: null | string;
}

export interface ISa1 {
	id: number;
	sa1_code: number;
	status: ERevAreaSaleStatus | null;
	agency_id: null | number;
	agency_name: null | string;
	t_reservation_expiry_at?: string;
	cdar_id?: number;
	transaction_id?: null | number;
}

export interface ISa1InfoView {
	id: number;
	sa1_code: number;
	status: ERevAreaSaleStatus | null;
	agency_id: null | number;
	agency_name: null | string;
	t_reservation_expiry_at?: string;
	cdar_id?: number;
	transaction_id?: null | number;
	name: string;
	key: string;
}
export interface ICdar {
	cdar_id: number;
	tmp_cdar_id: number;
	/* state_id: number; */
	name: string;
	description?: string;
	sa1s: ISa1[];
	cda_id: number;
	cda_name: string;
	is_provisional: boolean;
	is_royalty: boolean;
	is_dirty?: boolean;
	is_sold?: boolean;
	is_default?: boolean;
	status: ERevAreaSaleStatus | null;
	is_deleted?: boolean;
	t_reservation_expiry_at?: string;
	transaction_id?: number | null;
	sold_stats?: {
		available: number;
		reserved: number;
		sold: number;
		cdar_id: number;
	};
}

export interface IHttpServicePostResponse {
	id: number;
}

export interface IHttpServiceResponse<T> {
	data: T;
	message?: string;
	success: boolean;
}

export enum ENotificationTypes {
	Error = 'error',
	Success = 'success',
	Info = 'info',
	Warning = 'warning'
}

export interface INotificationContent {
	message: string;
	type: ENotificationTypes;
	id: number;
}

export interface IAustraliaState {
	id: number;
	name: string;
	code: string;
	bounds: {
		east: number;
		north: number;
		west: number;
		south: number;
	};
	latitude: number;
	longitude: number;
}

export interface IBoundsSuburb extends ISuburb {
	bounds: {
		east: number;
		north: number;
		west: number;
		south: number;
	};
	state_name: string;
	postcode: string;
	region_name: string;
}

export interface IZenuSuburb {
	id: number;
	abs_suburb_id: string;
	name: string;
	state: string;
	postcode: string;
	region_name: string;
	bounds: {
		east: number;
		north: number;
		west: number;
		south: number;
	};
}

export interface IZenuRegion {
	id: number;
	name: string;
	state: string;
}

export enum EMapServiceSyncStatus {
	InProgress = 'in_progress',
	Complete = 'complete'
}

export interface IMapServiceSyncStatus {
	status: EMapServiceSyncStatus;
	submitted_at: string;
	submitted_by: number;
	completed_at: string;
	tilset: string;
	tileset_type: string;
}

export interface INavMenu {
	id: number;
	name: string;
	icon: string;
	ui_state: string | null;
	sequence: number;
	is_accordion?: boolean;
	parent_id: number | null;
	sub_menu?: INavMenu[];
}

export interface INavSubMenu {
	label: string;
	route: string;
	parent_id: number;
}

export interface ICdaSearchResponse {
	name: string;
	center: {
		latitude: number;
		longitude: number;
	};
	id: number;
}

export interface IGetCdaChangelogHttpResponse {
	cda: ICda[];
	tmpCda: ICda[];
}

export interface IGetCdarChangelogHttpResponse {
	cdar: ICdar[];
	tmpCdar: ICdar[];
}

export type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];

export type TCdaMapFeatureState = { clicked: boolean } | { hover: boolean };

export type TSa1MapFeatureState = AtLeastOne<{
	newlyAddedToCdar: boolean;
	clicked: boolean;
	hover: boolean;
	isSold: boolean;
	isReserved: boolean;
	isSelected: boolean;
	status: boolean;
}>;

export type TCdarMapFeatureState = AtLeastOne<{
	clicked: boolean;
	hover: boolean;
	isSold: boolean;
	isReserved: boolean;
	isSelected: boolean;
}>;

export type TAusStateMapFeatureState = AtLeastOne<{
	hover: boolean;
}>;

export interface IDirtyCda {
	cda_id: number;
	state_id: number;
	name: string;
	description: string;
}

export enum EMapServiceJobStatus {
	Complete = 'complete',
	InProgress = 'in_process'
}

export interface IMapSyncJob {
	id: number;
	type: 'cda_submit' | 'cdar_submit';
	status: EMapServiceJobStatus;
	started_at: string;
	completed_at: string;
	job_logs: string;
	sync_upload_id: string;
	submitted_by?: string;
}

export interface IAgency {
	name: string;
	agency_id: number;
	email: string;
	logo: string;
	small_logo: string;
	font_color: string;
	bg_color: string;
	is_royalty: boolean;
	default_category: string;
}

export interface ISalesAreaListItem {
	id: number;
	name: string;
	status: ERevAreaSaleStatus | null;
	type: ESalesAreaListItemType;
	selected?: boolean;
	cdarId?: number; //helpful for reverse lookup for finding cdar when area is sa1
	reservationExpiry?: string;
	transaction_id: number | null;
	prospect_customer?: string | null;
}

export enum ERevAreaSaleStatus {
	Sold = 'sold',
	Reserved = 'reserved'
}

export enum ERevAreaSaleType {
	Sell = 'sell',
	Reserve = 'reserve',
	Release = 'release',
	ExtendReservation = 'reserve_extend'
}

export enum ESalesAreaListItemType {
	Cdar = 'cdar',
	Sa1 = 'sa1'
}

export interface IAreaSaleSubmissionError {
	message: string;
	invalidAreas?: ISalesAreaListItem[];
}

interface ISalesTransactionRequestBodyCommon {
	agency_id: number;
	status: ERevAreaSaleType;
}

interface ISalesTransactionRequestBodyWithCdar extends ISalesTransactionRequestBodyCommon {
	cdar_id: number;
	is_transaction?: boolean;
}

interface ISalesTransactionRequestBodyWithSa1 extends ISalesTransactionRequestBodyCommon {
	sa1s: number[];
	is_transaction?: boolean;
}

export type ISalesTransactionRequestBody = ISalesTransactionRequestBodyWithCdar | ISalesTransactionRequestBodyWithSa1;

export interface ISalesAreaSearch {
	id: number;
	text: string;
	suburb: string | null;
	sa1_code: number | null;
	state_id: number;
	cda_id: number;
	bounds: any;
}

//ISa1Detail is not a good name, todo - change to appropriate name
//difference between this interface and ISa1 is that the latter has some properties specific
//to manage-cda & manage-cdar CRUD
export interface ISa1Detail {
	id: number;
	sa1_code: number;
	state_id: number;
	cda_id: number;
	bounds: IGeoBound | null;
}

//ISuburbDetail is not a good name, todo - change to appropriate name
//difference between this interface and ISuburbDetail is that the latter has some properties specific
//to manage-cda & manage-cdar CRUD
export interface ISuburbDetail {
	state_id: number;
	cda_id: number;
	bounds: IGeoBound | null;
}

export interface ISalesMapSearchSelectedItem {
	cda_id: number;
	state_id: number;
	bounds: IGeoBound | null;
}

export enum ELocalStorageKeys {
	LastMapSyncJobId = 'last-map-sync-job-id',
	LastMapSyncJobDate = 'last-map-sync-job-date'
}

export interface ITooltipContent {
	label: string;
	value: string | number | undefined | null;
}

export interface ISaleVerificationModalSubmit {
	agencyId?: number;
	note?: string;
	pinCdar?: boolean;
	reserveExtendDays?: number;
	prospect_agency?: string;
}

export enum ELoggedInUserRole {
	Administrator = 'Administrator',
	Sales = 'Sales'
}
export interface ILoggedInUser {
	id: number;
	email: string;
	firstName: string;
	lastName: string;
	role: ELoggedInUserRole;
	state: string;
}
interface IMapCdaColor {
	fill: string;
	outline: string;
	selected: string;
}
interface IMapCdarColor {
	newlyAddedToCdar: string;
	tmpFill: string;
	outline: string;
	selected: string;
	masterFill: string;
}
interface IMapSuburbColor {
	newlyAddedToCda: string;
	outline: string;
	outlineAvailable: string;
	selected: string;
}
interface IMapSa1Color {
	outlineAvailable: string;
	fill: string;
	outline: string;
	selected: string;
}
interface IMapSalesColor {
	cdarOutline: string;
	sa1Outline: string;
	sold: string;
	soldLabel: string;
	reserved: string;
	reservedLabel: string;
	selectedArea: string;
}
interface IMapStateColor {
	outline: string;
}
interface IMapNeighbouringSuburbColor {
	outline: string;
	fill: string;
	selected: string;
}
export interface IMapColor {
	cda: IMapCdaColor;
	cdar: IMapCdarColor;
	suburb: IMapSuburbColor;
	sa1: IMapSa1Color;
	sales: IMapSalesColor;
	state: IMapStateColor;
	neighbouringSuburb: IMapNeighbouringSuburbColor;
}
export interface IMapSystemSetting {
	default_reservation_period: number;
	max_reservation_extension_period: number;
}
export interface ISettingApiResponse {
	json: any;
	type: string;
}
interface ISettingType {
	System: IMapSystemSetting | null;
	MapColors: IMapColor | null;
}

export interface ISettingApiParams {
	type: string;
	jsonData: ISettingType;
}

export interface ISalesPerson {
	id: number;
	firstname: string;
	lastname: string;
	email: string;
	role: string;
}

export interface ICdarAutoComplete {
	id: number;
	name: string;
}
export interface IProspectiveCustomer {
	firstname: string;
	lastname: string;
	transaction_id: number;
	area_type: string;
	transaction_type: string;
	transaction_date: string;
	sa1_count: number;
	prospect_agency: string;
	t_reservation_expiry_at?: string;
}
export interface IMapLegend {
	label: string;
	color: string;
	type: string;
	patternImageUrl?: string;
	opacity?: number;
}

export interface ITransactionSa1s {
	bounds: string;
	sa1_id: number;
	state_id: number;
	transaction_type: string;
}
export interface ITransactionArea {
	bounds: string;
	sa1_id?: number;
	state_id: number;
	transaction_type: string;
	cdar_id?: number;
}
export interface ICdarByAgency {
	bounds: string;
	latitude: number;
	longitude: number;
	cdar_id: number;
	state_id: number;
}
export interface ISa1ByAgency {
	bounds: string;
	latitude: number;
	longitude: number;
	sa1_id: number;
	state_id: number;
}

export interface ISa1CdarByAgency {
	cdar: ICdarByAgency[];
	sa1s: ISa1ByAgency[];
}

export interface IProspectiveCustomerOption {
	prospect_agency: string;
	reserved_sa1s: number;
}
