import { CancelToken } from 'axios';
import mapboxgl from 'mapbox-gl';
import {
	ELocalStorageKeys,
	EMapServiceJobStatus,
	IAgency,
	IAustraliaState,
	IHttpServiceResponse,
	ILoggedInUser,
	IMapSyncJob,
	ISa1Detail,
	ISalesAreaSearch,
	ISalesTransactionRequestBody,
	ISuburbDetail,
	ISettingApiParams,
	ISettingApiResponse,
	INavMenu,
	ISalesPerson,
	ICdarAutoComplete,
	IProspectiveCustomer,
	ITransactionArea,
	ISa1CdarByAgency,
	IProspectiveCustomerOption,
	IZenuSuburb,
	IZenuRegion,
	IBoundsSuburb
} from 'shared/types/Types';
import {
	ISa1AutoComplete,
	ISa1PerformanceReportApiParams,
	ISa1PerformanceReportResult,
	ISa1StatusReportResult,
	Sa1HistoricalReportFilterParams,
	ISa1HistoricalReportResult
} from 'modules/reports/Report';
import HttpService from './HttpService';
import { LocalStorageService } from './LocalStorageService';
import UtilityService from './UtilityService';

const getAllSuburbs = async () => {
	const res = (await HttpService.get('suburb/all')) as IHttpServiceResponse<IBoundsSuburb[]>;
	return res.data;
};

const getZenuSuburbs = async (params: { searchText: string }) => {
	const res = (await HttpService.get('suburb/zenu', {
		search_text: params.searchText
	})) as IHttpServiceResponse<IZenuSuburb[]>;
	return res.data;
};

const getZenuNeighbouringSuburbs = async (suburbId: number) => {
	const res = (await HttpService.get('suburb/neighbours-zenu', {
		suburb_id: suburbId
	})) as IHttpServiceResponse<IZenuSuburb[]>;
	return res.data;
};

const getZenuRegions = async (params: { searchText: string }) => {
	const res = (await HttpService.get('region/zenu', {
		search_text: params.searchText
	})) as IHttpServiceResponse<IZenuRegion[]>;
	return res.data;
};

const getZenuRegionSuburbs = async (regionId: number) => {
	const res = (await HttpService.get(`region/${regionId}/suburbs-zenu`)) as IHttpServiceResponse<IZenuSuburb[]>;
	return res.data;
};

const getAustraliaStates = async () => {
	const res = (await HttpService.get('state')) as IHttpServiceResponse<IAustraliaState[]>;
	return res.data;
};

const getSystemSettings = async (type?: string) => {
	const res = (await HttpService.get(
		`setting${type ? `?type=${type}` : ''}`
	)) as IHttpServiceResponse<ISettingApiResponse>;
	return res;
};
const saveSystemConfiguration = async (requestBody: ISettingApiParams) => {
	const res = (await HttpService.put('setting', requestBody)) as IHttpServiceResponse<unknown>;
	return res;
};

const getMapServiceSyncJobs = async (params?: { limit: number }) => {
	const res = (await HttpService.get(`map-service-job`, params)) as IHttpServiceResponse<IMapSyncJob[]>;
	updateLatestSyncJobInLocalStorage(res.data);
	return res.data;
};

const getAgencyBySearchText = async (params: { searchText: string; royalty?: boolean }) => {
	const res = (await HttpService.get(`agency`, {
		search_text: params.searchText,
		...(params.royalty && { is_royalty: params.royalty })
	})) as IHttpServiceResponse<IAgency[]>;
	return res.data;
};

const getSa1BySearchText = async (params: { searchText: string }) => {
	const res = (await HttpService.get(`sa1`, {
		search_text: params.searchText
	})) as IHttpServiceResponse<ISa1AutoComplete[]>;
	return res.data;
};
const getSalesPersonBySearchText = async (params: { searchText: string }) => {
	const res = (await HttpService.get(`user`, {
		search_text: params.searchText
	})) as IHttpServiceResponse<ISalesPerson[]>;
	return res.data;
};

const submitSalesTransaction = async (requestBody: ISalesTransactionRequestBody) => {
	const sanitizedPayload = UtilityService.replaceEmptyAttributeValuesByNull(requestBody);
	const res = (await HttpService.post(`transaction/sa1/submit`, sanitizedPayload)) as IHttpServiceResponse<unknown>;
	return res.data;
};
const submitSalesSellTransaction = async (requestBody: ISalesTransactionRequestBody) => {
	const sanitizedPayload = UtilityService.replaceEmptyAttributeValuesByNull(requestBody);
	const res = (await HttpService.post(`transaction/sa1/sell`, sanitizedPayload)) as IHttpServiceResponse<unknown>;
	return res.data;
};
const submitSalesReleaseTransaction = async (requestBody: ISalesTransactionRequestBody) => {
	const sanitizedPayload = UtilityService.replaceEmptyAttributeValuesByNull(requestBody);
	const res = (await HttpService.post(`transaction/sa1/release`, sanitizedPayload)) as IHttpServiceResponse<unknown>;
	return res.data;
};
const submitSalesReserveTransaction = async (requestBody: ISalesTransactionRequestBody) => {
	const sanitizedPayload = UtilityService.replaceEmptyAttributeValuesByNull(requestBody);
	const res = (await HttpService.post(`transaction/sa1/reserve`, sanitizedPayload)) as IHttpServiceResponse<unknown>;
	return res;
};

const getProspectiveCustomers = async (requestBody: { search_text: string }) => {
	const res = (await HttpService.get(`sa1/prospect-agency/search`, requestBody)) as IHttpServiceResponse<
		IProspectiveCustomer[]
	>;
	return res.data;
};
const getMyReservations = async (userId: number) => {
	const res = (await HttpService.get(`sa1/sales-user/${userId}`)) as IHttpServiceResponse<IProspectiveCustomer[]>;
	return res.data;
};

const submitReserveExtensionTransaction = async (requestBody: ISalesTransactionRequestBody) => {
	const sanitizedPayload = UtilityService.replaceEmptyAttributeValuesByNull(requestBody);
	const res = (await HttpService.post(
		`transaction/sa1/extend-reserve`,
		sanitizedPayload
	)) as IHttpServiceResponse<unknown>;
	return res.data;
};

const searchSa1AndSuburbByText = async (searchText: string, cancelToken?: CancelToken) => {
	const res = (await HttpService.get(
		`sa1-suburb/search`,
		{
			search_text: searchText
		},
		{
			cancelToken: cancelToken
		}
	)) as IHttpServiceResponse<ISalesAreaSearch[]>;
	return res.data;
};

const getSuburbById = async (suburbId: number) => {
	const res = (await HttpService.get(`suburb/${suburbId}`)) as IHttpServiceResponse<ISuburbDetail>;
	return res.data;
};

const getSa1BySa1Code = async (sa1Code: number) => {
	const res = (await HttpService.get(`sa1/${sa1Code}`)) as IHttpServiceResponse<ISa1Detail>;
	return res.data;
};

const updateLatestSyncJobInLocalStorage = (jobs: IMapSyncJob[]) => {
	//filter out non completed jobs, we are not interested in them
	const completedJobs = jobs.filter((job) => job.status === EMapServiceJobStatus.Complete);
	if (completedJobs.length === 0) {
		return;
	}

	//get the last completed job
	const latestJob = completedJobs.reduce((a, b) => {
		//reference - https://stackoverflow.com/a/57731226/1508479
		return new Date(a.completed_at) > new Date(b.completed_at) ? a : b;
	});

	const lastMapSyncJobIdInLs = LocalStorageService.getState<{ date: string } | undefined>(
		ELocalStorageKeys.LastMapSyncJobDate
	);

	//we have nothing in localstorage
	if (!lastMapSyncJobIdInLs) {
		LocalStorageService.putState(ELocalStorageKeys.LastMapSyncJobDate, { date: latestJob.completed_at });
	}
	//we have older id in localstorage
	else if (new Date(lastMapSyncJobIdInLs.date).getTime() !== new Date(latestJob.completed_at).getTime()) {
		//clear mapbox tileset cache in browser as we need to retrieve updated tiles from server
		//because map sync job involves tileset updates
		mapboxgl.clearStorage();
		LocalStorageService.putState(ELocalStorageKeys.LastMapSyncJobDate, { date: latestJob.completed_at });
	}
};

const validateAuthToken = async (requestBody: { token: string }) => {
	const sanitizedPayload = UtilityService.replaceEmptyAttributeValuesByNull(requestBody);
	const res = (await HttpService.post(`auth/verify`, sanitizedPayload)) as IHttpServiceResponse<unknown>;
	return res;
};

const getUser = async () => {
	const res = (await HttpService.get(`auth/user`)) as IHttpServiceResponse<ILoggedInUser>;
	return res.data;
};
const getMenu = async () => {
	const res = (await HttpService.get(`menu`)) as IHttpServiceResponse<INavMenu[]>;
	return res;
};

const getSA1PerformanceRecord = async (filterParams: ISa1PerformanceReportApiParams) => {
	const sanitizedPayload = UtilityService.replaceEmptyAttributeValuesByNull(filterParams);
	const res = (await HttpService.post(`report/sa1-performance`, sanitizedPayload)) as IHttpServiceResponse<
		ISa1PerformanceReportResult[]
	>;
	return res;
};
const getSA1StatusRecord = async (filterParams: ISa1PerformanceReportApiParams) => {
	const sanitizedPayload = UtilityService.replaceEmptyAttributeValuesByNull(filterParams);
	const res = (await HttpService.post(`report/sa1-status`, sanitizedPayload)) as IHttpServiceResponse<
		ISa1StatusReportResult[]
	>;
	return res;
};
const getSA1HistoricalRecord = async (filterParams: Sa1HistoricalReportFilterParams) => {
	const sanitizedPayload = UtilityService.replaceEmptyAttributeValuesByNull(filterParams);
	const res = (await HttpService.post(`report/transaction-history`, sanitizedPayload)) as IHttpServiceResponse<
		ISa1HistoricalReportResult[]
	>;
	return res;
};

const getCdarBySearchText = async (params: { searchText: string }) => {
	const res = (await HttpService.get(`cdar/search`, {
		search_text: params.searchText
	})) as IHttpServiceResponse<ICdarAutoComplete[]>;
	return res.data;
};

const getTransactionedAreaByTransactionId = async (transactionId: number) => {
	const res = (await HttpService.get(`transaction/${transactionId}`)) as IHttpServiceResponse<ITransactionArea[]>;
	return res.data;
};
const getSa1CdarByAgency = async (agencyId: number) => {
	const res = (await HttpService.get(`agency/${agencyId}/sold-sa1-cdar`)) as IHttpServiceResponse<ISa1CdarByAgency>;
	return res.data;
};

const getProspectiveCustomerBySearchText = async (params: { searchText: string }) => {
	const res = (await HttpService.get(`sa1/all/prospect-agency`, {
		search_text: params.searchText
	})) as IHttpServiceResponse<IProspectiveCustomerOption[]>;
	return res.data;
};

const CommonApiService = {
	getSa1BySa1Code,
	getSuburbById,
	getMapServiceSyncJobs,
	getAllSuburbs,
	getZenuSuburbs,
	getZenuNeighbouringSuburbs,
	getZenuRegions,
	getZenuRegionSuburbs,
	getAustraliaStates,
	getAgencyBySearchText,
	submitSalesTransaction,
	searchSa1AndSuburbByText,
	submitReserveExtensionTransaction,
	validateAuthToken,
	submitSalesSellTransaction,
	submitSalesReleaseTransaction,
	submitSalesReserveTransaction,
	getUser,
	getSystemSettings,
	saveSystemConfiguration,
	getSa1BySearchText,
	getSA1PerformanceRecord,
	getSA1StatusRecord,
	getMenu,
	getSalesPersonBySearchText,
	getCdarBySearchText,
	getSA1HistoricalRecord,
	getProspectiveCustomers,
	getMyReservations,
	getTransactionedAreaByTransactionId,
	getSa1CdarByAgency,
	getProspectiveCustomerBySearchText
};

export default CommonApiService;
