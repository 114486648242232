import {
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
	Divider,
	Slide
} from '@material-ui/core';
import Map from 'shared/components/Map';
import React, { Component } from 'react';
import AreaLoaderContainer from 'shared/components/AreaLoaderContainer';
import { NotificationContext } from 'shared/providers/NotificationProvider';
import {
	ELoadingIndicatorStatus,
	ERevAreaSaleType,
	IAgency,
	IAreaSaleSubmissionError,
	IAustraliaState,
	ISalesAreaListItem,
	ISaleVerificationModalSubmit,
	IMapLegend,
	IProspectiveCustomer,
	IMapSystemSetting,
	ISalesPerson,
	IMapColor,
	IProspectiveCustomerOption,
	ISalesAreaSearch
} from 'shared/types/Types';
import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab';
import ProspectiveCustomerListModal from './ProspectiveCustomerListModal';
import Sa1AreaList from 'shared/hoc/sales/SelectedSa1CdarAreaList';
import SalesSubmissionErrors from 'shared/hoc/sales/TransactionSubmissionErrors';
import MapLegend from 'shared/components/MapLegend';
import TransactionModal from 'shared/hoc/sales/TransactionModal';
/* import MapSearchInput from 'shared/components/MapSearchInput'; */
import AbsolutePositionContainer from 'shared/components/AbsolutePositionContainer';
import Sa1AreaListToolbar from 'shared/hoc/sales/SelectdSa1CdarAreaListToolbar';
import AdminSalesWrapper from 'shared/hoc/sales/AdminSalesWrapperHoc';
import ReservationListModal from 'shared/hoc/sales/ReservationsModal';
import FullAreaLoader from 'shared/components/FullAreaLoader';

enum ESa1SalesLoaders {
	ShowAgencyAutocompleteLoader = 'show-agency-autocomplete-loader',
	ShowSalesPersonAutocompleteLoader = 'show-sales-person-autocomplete-loader',
	SalesTransactionSubmitLoader = 'sales-transaction-submit-loader',
	ShowPropspectiveCustomerAutocompleteLoader = 'show-propspective -customer-autocomplete-loader',
	ShowSa1SuburbAutoCompleteLoader = 'show-sa1-suburb-autocomplete-loader'
}
type ESa1LoadingIndicatorsStatus = ELoadingIndicatorStatus | ESa1SalesLoaders;

enum ESalesModals {
	SubmissionErrorModal = 'submission-error-modal',
	SubmissionVerificationModal = 'submission-Verification-modal',
	AgencyCdaSelectionModal = 'agency-cda-selection-modal',
	ProspectiveCustomerSelectModal = 'prospective-customer-selection-modal',
	ReservationSelectModal = 'reservation-selection-modal',
	None = 'none'
}

interface IProps {
	autocomplete: {
		agency: string;
		salesPerson: string;
		prospectiveCustomer: string;
		sa1Suburb: string;
	};
	getMapLegend: () => IMapLegend[];
	isShowMap: boolean;
	selectedAusStateId: number | null;
	ausStates: IAustraliaState[];
	agencies: IAgency[];
	loadingIndicator: ESa1LoadingIndicatorsStatus;
	salesAreaList: ISalesAreaListItem[];
	activeModal: ESalesModals;
	salesPersons: ISalesPerson[];
	reservations: IProspectiveCustomer[];
	onMapLoadComplete: () => void;
	onGoingAreaSaleActionType: ERevAreaSaleType | null;
	submissionErrors: IAreaSaleSubmissionError[];
	areasEligibleForSubmission: ISalesAreaListItem[]; //filtered out and validated areas that will be sent to backend for
	prospectiveCustomers: IProspectiveCustomer[];
	prospectiveCustomerOptions: IProspectiveCustomerOption[];
	mapSystemsConfig: IMapSystemSetting | null;
	mapColors: IMapColor;
	isModalLoading: boolean;
	onChangeAgencyAutocompleteInput: (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => void;
	onChangeSalesPersonAutocompleteInput: (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => void;

	onChangeProspectiveCustomerAutocompleteInput: (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => void;
	onClickCancelSubmission: () => void;
	onClickSubmitSaleAction: (data: ISaleVerificationModalSubmit) => void;
	onCloseSubmissionErrorsModal: () => void;
	onChangeAgencyAutocomplete: (ev: unknown, value: IAgency | null, reason: AutocompleteChangeReason) => void;
	onChangeSalesPersonAutocomplete: (ev: unknown, value: ISalesPerson | null, reason: AutocompleteChangeReason) => void;
	onChangeAusStateDropdown: (ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
	onClickRemoveSelectedAreaListItem: (clickedArea: ISalesAreaListItem) => void;
	onClickSelectedAreaListItem: (clickedArea: ISalesAreaListItem) => void;
	onClickDeSelectAllSalesAreas: () => void;
	onClickExtendReservation: () => void;
	onClickReserveArea: () => void;
	onClickReleaseArea: () => void;
	onClickSellArea: () => void;
	onClickCopySA1: () => void;
	onClickRemoveAllSelectedAreaListItems: () => void;
	onAreaListItemMouseOver: (area: ISalesAreaListItem) => void;
	onAreaListItemMouseOut: (area: ISalesAreaListItem) => void;
	onClickSelectAllSalesAreas: () => void;
	getProspectiveCustomers: (prospectCustomer: string) => void;
	getSa1ByTransactionIdForSalesPerson: () => void;
	getTotalSa1sFromList: () => number;
	getTotalSelectedSa1sFromList: () => number;
	onChangeSa1SuburbAutocompleteInput: (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => void;
	onChangeSa1SuburbAutocomplete: (
		ev: unknown,
		value: ISalesAreaSearch | string | null,
		reason: AutocompleteChangeReason
	) => void;

	sa1SuburbOptions: ISalesAreaSearch[];
}

interface IState {
	prospectiveCustomerError: {
		isError: boolean;
		errorMessage: string;
	};
	prospectiveCustomerSearchText: string;
}

class AdminSales extends Component<IProps, IState> {
	prospectCustomerRef: React.RefObject<HTMLInputElement>;
	constructor(props: IProps) {
		super(props);
		this.prospectCustomerRef = React.createRef();
	}
	state: Readonly<IState> = {
		prospectiveCustomerError: {
			isError: false,
			errorMessage: ''
		},
		prospectiveCustomerSearchText: ''
	};
	render() {
		const totalSa1s = this.props.getTotalSa1sFromList();
		return (
			<Grid direction="column" container>
				{this.props.loadingIndicator === ELoadingIndicatorStatus.ShowFullAreaLoader && <FullAreaLoader />}
				<Paper elevation={0} className="paper-container admin-sales-sidebar" style={{ width: '100%' }}>
					<Grid container alignItems="center" style={{ paddingTop: 5 }}>
						<Grid item container style={{ width: 'auto', marginRight: 10 }}>
							<Typography color="secondary" variant="h6" style={{ fontSize: '0.975rem' }}>
								Admin Sales
							</Typography>
						</Grid>
						<Divider orientation="vertical" flexItem style={{ backgroundColor: '#bdbdbd' }} />
						<Grid item style={{ marginRight: 15, width: 180, marginLeft: 10 }}>
							<FormControl style={{ width: '100%' }} variant="outlined">
								<InputLabel id="manage-cda-state-filter">State</InputLabel>
								<Select
									value={this.props.selectedAusStateId || ''}
									onChange={this.props.onChangeAusStateDropdown}
									label="State"
									inputProps={{
										name: 'State',
										id: 'outlined-age-native-simple'
									}}
								>
									{this.props.ausStates.map((ausState) => {
										return (
											<MenuItem key={ausState.name} value={ausState.id} className="stateFilterOptions">
												{ausState.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>

						<Grid item style={{ marginRight: 15, width: 220 }}>
							<FormControl style={{ width: '100%' }}>
								<Autocomplete
									inputValue={this.props.autocomplete.agency}
									onInputChange={this.props.onChangeAgencyAutocompleteInput}
									size="small"
									onChange={this.props.onChangeAgencyAutocomplete}
									options={this.props.agencies}
									getOptionLabel={(option) => `(${option.agency_id}) ${option.name}`}
									renderOption={(option) => (
										<Typography style={{ fontSize: '0.80rem' }}>
											({option.agency_id}) {option.name}
										</Typography>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Agency"
											variant="outlined"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{this.props.loadingIndicator === ESa1SalesLoaders.ShowAgencyAutocompleteLoader ? (
															<CircularProgress color="secondary" size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
							</FormControl>
						</Grid>
						<Grid item style={{ marginRight: 15, width: 220 }}>
							<FormControl style={{ width: '100%' }}>
								<Autocomplete
									inputValue={this.props.autocomplete.salesPerson}
									onInputChange={this.props.onChangeSalesPersonAutocompleteInput}
									size="small"
									onChange={this.props.onChangeSalesPersonAutocomplete}
									options={this.props.salesPersons}
									getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
									renderOption={(option) => (
										<Typography style={{ fontSize: '0.80rem' }}>
											{option.firstname} {option.lastname}
										</Typography>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Account Manager"
											variant="outlined"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{this.props.loadingIndicator === ESa1SalesLoaders.ShowSalesPersonAutocompleteLoader && (
															<CircularProgress color="secondary" size={20} />
														)}
														{params.InputProps.endAdornment}
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
							</FormControl>
						</Grid>

						<Grid item style={{ marginRight: 15, width: 200 }}>
							<FormControl style={{ width: '100%' }}>
								<Autocomplete
									inputValue={this.props.autocomplete.prospectiveCustomer}
									onInputChange={this.props.onChangeProspectiveCustomerAutocompleteInput}
									size="small"
									onChange={this.onChangeProspectiveCustomerAutocomplete}
									options={this.props.prospectiveCustomerOptions}
									getOptionLabel={(option) => `${option.prospect_agency} (${option.reserved_sa1s})`}
									renderOption={(option) => (
										<Typography style={{ fontSize: '0.80rem' }}>
											{option.prospect_agency} ({option.reserved_sa1s})
										</Typography>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Prospective Customer"
											variant="outlined"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{this.props.loadingIndicator ===
														ESa1SalesLoaders.ShowPropspectiveCustomerAutocompleteLoader ? (
															<CircularProgress color="secondary" size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
							</FormControl>
						</Grid>
						<Grid item style={{ marginRight: 15, width: 240 }}>
							<FormControl style={{ width: '100%' }}>
								<Autocomplete
									freeSolo
									inputValue={this.props.autocomplete.sa1Suburb}
									onInputChange={this.props.onChangeSa1SuburbAutocompleteInput}
									size="small"
									onChange={this.props.onChangeSa1SuburbAutocomplete}
									options={this.props.sa1SuburbOptions}
									getOptionLabel={(option) => option.text}
									renderOption={(option) => <Typography style={{ fontSize: '0.80rem' }}>{option.text}</Typography>}
									renderInput={(params) => (
										<TextField
											/* size="small" */
											{...params}
											label="SA1 or Postcode or Suburb"
											variant="outlined"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{this.props.loadingIndicator === ESa1SalesLoaders.ShowSa1SuburbAutoCompleteLoader ? (
															<CircularProgress color="secondary" size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
							</FormControl>
							{/* <MapSearchInput onSelectOption={this.props.onSelectOptionFromMapSearchBox} /> */}
						</Grid>
					</Grid>
				</Paper>
				<Grid item xs container direction="column">
					<Grid item xs container direction="column">
						{this.props.loadingIndicator === ELoadingIndicatorStatus.ShowNavBarLoader && <LinearProgress />}
						<AreaLoaderContainer isLoading={!this.props.isShowMap}>
							<Grid
								container
								direction="column"
								style={{
									height: 'calc(100vh - 101px)',
									width: '100%'
								}}
							>
								<Grid item xs>
									<Map onMapLoadComplete={this.props.onMapLoadComplete}>
										<AbsolutePositionContainer>
											<Grid container direction="column">
												<Slide
													unmountOnExit
													direction="right"
													in={this.props.salesAreaList.length > 0}
													style={{ marginTop: 10, marginLeft: 10 }}
												>
													<Paper style={{ height: 400, width: 370 }}>
														<Sa1AreaListToolbar
															transactions={['sell', 'reserve', 'extend_reservation', 'release', 'copySA1']}
															onClickExtendReservation={this.props.onClickExtendReservation}
															onClickSell={this.props.onClickSellArea}
															onClickReserve={this.props.onClickReserveArea}
															onClickRelease={this.props.onClickReleaseArea}
															onClickCopySA1={this.props.onClickCopySA1}
															numSelected={this.props.salesAreaList.filter((s) => s.selected).length}
															onClickRemoveAllAreas={this.props.onClickRemoveAllSelectedAreaListItems}
														/>
														<Grid className="scroll-v" style={{ height: totalSa1s !== 0 ? 300 : 335 }}>
															<Sa1AreaList
																mapColors={this.props.mapColors}
																onClickDeSelectAllAreas={this.props.onClickDeSelectAllSalesAreas}
																onClickSelectAllAreas={this.props.onClickSelectAllSalesAreas}
																onClickArea={this.props.onClickSelectedAreaListItem}
																areas={this.props.salesAreaList}
																onClickRemoveArea={this.props.onClickRemoveSelectedAreaListItem}
																onAreaMouseOver={this.props.onAreaListItemMouseOver}
																onAreaMouseOut={this.props.onAreaListItemMouseOut}
															/>
														</Grid>
														{totalSa1s !== 0 && (
															<Grid container direction="column">
																<Divider style={{ backgroundColor: '#bdbdbd' }} />

																<Grid item container justify="space-between" style={{ padding: 7 }}>
																	<Typography color="secondary" variant="h6" style={{ fontSize: '0.899rem' }}>
																		Total SA1(s) : {this.props.getTotalSa1sFromList()}
																	</Typography>
																	<Typography color="secondary" variant="h6" style={{ fontSize: '0.899rem' }}>
																		Selected SA1(s) : {this.props.getTotalSelectedSa1sFromList()}
																	</Typography>
																</Grid>
															</Grid>
														)}
													</Paper>
												</Slide>
											</Grid>
										</AbsolutePositionContainer>
									</Map>
								</Grid>
								<MapLegend items={this.props.getMapLegend()} />
							</Grid>
						</AreaLoaderContainer>
					</Grid>
					{/** sales submission error modal */}
					{this.props.activeModal === ESalesModals.SubmissionErrorModal && (
						<SalesSubmissionErrors
							errors={this.props.submissionErrors}
							onClose={this.props.onCloseSubmissionErrorsModal}
						/>
					)}
					{/** sales verification error modal */}
					{this.props.activeModal === ESalesModals.SubmissionVerificationModal && (
						<TransactionModal
							mapSystemsConfig={this.props.mapSystemsConfig}
							isSubmitting={this.props.loadingIndicator === ESa1SalesLoaders.SalesTransactionSubmitLoader}
							areas={this.props.areasEligibleForSubmission}
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							saleType={this.props.onGoingAreaSaleActionType!}
							onClickCancelSubmission={this.props.onClickCancelSubmission}
							onClickSubmitSaleAction={this.props.onClickSubmitSaleAction}
						/>
					)}
					{this.props.activeModal === ESalesModals.ProspectiveCustomerSelectModal &&
						this.state.prospectiveCustomerSearchText && (
							<ProspectiveCustomerListModal
								prospectiveCustomers={this.props.prospectiveCustomers}
								prospectiveCustomerSearchText={this.state.prospectiveCustomerSearchText}
								onClickSubmit={this.props.getSa1ByTransactionIdForSalesPerson}
								onClickClose={this.props.onClickCancelSubmission}
								isModalLoading={this.props.isModalLoading}
							/>
						)}
					{/** view my reservations modal */}
					{this.props.activeModal === ESalesModals.ReservationSelectModal && (
						<ReservationListModal
							reservations={this.props.reservations}
							onClickSubmit={this.props.getSa1ByTransactionIdForSalesPerson}
							onClickClose={this.props.onClickCancelSubmission}
							isModalLoading={this.props.isModalLoading}
						/>
					)}
				</Grid>
			</Grid>
		);
	}
	onChangeProspectiveCustomerAutocomplete = async (
		ev: unknown,
		value: IProspectiveCustomerOption | null,
		reason: AutocompleteChangeReason
	) => {
		if (!value) {
			return;
		}
		this.setState({
			prospectiveCustomerSearchText: value.prospect_agency
		});
		this.props.getProspectiveCustomers(value.prospect_agency);
	};
}

AdminSales.contextType = NotificationContext;

export default AdminSalesWrapper(AdminSales);
