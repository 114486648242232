import {
	CircularProgress,
	Divider,
	FormControl,
	Grid,
	LinearProgress,
	Paper,
	TextField,
	Typography,
	Button
} from '@material-ui/core';
import mapboxgl, { LngLatLike } from 'mapbox-gl';
import React, { Component } from 'react';
import Split from 'react-split';
import AreaLoaderContainer from 'shared/components/AreaLoaderContainer';
import Map from 'shared/components/Map';
import Config from 'shared/constants/Config';
import { NotificationContext } from 'shared/providers/NotificationProvider';
import CommonApiService from 'shared/services/CommonApiService';
import UtilityService from 'shared/services/UtilityService';
import {
	IZenuSuburb,
	IGeoBound,
	IMapboxMouseMoveEvent,
	ISuburb,
	ISuburbGeoJsonFeature,
	IMapColor,
	IZenuRegion,
	IBoundsSuburb,
	ENotificationTypes
} from 'shared/types/Types';
import MapLegend from 'shared/components/MapLegend';
import ViewNeighbouringSuburbsForm from './ViewNeighbouringSuburbsForm';
import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab';
import { find as lodashFind } from 'lodash';

// mapbox gl js has issues with webpack/babel transpiling. Reference - https://github.com/mapbox/mapbox-gl-js/issues/10173
//eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
//eslint-disable-next-line import/no-webpack-loader-syntax, @typescript-eslint/no-var-requires, @typescript-eslint/ban-ts-comment
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

enum EMapLayers {
	SuburbFill = 'suburb-fill',
	SuburbOutline = 'suburb-outline',
	SelectedSuburbFill = 'selected-suburb-fill',
	SelectedSuburbOutline = 'selected-suburb-outline',
	SurroundingSuburbFill = 'surrounding-suburb-fill',
	SurroundingSuburbOutline = 'surrounding-suburb-outline'
}

enum EViewSuburbRegionLoaders {
	SuburbAutoCompleteLoader = 'show-suburb-autocomplete-loader',
	RegionAutoCompleteLoader = 'show-region-autocomplete-loader',
	None = 'none'
}

interface IState {
	suburbs: IBoundsSuburb[];
	zenuSuburbs: IZenuSuburb[];
	zenuRegions: IZenuRegion[];
	suburbNeighbours: IZenuSuburb[];
	selectedSuburb: string;
	selectedSuburbId: number;
	selectedRegion: string;
	isLoading: boolean;
	loadingIndicator: EViewSuburbRegionLoaders;
	showAreaLoader: boolean;
	isShowMap: boolean;
	mapColors: IMapColor;
}

//definition for state variables that we store inside mapbox map feature
type TSuburbMapFeatureState = { newlyAddedToCda: boolean } | { clicked: boolean } | { hover: boolean };
class ViewSuburbRegion extends Component<unknown, IState> {
	state: Readonly<IState> = {
		selectedSuburb: '',
		selectedRegion: '',
		selectedSuburbId: 0,
		isLoading: false,
		suburbs: [],
		zenuSuburbs: [],
		zenuRegions: [],
		loadingIndicator: EViewSuburbRegionLoaders.None,
		suburbNeighbours: [],
		showAreaLoader: false,
		isShowMap: false,
		mapColors: Config.MapColors
	};

	private popup = new mapboxgl.Popup({
		closeButton: false,
		closeOnClick: false,
		offset: 10
	});

	private map!: mapboxgl.Map;
	context!: React.ContextType<typeof NotificationContext>;

	private hoverState = {
		cda: 0,
		suburb: '',
		state: 0
	};

	constructor(props: unknown) {
		super(props);
		this.onMapHoverStart = UtilityService.debounce(this.onMapHoverStart, 25);
		this.onMapHoverEnd = UtilityService.debounce(this.onMapHoverEnd, 25);
	}

	componentDidMount = async () => {
		await this.getSystemMapColors();
	};

	render(): JSX.Element {
		return (
			<Grid container direction="column" className="split-container">
				<Grid item xs>
					{this.state.isLoading && <LinearProgress />}
					<Split
						sizes={[40, 60]}
						minSize={250}
						expandToMin={false}
						gutterSize={10}
						gutterAlign="center"
						snapOffset={30}
						dragInterval={1}
						cursor="col-resize"
						className="split-parent split-horizontal"
						onDragEnd={() => this.map.resize()}
					>
						{/* sidebar */}
						<div className="split-item pl10 pt10">
							<AreaLoaderContainer isLoading={this.state.showAreaLoader}>
								<Grid direction="column" container style={{ height: '100%', width: '100%' }}>
									<Grid xs item container direction="column">
										<Typography className="mb10" color="secondary" variant="h5">
											View Neighbouring Suburbs
										</Typography>
										<Grid item xs>
											<Paper elevation={0} className="paper-container stretch">
												<Grid container direction="column" className="stretch">
													<FormControl style={{ marginTop: 10, width: '100%' }}>
														<Autocomplete
															inputValue={this.state.selectedSuburb}
															onInputChange={this.onChangeSuburbAutocompleteInput}
															size="small"
															onChange={this.onChangeSuburbDropdown}
															options={this.state.zenuSuburbs}
															getOptionLabel={(option) => option.name + '-' + option.postcode + '-' + option.state}
															renderOption={(option) => (
																<Typography style={{ fontSize: '0.80rem' }}>
																	{option.name} ({option.postcode}) ({option.state})
																</Typography>
															)}
															renderInput={(params) => (
																<TextField
																	{...params}
																	label="Suburb Search"
																	variant="outlined"
																	InputProps={{
																		...params.InputProps,
																		endAdornment: (
																			<React.Fragment>
																				{this.state.loadingIndicator ===
																				EViewSuburbRegionLoaders.SuburbAutoCompleteLoader ? (
																					<CircularProgress color="secondary" size={20} />
																				) : null}
																				{params.InputProps.endAdornment}
																			</React.Fragment>
																		)
																	}}
																/>
															)}
														/>
													</FormControl>
													<Typography
														variant="body1"
														color="secondary"
														style={{ textAlign: 'center', marginTop: 10, height: 27 }}
													>
														OR
													</Typography>
													<FormControl style={{ marginTop: 10, width: '100%' }}>
														<Autocomplete
															inputValue={this.state.selectedRegion}
															onInputChange={this.onChangeRegionAutocompleteInput}
															size="small"
															onChange={this.onChangeRegionDropdown}
															options={this.state.zenuRegions}
															getOptionLabel={(option) => option.name + '-' + option.state}
															renderOption={(option) => (
																<Typography style={{ fontSize: '0.80rem' }}>
																	{option.name} ({option.state})
																</Typography>
															)}
															renderInput={(params) => (
																<TextField
																	{...params}
																	label="Region Search"
																	variant="outlined"
																	InputProps={{
																		...params.InputProps,
																		endAdornment: (
																			<React.Fragment>
																				{this.state.loadingIndicator ===
																				EViewSuburbRegionLoaders.RegionAutoCompleteLoader ? (
																					<CircularProgress color="secondary" size={20} />
																				) : null}
																				{params.InputProps.endAdornment}
																			</React.Fragment>
																		)
																	}}
																/>
															)}
														/>
													</FormControl>
													<Grid style={{ textAlign: 'right', margin: '5px 0' }}>
														<Button color="secondary" variant="outlined" onClick={this.onClickReset}>
															Reset
														</Button>
													</Grid>
													<Divider light style={{ margin: '5px 0' }} />
													{this.renderSuburbForm()}
												</Grid>
											</Paper>
										</Grid>
									</Grid>
								</Grid>
							</AreaLoaderContainer>
						</div>
						{/* map */}
						<div className="split-item">
							<AreaLoaderContainer isLoading={!this.state.isShowMap}>
								<Grid
									container
									direction="column"
									style={{
										height: 'calc(100vh - 48px)',
										width: '100%'
									}}
								>
									<Grid item xs>
										<Map onMapLoadComplete={this.onMapLoadComplete} />
									</Grid>
									<MapLegend items={this.getMapLegend()} />
								</Grid>
							</AreaLoaderContainer>
						</div>
					</Split>
				</Grid>
			</Grid>
		);
	}

	renderSuburbForm = (): JSX.Element | null => {
		return (
			<ViewNeighbouringSuburbsForm
				suburbs={this.state.suburbNeighbours}
				showRegionHeader={this.state.selectedRegion !== '' ? true : false}
				showSuburbHeader={this.state.selectedSuburb !== '' ? true : false}
				onSuburbListItemHoverStart={this.onSuburbListHoverStart}
				onSuburbListItemHoverEnd={this.onSuburbListHoverEnd}
				onClickCopySuburbs={this.onClickCopySuburbs}
			/>
		);
	};

	getMapLegend = () => {
		return [
			{
				label: 'Selected Suburb',
				type: 'area',
				color: this.state.mapColors['neighbouringSuburb']['selected'],
				opacity: 0.7
			},
			{
				label: 'Region Suburbs OR Neighbouring Suburbs',
				type: 'area',
				color: this.state.mapColors['neighbouringSuburb']['fill'],
				opacity: 0.7
			}
		];
	};

	getSystemMapColors = async () => {
		const apiRes: any = await CommonApiService.getSystemSettings('map_colors');
		if (!apiRes?.success) {
			return;
		}
		this.setState({
			mapColors: apiRes.data[0].json.MapColors,
			isShowMap: true
		});
	};

	getRevSuburbs = async () => {
		try {
			this.setState({
				isLoading: true,
				showAreaLoader: true
			});
			const suburbs = await CommonApiService.getAllSuburbs();
			this.setState({
				suburbs: suburbs
			});
		} finally {
			this.setState({
				isLoading: false,
				showAreaLoader: false
			});
		}
	};

	onChangeSuburbAutocompleteInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		this.setState({
			selectedSuburb: value
		});
		if (reason === 'reset' && !this.state.selectedSuburb) {
			this.setState({
				selectedSuburb: ''
			});
		}
		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (reason !== 'input' || value.trim().length < 3) {
			return;
		}
		this.getZenuSuburbs(value);
	};

	getZenuSuburbs = async (value: string) => {
		try {
			this.setState({
				loadingIndicator: EViewSuburbRegionLoaders.SuburbAutoCompleteLoader
			});
			const suburbs = await CommonApiService.getZenuSuburbs({ searchText: value });
			this.setState({
				zenuSuburbs: suburbs
			});
		} finally {
			this.setState({
				loadingIndicator: EViewSuburbRegionLoaders.None
			});
		}
	};

	onChangeRegionAutocompleteInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		this.setState({
			selectedRegion: value
		});
		if (reason === 'reset' && !this.state.selectedRegion) {
			this.setState({
				selectedRegion: ''
			});
		}
		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (reason !== 'input' || value.trim().length < 3) {
			return;
		}
		this.getZenuRegions(value);
	};

	getZenuRegions = async (value: string) => {
		try {
			this.setState({
				loadingIndicator: EViewSuburbRegionLoaders.RegionAutoCompleteLoader
			});
			const regions = await CommonApiService.getZenuRegions({ searchText: value });
			this.setState({
				zenuRegions: regions
			});
		} finally {
			this.setState({
				loadingIndicator: EViewSuburbRegionLoaders.None
			});
		}
	};

	onChangeSuburbDropdown = (ev: unknown, value: IZenuSuburb | null, reason: AutocompleteChangeReason) => {
		if (!value) {
			return;
		}
		this.selectSuburb(value.id);
	};

	onChangeRegionDropdown = (ev: unknown, value: IZenuRegion | null, reason: AutocompleteChangeReason) => {
		if (!value) {
			return;
		}
		this.selectRegion(value.id);
	};

	selectSuburb = (suburbId: number) => {
		const suburb = this.state.zenuSuburbs.find((s) => suburbId === s.id);
		if (!suburb) {
			throw new Error('Could not find suburb selected in local suburb array');
		}
		this.setState(
			{
				selectedSuburb: suburb.name,
				selectedSuburbId: suburbId,
				selectedRegion: ''
			},
			async () => {
				await this.getNeighbouringSuburbsBySuburb(suburbId);
				this.showNeighbouringSuburbsOnMap();
				this.showSelectedSuburbOnMap(suburb);
				if (this.state.suburbNeighbours.length > 0) {
					this.fitMapToArea(this.state.suburbNeighbours);
				} else if (suburb.bounds) {
					this.fitMapToBoundsWithPadding(suburb.bounds);
				}
			}
		);
	};

	selectRegion = (regionId: number) => {
		const region = this.state.zenuRegions.find((r) => regionId === r.id);
		if (!region) {
			throw new Error('Could not find region selected in local regions array');
		}
		this.setState(
			{
				selectedRegion: region.name,
				selectedSuburbId: 0,
				selectedSuburb: ''
			},
			async () => {
				await this.getZenuRegionSuburbs(regionId);
				this.showNeighbouringSuburbsOnMap();
				this.showSelectedSuburbOnMap(null);
				if (this.state.suburbNeighbours.length > 0) {
					this.fitMapToArea(this.state.suburbNeighbours);
				}
			}
		);
	};

	getNeighbouringSuburbsBySuburb = async (suburbId: number) => {
		try {
			this.setState({
				isLoading: true
			});
			const suburbNeighbours = await CommonApiService.getZenuNeighbouringSuburbs(suburbId);

			this.setState({
				suburbNeighbours: suburbNeighbours
			});
		} finally {
			this.setState({
				isLoading: false
			});
		}
		return;
	};

	getZenuRegionSuburbs = async (regionId: number) => {
		try {
			this.setState({
				isLoading: true
			});
			const suburbNeighbours = await CommonApiService.getZenuRegionSuburbs(regionId);

			this.setState({
				suburbNeighbours: suburbNeighbours
			});
		} finally {
			this.setState({
				isLoading: false
			});
		}
		return;
	};

	onSuburbListHoverStart = (suburb: ISuburb) => {
		this.updateSuburbMapFeatureState(suburb.abs_suburb_id, { hover: true });
	};

	onSuburbListHoverEnd = (suburb: ISuburb) => {
		this.updateSuburbMapFeatureState(suburb.abs_suburb_id, { hover: false });
	};

	onClickReset = () => {
		this.setState(
			{
				selectedSuburb: '',
				selectedRegion: '',
				selectedSuburbId: 0,
				zenuSuburbs: [],
				zenuRegions: [],
				suburbNeighbours: []
			},
			() => {
				this.showNeighbouringSuburbsOnMap();
				this.showSelectedSuburbOnMap(null);
			}
		);
	};

	onClickCopySuburbs = () => {
		const selectedAreas = this.state.suburbNeighbours;
		let suburbs = `suburbName,suburbId,regionName,postcode,state\n`;

		if (this.state.selectedSuburb !== '' && this.state.selectedSuburbId > 0) {
			const suburb = this.state.zenuSuburbs.find((s) => this.state.selectedSuburbId === s.id);
			if (suburb) {
				suburbs += `*${suburb.name},${suburb.id},${suburb.region_name},${suburb.postcode},${suburb.state}\n`;
			}
		}

		selectedAreas.forEach((s) => {
			suburbs += `${s.name},${s.id},${s.region_name},${s.postcode},${s.state}\n`;
		});

		navigator.clipboard.writeText(suburbs).then(() =>
			this.context.addNotification({
				message: 'Suburbs copied to clipboard.',
				type: ENotificationTypes.Success
			})
		);
	};

	/***** MAP METHODS *****/

	showAllSuburbsOnMap = () => {
		if (!this.state.suburbs) {
			return;
		}
		let suburbIds = this.state.suburbs.map((s) => s.abs_suburb_id);

		if (suburbIds.length === 0) {
			suburbIds = [''];
		}

		this.map.setFilter(EMapLayers.SuburbFill, ['match', ['get', 'abs_id'], suburbIds, true, false]);
		this.map.setFilter(EMapLayers.SuburbOutline, ['match', ['get', 'abs_id'], suburbIds, true, false]);
	};

	showNeighbouringSuburbsOnMap = () => {
		let suburbIds = this.state.suburbNeighbours.map((s) => s.abs_suburb_id);

		if (suburbIds.length === 0) {
			suburbIds = [''];
		}

		this.map.setFilter(EMapLayers.SurroundingSuburbFill, ['match', ['get', 'abs_id'], suburbIds, true, false]);
		this.map.setFilter(EMapLayers.SurroundingSuburbOutline, ['match', ['get', 'abs_id'], suburbIds, true, false]);
	};

	showSelectedSuburbOnMap = (suburb: IZenuSuburb | null) => {
		const suburbLayerFeatureIds = [suburb ? suburb.abs_suburb_id : ''];

		this.map.setFilter(EMapLayers.SelectedSuburbFill, ['match', ['get', 'abs_id'], suburbLayerFeatureIds, true, false]);
		this.map.setFilter(EMapLayers.SelectedSuburbOutline, [
			'match',
			['get', 'abs_id'],
			suburbLayerFeatureIds,
			true,
			false
		]);
	};

	onMapLoadComplete = async (map: mapboxgl.Map) => {
		this.map = map;
		this.addMapSources();
		this.addMapLayers();
		this.registerMapEvents();
		await this.getRevSuburbs();
		this.showAllSuburbsOnMap();
	};

	addMapSources = () => {
		this.map.addSource('suburbs', {
			type: 'vector',
			url: `mapbox://${Config.MapTilesets.AustraliaSuburbs.Id}`,
			promoteId: { [Config.MapTilesets.AustraliaSuburbs.SourceLayer]: 'abs_id' }
		});
	};

	addMapLayers = () => {
		this.map.addLayer({
			id: EMapLayers.SuburbFill,
			type: 'fill',
			source: 'suburbs',
			'source-layer': Config.MapTilesets.AustraliaSuburbs.SourceLayer,
			filter: ['match', ['get', 'abs_id'], [''], true, false],
			paint: {
				'fill-color': Config.MapColors.available,
				'fill-outline-color': Config.MapColors.available
			}
		});

		this.map.addLayer({
			id: EMapLayers.SuburbOutline,
			type: 'line',
			source: 'suburbs',
			'source-layer': Config.MapTilesets.AustraliaSuburbs.SourceLayer,
			filter: ['match', ['get', 'abs_id'], [''], true, false],
			paint: {
				'line-color': this.state.mapColors['neighbouringSuburb']['outline'],
				'line-width': 1
			}
		});

		this.map.addLayer({
			id: EMapLayers.SelectedSuburbFill,
			type: 'fill',
			source: 'suburbs',
			'source-layer': Config.MapTilesets.AustraliaSuburbs.SourceLayer,
			filter: ['match', ['get', 'abs_id'], [''], true, false],
			paint: {
				'fill-outline-color': Config.MapColors.available,
				'fill-color': this.state.mapColors['neighbouringSuburb']['selected'],
				'fill-opacity': 0.7
			}
		});

		this.map.addLayer({
			id: EMapLayers.SelectedSuburbOutline,
			type: 'line',
			source: 'suburbs',
			'source-layer': Config.MapTilesets.AustraliaSuburbs.SourceLayer,
			filter: ['match', ['get', 'abs_id'], [''], true, false],
			paint: {
				'line-color': this.state.mapColors['neighbouringSuburb']['selected'],
				'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 4, 0]
			}
		});

		this.map.addLayer({
			id: EMapLayers.SurroundingSuburbFill,
			type: 'fill',
			source: 'suburbs',
			'source-layer': Config.MapTilesets.AustraliaSuburbs.SourceLayer,
			filter: ['match', ['get', 'abs_id'], [''], true, false],
			paint: {
				'fill-outline-color': Config.MapColors.available,
				'fill-color': this.state.mapColors['neighbouringSuburb']['fill'],
				'fill-opacity': 0.7
			}
		});

		this.map.addLayer({
			id: EMapLayers.SurroundingSuburbOutline,
			type: 'line',
			source: 'suburbs',
			'source-layer': Config.MapTilesets.AustraliaSuburbs.SourceLayer,
			filter: ['match', ['get', 'abs_id'], [''], true, false],
			paint: {
				'line-color': this.state.mapColors['neighbouringSuburb']['fill'],
				'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 4, 2]
			}
		});
	};

	registerMapEvents = () => {
		this.registerSuburbClickEvent();
		this.registerMapHoverEvent();
		this.registerMapMoveEvents();
	};

	registerSuburbClickEvent = () => {
		this.map.on('click', EMapLayers.SurroundingSuburbFill, this.onClickSuburbWhichAreAvailable);
		this.map.on('click', EMapLayers.SuburbFill, this.onClickSuburbWhichAreAvailable);
	};

	registerMapHoverEvent = () => {
		this.map.on('mousemove', this.onMapHoverStart);

		/**
		 * Mousemove event fires on entire map and its implementation
		 * has logic to clear existing hover outlines and tooltip if it does not find any feature underneath the
		 * mouse
		 *
		 * Essentially mousemove itself clears up the hover styles that it had applied
		 *
		 * In certain cases though mousemove will not be able to do job such as when the map is positioned in such
		 * a way that suburb or CDA boundary is at the edge of right side panel and mouse directly exits the map
		 * view without moving over any other areas. In such cases the hover styles and tooltip will remain
		 *
		 * So for these edge cases we need to listen to mouseleave events and we need listen to each layer
		 * separately as the callback does not accept array of values
		 */

		this.map.on('mouseleave', EMapLayers.SuburbFill, this.onMapHoverEnd);
		this.map.on('mouseleave', EMapLayers.SurroundingSuburbFill, this.onMapHoverEnd);
		this.map.on('mouseleave', EMapLayers.SelectedSuburbFill, this.onMapHoverEnd);
	};

	registerMapMoveEvents = () => {
		this.map.on('movestart', () => {
			this.map.off('mousemove', this.onMapHoverStart);
		});
		this.map.on('moveend', () => {
			this.map.on('mousemove', this.onMapHoverStart);
		});
	};

	onMapHoverStart = (e: IMapboxMouseMoveEvent) => {
		/* if (this.state.selectedSuburb === '') {
			return;
		} */

		const features = this.map.queryRenderedFeatures(e.point, {
			layers: [EMapLayers.SuburbFill, EMapLayers.SurroundingSuburbFill, EMapLayers.SelectedSuburbFill]
		});
		this.map.getCanvas().style.cursor = 'pointer';

		if (features.length === 0) {
			this.cleanExistingHoverArea();
			this.popup.remove();
			return;
		}

		const isHoverOnSuburb = features.find(
			(f) => f.layer.id === EMapLayers.SurroundingSuburbFill || f.layer.id === EMapLayers.SelectedSuburbFill
		);
		if (isHoverOnSuburb) {
			const suburb = isHoverOnSuburb as ISuburbGeoJsonFeature;
			this.onHoverZenuSuburb(suburb, e);
			return;
		}
		const hoverOnSuburb = features.find((f) => f.layer.id === EMapLayers.SuburbFill);
		const suburb = hoverOnSuburb as ISuburbGeoJsonFeature;
		this.onHoverSuburb(suburb, e);
	};

	onClickSuburbWhichAreAvailable = (e: IMapboxMouseMoveEvent) => {
		const bbox = UtilityService.getBoundingboxFromMapMouseEvent(e);
		const features = this.map.queryRenderedFeatures(bbox, {
			layers: [EMapLayers.SurroundingSuburbFill, EMapLayers.SuburbFill]
		}) as ISuburbGeoJsonFeature[];
		const suburbFeature = features[0];
		const suburb = suburbFeature.properties;

		//if suburb is already selected, de-select it
		/* if (suburbFeature.state.clicked) {
			this.removeSuburbFromCda(suburb.abs_id);
			return;
		} */
		console.log('clicked suburb:', suburb);
	};

	onHoverZenuSuburb = (suburb: ISuburbGeoJsonFeature, e: IMapboxMouseMoveEvent) => {
		const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];

		//need to update state in feature to remove hover outline
		this.cleanExistingHoverArea();
		this.hoverState.suburb = suburb.properties.abs_id;
		this.updateSuburbMapFeatureState(this.hoverState.suburb, { hover: true });

		//tooltip logic
		let tooltipContent = `Suburb: ${suburb.properties.name}`;
		const zenuSuburbFind = lodashFind(this.state.zenuSuburbs, { abs_suburb_id: this.hoverState.suburb });
		const zenuSuburbNeighborFind = lodashFind(this.state.suburbNeighbours, { abs_suburb_id: this.hoverState.suburb });
		if (zenuSuburbFind) {
			tooltipContent += `<br>Region: ${zenuSuburbFind.region_name}`;
			tooltipContent += `<br>Suburb Id: ${zenuSuburbFind.id}`;
			tooltipContent += `<br>State: ${zenuSuburbFind.state}`;
			tooltipContent += `<br>Postcode: ${zenuSuburbFind.postcode}`;
		} else if (zenuSuburbNeighborFind) {
			tooltipContent += `<br>Region: ${zenuSuburbNeighborFind.region_name}`;
			tooltipContent += `<br>Suburb Id: ${zenuSuburbNeighborFind.id}`;
			tooltipContent += `<br>State: ${zenuSuburbNeighborFind.state}`;
			tooltipContent += `<br>Postcode: ${zenuSuburbNeighborFind.postcode}`;
		}
		this.popup.setLngLat(coordinates).setHTML(tooltipContent).addTo(this.map);
	};

	onHoverSuburb = (suburb: ISuburbGeoJsonFeature, e: IMapboxMouseMoveEvent) => {
		const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];

		//need to update state in feature to remove hover outline
		this.cleanExistingHoverArea();
		this.hoverState.suburb = suburb.properties.abs_id;
		this.updateSuburbMapFeatureState(this.hoverState.suburb, { hover: true });

		//tooltip logic
		let tooltipContent = `Suburb: ${suburb.properties.name}`;
		const revSuburbFind = lodashFind(this.state.suburbs, { abs_suburb_id: this.hoverState.suburb });
		if (revSuburbFind) {
			tooltipContent += `<br>Region: ${revSuburbFind.region_name}`;
			tooltipContent += `<br>Suburb Id: ${revSuburbFind.id}`;
			tooltipContent += `<br>State: ${revSuburbFind.state_name}`;
			tooltipContent += `<br>Postcode: ${revSuburbFind.postcode}`;
		}
		this.popup.setLngLat(coordinates).setHTML(tooltipContent).addTo(this.map);
	};

	onMapHoverEnd = () => {
		this.map.getCanvas().style.cursor = '';
		this.popup.remove();
	};

	//finds any existing feature that is in hover state and removes
	//all styling and resets state so that a fresh area can be hovered onto
	cleanExistingHoverArea = () => {
		if (this.hoverState.suburb) {
			this.updateSuburbMapFeatureState(this.hoverState.suburb, { hover: false });
		}
	};

	updateSuburbMapFeatureState = (absSuburbId: string, options: TSuburbMapFeatureState = { clicked: true }) => {
		this.map.setFeatureState(
			{
				source: 'suburbs',
				sourceLayer: Config.MapTilesets.AustraliaSuburbs.SourceLayer,
				id: absSuburbId
			},
			options
		);
	};

	fitMapToArea = (zenuSuburbs: IZenuSuburb[]) => {
		const bounds: IGeoBound[] = [];
		zenuSuburbs.forEach((zenuSuburb: IZenuSuburb) => {
			const bound = zenuSuburb.bounds;
			if (bound && bound.north && bound.south && bound.east && bound.west) {
				bounds.push(bound);
			}
		});
		if (bounds.length === 0) {
			return;
		}
		const northBound = [...bounds].reduce(function (prev, curr) {
			return prev.north > curr.north ? prev : curr;
		});
		const southBound = [...bounds].reduce(function (prev, curr) {
			return prev.south < curr.south ? prev : curr;
		});
		const eastBound = [...bounds].reduce(function (prev, curr) {
			return prev.east > curr.east ? prev : curr;
		});
		const westBound = [...bounds].reduce(function (prev, curr) {
			return prev.west < curr.west ? prev : curr;
		});
		this.fitMapToBoundsWithPadding({
			north: northBound.north,
			south: southBound.south,
			east: eastBound.east,
			west: westBound.west
		});
	};

	fitMapToBoundsWithPadding = (bounds: IGeoBound, padding = 20) => {
		const mapboxBounds = UtilityService.getMapBoxBoundingBox(bounds);
		this.map.fitBounds(mapboxBounds, { padding });
	};
}

ViewSuburbRegion.contextType = NotificationContext;

export default ViewSuburbRegion;
