import { CircularProgress, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import React from 'react';

interface IProps {
	title?: string;
	message: string;
	okBtnText?: string;
	onClickOkay: () => void;
}

const useStyles = makeStyles((theme) => ({
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	}
}));

const InfoModal: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const classes = useStyles();
	return (
		<Dialog open onClose={props.onClickOkay} disableBackdropClick>
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent>
				<Typography variant="body2">{props.message}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClickOkay} color="primary">
					{props.okBtnText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

InfoModal.defaultProps = {
	title: 'Info',
	okBtnText: 'Okay'
};

export default InfoModal;
