import React from 'react';
import AreaLoader from './AreaLoader';

interface IProps {
	isLoading: boolean;
}

const AreaLoaderContainer: React.FunctionComponent<IProps> = (props): JSX.Element => {
	return (
		<>
			{props.isLoading && <AreaLoader status={true} />}
			{!props.isLoading && props.children}
		</>
	);
};

export default AreaLoaderContainer;
