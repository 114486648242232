import mapboxgl, { LngLatBoundsLike, MapboxGeoJSONFeature, PointLike } from 'mapbox-gl';
import { IGeoBound, IMapboxMouseMoveEvent, ITooltipContent } from 'shared/types/Types';
import { debounce as lodashDebounce } from 'lodash';
import { format, utcToZonedTime } from 'date-fns-tz';
import { format as dateFormat } from 'date-fns';
import queryStringParser from 'query-string';

const getMapBoxBoundingBox = (bounds: IGeoBound) => {
	const southWest = new mapboxgl.LngLat(bounds.west, bounds.south);
	const northEast = new mapboxgl.LngLat(bounds.east, bounds.north);
	const boundingBox = new mapboxgl.LngLatBounds(southWest, northEast);
	return boundingBox;
};

const getAusStateBounds = () => {
	return {
		/* south: -55.3228175,
		north: -9.0882278,
		west: 72.2460938,
		east: 168.2249543 */
		west: 113.338953078,
		south: -43.6345972634,
		east: 153.569469029,
		north: -10.6681857235
	};
};
const queryStringify = function queryStringify<T>(queryParams: T) {
	const filters = removeNullAndEmptyKeysFromObject(queryParams);
	return queryStringParser.stringify(filters);
};

export const queryParse = (queryString: string): queryStringParser.ParsedQuery<string> => {
	return queryStringParser.parse(queryString);
};

const removeNullAndEmptyKeysFromObject = function removeNullAndEmptyKeysFromObject(obj: any) {
	if (typeof obj !== 'object') {
		throw Error('Argument should be of type object');
	}

	const newObj: any = {};
	for (const key in obj) {
		if (typeof obj[key] != 'undefined' && obj[key]) {
			newObj[key] = obj[key];
		}
		if (obj[key] !== '' && obj[key] !== null) {
			newObj[key] = obj[key];
		}
	}
	return newObj;
};

/**
 * 
If the feature is polygon then we will have below values in coordinates attribute 
coordinates:[
    [number,number],
    [number,number],
    [number,number]
]

If feature is Multipolygon then we have below values
//type 2
coordinates:[
    [
        [number,number],
        [number,number],
        [number,number],
        [number,number]
    ],[
        [number,number],
        [number,number]
    ]
] 

In order to get bounding box we need to prepare coordinates array of the form

coordinates:[
	[number,number]
]

getBoundingBoxFromPolygonFeatures does this job
 */
const getBoundingBoxFromPolygonFeatures = (feature: MapboxGeoJSONFeature[]) => {
	const coordinates = feature
		.flatMap((f) => {
			if (f.geometry.type !== 'Polygon' && f.geometry.type !== 'MultiPolygon') {
				throw new Error('All features must be of type Polygon or Multipolygon to derive a bounding box');
			}

			if (f.geometry.type === 'MultiPolygon') {
				return f.geometry.coordinates.flatMap((c) => c);
			}

			return f.geometry.coordinates;
		})
		.flat(1) as [[number, number], [number, number]];

	//below code copied from - https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
	return coordinates.reduce((bounds: { extend: (arg0: any) => any }, coord: any) => {
		return bounds.extend(coord);
	}, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])) as LngLatBoundsLike;
};

const sleep = (ms = 500) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debounce = (cb: any, waitFor = 500) => {
	return lodashDebounce(cb, waitFor);
};

const getBoundingboxFromMapMouseEvent = (e: IMapboxMouseMoveEvent) => {
	/**
	 * Note - all the PointLike type declaration below just to get bbox were done because
	 * typescript was complaining of the type mismatch when calling queryRenderedFeatures
	 */
	const ns: PointLike = [e.point.x - 5, e.point.y - 5];
	const ew: PointLike = [e.point.x + 5, e.point.y + 5];
	const bbox: [PointLike, PointLike] = [ns, ew];
	return bbox;
};

const removeUndefinedKeysFromObject = (obj: Record<string, any>): Record<string, any> => {
	const objectKeys = Object.keys(obj);

	const keysWithoutUndefinedValues = objectKeys.filter((key) => obj[key]);

	const objWithoutUndefinedValues: Record<string, any> = {};
	keysWithoutUndefinedValues.forEach((key) => (objWithoutUndefinedValues[key] = obj[key]));

	return objWithoutUndefinedValues;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function replaceEmptyAttributeValuesByNull(data: { [key: string]: any }) {
	Object.keys(data).forEach((key) => {
		if (typeof data[key] === 'string' && data[key] === '') {
			data[key] = null;
		}

		if (Array.isArray(data[key]) && data[key].length > 0) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			data[key].forEach((nestedObject: any) => {
				if (typeof nestedObject === 'object') {
					nestedObject = replaceEmptyAttributeValuesByNull(nestedObject);
				}
			});
		}
	});

	return data;
}

//we store booleans as integers 1 and 0 in mysql and hence on frontend
const getAreaStatusFromDatabaseValue = (dbStatus: number) => {
	//todo
};

function immutableUpdateObjectInArray<T>(array: T[], action: { item: T; index: number }) {
	return array.map((item, index) => {
		if (index !== action.index) {
			// This isn't the item we care about - keep it as-is
			return item;
		}

		// Otherwise, this is the one we want - return an updated value
		return {
			...item,
			...action.item
		};
	});
}

const getHtmlTableForTooltip = (items: ITooltipContent[]) => {
	let html = `<table style="border-collapse: collapse;width: 100%;margin:0px;padding:0px;">		
		<tbody>`;

	items.forEach((i) => {
		html += `<tr>
			<td style="color:#8F8F8F;border: 1px solid #eee;padding:0">${i.label}</td>
			<td style="border: 1px solid #eee;padding-left:2px">${i.value}</td>
			</tr>`;
	});

	html += `</tbody></table>`;
	return html;
};

const getTimeAndDateWithTimeZone = (date: string, formate: string) => {
	return dateFormat(new Date(new Date(date).toLocaleString('en-US', { timeZoneName: 'short' })), formate);
};

const getUtcDatetimeForADay = (date: Date) => {
	//now
	let from = new Date();
	//if date is passed in args then we treat that date as from date
	if (date) {
		from = new Date(date);
	}
	/*
	as we strictly need date strings for 24 hours starting with `from` date
	we need to set minutes,seconds and hours to 0
	For more info - https://github.com/team-avesta/wiki/tree/master/engineering/databases/dates_and_timezones_in_postgres
	*/
	from.setMinutes(0);
	from.setHours(0);
	from.setSeconds(0);

	const to = new Date(from);
	to.setDate(from.getDate() + 1);
	to.setSeconds(to.getSeconds() - 1);
	return {
		from: from.toISOString(),
		to: to.toISOString()
	};
};

const getDifferenceBetweenDatesInDays = (startDate: Date, endDate: Date) => {
	const date1: any = new Date(startDate);
	const date2: any = new Date(endDate);
	const diffTime = Math.abs(date2 - date1);
	return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

const UTCDateToDate = (date: string, fmt: string, tz: string) => {
	return format(utcToZonedTime(date, tz), fmt, { timeZone: tz });
};

const getDateForCSVName = () => {
	return dateFormat(new Date(), 'ddMMyyyyHHmmss');
};

const downloadCSVFile = (uri: string, fileName: string) => {
	const link = document.createElement('a');
	link.download = fileName;
	link.href = uri;
	link.click();
};
const capitalizeFirstLetter = (name: string) => {
	return name.charAt(0).toUpperCase() + name.slice(1);
};

const getTransactionAreaLatLong = (bound: IGeoBound, type: string) => {
	if (type === 'sa1') {
		return {
			north: bound.north - 0.0115,
			east: bound.east - 0.0115,
			south: bound.south + 0.0115,
			west: bound.west + 0.0115
		};
	}
	return {
		north: bound.north - 0.5,
		east: bound.east - 0.5,
		south: bound.south + 0.5,
		west: bound.west + 0.5
	};
};

const UtilityService = {
	getMapBoxBoundingBox,
	sleep,
	getBoundingBoxFromPolygonFeatures,
	debounce,
	getBoundingboxFromMapMouseEvent,
	removeUndefinedKeysFromObject,
	replaceEmptyAttributeValuesByNull,
	getAreaStatusFromDatabaseValue,
	immutableUpdateObjectInArray,
	getHtmlTableForTooltip,
	getTimeAndDateWithTimeZone,
	getUtcDatetimeForADay,
	getDifferenceBetweenDatesInDays,
	UTCDateToDate,
	downloadCSVFile,
	getDateForCSVName,
	queryStringify,
	queryParse,
	capitalizeFirstLetter,
	getTransactionAreaLatLong,
	getAusStateBounds
};

export default UtilityService;
