import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab/Autocomplete';
import { CircularProgress, Divider, FormHelperText, Grid, InputLabel, OutlinedInput } from '@material-ui/core';
import { IAgency } from 'shared/types/Types';
import ConfirmModalWithContent from 'shared/components/ConfirmModalWithContent';
import UtilityService from 'shared/services/UtilityService';
import CommonApiService from 'shared/services/CommonApiService';
import { IAppraisalEnquirySendEmailSubmit } from './AppraisalEnquiryTypes';

interface IProps {
	onClickCancelSubmission: () => void;
	onClickSubmitForwardEnquiryAction: (data: IAppraisalEnquirySendEmailSubmit) => void;
	isSubmitting?: boolean;
}

const SendEmailModal: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const [agencies, setAgencies] = React.useState<IAgency[]>([]);
	const [selAgency, setSelAgency] = React.useState<IAgency | null>(null);
	const [email, setEmail] = React.useState<string | null>(null);
	const [emailError, setEmailError] = React.useState({
		isError: false,
		errorMessage: ''
	});
	const saleNotesRef = React.useRef<HTMLInputElement>(null);
	const [agencyAutocompleteInputValue, setAgencyAutocompleteInputValue] = React.useState('');
	const [agencyValidationError, setAgencyValidationError] = React.useState({ isError: false, errorMessage: '' });
	const [isLoading, setIsLoading] = React.useState(false);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getAgencyBySearchText = React.useCallback(
		UtilityService.debounce(async (searchText: string) => {
			try {
				const queryParams = {
					searchText
				};
				const res = await CommonApiService.getAgencyBySearchText(queryParams);
				setAgencies(res);
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		}),
		[]
	);

	const onChangeAgencyAutocompleteInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		setAgencyAutocompleteInputValue(value);
		if (reason !== 'input') {
			return;
		}

		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (value.trim().length < 3) {
			return;
		}

		setIsLoading(true);
		getAgencyBySearchText(value);
	};
	const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmailError({
			isError: false,
			errorMessage: ''
		});
		if (!event.target.value) {
			setEmail(null);
			return;
		}
		setEmail(event.target.value);
		setSelAgency(null);
		setAgencyAutocompleteInputValue('');
	};

	const onChangeAgencyAutocomplete = (ev: unknown, value: IAgency | null, reason: AutocompleteChangeReason) => {
		if (!value) {
			return;
		}
		setAgencyValidationError({
			isError: false,
			errorMessage: ''
		});
		setSelAgency(value);
		setEmail(null);
	};

	const isValidEmail = (email: string): boolean => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	};

	const isValidationPassing = () => {
		let returnValue = true;
		if (!email && !selAgency) {
			setAgencyValidationError({
				isError: true,
				errorMessage: 'Please select a agency or Email to Send mail'
			});
			returnValue = false;
		}
		if (email && !isValidEmail(email)) {
			setEmailError({
				isError: true,
				errorMessage: 'Please enter a valid email.'
			});
			returnValue = false;
		}

		return returnValue;
	};

	const onClickSubmit = () => {
		const isValid = isValidationPassing();
		if (!isValid) {
			return;
		}
		const submitPayload = getPayloadForSubmission();
		props.onClickSubmitForwardEnquiryAction(submitPayload);
	};

	const getPayloadForSubmission = (): IAppraisalEnquirySendEmailSubmit => {
		return {
			...(email && { email: email }),
			...(selAgency && { agencyId: selAgency.agency_id }),
			...(saleNotesRef.current?.value && { note: saleNotesRef.current?.value })
		};
	};

	return (
		<ConfirmModalWithContent
			cancelBtnText="Cancel"
			title="Send Email"
			onClickCancel={props.onClickCancelSubmission}
			onClickConfirm={onClickSubmit}
			size={'xs'}
			isLoading={props.isSubmitting}
		>
			<Grid className="stretch">
				<InputLabel className="modal-label">Enter Email</InputLabel>
				<OutlinedInput
					value={email}
					onChange={onChangeEmail}
					style={{ marginTop: 5 }}
					fullWidth
					error={emailError.isError}
					placeholder="Enter the Email address"
					labelWidth={0}
					className="modal-textfield"
				/>
				{emailError.isError && <FormHelperText style={{ color: '#ff1744' }}>{emailError.errorMessage}</FormHelperText>}
				<Grid container alignItems="center" style={{ marginTop: 15 }}>
					<Divider style={{ width: '42%', marginRight: 20 }} /> OR <Divider style={{ width: '42%', marginLeft: 20 }} />
				</Grid>
				<InputLabel className="modal-label">Search Agency</InputLabel>
				<Autocomplete
					inputValue={agencyAutocompleteInputValue}
					onInputChange={onChangeAgencyAutocompleteInput}
					size="small"
					className="autoComplete"
					onChange={onChangeAgencyAutocomplete}
					options={agencies}
					getOptionLabel={(option) => `(${option.agency_id}) ${option.name}`}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							error={agencyValidationError.isError}
							placeholder="Start typing to get search suggestions"
							helperText={agencyValidationError.isError && agencyValidationError.errorMessage}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{isLoading ? <CircularProgress color="secondary" size={20} /> : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								)
							}}
						/>
					)}
				/>

				<InputLabel className="modal-label">Notes</InputLabel>
				<OutlinedInput
					inputRef={saleNotesRef}
					style={{ marginTop: 5 }}
					fullWidth
					placeholder="Enter notes related to this action here"
					multiline
					rowsMax={4}
					className="modal-textfield"
					labelWidth={0}
				/>
			</Grid>
		</ConfirmModalWithContent>
	);
};

export default SendEmailModal;
