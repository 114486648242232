import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoggedInUserContext } from 'shared/providers/LoggedInUserProvider';
import PrivateLayout from 'shared/components/PrivateLayout';
import ManageCda from 'modules/manage-cda/ManageCda';
import ManageCdar from 'modules/manage-cdar/ManageCdar';
import ViewNeighbouringSuburbs from 'modules/view-neighbouring-suburbs/ViewNeighbouringSuburbs';
import SalesAdmin from 'modules/sales-admin/AdminSales';
import SalesStaff from 'modules/staff-sales/StaffSales';
import Settings from 'modules/settings/Settings';
import Sa1PerformanceReport from 'modules/reports/sa1-performance-report/Sa1PerformanceReport';
import Sa1StatusReport from 'modules/reports/sa1-status-report/Sa1StatusReport';
import HistoricalReport from 'modules/reports/historical-report/HistoricalReport';
import MapServiceSyncReport from 'modules/reports/map-service-sync-report/MapServiceSyncReport';
import Concierge from 'modules/concierge/appraisalEnquiry/AppraisalEnquiry';
import CommonApiService from 'shared/services/CommonApiService';
import { ILoggedInUser } from 'shared/types/Types';
import FullAreaLoader from 'shared/components/FullAreaLoader';

export default function Layout(): JSX.Element {
	const { setLoggedInUser } = useContext(LoggedInUserContext);
	const [user, setUser] = React.useState<ILoggedInUser | null>(null);
	const [isLoading, setIsLoading] = React.useState(false);

	useEffect(() => {
		const getUser = async () => {
			try {
				setIsLoading(true);
				const user = await CommonApiService.getUser();
				setUser(user);
				setLoggedInUser(user);
			} catch (error) {
				throw new Error(error);
			} finally {
				setIsLoading(false);
			}
		};
		getUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getRoutesByRole = () => {
		if (!user) {
			return null;
		}
		if (user?.role === 'Sales') {
			return (
				<PrivateLayout>
					<Switch>
						<Route exact path="/ui/sales" component={SalesStaff}></Route>
						<Route exact path="/ui/settings" component={Settings}></Route>
						<Route exact path="/ui/report/sa1-performance-report" component={Sa1PerformanceReport}></Route>
						<Redirect to="/ui/sales" />
					</Switch>
				</PrivateLayout>
			);
		}

		return (
			<PrivateLayout>
				<Switch>
					<Route exact path="/ui/manage/cda" component={ManageCda}></Route>
					<Route exact path="/ui/manage/cdar" component={ManageCdar}></Route>
					<Route exact path="/ui/view/neighbouring-suburbs" component={ViewNeighbouringSuburbs}></Route>
					<Route exact path="/ui/sales-admin" component={SalesAdmin}></Route>
					<Route exact path="/ui/settings" component={Settings}></Route>
					<Route exact path="/ui/report/sa1-performance-report" component={Sa1PerformanceReport}></Route>
					<Route exact path="/ui/report/sa1-status-report" component={Sa1StatusReport}></Route>
					<Route exact path="/ui/report/historical-report" component={HistoricalReport}></Route>
					<Route exact path="/ui/report/map-service-sync-report" component={MapServiceSyncReport}></Route>
					<Route exact path="/ui/concierge/appraisal-enquiry" component={Concierge}></Route>
					<Redirect to="/ui/sales-admin" />
				</Switch>
			</PrivateLayout>
		);
	};
	return <>{user && !isLoading ? <>{getRoutesByRole()}</> : <FullAreaLoader />}</>;
}
