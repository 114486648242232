/**
 * Author - Vinay
 * Source of this provider - https://medium.com/yld-blog/handling-global-notifications-with-reacts-context-api-7d8135510d50
 */

import React, { useState, useCallback } from 'react';
import { ENotificationTypes, INotificationContent } from 'shared/types/Types';

interface IProps {
	children?: React.ReactNode;
}

export interface INotificationContext {
	notifications: INotificationContent[];
	addNotification: (notification: { message: string; type: ENotificationTypes }) => void;
	removeNotification: (notificationId: number) => void;
}

export const NotificationContext = React.createContext<INotificationContext>({
	notifications: [],
	addNotification: () => {
		console.log('add notification');
	},
	removeNotification: () => {
		console.log('remove notification');
	}
});

const NotificationContentProvider: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const [notifications, setNotifications] = useState<INotificationContent[]>([]);

	const removeNotification = (notificationId: number) => {
		if (notifications.length === 0) {
			return;
		}
		setNotifications(notifications.filter((notification) => notification.id !== notificationId));
	};

	const addNotification = (notification: { message: string; type: ENotificationTypes }) => {
		setNotifications([
			...notifications,
			{ id: new Date().getTime(), message: notification.message, type: notification.type }
		]);
	};

	const contextValue = {
		notifications,
		addNotification,
		removeNotification
		/* addNotification: useCallback((notification) => addNotification(notification), []),
		removeNotification: useCallback((notificationId) => removeNotification(notificationId), []) */
	};

	return <NotificationContext.Provider value={contextValue}>{props.children}</NotificationContext.Provider>;
};

export default NotificationContentProvider;
