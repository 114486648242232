import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { IMapSyncJob } from 'shared/types/Types';
import LoadingSpinner from './LoadingSpinner';

interface IProps {
	job: IMapSyncJob;
}
const MapSyncInProgressMessage: React.FunctionComponent<IProps> = (props: IProps) => {
	return (
		<Grid alignItems="center" container direction="row" style={{ minHeight: 80 }}>
			<Grid item xs={4}>
				<LoadingSpinner hideMessage={true} />
			</Grid>
			<Grid item xs={8}>
				<p style={{ margin: 0 }}>Map Sync is in progress</p>
				<p style={{ margin: 0 }}>Job Type: {props.job.type}</p>
				<p style={{ margin: 0 }}>Job submitted at: {props.job.started_at}</p>
				{/* <Typography variant="h6">Map Sync is in progress</Typography>
				<Typography variant="caption">Job Type: {props.job.type}</Typography>
				<Typography variant="caption">Job submitted at: {props.job.started_at}</Typography> */}
			</Grid>
		</Grid>
	);
};

export default MapSyncInProgressMessage;
