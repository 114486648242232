import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, Grid, ListItemText, makeStyles, Switch, TextField, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/ClearOutlined';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import RevAreaList from 'shared/components/RevAreaList';
import { ERevAreaSaleStatus, ICdar, ISa1, ISa1InfoView } from 'shared/types/Types';
import * as yup from 'yup';

interface IProps {
	initialValues: ICdar;
	onClickSave: (cdar: ICdar) => void;
	onClickRemoveSa1FromCdar: (sa1: ISa1) => void;
	onClickCancel: () => void;
	onSa1ListItemHoverStart?: (sa1: ISa1) => void;
	onSa1ListItemHoverEnd?: (sa1: ISa1) => void;
}

interface formValidation {
	name: boolean;
}

interface IFormikForm {
	name: string;
	description: string;
	is_royalty: boolean;
}

const schema = yup.object().shape({
	name: yup.string().required(),
	description: yup.string(),
	is_royalty: yup.boolean()
});

const ManageCdarForm: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const { initialValues } = props;
	const [error, setError] = React.useState<formValidation>({ name: false });
	const sa1Ref = React.useRef(initialValues.sa1s);
	const { control, formState, getValues } = useForm({
		defaultValues: { ...initialValues },
		resolver: yupResolver(schema)
	});
	const { isDirty } = formState;
	const onSubmit = () => {
		const data = getValues() as IFormikForm;
		if (!data.name) {
			setError({ name: true });
			return;
		}
		const isFormDirty = () => {
			//react-hook-form does not detect dirtiness of form with material ui switch
			//hence this custom logic
			if (data.is_royalty !== initialValues.is_royalty) {
				return true;
			}

			//suburbs are not a part of react-hook-form state, so we need manual maintenance whenever
			//there are changes in suburbs
			//check 1 - if length is unequal, changes have been made to suburbs
			if (initialValues.sa1s.length !== sa1Ref.current.length) {
				return true;
			}

			//check 2 - if length is equal, we need to match them one by one
			for (let i = 0; i < initialValues.sa1s.length; i++) {
				const found = sa1Ref.current.find((refSub) => refSub.sa1_code === initialValues.sa1s[i].sa1_code);
				if (!found) {
					return true;
				}
			}

			return false;
		};
		//submit form only if dirty
		if (isFormDirty() || isDirty) {
			props.onClickSave({
				...initialValues,
				...data
			});
		}
	};
	const classes = useStyles();
	//name attribute is required by RevAreaList
	const sa1List = initialValues.sa1s.map((sa1) => {
		return {
			...sa1,
			name: sa1.sa1_code.toString(),
			key: sa1.sa1_code.toString()
		};
	});
	const sa1ListRowMouseEnter = (sa1: ISa1) => {
		if (props.onSa1ListItemHoverStart) {
			props.onSa1ListItemHoverStart(sa1);
		}
	};

	const sa1ListRowMouseLeave = (sa1: ISa1) => {
		if (props.onSa1ListItemHoverEnd) {
			props.onSa1ListItemHoverEnd(sa1);
		}
	};
	const getAreaText = (area: ISa1InfoView) => {
		if (area.status) {
			return `${area.name} - ${area.status}`;
		}
		return area.name;
	};
	return (
		<Grid container item xs direction="column">
			<form noValidate>
				<Grid container item direction="column" className="stretch" style={{ maxHeight: 'calc(100vh - 235px)' }}>
					<Grid item xs container direction="column">
						<FormControl>
							<Controller
								as={
									<TextField
										error={error.name ? true : false}
										helperText={error.name ? 'CDAR name is required' : ''}
										placeholder="Enter CDAR Name"
										label="Name"
									/>
								}
								name="name"
								control={control}
							/>
						</FormControl>

						<FormControl>
							<Controller
								as={
									<TextField
										style={{ marginTop: 5 }}
										multiline
										rowsMax={4}
										label="Description"
										placeholder="Enter CDAR Description"
									/>
								}
								name="description"
								control={control}
							/>
						</FormControl>

						{!initialValues.is_default && (
							<Controller
								render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
									<Grid container justify="space-between" className="mt5 mb5">
										<Typography color="secondary" variant="body1">
											Is Royalty ?
										</Typography>
										<Switch
											color="primary"
											size="small"
											onChange={(e) => {
												onChange(e.target.checked);
											}}
											checked={value}
											inputRef={ref}
											value={value}
										/>
									</Grid>
								)}
								name="is_royalty"
								control={control}
							/>
						)}
						{sa1List.length > 0 && (
							<>
								<Typography color="secondary" variant="caption">
									Sa1 in {initialValues.name}
								</Typography>

								<RevAreaList
									height="calc(100vh - 435px)"
									areaList={sa1List}
									onHoverListStart={sa1ListRowMouseEnter}
									onHoverListEnd={sa1ListRowMouseLeave}
									rowContent={(sa1) => {
										return (
											<>
												<ListItemText primary={getAreaText(sa1)} />
												{sa1.status !== ERevAreaSaleStatus.Sold && (
													<ClearIcon
														color="secondary"
														className={classes.sa1ListItemDeleteBtn}
														onClick={() => {
															props.onClickRemoveSa1FromCdar(sa1);
														}}
													/>
												)}
											</>
										);
									}}
								/>
							</>
						)}
					</Grid>

					{/** form save and cancel buttons */}
					<Grid item style={{ marginTop: 5 }}>
						<Button onClick={onSubmit} disableElevation size="small" color="primary">
							SAVE
						</Button>
						<Button disableElevation size="small" onClick={props.onClickCancel}>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	error: {
		color: theme.palette.error.light,
		display: 'inline-block',
		fontSize: 12
	},
	sa1ListContainer: {
		marginTop: 10,
		overflowY: 'auto',
		overflowX: 'hidden'
	},
	sa1List: {
		//maxHeight: '100%',
		backgroundColor: '#efefef'
	},
	sa1ListItemDeleteBtn: {
		cursor: 'pointer'
	}
}));

export default ManageCdarForm;
