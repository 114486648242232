import React from 'react';
import { Grid, LinearProgress } from '@material-ui/core';

interface IProps {
	showLoader: boolean;
}

const LoadingLinear: React.FunctionComponent<IProps> = (props): JSX.Element => {
	return <Grid style={{ height: 5 }}>{props.showLoader && <LinearProgress />}</Grid>;
};

export default LoadingLinear;
