/**
 * Author - Vinay
 * This file is meant to be used for showing app wide notifications using a notification component
 * and notification provider
 * Source - https://medium.com/yld-blog/handling-global-notifications-with-reacts-context-api-7d8135510d50
 */

import { useContext } from 'react';
import { NotificationContext } from 'shared/providers/NotificationProvider';

function UseNotification() {
	const { notifications, addNotification, removeNotification } = useContext(NotificationContext);

	return { notifications, addNotification, removeNotification };
}

export default UseNotification;
