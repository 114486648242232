import {
	Grid,
	Paper,
	Slide,
	LinearProgress,
	Typography,
	Divider,
	Button,
	FormControl,
	TextField,
	CircularProgress
} from '@material-ui/core';
import Map from 'shared/components/Map';
import React, { Component } from 'react';
import AreaLoaderContainer from 'shared/components/AreaLoaderContainer';
import {
	ELoadingIndicatorStatus,
	ERevAreaSaleType,
	IAreaSaleSubmissionError,
	ISalesAreaListItem,
	ISaleVerificationModalSubmit,
	IMapLegend,
	IMapSystemSetting,
	IProspectiveCustomer,
	IMapColor,
	ISalesAreaSearch
} from 'shared/types/Types';
import Sa1AreaList from 'shared/hoc/sales/SelectedSa1CdarAreaList';
import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab';
import SalesSubmissionErrors from 'shared/hoc/sales/TransactionSubmissionErrors';
import MapLegend from 'shared/components/MapLegend';
import TransactionModal from 'shared/hoc/sales/TransactionModal';
/* import MapSearchInput from 'shared/components/MapSearchInput'; */
import AbsolutePositionContainer from 'shared/components/AbsolutePositionContainer';
import AdminSalesWrapper from 'shared/hoc/sales/AdminSalesWrapperHoc';
import Sa1AreaListToolbar from 'shared/hoc/sales/SelectdSa1CdarAreaListToolbar';
import ViewMyReservationsModal from 'shared/hoc/sales/ReservationsModal';
import FullAreaLoader from 'shared/components/FullAreaLoader';
import { LoggedInUserContext } from 'shared/providers/LoggedInUserProvider';

enum ESa1SalesLoaders {
	SalesTransactionSubmitLoader = 'sales-transaction-submit-loader',
	ShowSa1SuburbAutoCompleteLoader = 'show-sa1-suburb-autocomplete-loader'
}

type ESa1LoadingIndicatorsStatus = ELoadingIndicatorStatus | ESa1SalesLoaders;

enum ESalesModals {
	SubmissionErrorModal = 'submission-error-modal',
	SubmissionVerificationModal = 'submission-Verification-modal',
	AgencyCdaSelectionModal = 'agency-cda-selection-modal',
	ReservationSelectModal = 'reservation-selection-modal',
	None = 'none'
}

interface IProps {
	autocomplete: {
		agency: string;
		salesPerson: string;
		prospectiveCustomer: string;
		sa1Suburb: string;
	};
	onMapLoadComplete: () => void;
	isShowMap: boolean;
	loadingIndicator: ESa1LoadingIndicatorsStatus;
	getMapLegend: () => IMapLegend[];
	salesAreaList: ISalesAreaListItem[];
	reservations: IProspectiveCustomer[];
	isModalLoading: boolean;
	onClickRemoveSelectedAreaListItem: (clickedArea: ISalesAreaListItem) => void;
	onClickSelectedAreaListItem: (clickedArea: ISalesAreaListItem) => void;
	onClickDeSelectAllSalesAreas: () => void;
	onClickExtendReservation: () => void;
	onClickReserveArea: () => void;
	onClickReleaseArea: () => void;
	onClickRemoveAllSelectedAreaListItems: () => void;
	onAreaListItemMouseOver: (area: ISalesAreaListItem) => void;
	onAreaListItemMouseOut: (area: ISalesAreaListItem) => void;
	onClickSelectAllSalesAreas: () => void;
	onClickCopySA1: () => void;
	activeModal: ESalesModals;
	mapColors: IMapColor;
	mapSystemsConfig: IMapSystemSetting | null;
	submissionErrors: IAreaSaleSubmissionError[];
	areasEligibleForSubmission: ISalesAreaListItem[]; //filtered out and validated areas that will be sent to backend for sale transaction
	onGoingAreaSaleActionType: ERevAreaSaleType | null;
	onClickCancelSubmission: () => void;
	onClickSubmitSaleAction: (data: ISaleVerificationModalSubmit) => void;
	onCloseSubmissionErrorsModal: () => void;
	viewReservations: (userId: number) => void;
	getSa1ByTransactionIdForSalesPerson: () => void;
	getTotalSa1sFromList: () => number;
	getTotalSelectedSa1sFromList: () => number;
	onChangeSa1SuburbAutocompleteInput: (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => void;
	onChangeSa1SuburbAutocomplete: (
		ev: unknown,
		value: ISalesAreaSearch | string | null,
		reason: AutocompleteChangeReason
	) => void;

	sa1SuburbOptions: ISalesAreaSearch[];
}

class Sales extends Component<IProps, unknown> {
	context!: React.ContextType<typeof LoggedInUserContext>;
	render() {
		const totalSa1s = this.props.getTotalSa1sFromList();
		return (
			<Grid direction="column" container>
				{this.props.loadingIndicator === ELoadingIndicatorStatus.ShowFullAreaLoader && <FullAreaLoader />}
				<Paper elevation={0} className="paper-container admin-sales-sidebar" style={{ width: '100%', maxHeight: 48 }}>
					<Grid container alignItems="center" style={{ paddingTop: 5 }}>
						<Grid item container style={{ width: 'auto', marginRight: 10 }}>
							<Typography color="secondary" variant="h6" style={{ fontSize: '0.975rem' }}>
								Sales
							</Typography>
						</Grid>
						<Divider orientation="vertical" flexItem style={{ backgroundColor: '#bdbdbd' }} />
						<Grid item style={{ marginRight: 15, width: 240, marginLeft: 10 }}>
							<FormControl style={{ width: '100%' }}>
								<Autocomplete
									freeSolo
									inputValue={this.props.autocomplete.sa1Suburb}
									onInputChange={this.props.onChangeSa1SuburbAutocompleteInput}
									size="small"
									onChange={this.props.onChangeSa1SuburbAutocomplete}
									options={this.props.sa1SuburbOptions}
									getOptionLabel={(option) => option.text}
									renderOption={(option) => <Typography style={{ fontSize: '0.80rem' }}>{option.text}</Typography>}
									renderInput={(params) => (
										<TextField
											/* size="small" */
											{...params}
											label="Search SA1 or Postcode or Suburb"
											variant="outlined"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{this.props.loadingIndicator === ESa1SalesLoaders.ShowSa1SuburbAutoCompleteLoader ? (
															<CircularProgress color="secondary" size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
							</FormControl>
							{/* <MapSearchInput onSelectOption={this.props.onSelectOptionFromMapSearchBox} /> */}
						</Grid>
						<Grid item>
							<Button
								style={{ padding: '5px 15px' }}
								color="secondary"
								variant="outlined"
								onClick={() => {
									this.props.viewReservations(this.context.loggedInUser?.id as number);
								}}
							>
								View My Reservations
							</Button>
						</Grid>
					</Grid>
				</Paper>
				<Grid item xs container direction="column">
					<Grid item xs container direction="column">
						{this.props.loadingIndicator === ELoadingIndicatorStatus.ShowNavBarLoader && <LinearProgress />}
						<AreaLoaderContainer isLoading={!this.props.isShowMap}>
							<Grid
								container
								direction="column"
								style={{
									height: 'calc(100vh - 96px)',
									width: '100%'
								}}
							>
								<Grid item xs>
									<Map onMapLoadComplete={this.props.onMapLoadComplete}>
										<AbsolutePositionContainer>
											<Grid container direction="column">
												<Slide
													unmountOnExit
													direction="right"
													in={this.props.salesAreaList.length > 0}
													style={{ marginTop: 10, marginLeft: 10 }}
												>
													<Paper style={{ height: 400, width: 370 }}>
														<Sa1AreaListToolbar
															transactions={['reserve', 'extend_reservation', 'release', 'copySA1']}
															onClickExtendReservation={this.props.onClickExtendReservation}
															onClickReserve={this.props.onClickReserveArea}
															onClickCopySA1={this.props.onClickCopySA1}
															onClickRelease={this.props.onClickReleaseArea}
															numSelected={this.props.salesAreaList.filter((s) => s.selected).length}
															onClickRemoveAllAreas={this.props.onClickRemoveAllSelectedAreaListItems}
														/>
														<Grid className="scroll-v" style={{ height: totalSa1s !== 0 ? 300 : 335 }}>
															<Sa1AreaList
																mapColors={this.props.mapColors}
																onClickDeSelectAllAreas={this.props.onClickDeSelectAllSalesAreas}
																onClickSelectAllAreas={this.props.onClickSelectAllSalesAreas}
																onClickArea={this.props.onClickSelectedAreaListItem}
																areas={this.props.salesAreaList}
																onClickRemoveArea={this.props.onClickRemoveSelectedAreaListItem}
																onAreaMouseOver={this.props.onAreaListItemMouseOver}
																onAreaMouseOut={this.props.onAreaListItemMouseOut}
															/>
														</Grid>
														{totalSa1s !== 0 && (
															<Grid container direction="column">
																<Divider style={{ backgroundColor: '#bdbdbd' }} />
																<Grid item container justify="space-between" style={{ padding: 7 }}>
																	<Typography color="secondary" variant="h6" style={{ fontSize: '0.899rem' }}>
																		Total SA1(s) : {this.props.getTotalSa1sFromList()}
																	</Typography>
																	<Typography color="secondary" variant="h6" style={{ fontSize: '0.899rem' }}>
																		Selected SA1(s) : {this.props.getTotalSelectedSa1sFromList()}
																	</Typography>
																</Grid>
															</Grid>
														)}
													</Paper>
												</Slide>
											</Grid>
										</AbsolutePositionContainer>
									</Map>
								</Grid>
								<MapLegend items={this.props.getMapLegend()} />
							</Grid>
						</AreaLoaderContainer>

						{/* </Grid> */}
					</Grid>

					{this.props.activeModal === ESalesModals.SubmissionErrorModal && (
						<SalesSubmissionErrors
							errors={this.props.submissionErrors}
							onClose={this.props.onCloseSubmissionErrorsModal}
						/>
					)}

					{/** sales verification error modal */}
					{this.props.activeModal === ESalesModals.SubmissionVerificationModal && (
						<TransactionModal
							mapSystemsConfig={this.props.mapSystemsConfig}
							isSubmitting={this.props.loadingIndicator === ESa1SalesLoaders.SalesTransactionSubmitLoader}
							areas={this.props.areasEligibleForSubmission}
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							saleType={this.props.onGoingAreaSaleActionType!}
							onClickCancelSubmission={this.props.onClickCancelSubmission}
							onClickSubmitSaleAction={this.props.onClickSubmitSaleAction}
						/>
					)}
					{/** view my reservations modal */}
					{this.props.activeModal === ESalesModals.ReservationSelectModal && (
						<ViewMyReservationsModal
							reservations={this.props.reservations}
							onClickSubmit={this.props.getSa1ByTransactionIdForSalesPerson}
							onClickClose={this.props.onClickCancelSubmission}
							isModalLoading={this.props.isModalLoading}
						/>
					)}
				</Grid>
			</Grid>
		);
	}
}
Sales.contextType = LoggedInUserContext;

export default AdminSalesWrapper(Sales);
