import {
	DialogContent,
	Radio,
	Typography,
	makeStyles,
	TableHead,
	TableRow,
	TableCell,
	TableSortLabel,
	TableContainer,
	Table,
	TableBody,
	Divider
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { IProspectiveCustomer } from 'shared/types/Types';
import UtilityService from 'shared/services/UtilityService';
import FullAreaLoader from 'shared/components/FullAreaLoader';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	table: {
		borderCollapse: 'collapse',
		marginTop: 10,
		width: '100%'
	},
	tableHeader: {
		border: '1px solid #ccc',
		padding: 4,
		fontSize: '0.700rem'
	},
	tableCell: {
		border: '1px solid #ccc',
		padding: 5,
		fontSize: '0.750rem',
		textAlign: 'center'
	},
	tableRow: {
		'&hover': {
			background: '#eee'
		}
	},
	uppercase: {
		textTransform: 'uppercase'
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	},
	container: {
		maxHeight: 440
	}
}));

interface IProps {
	isModalLoading: boolean;
	prospectiveCustomers: IProspectiveCustomer[];
	prospectiveCustomerSearchText: string;
	onClickSubmit: (transactionId: number) => void;
	onClickClose: () => void;
}
interface HeadCell {
	id: string;
	label: string;
	is_short: boolean;
}
type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface Data {
	transaction_id: number;
	sales_person: string;
	area_type: string;
	transaction_type: string;
	sa1: number;
	date_time: string;
}

function createData(
	transaction_id: number,
	sales_person: string,
	area_type: string,
	transaction_type: string,
	sa1: number,
	date_time: string
): Data {
	return { transaction_id, sales_person, area_type, transaction_type, sa1, date_time };
}
interface ITableHeaderProps {
	classes: ReturnType<typeof useStyles>;
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
	order: Order;
	orderBy: string;
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: 12
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: 0,
			color: theme.palette.grey[500]
		}
	});

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

const headCells: HeadCell[] = [
	{ id: 'transaction_id', label: 'Id', is_short: true },
	{ id: 'sales_person', label: 'Sales person', is_short: false },
	{ id: 'area_type', label: 'Area Type', is_short: false },
	{ id: 'transaction_type', label: 'Transaction Type', is_short: false },
	{ id: 'sa1', label: 'SA1', is_short: true },
	{ id: 'date_time', label: 'Reserved at', is_short: true }
];
interface DialogTitleProps extends WithStyles<typeof styles> {
	children: React.ReactNode;
	onClose: () => void;
}

const ProspectiveCustomerListModal: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const classes = useStyles();
	const [selectedTransactionId, setSelectedTransactionId] = React.useState<number | null>(null);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<string>('');
	const [rows, setRows] = React.useState<Data[]>([]);

	useEffect(() => {
		setIsLoading(props.isModalLoading);
	}, [props.isModalLoading]);

	useEffect(() => {
		const record: Data[] = [];
		if (props.prospectiveCustomers.length > 0) {
			props.prospectiveCustomers.forEach((customer: IProspectiveCustomer) => {
				record.push(
					createData(
						customer.transaction_id,
						`${customer.firstname} ${customer.lastname}`,
						customer.area_type,
						UtilityService.capitalizeFirstLetter(customer.transaction_type),
						customer.sa1_count,
						customer.transaction_date
					)
				);
			});
		}

		setRows(record);
	}, [props.prospectiveCustomers]);

	const TableHeader = (props: ITableHeaderProps) => {
		const { classes, order, orderBy, onRequestSort } = props;
		const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};
		return (
			<TableHead>
				<TableRow className="material-table-header">
					<TableCell />
					{headCells.map((headCell, index) =>
						headCell.is_short ? (
							<TableCell key={index} align="center" sortDirection={orderBy === headCell.id ? order : false}>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id as keyof Data)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<span className={classes.visuallyHidden}>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</span>
									) : null}
								</TableSortLabel>
							</TableCell>
						) : (
							<TableCell key={index} align="center">
								{headCell.label}
							</TableCell>
						)
					)}
				</TableRow>
			</TableHead>
		);
	};

	const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
		const { children, classes, onClose, ...other } = props;
		return (
			<MuiDialogTitle disableTypography className={classes.root} {...other}>
				<Typography variant="h6" className="Modal-title">
					{children}
				</Typography>
				{onClose ? (
					<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
						<CloseIcon />
					</IconButton>
				) : null}
			</MuiDialogTitle>
		);
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedTransactionId(parseInt(event.target.value));
	};

	const onClickSubmit = () => {
		setIsLoading(true);
		props.onClickSubmit(selectedTransactionId as number);
	};

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	return (
		<Dialog aria-labelledby="customized-dialog-title" open={true} onClose={props.onClickClose} maxWidth={false}>
			{isLoading && <FullAreaLoader type="dialog" />}
			<DialogTitle
				onClose={props.onClickClose}
			>{`Search results for Prospective Customer - ${props.prospectiveCustomerSearchText}`}</DialogTitle>
			<Divider />
			<DialogContent style={{ padding: 15, marginTop: 10 }} className="modal-content">
				<TableContainer className={classes.container}>
					<Table
						stickyHeader
						className={classes.table}
						aria-labelledby="tableTitle"
						size="small"
						aria-label="enhanced table"
					>
						<TableHeader classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
						<TableBody className="material-table-body">
							{stableSort(rows as any, getComparator(order, orderBy)).map((row, index) => {
								return (
									<TableRow hover tabIndex={-1} key={index}>
										<TableCell padding="checkbox">
											<Radio
												name={row.transaction_id.toString()}
												checked={selectedTransactionId === row.transaction_id}
												value={row.transaction_id}
												onChange={handleChange}
												color="primary"
												size="small"
											/>
										</TableCell>
										<TableCell>{row.transaction_id}</TableCell>
										<TableCell>{row.sales_person}</TableCell>
										<TableCell align="center">{row.area_type}</TableCell>
										<TableCell align="center">{row.transaction_type}</TableCell>
										<TableCell>{row.sa1}</TableCell>
										<TableCell>{UtilityService.getTimeAndDateWithTimeZone(row.date_time as string, 'PP')}</TableCell>
									</TableRow>
								);
							})}
							{rows.length === 0 && (
								<TableRow>
									<TableCell colSpan={7} className="empty-record-cell">
										<Typography variant="body2" style={{ textAlign: 'center' }}>
											No Record found for this Prospective Customer.
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>

			<DialogActions>
				<Button variant="contained" className="cancel-btn" onClick={props.onClickClose}>
					CLOSE
				</Button>
				<Button variant="contained" className="submit-btn" onClick={onClickSubmit} disabled={!selectedTransactionId}>
					SUBMIT
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ProspectiveCustomerListModal;
