import {
	Typography,
	makeStyles,
	TableHead,
	TableRow,
	TableCell,
	TableSortLabel,
	TableContainer,
	Table,
	TableBody,
	Tooltip,
	Checkbox
} from '@material-ui/core';
import MapIcon from '@material-ui/icons/RoomOutlined';
import React, { useEffect } from 'react';
import UtilityService from 'shared/services/UtilityService';
import FullAreaLoader from 'shared/components/FullAreaLoader';
import { IAppraisalEnquiry } from './AppraisalEnquiryTypes';

const useStyles = makeStyles((theme) => ({
	table: {
		borderCollapse: 'collapse',
		width: '100%'
	},
	tableHeader: {
		border: '1px solid #ccc',
		padding: 3,
		fontSize: '1rem',
		fontWeight: 'normal'
	},
	tableCell: {
		border: '1px solid #ccc',
		padding: 5,
		fontSize: '0.750rem',
		textAlign: 'center'
	},
	tableRow: {
		'&hover': {
			background: '#eee'
		}
	},
	uppercase: {
		textTransform: 'uppercase'
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	},
	container: {
		maxHeight: 440
	},
	goOnMapBtn: {
		backgroundColor: '#fff',
		color: '#FF6400',
		fontSize: 20,
		height: 35,
		width: 35
	}
}));

interface IProps {
	isLoading: boolean;
	result: IAppraisalEnquiry[];
	onForwardEnquiry: () => void;
	onShowEnquiryOnMap: (gnafId: string) => void;
	onSelectEnquiry: (enquiries: string[]) => void;
}
interface HeadCell {
	id: string;
	label: string;
	is_short: boolean;
}
type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface Data {
	gnaf: string;
	sa1: number;
	address: string;
	state: string;
	suburb: string;
	date: string;
	latitude: number;
	longitude: number;
}

function createData(
	gnaf: string,
	sa1: number,
	address: string,
	state: string,
	suburb: string,
	date: string,
	latitude: number,
	longitude: number
): Data {
	return { gnaf, sa1, address, state, suburb, date, latitude, longitude };
}
interface ITableHeaderProps {
	classes: ReturnType<typeof useStyles>;
	order: Order;
	orderBy: string;
	numSelected: number;
	rowCount: number;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
}

const headCells: HeadCell[] = [
	{ id: 'gnaf', label: 'Gnaf', is_short: true },
	{ id: 'sa1', label: 'Sa1', is_short: false },
	{ id: 'address', label: 'Address', is_short: false },
	{ id: 'state', label: 'State', is_short: true },
	{ id: 'suburb', label: 'Suburb', is_short: false },
	{ id: 'date', label: 'Date', is_short: true }
];

const ResultTable: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const classes = useStyles();
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<string>('');
	const [rows, setRows] = React.useState<Data[]>([]);
	const [selected, setSelected] = React.useState<string[]>([]);

	useEffect(() => {
		const record: Data[] = [];
		if (props.result.length > 0) {
			props.result.forEach((result: any) => {
				record.push(
					createData(
						result.gnaf,
						result.sa1,
						result.address,
						result.state.name,
						result.suburb,
						result.date,
						result.latitude,
						result.longitude
					)
				);
			});
		}

		setRows(record);
	}, [props.result]);

	const TableHeader = (props: ITableHeaderProps) => {
		const { classes, order, orderBy, onRequestSort, numSelected, rowCount, onSelectAllClick } = props;
		const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};
		return (
			<TableHead>
				<TableRow>
					<TableCell padding="checkbox">
						<Checkbox
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{ 'aria-label': 'Select all Enquiries' }}
							color="primary"
						/>
					</TableCell>
					<TableCell style={{ padding: '6px 0px 0px 6px' }} />
					{headCells.map((headCell, index) =>
						headCell.is_short ? (
							<TableCell key={index} align="center" sortDirection={orderBy === headCell.id ? order : false}>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id as keyof Data)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<span className={classes.visuallyHidden}>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</span>
									) : null}
								</TableSortLabel>
							</TableCell>
						) : (
							<TableCell key={index} align="center">
								{headCell.label}
							</TableCell>
						)
					)}
				</TableRow>
			</TableHead>
		);
	};

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => n.gnaf);
			setSelected(newSelecteds);
			props.onSelectEnquiry(newSelecteds);
			return;
		}
		setSelected([]);
		props.onSelectEnquiry([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
		props.onSelectEnquiry(newSelected);
	};

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	return (
		<TableContainer>
			<Table className={classes.table} aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
				<TableHeader
					onSelectAllClick={handleSelectAllClick}
					rowCount={rows.length}
					numSelected={selected.length}
					classes={classes}
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
				/>
				<TableBody>
					{stableSort(rows as any, getComparator(order, orderBy)).map((row, index) => {
						const isItemSelected = isSelected(row.gnaf as string);
						const labelId = `enhanced-table-checkbox-${index}`;
						return (
							<TableRow hover tabIndex={-1} key={index}>
								<TableCell padding="checkbox">
									<Checkbox
										checked={isItemSelected}
										onClick={(event) => handleClick(event, row.gnaf as string)}
										inputProps={{ 'aria-labelledby': labelId }}
										color="primary"
									/>
								</TableCell>
								<TableCell
									style={{ padding: '6px 0px 0px 6px', cursor: 'pointer' }}
									onClick={() => props.onShowEnquiryOnMap(row.gnaf as string)}
								>
									<Tooltip title="Show enquiry on map">
										<MapIcon color="primary" />
									</Tooltip>
								</TableCell>
								<TableCell>{row.gnaf}</TableCell>
								<TableCell>{row.sa1}</TableCell>
								<TableCell>{row.address}</TableCell>
								<TableCell>{row.state}</TableCell>
								<TableCell>{row.suburb}</TableCell>
								<TableCell>{UtilityService.getTimeAndDateWithTimeZone(row.date as string, 'PP')}</TableCell>
							</TableRow>
						);
					})}
					{rows.length === 0 && (
						<TableRow>
							<TableCell colSpan={8} style={{ padding: 20 }}>
								<Typography variant="body2" style={{ textAlign: 'center' }}>
									No Enquiries found
								</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default ResultTable;
