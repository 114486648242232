import { merge as lodashMerge } from 'lodash';
import defaultConfig from './Config.default';
import stagingConfig from './Config.staging';
import uatConfig from './Config.uat';
import prodConfig from './Config.prod';

const Config = defaultConfig;
const NODE_ENV = process.env.REACT_APP_RUNTIME_ENV as 'development' | 'staging' | 'production' | 'uat';

if (NODE_ENV === 'uat') {
	//only change those keys that are different from default Config
	lodashMerge(Config, uatConfig);
}

if (NODE_ENV === 'staging') {
	//only change those keys that are different from default Config
	lodashMerge(Config, stagingConfig);
}

if (NODE_ENV === 'production') {
	lodashMerge(Config, prodConfig);
}

export default Config;
