import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	Grid,
	TextField,
	DialogContent,
	DialogTitle,
	Dialog,
	Button,
	CircularProgress,
	makeStyles
} from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { IMapSystemSetting } from 'shared/types/Types';
import { withStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

interface IProps {
	systemSettings: IMapSystemSetting | null;
	onClickClose: () => void;
	onClickSubmitMapSystem: (data: IMapSystemSetting) => void;
}

const initialValues = {
	default_reservation_period: '',
	max_reservation_extension_period: ''
};
const periodYupValidation = (name: string) => {
	return yup
		.number()
		.required(`${name} Map color is a required field`)
		.min(0, `${name} must be greater than 0`)
		.integer(`${name} must be an integer`);
};
const schema = yup.object().shape({
	default_reservation_period: periodYupValidation('Default Reservation period'),
	max_reservation_extension_period: periodYupValidation('Max Reservation Extension period')
});

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	}
}));

const ConfigureColorModal: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const getFormValues = () => {
		return !props.systemSettings ? initialValues : props.systemSettings;
	};
	const { errors, control, register, handleSubmit } = useForm({
		defaultValues: { ...getFormValues() },
		resolver: yupResolver(schema)
	});
	const [isLoading, setIsLoading] = React.useState(false);
	const [errorsObj, setErrors] = React.useState(errors);

	const onClickSubmit = (data: IMapSystemSetting) => {
		setIsLoading(true);
		props.onClickSubmitMapSystem(data);
	};

	if (JSON.stringify(errors) !== JSON.stringify(errorsObj)) {
		setErrors(errors);
	}

	const classes = useStyles();
	return (
		<Dialog aria-labelledby="customized-dialog-title" open={true} onClose={props.onClickClose} className="map-setting">
			<DialogTitle>System Configuration</DialogTitle>
			<DialogContent dividers>
				<Grid className="stretch">
					<Controller
						as={
							<TextField
								ref={register}
								fullWidth
								type="number"
								error={errorsObj.default_reservation_period ? true : false}
								label="Default Reservation period"
								helperText={
									errorsObj.default_reservation_period
										? errorsObj.default_reservation_period.message
										: 'Default Reservation period in days'
								}
							/>
						}
						name="default_reservation_period"
						control={control}
					/>

					<Controller
						as={
							<TextField
								ref={register}
								fullWidth
								type="number"
								error={errorsObj.max_reservation_extension_period ? true : false}
								label="Max Reservation Extension period"
								helperText={
									errorsObj.max_reservation_extension_period
										? errorsObj.max_reservation_extension_period.message
										: 'Max Reservation Extension period in days'
								}
							/>
						}
						name="max_reservation_extension_period"
						control={control}
					/>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClickClose}>CLOSE</Button>
				<div className={classes.wrapper}>
					<Button
						disabled={isLoading}
						color="primary"
						onClick={handleSubmit((formData: IMapSystemSetting) => {
							onClickSubmit(formData);
						})}
					>
						SUBMIT
					</Button>
					{isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default ConfigureColorModal;
