import React, { useEffect } from 'react';
import { Grid, Typography, Fab, Tooltip, FormGroup, Switch, FormControlLabel } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { Sa1HistoricalReportFilterParams, ITableFilterParam, ISa1HistoricalReportResult } from '../Report';
import CommonApiService from 'shared/services/CommonApiService';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import ReportFilter from './HistoricalReportFilter';
import ReportResult from './HistoricalReportResult';
import EmptyScreen from 'shared/components/EmptyScreen';
import UtilityService from 'shared/services/UtilityService';
import LinearProgress from 'shared/components/LoadingLinear';
import { scryRenderedComponentsWithType } from 'react-dom/test-utils';
interface IMatchParams {
	id: string;
}
type IProps = RouteComponentProps<IMatchParams>;

const Sa1HistoricalReport: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const [filterParams, setFilterParams] = React.useState<Sa1HistoricalReportFilterParams | null>(null);
	const [tableFilterParams, setTableFilterParams] = React.useState<ITableFilterParam | null>(null);
	const [filterURLParams, setFilterURLParams] = React.useState<Sa1HistoricalReportFilterParams | null>(null);
	const [tableFilterURLParams, setTableFilterURLParams] = React.useState<ITableFilterParam | null>(null);
	const [records, setRecords] = React.useState<ISa1HistoricalReportResult[]>([]);
	const [totalRecords, setTotalRecords] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isShowExportBtn, setIsShowExportBtn] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string | null>(null);
	const [type, setType] = React.useState<boolean>(false);

	useEffect(() => {
		const filterParams = fetchParamsFromURL();
		if (Object.keys(filterParams).length !== 0 && filterParams.constructor === Object) {
			const urlParams: any = UtilityService.queryParse(props.history.location.search);
			if (urlParams['type']) {
				filterParams.type = filterParams.type === 'sa1' ? false : true;
			}

			onClickApplyFilter(filterParams, 'fromUrl');
			//getFilteredRecord(filterParams as Sa1HistoricalReportFilterParams);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchParamsFromURL = () => {
		const filterParams: any = getFilterParamsFromURL();
		setFilterURLParams(filterParams as Sa1HistoricalReportFilterParams);
		const tableParams = getTableFilterParamsFromURL();
		setTableFilterURLParams(tableParams as ITableFilterParam);

		return {
			...filterParams,
			...tableParams
		};
	};

	const getFilterParamsFromURL = () => {
		const urlParams: any = UtilityService.queryParse(props.history.location.search);
		return {
			...(urlParams['from_date'] && {
				fromDate: new Date(Number(urlParams['from_date']))
			}),
			...(urlParams['to_date'] && {
				toDate: new Date(Number(urlParams['to_date']))
			}),
			...(urlParams['sa1_id'] && { sa1: urlParams['sa1_id'] }),
			...(urlParams['agency_id'] && {
				agencyId: Number(urlParams['agency_id'])
			}),
			...(urlParams['agency_name'] && { agencyName: urlParams['agency_name'] }),
			...(urlParams['prospect_agency_name'] && {
				prospectAgencyName: urlParams['prospect_agency_name'] as string
			}),
			...(urlParams['state_id'] && { stateId: Number(urlParams['state_id']) }),
			...(urlParams['transaction_type'] && {
				transactionType: urlParams['transaction_type']
			}),
			...(urlParams['sales_person_name'] && {
				salesPersonName: urlParams['sales_person_name']
			}),
			...(urlParams['sales_person_id'] && {
				salesPersonId: Number(urlParams['sales_person_id'])
			})
		};
	};

	const getTableFilterParamsFromURL = () => {
		const urlParams = UtilityService.queryParse(props.history.location.search);
		return {
			...(urlParams['limit'] && { limit: Number(urlParams['limit']) }),
			...(urlParams['offset'] && { offset: Number(urlParams['offset']) }),
			...(urlParams['column_name'] && {
				column_name: urlParams['column_name']
			}),
			...(urlParams['order_by'] && { order_by: urlParams['order_by'] })
		};
	};

	const onTableFilter = (tableFilterParams: ITableFilterParam) => {
		setTableFilterParams(tableFilterParams);
		const params = {
			...filterParams,
			...tableFilterParams
		};
		setUrl(params as Sa1HistoricalReportFilterParams);
		getFilteredRecord(params as Sa1HistoricalReportFilterParams);
	};

	const setUrl = (params: Sa1HistoricalReportFilterParams) => {
		const urlParams = UtilityService.queryStringify(params);
		props.history.push('/ui/report/historical-report?' + urlParams);
	};

	const apiFilterParams = (params: Sa1HistoricalReportFilterParams) => {
		const filterParams = { ...params };
		if (filterParams['agency_name']) {
			delete filterParams['agency_name'];
		}
		if (filterParams['state_id'] === 9999) {
			delete filterParams['state_id'];
		}
		if (filterParams['prospect_agency_name']) {
			filterParams['prospect_agency_name'] = decodeURI(filterParams['prospect_agency_name']);
		}
		if (filterParams['sales_person_name']) {
			delete filterParams['sales_person_name'];
		}
		if (filterParams['transaction_type'] === 'none') {
			delete filterParams['transaction_type'];
		}
		if (filterParams['type'] === 'cdar') {
			delete filterParams['sa1_id'];
		}
		filterParams['from_date'] = getFilterDate(filterParams['from_date'], 'from');
		filterParams['to_date'] = getFilterDate(filterParams['to_date'], 'to');
		return filterParams;
	};

	const getFilteredRecord = async (params: Sa1HistoricalReportFilterParams) => {
		try {
			setIsLoading(true);
			setIsShowExportBtn(false);
			const filterParams = apiFilterParams(params);
			const apiRes: any = await CommonApiService.getSA1HistoricalRecord(filterParams);

			if (!apiRes?.success) {
				setRecords([]);
				return;
			}
			if (apiRes.data.length > 0) {
				setIsShowExportBtn(true);
			}

			setRecords(apiRes.data);
			setTotalRecords(apiRes.total_count);
		} finally {
			setIsLoading(false);
		}
	};

	const getEmptyScreenMsg = () => {
		if (!filterParams || (Object.keys(filterParams).length === 0 && filterParams.constructor === Object)) {
			return 'Select some filters and click on APPLY button to get the data';
		}
		return 'No data available';
	};
	const isValidForm = (filterParams: any, type: string) => {
		const { fromDate, toDate } = filterParams;
		if (!fromDate || !toDate) {
			setError('From Date and To Date is required field.');
			return false;
		}
		if (UtilityService.getDifferenceBetweenDatesInDays(fromDate, toDate) > 365) {
			setError('Date Range can not exceed more than 1 year.');
			return false;
		}
		return true;
	};
	const getApiParams = (filterParams: any, filterType: string) => {
		const {
			fromDate,
			toDate,
			sa1,
			stateId,
			agencyId,
			agencyName,
			transactionType,
			prospectAgencyName,
			salesPersonId,
			salesPersonName,
			limit,
			offset
		} = filterParams;
		if (filterType === 'search') {
			return {
				from_date: fromDate,
				to_date: toDate,
				...(sa1 && { sa1_id: sa1 }),
				...(agencyId && { agency_id: agencyId as number }),
				...(agencyId && { agency_name: agencyName }),
				...(stateId && { state_id: stateId as number }),
				...(transactionType && { transaction_type: transactionType }),
				...(prospectAgencyName && { prospect_agency_name: prospectAgencyName }),
				...(salesPersonId && { sales_person_id: salesPersonId as number }),
				...(salesPersonId && { sales_person_name: salesPersonName }),
				type: type ? 'cdar' : 'sa1',
				limit,
				offset
			};
		}
		return {
			from_date: new Date(fromDate).getTime(),
			to_date: new Date(toDate).getTime(),
			...(sa1 && { sa1_id: sa1 }),
			...(agencyId && { agency_id: agencyId }),
			...(agencyId && { agency_name: agencyName }),
			...(stateId && { state_id: stateId as number }),
			...(transactionType && { transaction_type: transactionType }),
			...(prospectAgencyName && { prospect_agency_name: prospectAgencyName }),
			...(salesPersonId && { sales_person_id: salesPersonId as number }),
			...(salesPersonId && { sales_person_name: salesPersonName }),
			type: type ? 'cdar' : 'sa1',
			limit,
			offset
		};
	};
	const getFilterDate = (date: Date | string, type: string) => {
		let filterDate = date;
		if (typeof date === 'string') {
			filterDate = new Date(date);
		}
		let utcDate;
		if (type === 'from') {
			utcDate = UtilityService.getUtcDatetimeForADay(filterDate as Date).from;
			return UtilityService.UTCDateToDate(utcDate, 'yyyy-MM-dd HH:mm:ss', 'UTC');
		}
		utcDate = UtilityService.getUtcDatetimeForADay(filterDate as Date).to;
		return UtilityService.UTCDateToDate(utcDate, 'yyyy-MM-dd HH:mm:ss', 'UTC');
	};

	const getTableFilterForURL = (filterParams: any) => {
		const { column_name, order_by } = filterParams;
		return {
			...(column_name && { column_name }),
			...(order_by && { order_by })
		};
	};
	const onClickApplyFilter = (filterParams: any, type: string) => {
		setError('');
		if (!isValidForm(filterParams, type)) {
			return;
		}
		let filterApiParams = getApiParams({ ...filterParams }, type);
		setFilterParams(filterApiParams);
		if (type === 'search') {
			setUrl(filterApiParams);
			setTableFilterURLParams({
				offset: 0,
				limit: 10,
				order_by: 'asc',
				column_name: ''
			});
		}
		if (type === 'fromUrl') {
			filterApiParams = {
				...filterApiParams,
				...getTableFilterForURL(filterParams)
			};
			setTableFilterParams(getTableFilterForURL(filterParams));
		}
		getFilteredRecord(filterApiParams);
	};
	const onReset = () => {
		setError('');
		setRecords([]);
		setFilterParams(null);
		setType(false);
		setIsShowExportBtn(false);
		props.history.push('/ui/report/historical-report');
	};

	const apiExportFilterParams = () => {
		const params = {
			...(tableFilterParams && tableFilterParams),
			...filterParams,
			is_export: true
		};
		if (params['agency_name']) {
			delete params['agency_name'];
		}
		if (params['type'] === 'cdar') {
			delete params['sa1_id'];
		}
		if (params['state_id'] === 9999) {
			delete params['state_id'];
		}
		if (params['prospect_agency_name']) {
			params['prospect_agency_name'] = decodeURI(params['prospect_agency_name']);
		}
		if (params['sales_person_name']) {
			delete params['sales_person_name'];
		}
		if (params['transaction_type'] === 'none') {
			delete params['transaction_type'];
		}
		params['from_date'] = getFilterDate(params['from_date'], 'from');
		params['to_date'] = getFilterDate(params['to_date'], 'to');
		return params;
	};

	const onExport = async () => {
		try {
			const params = apiExportFilterParams();
			setIsLoading(true);
			setIsShowExportBtn(false);
			const apiRes: any = await CommonApiService.getSA1HistoricalRecord(params as any);
			setIsShowExportBtn(true);
			if (!apiRes?.success) {
				return;
			}
			UtilityService.downloadCSVFile(apiRes.uri, `SA1HistoricalReport${UtilityService.getDateForCSVName()}.csv`);
		} finally {
			setIsLoading(false);
		}
	};

	const onTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setType(event.target.checked);
		if (filterParams?.from_date && filterParams?.to_date) {
			filterParams['type'] = event.target.checked ? 'cdar' : 'sa1';
			if (filterParams['type'] === 'cdar') {
				delete filterParams['sa1_id'];
			}
			setUrl(filterParams as Sa1HistoricalReportFilterParams);
			getFilteredRecord(filterParams);
		}
	};

	const renderTypeComponent = () => {
		return (
			<Grid item>
				<FormGroup>
					<Typography component="div">
						<Grid component="label" container alignItems="center" spacing={1}>
							<Typography variant="body2" color="textSecondary">
								SA1&nbsp;&nbsp;
							</Typography>
							<FormControlLabel
								control={<Switch checked={type} color="primary" onChange={onTypeChange} />}
								label={
									<Typography variant="body2" color="textSecondary">
										Royalty Area
									</Typography>
								}
							/>
						</Grid>
					</Typography>
				</FormGroup>
			</Grid>
		);
	};

	return (
		<Grid direction="column" container style={{ width: '100%', height: '100%' }}>
			<LinearProgress showLoader={isLoading} />
			<Grid item container style={{ padding: 10 }} justify="space-between">
				<Grid item>
					<Typography className="mb10" color="secondary" variant="h6">
						Historical Report
					</Typography>
				</Grid>
				<Grid item>
					<Grid container>
						{renderTypeComponent()}
						<Grid item>
							<Tooltip title="Download Report in CSV">
								<span>
									<Fab
										size="small"
										disabled={!isShowExportBtn}
										onClick={onExport}
										style={{
											backgroundColor: isShowExportBtn ? '#0d8f4e' : '#bdbdbd',
											color: '#fff',
											marginRight: 15
										}}
									>
										<CloudDownloadOutlinedIcon />
									</Fab>
								</span>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				className="pl10 rl10 admin-sales"
				style={{
					minHeight: 50,
					maxHeight: 130,
					overflow: 'auto',
					marginBottom: 10
				}}
			>
				<ReportFilter
					error={error}
					filterUrlParams={filterURLParams}
					onClickApplyFilter={onClickApplyFilter}
					onReset={onReset}
					reportType={type ? 'cdar' : 'sa1'}
				/>
			</Grid>
			<Grid item xs className="pl10 rl10" style={{ marginBottom: 10, overflow: 'auto' }}>
				{records.length > 0 ? (
					<ReportResult
						records={records}
						totalRecord={totalRecords}
						onTableFilter={onTableFilter}
						tableFilterParams={tableFilterURLParams}
						reportType={type ? 'cdar' : 'sa1'}
					/>
				) : (
					<EmptyScreen emptyText={getEmptyScreenMsg()} />
				)}
			</Grid>
		</Grid>
	);
};

export default Sa1HistoricalReport;
