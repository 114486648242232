import React, { useEffect } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ISa1HistoricalReportResult, ITableFilterParam } from '../Report';
/* import UtilityService from 'shared/services/UtilityService'; */
import { format } from 'date-fns';
import { TransactionTypeOptions } from 'shared/constants/AppData';

interface IProps {
	reportType: string;
	tableFilterParams: ITableFilterParam | null;
	records: ISa1HistoricalReportResult[];
	totalRecord: number;
	onTableFilter: (filterParams: ITableFilterParam) => void;
}
interface HeadCell {
	id: string;
	label: string;
	is_short: boolean;
}

const Sa1HistoricalReportResult: React.FunctionComponent<IProps> = (props): JSX.Element => {
	type Order = 'asc' | 'desc';

	interface ITableHeaderProps {
		reportType: string;
		classes: ReturnType<typeof useStyles>;
		onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
		order: Order;
		orderBy: string;
	}

	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			root: {
				width: '100%'
			},
			paper: {
				width: '100%',
				marginBottom: theme.spacing(2)
			},
			table: {
				minWidth: 750
			},
			visuallyHidden: {
				border: 0,
				clip: 'rect(0 0 0 0)',
				height: 1,
				margin: -1,
				overflow: 'hidden',
				padding: 0,
				position: 'absolute',
				top: 20,
				width: 1
			}
		})
	);

	const classes = useStyles();
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<string>('');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	useEffect(() => {
		setTableFilteredParams();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.tableFilterParams]);

	const setTableFilteredParams = () => {
		const tableFilterUrlParams = props.tableFilterParams;
		if (
			!tableFilterUrlParams ||
			(Object.keys(tableFilterUrlParams).length === 0 && tableFilterUrlParams.constructor !== Object)
		) {
			return;
		}

		if (tableFilterUrlParams['order_by']) {
			setOrder(tableFilterUrlParams['order_by'] as Order);
		}
		if (tableFilterUrlParams['column_name']) {
			setOrderBy(tableFilterUrlParams['column_name']);
		}
		if (tableFilterUrlParams['limit']) {
			setRowsPerPage(tableFilterUrlParams['limit']);
		}
		if (tableFilterUrlParams['offset'] !== null && tableFilterUrlParams['limit']) {
			setPage(Number((tableFilterUrlParams['offset'] as number) / tableFilterUrlParams['limit']));
		}
	};

	const TableHeader = (props: ITableHeaderProps) => {
		const { classes, order, orderBy, onRequestSort, reportType } = props;
		const headCells: HeadCell[] = [
			{ id: 'agency_id', label: 'Agency Id', is_short: true },
			{ id: 'agency_name', label: 'Agency Name', is_short: true },
			{ id: 'prospect_agency', label: 'Prospect Customer', is_short: false },
			{ id: 'transaction_id', label: 'Transaction Id', is_short: true },
			{ id: 'transaction_type', label: 'Transaction Type', is_short: true },
			{ id: 'transaction_performed_by', label: 'Transaction Performed By', is_short: false },
			{ id: 'tstamp', label: 'Datetime', is_short: true },
			{ id: 'note', label: 'Note', is_short: true }
		];
		if (reportType === 'sa1') {
			headCells.splice(0, 0, { id: 'sa1_code', label: 'SA1', is_short: true });
		}
		if (reportType === 'cdar') {
			headCells.splice(0, 0, { id: 'cda_name', label: 'Council Name', is_short: true });
			headCells.splice(1, 0, { id: 'cdar_name', label: 'Royalty Area', is_short: true });
		}
		const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};
		return (
			<TableHead>
				<TableRow>
					{headCells.map((headCell, index) =>
						headCell.is_short ? (
							<TableCell key={index} align="center" sortDirection={orderBy === headCell.id ? order : false}>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<span className={classes.visuallyHidden}>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</span>
									) : null}
								</TableSortLabel>
							</TableCell>
						) : (
							<TableCell key={index} align="center">
								{headCell.label}
							</TableCell>
						)
					)}
				</TableRow>
			</TableHead>
		);
	};

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
		props.onTableFilter({
			...(property && { column_name: property }),
			...(property && { order_by: isAsc ? 'desc' : 'asc' }),
			offset: page === 0 ? 0 : rowsPerPage * page,
			limit: rowsPerPage
		});
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		props.onTableFilter({
			...(orderBy && { column_name: orderBy }),
			...(orderBy && { order_by: order }),
			offset: newPage === 0 ? 0 : rowsPerPage * newPage,
			limit: rowsPerPage
		});
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		props.onTableFilter({
			...(orderBy && { column_name: orderBy }),
			...(orderBy && { order_by: order }),
			offset: 0,
			limit: parseInt(event.target.value, 10)
		});
	};

	const renderDateTime = (dateTime: null | string) => {
		if (!dateTime) {
			return '-';
		}
		return format(new Date(dateTime), 'dd/MM/yyyy, hh:mm a');
	};

	const renderTransactionType = (type: string) => {
		const record = TransactionTypeOptions.find((transactionType) => transactionType.value === type);
		if (!record) {
			return '-';
		}
		return record.name;
	};
	const getTransactionPerformedBy = (record: ISa1HistoricalReportResult) => {
		if (!record.firstname && !record.lastname) {
			return '-';
		}
		return `${record.firstname} ${record.lastname}`;
	};

	const renderCellsByReportType = (record: ISa1HistoricalReportResult) => {
		if (props.reportType === 'sa1') {
			return <TableCell align="center">{record.sa1_code !== null ? record.sa1_code : '-'}</TableCell>;
		}
		return (
			<>
				<TableCell align="center">{record.cda_name !== null ? record.cda_name : '-'}</TableCell>
				<TableCell align="center">{record.cdar_name !== null ? record.cdar_name : '-'}</TableCell>
			</>
		);
	};

	return (
		<Paper className="paper-container" style={{ padding: 10 }}>
			<TableContainer>
				<Table className={classes.table} aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
					<TableHeader
						classes={classes}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						reportType={props.reportType}
					/>
					<TableBody>
						{props.records.map((record, index) => {
							return (
								<TableRow hover tabIndex={-1} key={index}>
									{renderCellsByReportType(record)}
									<TableCell align="center">{record.agency_id !== null ? record.agency_id : '-'}</TableCell>
									<TableCell align="center">{record.agency_name !== null ? record.agency_name : '-'}</TableCell>
									<TableCell align="center">{record.prospect_agency !== null ? record.prospect_agency : '-'}</TableCell>
									<TableCell align="center">{record.transaction_id !== null ? record.transaction_id : '-'}</TableCell>
									<TableCell align="center">
										{record.transaction_type !== null ? renderTransactionType(record.transaction_type) : '-'}
									</TableCell>
									<TableCell align="center">{getTransactionPerformedBy(record)}</TableCell>
									<TableCell align="center">{renderDateTime(record.tstamp)}</TableCell>
									<TableCell align="center">{record.note !== null ? record.note : '-'}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{props.totalRecord && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={props.totalRecord}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
};

export default Sa1HistoricalReportResult;
