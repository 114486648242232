import React from 'react';
import { Grid, Typography, makeStyles, List, ListItem, ListItemText, Switch } from '@material-ui/core';
import { ICda, ISuburb } from 'shared/types/Types';

interface IProps {
	cda: ICda;
	onSuburbListItemHoverStart?: (suburb: ISuburb) => void;
	onSuburbListItemHoverEnd?: (suburb: ISuburb) => void;
}

const CdaInfoView: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const classes = useStyles();

	const suburbListRowMouseEnter = (suburb: ISuburb) => {
		if (props.onSuburbListItemHoverStart) {
			props.onSuburbListItemHoverStart(suburb);
		}
	};

	const suburbListRowMouseLeave = (suburb: ISuburb) => {
		if (props.onSuburbListItemHoverEnd) {
			props.onSuburbListItemHoverEnd(suburb);
		}
	};

	return (
		<Grid container item xs direction="column">
			<Typography variant="body1">{props.cda.name}</Typography>
			<Grid container justify="space-between" className="mt5 mb5">
				<Typography color="secondary" variant="body1">
					Search radius in kilometer
				</Typography>
				<Typography color="secondary" variant="body1">
					{props.cda.provisional_search_radius ? props.cda.provisional_search_radius : 'NA'}
				</Typography>
			</Grid>
			<Grid container justify="space-between" className="mt5 mb5">
				<Typography color="secondary" variant="body1">
					Reservation expiry in days
				</Typography>
				<Typography color="secondary" variant="body1">
					{props.cda.reservation_expiry_in_days ? props.cda.reservation_expiry_in_days : 'NA'}
				</Typography>
			</Grid>
			<Grid container justify="space-between" className="mt5 mb5">
				<Typography color="secondary" variant="body1">
					Automate provisional assignment
				</Typography>
				<Typography color="secondary" variant="body1">
					{props.cda.automate_provisional_assignment ? 'YES' : 'NO'}
				</Typography>
				{/* <Switch readOnly color="primary" size="small" checked={props.cda.automate_provisional_assignment} /> */}
			</Grid>
			<Typography color="secondary" variant="body2">
				Suburbs in {props.cda.name}
			</Typography>
			<Grid item xs className={classes.suburbsListContainer}>
				<List dense className={classes.suburbsList}>
					{props.cda.suburbs.map((suburb) => (
						<ListItem
							dense
							key={suburb.id}
							className={classes.suburbListRow}
							onMouseEnter={() => suburbListRowMouseEnter(suburb)}
							onMouseLeave={() => suburbListRowMouseLeave(suburb)}
						>
							<Grid container>
								<ListItemText primary={suburb.name} />
							</Grid>
						</ListItem>
					))}
				</List>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	error: {
		color: theme.palette.error.light,
		display: 'inline-block',
		fontSize: 12
	},
	suburbsListContainer: {
		marginTop: 10,
		overflowY: 'auto',
		overflowX: 'hidden'
	},
	suburbsList: {
		//maxHeight: '100%',
		backgroundColor: '#efefef'
	},
	suburbsListItemDeleteBtn: {
		cursor: 'pointer'
	},
	suburbListRow: {
		'&:hover': {
			color: theme.palette.primary.main,
			border: '1px solid black'
		}
	}
}));

export default CdaInfoView;
