/**
 * Author - Vinay
 * This component is to be used when we need to show/hide a component
 * 
 * Source - https://wecodetheweb.com/2019/03/02/easy-modals-with-react-hooks/
 * 
 * Example usage of this component
 * <ToggleContent
        toggle={(show) => (
            <Button onClick={show} color="primary" variant="outlined">
                clear
            </Button>
        )}
        content={(hide) => (
            <ConfirmModalWithMessage
                isLoading={activityStatus === EActivityStatus.ClearingTmpCda}
                onClickConfirm={() => {
                    onClickClearTmpCda(hide);
                }}
                onClickCancel={hide}
                message={'Are you sure you want to clear all Tmp CDA changes across entire Australia ?'}
                title="Clear Tmp CDA changes ?"
            />
        )}
    />
 */

import React, { useState } from 'react';

type Toggle = (a: () => void) => JSX.Element;

interface IProps {
	toggle: Toggle;
	content: Toggle;
}

const ToggleContent: React.FunctionComponent<IProps> = ({ toggle, content }): JSX.Element => {
	const [isShown, setIsShown] = useState(false);
	const hide = () => setIsShown(false);
	const show = () => setIsShown(true);

	return (
		<>
			{toggle(show)}
			{isShown && content(hide)}
		</>
	);
};

export default ToggleContent;
