import React from 'react';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, Grid } from '@material-ui/core';

interface IProps {
	message?: string;
	hideMessage?: boolean;
	size?: number;
}

const LoadingSpinner: React.FunctionComponent<IProps> = (props): JSX.Element => {
	return (
		<Grid container justify="center" alignItems="center">
			<CircularProgress size={props.size || 40} />
			<Typography style={{ marginLeft: 10 }} variant="subtitle2">
				{!props.hideMessage && (props.message || 'Loading . . .')}
			</Typography>
		</Grid>
	);
};

export default LoadingSpinner;
