import Map from 'shared/components/Map';
import mapboxgl, { LngLatLike, PointLike } from 'mapbox-gl';
import React, { Component } from 'react';
import {
	IAustraliaState,
	ELoadingIndicatorStatus,
	IMapColor,
	ISalesAreaSearch,
	IMapboxMouseMoveEvent,
	ISa1GeoJsonFeature,
	ICdarGeoJsonFeature,
	IStateGeoJsonFeature,
	TCdarMapFeatureState,
	TSa1MapFeatureState,
	TAusStateMapFeatureState,
	ITooltipContent,
	ERevAreaSaleStatus,
	ICdarWithAgency,
	IGeoBound
} from 'shared/types/Types';
import { IAppraisalEnquirySendEmailSubmit } from './AppraisalEnquiryTypes';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import UtilityService from 'shared/services/UtilityService';
import axios, { CancelTokenSource } from 'axios';
import CdarService from 'shared/services/CdarService';
import { find as lodashFind } from 'lodash';
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Typography,
	Divider,
	LinearProgress,
	TextField,
	CircularProgress,
	Button
} from '@material-ui/core';
import MailIcon from '@material-ui/icons/MailOutlineOutlined';
import CommonApiService from 'shared/services/CommonApiService';
import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import AreaLoaderContainer from 'shared/components/AreaLoaderContainer';
import MapLegend from 'shared/components/MapLegend';
import Config from 'shared/constants/Config';
import ResultTable from './AppraisalEnquiryTable';
import ForwardEnquiryModal from './ForwardEnquiryModal';
import { IAppraisalEnquiry, ISuburbAutoComplete } from './AppraisalEnquiryTypes';
import { RouteComponentProps } from 'react-router-dom';

enum ESa1SalesLoaders {
	ShowSuburbAutocompleteLoader = 'show-suburb-autocomplete-loader'
}
type ESa1LoadingIndicatorsStatus = ELoadingIndicatorStatus | ESa1SalesLoaders;

enum EMapLayers {
	StateFill = 'state-fill',
	StateOutline = 'state-outline',
	StateHoverOutline = 'state-hover-fill',
	CdaFill = 'cda-fill',
	CdaOutline = 'cda-outline',
	CdaHoverOutline = 'cda-hover-fill',
	CdarFill = 'cdar-fill',
	CdarOutline = 'cdar-outline',
	CdarHoverOutline = 'cdar-hover-outline',
	CdarLabel = 'cdar-label',
	RoyaltyCdarFillPattern = 'royalty-cdar-fill-pattern',
	Sa1Fill = 'sa1-fill',
	Sa1Outline = 'sa1-outline',
	Sa1HoverOutline = 'sa1-hover-outline',
	Sa1Label = 'sa1-label'
}

interface IState {
	cdar: ICdarWithAgency[];
	loadingIndicator: ESa1LoadingIndicatorsStatus;
	ausStates: IAustraliaState[];
	selectedAusStateId: number | null;
	isShowMap: boolean;
	mapColors: IMapColor;
	filters: {
		from_date: Date | null;
		to_date: Date | null;
		state_id: number | null;
		suburb: ISuburbAutoComplete | null;
	};
	suburbs: ISuburbAutoComplete[];
	isShowForwardEnquiryModal: boolean;
	isSubmitting: boolean;
	selectedEnquiries: string[];
	errorMsg: string;
}
let axiosRequestCancelToken: CancelTokenSource | null = null;

const result = [
	{
		gnaf: 'GAVIC719006410',
		sa1: 112001,
		address: 'Great Ocean Road, VIC',
		state: {
			state_id: 2,
			name: 'Victoria',
			code: 'VIC'
		},
		suburb: 'Richmond',
		latitude: 144.95760432108244,
		longitude: -37.84513537926272,
		date: '2021-04-01T10:31:34.000Z'
	},
	{
		gnaf: 'GAVIC719006411',
		sa1: 112002,
		address: 'Grampians National Park, VIC',
		state: {
			state_id: 1,
			name: 'New South Wales',
			code: 'NSW'
		},
		suburb: 'Richmond',
		latitude: 150.31414307301293,
		longitude: -33.731634684234635,
		date: '2021-03-02T10:31:34.000Z'
	},
	{
		gnaf: 'GAVIC719005411',
		sa1: 223001,
		address: 'Federation Square, VIC',
		state: {
			state_id: 2,
			name: 'Victoria',
			code: 'VIC'
		},
		suburb: 'Docklands',
		latitude: 144.85760432108244,
		longitude: -37.14513537926272,
		date: '2021-04-11T10:31:34.000Z'
	},
	{
		gnaf: 'GAVIC719106411',
		sa1: 112115,
		address: 'Royal Botanic Gardens, VIC',
		state: {
			state_id: 2,
			name: 'Victoria',
			code: 'VIC'
		},
		suburb: 'Wyndham Vale',
		latitude: 144.75760432108244,
		longitude: -37.12513537926272,
		date: '2021-04-27T10:31:34.000Z'
	},
	{
		gnaf: 'GAVIC719006418',
		sa1: 112001,
		address: 'Federation Square, VIC',
		state: {
			state_id: 1,
			name: 'New South Wales',
			code: 'NSW'
		},
		suburb: 'Docklands',
		latitude: 150.31414307301293,
		longitude: -33.731634684234635,
		date: '2021-05-01T10:31:34.000Z'
	},
	{
		gnaf: 'GAVIC718006410',
		sa1: 112001,
		address: 'Great Ocean Road, VIC',
		state: {
			state_id: 1,
			name: 'New South Wales',
			code: 'NSW'
		},
		suburb: 'Richmond',
		latitude: 150.31414307301293,
		longitude: -33.731634684234635,
		date: '2021-05-11T10:31:34.000Z'
	},
	{
		gnaf: 'GAVIC819006411',
		sa1: 112002,
		address: 'Grampians National Park, VIC',
		state: {
			state_id: 2,
			name: 'Victoria',
			code: 'VIC'
		},
		suburb: 'Richmond',
		latitude: 144.88770432108244,
		longitude: -37.44513537926272,
		date: '2021-05-05T10:31:34.000Z'
	},
	{
		gnaf: 'GAVIC719006324',
		sa1: 223001,
		address: 'Federation Square, VIC',
		state: {
			state_id: 2,
			name: 'Victoria',
			code: 'VIC'
		},
		suburb: 'Docklands',
		latitude: 144.11770432108244,
		longitude: -37.11513537926272,
		date: '2021-05-04T10:31:34.000Z'
	},
	{
		gnaf: 'GAVIC719006424',
		sa1: 112115,
		address: 'Royal Botanic Gardens, VIC',
		state: {
			state_id: 1,
			name: 'New South Wales',
			code: 'NSW'
		},
		suburb: 'Wyndham Vale',
		latitude: 150.31414307301293,
		longitude: -33.731634684234635,
		date: '2021-03-01T10:31:34.000Z'
	},
	{
		gnaf: 'GAVIC719026418',
		sa1: 112001,
		address: 'Federation Square, VIC',
		state: {
			state_id: 1,
			name: 'New South Wales',
			code: 'NSW'
		},
		suburb: 'Docklands',
		latitude: 144.12365432108244,
		longitude: -37.65421537926272,
		date: '2021-04-04T10:31:34.000Z'
	},
	{
		gnaf: 'GAVIC729006410',
		sa1: 112001,
		address: 'Great Ocean Road, VIC',
		state: {
			state_id: 1,
			name: 'New South Wales',
			code: 'NSW'
		},
		suburb: 'Richmond',
		latitude: 144.12365432112345,
		longitude: -37.6542153721365,
		date: '2021-04-21T10:31:34.000Z'
	}
];
interface IMatchParams {
	id: string;
}
type IProps = RouteComponentProps<IMatchParams>;

class AppraisalEnquiry extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.getSuburbBySearchText = UtilityService.debounce(this.getSuburbBySearchText);
		this.onMapHoverStart = UtilityService.debounce(this.onMapHoverStart, 25);
		this.onMapHoverEnd = UtilityService.debounce(this.onMapHoverEnd, 25);
	}
	state: Readonly<IState> = {
		ausStates: [],
		cdar: [],
		selectedAusStateId: null,
		loadingIndicator: ELoadingIndicatorStatus.None,
		isShowMap: false,
		mapColors: Config.MapColors,
		filters: {
			from_date: null,
			to_date: null,
			state_id: null,
			suburb: null
		},
		suburbs: [],
		isShowForwardEnquiryModal: false,
		isSubmitting: false,
		selectedEnquiries: [],
		errorMsg: ''
	};

	private popup = new mapboxgl.Popup({
		closeButton: false,
		closeOnClick: false,
		offset: 15
	});
	private hoverState = {
		/* cda: 0, */
		cdar: 0,
		sa1: 0,
		state: 0
	};
	private map!: mapboxgl.Map;
	/* let axiosRequestCancelToken: CancelTokenSource | null = null; */
	async componentDidMount() {
		this.getRecordFromURL();
		await this.getSystemMapColors();
		await this.getAustralianStates();
	}
	render() {
		return (
			<Grid container direction="column">
				{this.state.loadingIndicator === ELoadingIndicatorStatus.ShowNavBarLoader && <LinearProgress />}
				<Grid item container>
					<Grid item className="pl10 pt10 rl10 admin-sales-sidebar" style={{ width: '60%' }}>
						<AreaLoaderContainer isLoading={this.state.loadingIndicator === ELoadingIndicatorStatus.ShowAreaLoader}>
							<Grid direction="column" container>
								<Grid item container direction="column">
									<Typography className="mb10" color="secondary" variant="h6">
										Appraisal Enquiry
									</Typography>
									<Grid item>
										<Grid container direction="column" className="admin-sales" wrap="nowrap">
											<Grid item>
												<Paper elevation={0} className="paper-container">
													<Grid container item alignItems="center" spacing={2}>
														<Grid item style={{ width: '20%' }}>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<KeyboardDatePicker
																	variant="inline"
																	autoOk
																	format="dd/MM/yyyy"
																	id="from-date-picker-inline"
																	label="From Date"
																	value={this.state.filters.from_date}
																	onChange={(date: Date | null) => {
																		this.handleDateChange(date, 'fromDate');
																	}}
																	KeyboardButtonProps={{
																		'aria-label': 'change date'
																	}}
																/>
															</MuiPickersUtilsProvider>
														</Grid>
														<Grid item style={{ width: '20%' }}>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<Grid container justify="space-around">
																	<KeyboardDatePicker
																		variant="inline"
																		autoOk
																		format="dd/MM/yyyy"
																		id="to-date-picker-inline"
																		label="To Date"
																		value={this.state.filters.to_date}
																		minDate={this.state.filters.from_date}
																		maxDate={new Date()}
																		onChange={(date: Date | null) => {
																			this.handleDateChange(date, 'toDate');
																		}}
																		KeyboardButtonProps={{
																			'aria-label': 'change date'
																		}}
																	/>
																</Grid>
															</MuiPickersUtilsProvider>
														</Grid>
														<Grid item style={{ width: '25%' }}>
															<FormControl style={{ width: '100%' }}>
																<InputLabel htmlFor="manage-cda-state-filter">State</InputLabel>
																<Select
																	value={this.state.filters.state_id || ''}
																	labelId="manage-cda-state-filter"
																	onChange={this.onChangeAusStateDropdown}
																>
																	{this.state.ausStates.map((ausState) => {
																		return (
																			<MenuItem key={ausState.name} value={ausState.id}>
																				{ausState.name}
																			</MenuItem>
																		);
																	})}
																</Select>
															</FormControl>
														</Grid>
														<Grid item style={{ width: '25%' }}>
															<FormControl style={{ width: '100%' }}>
																<Autocomplete
																	value={this.state.filters.suburb as ISuburbAutoComplete}
																	onInputChange={this.onChangeSuburbAutocompleteInput}
																	size="small"
																	onChange={this.onChangeSuburbAutocomplete}
																	options={this.state.suburbs}
																	getOptionLabel={(option) => `${option.suburb_name}`}
																	renderOption={(option) => (
																		<Typography style={{ fontSize: '0.80rem' }}>
																			({option.suburb_id}) {option.suburb_name}
																		</Typography>
																	)}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			label="Suburb"
																			variant="standard"
																			InputProps={{
																				...params.InputProps,
																				endAdornment: (
																					<React.Fragment>
																						{this.state.loadingIndicator ===
																							ESa1SalesLoaders.ShowSuburbAutocompleteLoader && (
																							<CircularProgress color="secondary" size={20} />
																						)}
																						{params.InputProps.endAdornment}
																					</React.Fragment>
																				)
																			}}
																		/>
																	)}
																/>
															</FormControl>
														</Grid>
													</Grid>
													{this.state.errorMsg && (
														<Grid>
															<small
																style={{
																	color: '#F44336',
																	marginTop: 15,
																	fontSize: '80%'
																}}
															>
																{`* ${this.state.errorMsg}`}
															</small>
														</Grid>
													)}
													<Grid
														container
														alignItems="center"
														justify="center"
														style={{ marginTop: 5, marginBottom: 10 }}
													>
														<Button variant="contained" className="cancel-btn" onClick={this.onClickReset}>
															Reset
														</Button>
														<Button
															color="primary"
															variant="outlined"
															onClick={this.onClickApplyFilter}
															style={{ marginLeft: 10, marginRight: 15 }}
														>
															Apply
														</Button>

														<Button
															color="primary"
															disabled={this.state.selectedEnquiries.length === 0}
															variant="outlined"
															onClick={this.onClickSendEnquiries}
															endIcon={<MailIcon />}
															style={{ marginRight: 0, margin: 5 }}
														>
															Forward Enquiries
														</Button>
													</Grid>
												</Paper>
											</Grid>
										</Grid>
									</Grid>

									<Divider />
									<Grid item xs className="concierge-table-section">
										<Paper elevation={0} className="paper-container stretch">
											<Grid container direction="column" className="stretch">
												<ResultTable
													result={result}
													onSelectEnquiry={this.onSelectEnquiry}
													isLoading={false}
													onForwardEnquiry={this.onForwardEnquiry}
													onShowEnquiryOnMap={this.onShowEnquiryOnMap}
												/>
											</Grid>
										</Paper>
									</Grid>
								</Grid>
							</Grid>
						</AreaLoaderContainer>
					</Grid>
					<Grid item className="concierge-map-section">
						<AreaLoaderContainer isLoading={!this.state.isShowMap}>
							<Grid
								container
								direction="column"
								style={{
									height: 'calc(100vh - 52px)',
									width: '100%'
								}}
							>
								<Grid item xs>
									<Map onMapLoadComplete={this.onMapLoadComplete} />
								</Grid>
								<MapLegend items={this.getMapLegend()} legendSpacing={5} />
							</Grid>
						</AreaLoaderContainer>
					</Grid>
				</Grid>

				{this.state.isShowForwardEnquiryModal && (
					<ForwardEnquiryModal
						onClickCancelSubmission={this.onClickCancelSubmission}
						onClickSubmitForwardEnquiryAction={this.onClickSubmitForwardEnquiryAction}
						isSubmitting={this.state.isSubmitting}
					/>
				)}
			</Grid>
		);
	}

	getRecordFromURL = () => {
		if (!this.props.location.search) {
			this.getLatestRecord();
			return;
		}
		this.setFilterParams();
	};

	getSuburbForAutoComplete = (suburbId: number, suburbName: string) => {
		return {
			suburb_id: suburbId,
			suburb_name: suburbName
		};
	};

	setFilterParams = () => {
		const urlParams = UtilityService.queryParse(this.props.history.location.search);
		const filterParams = {
			fromDate: new Date(Number(urlParams['from_date'])),
			toDate: new Date(Number(urlParams['to_date'])),
			stateId: urlParams['state_id'] && Number(urlParams['state_id']),
			suburbId: urlParams['suburb_id'] && Number(urlParams['suburb_id']),
			suburbName: urlParams['suburb_name'] && urlParams['suburb_name'].toString()
		};
		let suburb = null;
		if (filterParams.suburbId && filterParams.suburbName) {
			suburb = this.getSuburbForAutoComplete(filterParams.suburbId, decodeURI(filterParams.suburbName));
		}

		this.setState(
			{
				filters: {
					from_date: new Date(filterParams.fromDate),
					to_date: new Date(filterParams.toDate),
					state_id: filterParams.stateId ? filterParams.stateId : null,
					suburb
				},
				suburbs: suburb ? [suburb] : []
			},
			() => {
				this.setUrl();
				this.getEnquiries();
			}
		);
	};

	getLatestRecord = () => {
		const todayDate = new Date();
		const fromDate = todayDate.getDate() - 7;
		this.setState(
			{
				filters: {
					...this.state.filters,
					from_date: new Date(todayDate.setDate(fromDate)),
					to_date: new Date()
				}
			},
			() => {
				this.setUrl();
				this.getEnquiries();
			}
		);
	};

	getEnquiries = () => {
		const filters = {
			from_date: this.getFilterDate(this.state.filters.from_date as Date, 'from'),
			to_date: this.getFilterDate(this.state.filters.to_date as Date, 'to'),
			...(this.state.filters.state_id && { state_id: this.state.filters.state_id }),
			...(this.state.filters.suburb && { suburb_id: this.state.filters.suburb.suburb_id })
		};
	};

	getFilterDate = (date: Date | string, type: string) => {
		let filterDate = date;
		if (typeof date === 'string') {
			filterDate = new Date(date);
		}
		let utcDate;
		if (type === 'from') {
			utcDate = UtilityService.getUtcDatetimeForADay(filterDate as Date).from;
			return UtilityService.UTCDateToDate(utcDate, 'yyyy-MM-dd HH:mm:ss', 'UTC');
		}
		utcDate = UtilityService.getUtcDatetimeForADay(filterDate as Date).to;
		return UtilityService.UTCDateToDate(utcDate, 'yyyy-MM-dd HH:mm:ss', 'UTC');
	};
	onSelectEnquiry = (enquiries: string[]) => {
		this.setState({
			selectedEnquiries: enquiries
		});
	};
	onShowEnquiryOnMap = async (gnafId: string) => {
		const enquiry = result.find((r) => gnafId === r.gnaf);
		if (!enquiry) {
			return;
		}

		await this.onSelectState(enquiry.state.state_id);
		this.map.flyTo({
			center: [enquiry.latitude, enquiry.longitude],
			zoom: 13
		});
		new mapboxgl.Marker({ color: '#FF6400' }).setLngLat([enquiry.latitude, enquiry.longitude]).addTo(this.map);
	};

	fitMapToAusState = () => {
		const ausStateBounds = UtilityService.getAusStateBounds();
		const mapboxBounds = UtilityService.getMapBoxBoundingBox(ausStateBounds);
		this.map.fitBounds(mapboxBounds);
	};

	onClickCancelSubmission = () => {
		this.setState({ isShowForwardEnquiryModal: false });
	};

	onClickSubmitForwardEnquiryAction = (params: IAppraisalEnquirySendEmailSubmit) => {
		console.log('params', params);
	};

	onForwardEnquiry = () => {
		this.setState({ isShowForwardEnquiryModal: true });
	};

	onClickReset = () => {
		this.setState({
			filters: {
				from_date: null,
				to_date: null,
				state_id: null,
				suburb: null
			},
			errorMsg: ''
		});
	};

	getSuburbNameById = (suburbId: number) => {
		const suburbs = this.state.suburbs;
		if (!suburbId || suburbs.length === 0) {
			return;
		}
		const record = suburbs.find((suburb) => suburb.suburb_id === suburbId);
		if (!record) {
			return;
		}
		return encodeURI(record.suburb_name);
	};

	validateForm = () => {
		const { from_date, to_date } = this.state.filters;
		if (!from_date || !to_date) {
			this.setState({
				errorMsg: 'From Date and To Date is required field.'
			});
			return false;
		}
		if (UtilityService.getDifferenceBetweenDatesInDays(from_date, to_date) > 7) {
			this.setState({
				errorMsg: 'Date Range can not exceed more than 1 week.'
			});
			return false;
		}
		return true;
	};
	setUrl = () => {
		const { from_date, to_date, state_id, suburb } = this.state.filters;
		const filters = {
			from_date: new Date(from_date as Date).getTime(),
			to_date: new Date(to_date as Date).getTime(),
			...(state_id && { state_id: state_id }),
			...(suburb && { suburb_id: suburb.suburb_id }),
			...(suburb && { suburb_name: this.getSuburbNameById(suburb.suburb_id) })
		};
		const urlParams = UtilityService.queryStringify(filters);
		this.props.history.push('/ui/concierge/appraisal-enquiry?' + urlParams);
	};

	onClickApplyFilter = () => {
		if (!this.validateForm()) {
			return;
		}
	};
	onClickSendEnquiries = () => {
		return;
	};

	onChangeSuburbAutocompleteInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		if (reason === 'clear') {
			this.setState({
				filters: {
					...this.state.filters,
					suburb: null
				}
			});
			return;
		}

		if (reason !== 'input' || value.trim().length < 3) {
			return;
		}

		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (value.trim().length < 3) {
			return;
		}

		this.getSuburbBySearchText(value);
	};

	onChangeSuburbAutocomplete = async (
		ev: unknown,
		value: ISuburbAutoComplete | null,
		reason: AutocompleteChangeReason
	) => {
		if (!value) {
			return;
		}
		this.setState({
			filters: {
				...this.state.filters,
				suburb: value
			}
		});
	};
	getFormattedSuburbRecord = (res: ISalesAreaSearch[]) => {
		return res.map((suburb: ISalesAreaSearch) => {
			return {
				suburb_id: suburb.id,
				suburb_name: suburb.text
			};
		});
	};
	getSuburbBySearchText = async (searchText: string) => {
		if (axiosRequestCancelToken) {
			axiosRequestCancelToken.cancel();
		}
		axiosRequestCancelToken = axios.CancelToken.source();
		try {
			this.setState({
				loadingIndicator: ESa1SalesLoaders.ShowSuburbAutocompleteLoader
			});
			const res = await CommonApiService.searchSa1AndSuburbByText(searchText, axiosRequestCancelToken.token);
			const suburbs = this.getFormattedSuburbRecord(res);
			this.setState({
				suburbs
			});
		} catch (err) {
			console.log('🚀 ~ file: ReportFilter.tsx ~ line 207 ~ ReportFilter ~ getSuburbBySearchText= ~ err', err);
		} finally {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		}
		return;
	};

	handleDateChange = (date: Date | null, type: string) => {
		if (!date) {
			return;
		}

		if (type === 'fromDate') {
			this.setState({
				filters: {
					...this.state.filters,
					from_date: date
				}
			});
			return;
		}
		this.setState({
			filters: {
				...this.state.filters,
				to_date: date
			}
		});
	};
	onSelectState = (stateId: number) => {
		return new Promise<void>((resolve, reject) => {
			this.setState(
				{
					selectedAusStateId: stateId,
					cdar: []
				},
				async () => {
					this.navigateMapToStateZoomLevel();
					await this.getCdarSa1sByState(stateId);
					this.showRoyaltyCdarByCdaOnMap();
					this.showSa1WithinNonRoyaltyCdarOnMap();
					this.showTransactionStatusLayersOnMap();
					//this.showAllCdarOutlineByCdaOnMap();
					resolve();
				}
			);
		});
	};
	getCdarSa1sByState = async (stateId: number) => {
		this.setState({
			loadingIndicator: ELoadingIndicatorStatus.ShowNavBarLoader
		});
		try {
			const res = await CdarService.getCdarSa1sByStateId(stateId);
			let cdar = res as ICdarWithAgency[];

			//sa1sAdded and sa1sRemoved are required client side for sa1 map updates. They are not
			//sent in response model. Hence to make sure both arrays are initialized correctly, we setup up
			//default values here
			cdar = cdar.map((cdar) => {
				return { ...cdar, sa1sAdded: [], sa1sRemoved: [] };
			});
			this.setState({
				cdar,
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		} catch (err) {
			console.error(err);
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		}
		return;
	};
	showTransactionStatusLayersOnMap = () => {
		const royaltyCdars = this.state.cdar.filter((cdar) => cdar.is_royalty);
		const nonRoyaltySa1s = this.state.cdar
			.filter((cdar) => !cdar.is_royalty)
			.flatMap((cdar) => cdar.sa1s.map((sa1) => sa1));
		const soldCdarIds: number[] = [0];
		const reservedCdarIds: number[] = [0];
		const soldSa1Codes: number[] = [0];
		const reservedSa1Codes: number[] = [0];

		royaltyCdars.forEach((rCdar) => {
			if (rCdar.status === ERevAreaSaleStatus.Sold) {
				soldCdarIds.push(rCdar.cdar_id);
			} else if (rCdar.status === ERevAreaSaleStatus.Reserved) {
				reservedCdarIds.push(rCdar.cdar_id);
			}
		});

		nonRoyaltySa1s.forEach((sa1) => {
			if (sa1.status === ERevAreaSaleStatus.Sold) {
				soldSa1Codes.push(sa1.sa1_code);
			} else if (sa1.status === ERevAreaSaleStatus.Reserved) {
				reservedSa1Codes.push(sa1.sa1_code);
			}
		});

		soldSa1Codes.forEach((sa1Code) => this.updateSa1MapFeatureState(sa1Code, { isSold: true, isSelected: false }));
		reservedSa1Codes.forEach((sa1Code) =>
			this.updateSa1MapFeatureState(sa1Code, { isReserved: true, isSelected: false })
		);
		soldCdarIds.forEach((cdarId) => this.updateCdarMapFeatureState(cdarId, { isSold: true, isSelected: false }));
		reservedCdarIds.forEach((cdarId) =>
			this.updateCdarMapFeatureState(cdarId, { isReserved: true, isSelected: false })
		);
	};
	showSa1WithinNonRoyaltyCdarOnMap = () => {
		let sa1s = this.state.cdar
			.filter((cdar) => !cdar.is_royalty)
			.flatMap((cdar) => {
				return cdar.sa1s.map((sa1) => sa1);
			});
		sa1s = sa1s.filter((sa1) => sa1.status);
		let sa1Ids = sa1s.map((sa1) => sa1.sa1_code);

		if (sa1Ids.length === 0) {
			sa1Ids = [0];
		}
		console.log('sa1Ids', sa1Ids);

		//update layer filter (async operation as it goes to GPU via worker thread)
		this.map.setFilter(EMapLayers.Sa1Fill, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
		this.map.setFilter(EMapLayers.Sa1Label, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
		this.map.setFilter(EMapLayers.Sa1HoverOutline, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
		this.map.setFilter(EMapLayers.Sa1Outline, ['match', ['get', 'sa1_code'], sa1Ids, true, false]);
	};
	showRoyaltyCdarByCdaOnMap = () => {
		let royaltyCdars = this.state.cdar.filter((cdar) => cdar.is_royalty).map((cdar) => cdar);
		royaltyCdars = royaltyCdars.filter((cdar) => cdar.status);
		let royaltyCdarIds = royaltyCdars.map((cdar) => cdar.cdar_id);

		if (royaltyCdarIds.length === 0) {
			royaltyCdarIds = [0];
		}
		console.log('royaltyCdarIds', royaltyCdarIds);

		//update layer filter (async operation as it goes to GPU via worker thread)
		this.map.setFilter(EMapLayers.CdarFill, ['match', ['get', 'cdar_id'], royaltyCdarIds, true, false]);
		this.map.setFilter(EMapLayers.CdarLabel, ['match', ['get', 'cdar_id'], royaltyCdarIds, true, false]);
		this.map.setFilter(EMapLayers.RoyaltyCdarFillPattern, ['match', ['get', 'cdar_id'], royaltyCdarIds, true, false]);
		this.map.setFilter(EMapLayers.CdarOutline, ['match', ['get', 'cdar_id'], royaltyCdarIds, true, false]);
	};
	navigateMapToStateZoomLevel = () => {
		this.hideAllNonRoyaltySa1OnMap();
		this.hideAllRoyaltyCdarOnMap();
		this.hideAllTransactionStatusLayersOnMap();
		this.fitMapToStateBounds();
		//this.currentZoomLevel = EMapZoomLevels.State;
	};
	hideAllNonRoyaltySa1OnMap = () => {
		this.map.setFilter(EMapLayers.Sa1Fill, ['match', ['get', 'sa1_code'], [0], true, false]);
		this.map.setFilter(EMapLayers.Sa1Label, ['match', ['get', 'sa1_code'], [0], true, false]);
		this.map.setFilter(EMapLayers.Sa1HoverOutline, ['match', ['get', 'sa1_code'], [0], true, false]);
		this.map.setFilter(EMapLayers.Sa1Outline, ['match', ['get', 'sa1_code'], [0], true, false]);
	};
	hideAllRoyaltyCdarOnMap = () => {
		this.map.setFilter(EMapLayers.CdarFill, ['match', ['get', 'cdar_id'], [0], true, false]);
		this.map.setFilter(EMapLayers.CdarLabel, ['match', ['get', 'cdar_id'], [0], true, false]);
		this.map.setFilter(EMapLayers.CdarOutline, ['match', ['get', 'cdar_id'], [0], true, false]);
		this.map.setFilter(EMapLayers.RoyaltyCdarFillPattern, ['match', ['get', 'cdar_id'], [0], true, false]);
	};

	hideAllTransactionStatusLayersOnMap = () => {
		const royaltyCdars = this.state.cdar.filter((cdar) => cdar.is_royalty);
		const nonRoyaltySa1s = this.state.cdar
			.filter((cdar) => !cdar.is_royalty)
			.flatMap((cdar) => cdar.sa1s.map((sa1) => sa1));
		const soldCdarIds: number[] = [0];
		const reservedCdarIds: number[] = [0];
		const soldSa1Codes: number[] = [0];
		const reservedSa1Codes: number[] = [0];

		royaltyCdars.forEach((rCdar) => {
			if (rCdar.status === ERevAreaSaleStatus.Sold) {
				soldCdarIds.push(rCdar.cdar_id);
			} else if (rCdar.status === ERevAreaSaleStatus.Reserved) {
				reservedCdarIds.push(rCdar.cdar_id);
			}
		});

		nonRoyaltySa1s.forEach((sa1) => {
			if (sa1.status === ERevAreaSaleStatus.Sold) {
				soldSa1Codes.push(sa1.sa1_code);
			} else if (sa1.status === ERevAreaSaleStatus.Reserved) {
				reservedSa1Codes.push(sa1.sa1_code);
			}
		});

		soldSa1Codes.forEach((sa1Code) => this.updateSa1MapFeatureState(sa1Code, { isSold: false }));
		reservedSa1Codes.forEach((sa1Code) => this.updateSa1MapFeatureState(sa1Code, { isReserved: false }));
		soldCdarIds.forEach((cdarId) => this.updateCdarMapFeatureState(cdarId, { isSold: false }));
		reservedCdarIds.forEach((cdarId) => this.updateCdarMapFeatureState(cdarId, { isReserved: false }));
	};

	fitMapToStateBounds = () => {
		const state = this.getSelectedState();
		const bbox = UtilityService.getMapBoxBoundingBox(state.bounds as IGeoBound);
		this.map.fitBounds(bbox);
	};
	getSelectedState = () => {
		const state = this.state.ausStates.find((s) => this.state.selectedAusStateId === s.id);
		if (!state) {
			throw new Error('Unable to find state for showing suburbs');
		}
		return state;
	};

	onMapLoadComplete = (map: mapboxgl.Map) => {
		this.map = map;
		this.fitMapToAusState();
		this.addMapSources();
		this.addMapLayers();
		this.registerMapEvents();
	};

	registerMapEvents = () => {
		this.registerMapHoverEvent();
		this.registerMapMoveEvents();
	};
	registerMapMoveEvents = () => {
		this.map.on('movestart', () => {
			this.map.off('mousemove', this.onMapHoverStart);
		});
		this.map.on('moveend', () => {
			this.map.on('mousemove', this.onMapHoverStart);
		});
	};
	registerMapHoverEvent = () => {
		this.map.on('mousemove', this.onMapHoverStart);

		/**
		 * Mousemove event fires on entire map and its implementation
		 * has logic to clear existing hover outlines and tooltip if it does not find any feature underneath the
		 * mouse
		 *
		 * Essentially mousemove itself clears up the hover styles that it had applied
		 *
		 * In certain cases though mousemove will not be able to do job such as when the map is positioned in such
		 * a way that suburb or CDA boundary is at the edge of right side panel and mouse directly exits the map
		 * view without moving over any other areas. In such cases the hover styles and tooltip will remain
		 *
		 * So for these edge cases we need to listen to mouseleave events and we need listen to each layer
		 * seperately as the callback does not accept array of values
		 */
		this.map.on('mouseleave', EMapLayers.Sa1Fill, this.onMapHoverEnd);
		this.map.on('mouseleave', EMapLayers.CdarFill, this.onMapHoverEnd);
		this.map.on('mouseleave', EMapLayers.StateFill, this.onMapHoverEnd);
	};
	onMapHoverStart = (e: IMapboxMouseMoveEvent) => {
		const features = this.map.queryRenderedFeatures(e.point, {
			layers: [EMapLayers.CdarFill, EMapLayers.Sa1Fill, EMapLayers.StateFill]
		});
		this.map.getCanvas().style.cursor = 'pointer';

		if (features.length === 0) {
			this.cleanExistingHoverArea();
			this.popup.remove();
			return;
		}
		/**
		 * IMPORTANT - the sequence in which the hover functions called is important. DO NOT CHANGE without
		 * careful consideration of the impact
		 */
		const isHoverOnSa1 = features.find((f) => f.layer.id === EMapLayers.Sa1Fill);
		if (isHoverOnSa1) {
			const sa1Feature = isHoverOnSa1 as ISa1GeoJsonFeature;
			this.onHoverSa1(sa1Feature, e);
			return;
		}

		const isHoverOnCdar = features.find((f) => f.layer.id === EMapLayers.CdarFill);
		if (isHoverOnCdar) {
			const cdarFeature = isHoverOnCdar as ICdarGeoJsonFeature;
			this.onHoverCdar(cdarFeature, e);
			return;
		}
		const isHoverOnState = features.find((f) => f.layer.id === EMapLayers.StateFill);
		if (isHoverOnState) {
			const stateFeature = isHoverOnState as IStateGeoJsonFeature;
			this.onHoverState(stateFeature, e);
			return;
		}
	};
	onHoverSa1 = (sa1Feature: ISa1GeoJsonFeature, e: IMapboxMouseMoveEvent) => {
		const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];

		//need to update state in feature to remove hover outline
		this.cleanExistingHoverArea();
		this.hoverState.sa1 = sa1Feature.properties.sa1_code;
		this.updateSa1MapFeatureState(this.hoverState.sa1, { hover: true });

		//tooltip logic
		const tooltipContent: ITooltipContent[] = [{ label: 'SA1', value: sa1Feature.properties.sa1_code }];
		const cdar = lodashFind(this.state.cdar, { sa1s: [{ sa1_code: this.hoverState.sa1 }] });
		const sa1 = lodashFind(cdar?.sa1s, { sa1_code: this.hoverState.sa1 });
		if (cdar) {
			tooltipContent.push({
				label: 'CDAR',
				value: cdar.name
			});
		}

		if (sa1) {
			tooltipContent.push(this.getSaleStatusHtmlForTooltip(sa1.status));

			if (sa1.status === ERevAreaSaleStatus.Sold) {
				tooltipContent.push({ label: 'CUSTOMER', value: sa1.agency_name });
			} else {
				tooltipContent.push({ label: 'PROSPECTIVE CUSTOMER', value: sa1.agency_name });
			}
			if (sa1.status === ERevAreaSaleStatus.Reserved && sa1.t_reservation_expiry_at) {
				tooltipContent.push({
					label: 'RESERVE EXPIRY',
					value: UtilityService.getTimeAndDateWithTimeZone(sa1.t_reservation_expiry_at, 'PP')
				});
			}
		}

		const tooltipHtml = UtilityService.getHtmlTableForTooltip(tooltipContent);

		this.popup.setLngLat(coordinates).setHTML(tooltipHtml).addTo(this.map);
	};

	onHoverState = (stateFeature: IStateGeoJsonFeature, e: IMapboxMouseMoveEvent) => {
		//some islands have not been assigned id's in mapbox tilesets hence this condition
		if (!stateFeature.properties.state_id) {
			return;
		}

		const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];

		//need to update state in feature to remove hover outline
		this.cleanExistingHoverArea();
		this.hoverState.state = stateFeature.properties.state_id;

		this.updateStateMapFeatureState(this.hoverState.state, { hover: true });

		//tooltip logic
		const tooltipContent = `${stateFeature.properties.name}`;

		this.popup.setLngLat(coordinates).setHTML(tooltipContent).addTo(this.map);
	};

	onHoverCdar = (cdarFeature: ICdarGeoJsonFeature, e: IMapboxMouseMoveEvent) => {
		//need to update state in feature to remove hover outline
		this.cleanExistingHoverArea();
		this.hoverState.cdar = cdarFeature.id;
		this.updateCdarMapFeatureState(this.hoverState.cdar, { hover: true });

		//tooltip logic
		const coordinates: LngLatLike = [e.lngLat.lng, e.lngLat.lat];
		const cdarProperties = cdarFeature.properties;
		const cdar = this.state.cdar.find((cdar) => cdar.cdar_id === cdarProperties.cdar_id);

		if (!cdar) {
			throw new Error(`Could not retrieve cdar record for ${cdarProperties.cdar_id}`);
		}
		const tooltipContent = [{ label: 'CDAR', value: cdar.name }];

		if (cdar.is_royalty) {
			tooltipContent.push({ label: 'ROYALTY', value: 'Yes' });
			tooltipContent.push(this.getSaleStatusHtmlForTooltip(cdar.status));
			if (cdar.status === ERevAreaSaleStatus.Reserved && cdar.t_reservation_expiry_at) {
				tooltipContent.push({
					label: 'RESERVE EXPIRY',
					value: UtilityService.getTimeAndDateWithTimeZone(cdar.t_reservation_expiry_at, 'PP')
				});
			}

			if (cdar.agency_name) {
				if (cdar.status === 'sold') {
					tooltipContent.push({ label: 'CUSTOMER', value: cdar.agency_name });
				} else {
					tooltipContent.push({ label: 'PROSPECTIVE CUSTOMER', value: cdar.agency_name });
				}
			}
		} else {
			tooltipContent.push({ label: 'ROYALTY', value: 'No' });
		}

		const tooltipHtml = UtilityService.getHtmlTableForTooltip(tooltipContent);

		this.popup.setLngLat(coordinates).setHTML(tooltipHtml).addTo(this.map);
	};

	getSaleStatusHtmlForTooltip = (status: ERevAreaSaleStatus | null) => {
		if (status === ERevAreaSaleStatus.Sold) {
			return {
				label: 'STATUS',
				value: `<div style="display:inline-block;border-radius:3px;padding:2px 5px;background-color:${this.state.mapColors['sales']['sold']}"><span style="font-weight: bold;color:${this.state.mapColors['sales']['soldLabel']};">SOLD</span></div>`
			};
		} else if (status === ERevAreaSaleStatus.Reserved) {
			return {
				label: 'STATUS',
				value: `<div style="display:inline-block;border-radius:3px;padding:2px 5px;background-color:${this.state.mapColors['sales']['reserved']}"><span style="font-weight: bold;color:${this.state.mapColors['sales']['reservedLabel']};">RESERVED</span></div>`
			};
		} else {
			return {
				label: 'STATUS',
				value: 'Available'
			};
		}
	};
	onMapHoverEnd = () => {
		/* if (this.hoverState.cda !== 0) {
		this.updateCdaMapFeatureState(this.hoverState.cda, { hover: false });
		this.hoverState.cda = 0;
	} */

		if (this.hoverState.cdar !== 0) {
			this.updateCdarMapFeatureState(this.hoverState.cdar, { hover: false });
			this.hoverState.cdar = 0;
		}

		if (this.hoverState.sa1 !== 0) {
			this.updateSa1MapFeatureState(this.hoverState.sa1, { hover: false });
			this.hoverState.sa1 = 0;
		}

		if (this.hoverState.state !== 0) {
			this.updateStateMapFeatureState(this.hoverState.state, { hover: false });
			this.hoverState.state = 0;
		}

		this.map.getCanvas().style.cursor = '';
		this.popup.remove();
	};
	updateCdarMapFeatureState = (cdarId: number, options: TCdarMapFeatureState = { clicked: true }) => {
		this.map.setFeatureState(
			{
				source: 'cdar',
				sourceLayer: Config.MapTilesets.AustraliaCdar.SourceLayer,
				id: cdarId
			},
			options
		);
	};
	updateStateMapFeatureState = (stateId: number, options: TAusStateMapFeatureState = { hover: true }) => {
		this.map.setFeatureState(
			{
				source: 'state',
				sourceLayer: Config.MapTilesets.AustraliaState.SourceLayer,
				id: stateId
			},
			options
		);
	};

	updateSa1MapFeatureState = (sa1Code: number, options: TSa1MapFeatureState = { clicked: true }) => {
		this.map.setFeatureState(
			{
				source: 'sa1',
				sourceLayer: Config.MapTilesets.AustraliaSa1.SourceLayer,
				id: sa1Code
			},
			options
		);
	};

	cleanExistingHoverArea = () => {
		/* if (this.hoverState.cda) {
		this.updateCdaMapFeatureState(this.hoverState.cda, { hover: false });
	} */

		if (this.hoverState.sa1) {
			this.updateSa1MapFeatureState(this.hoverState.sa1, { hover: false });
		}

		if (this.hoverState.cdar) {
			this.updateCdarMapFeatureState(this.hoverState.cdar, { hover: false });
		}

		if (this.hoverState.state) {
			this.updateStateMapFeatureState(this.hoverState.state, { hover: false });
		}
	};

	addMapSources = () => {
		this.map.addSource('sa1', {
			type: 'vector',
			url: `mapbox://${Config.MapTilesets.AustraliaSa1.Id}?optimize=true`,
			promoteId: { [Config.MapTilesets.AustraliaSa1.SourceLayer]: 'sa1_code' }
		});

		this.map.addSource('cdar', {
			type: 'vector',
			url: `mapbox://${Config.MapTilesets.AustraliaCdar.Id}?optimize=true`,
			promoteId: { [Config.MapTilesets.AustraliaCdar.SourceLayer]: 'cdar_id' }
		});
		this.map.addSource('state', {
			type: 'vector',
			url: `mapbox://${Config.MapTilesets.AustraliaState.Id}?optimize=true`,
			promoteId: { [Config.MapTilesets.AustraliaState.SourceLayer]: 'state_id' }
		});
	};

	addMapLayers = () => {
		//State
		this.map.addLayer({
			id: EMapLayers.StateFill,
			type: 'fill',
			source: 'state',
			'source-layer': Config.MapTilesets.AustraliaState.SourceLayer,
			maxzoom: 5,
			paint: {
				'fill-color': Config.MapColors.available,
				'fill-outline-color': Config.MapColors.available
			}
		});

		this.map.addLayer(
			{
				id: EMapLayers.StateOutline,
				type: 'line',
				source: 'state',
				maxzoom: 5,
				'source-layer': Config.MapTilesets.AustraliaState.SourceLayer,
				layout: {
					'line-cap': 'square',
					'line-join': 'bevel',
					'line-round-limit': 1.05,
					'line-miter-limit': 2
				},
				paint: {
					'line-color': this.state.mapColors['state']['outline'],
					'line-width': 1
					//'line-width': ['case', ['boolean', ['feature-state', 'clicked'], false], 4, 2],
					/* 'line-dasharray': [2, 1] */
				}
			},
			'road-label'
		);

		this.map.addLayer({
			id: EMapLayers.StateHoverOutline,
			type: 'line',
			source: 'state',
			maxzoom: 5,
			'source-layer': Config.MapTilesets.AustraliaState.SourceLayer,
			layout: {
				'line-cap': 'round',
				'line-join': 'round',
				'line-round-limit': 1.05
			},
			paint: {
				'line-color': this.state.mapColors['state']['outline'],
				'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.5, 0],
				'line-offset': 2,
				'line-width': 3
			}
		});

		//SA1
		this.map.addLayer(
			{
				id: EMapLayers.Sa1Fill,
				type: 'fill',
				source: 'sa1',
				'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
				filter: ['match', ['get', 'sa1_code'], [''], true, false],
				paint: {
					'fill-color': [
						'case',
						['boolean', ['feature-state', 'isSold'], false],
						this.state.mapColors['sales']['sold'],
						['boolean', ['feature-state', 'isReserved'], false],
						this.state.mapColors['sales']['reserved'],
						['boolean', ['feature-state', 'isSelected'], false],
						this.state.mapColors['sales']['selectedArea'],
						Config.MapColors.available
					],
					'fill-opacity': 0.4,
					/* 'fill-outline-color': Config.MapColors.available */
					'fill-outline-color': this.state.mapColors['sales']['sa1Outline']
				}
			},
			'road-label'
		);

		this.map.addLayer(
			{
				id: EMapLayers.Sa1Outline,
				type: 'line',
				source: 'sa1',
				'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
				filter: ['match', ['get', 'sa1_code'], [''], true, false],
				layout: {
					'line-cap': 'square',
					'line-join': 'bevel',
					'line-round-limit': 1.05,
					'line-miter-limit': 2
				},
				paint: {
					'line-color': this.state.mapColors['sales']['sa1Outline'],
					'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 13, 1, 16, 3],
					//'line-width': ['case', ['boolean', ['feature-state', 'clicked'], false], 4, 2],
					'line-dasharray': [2, 1]
				}
			},
			'road-label'
		);

		this.map.addLayer({
			id: EMapLayers.Sa1Label,
			type: 'symbol',
			source: 'sa1',
			minzoom: 14,
			'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
			filter: ['match', ['get', 'sa1_code'], [''], true, false],
			layout: {
				'text-allow-overlap': true,
				'text-padding': 0,
				'text-size': ['interpolate', ['exponential', 0.5], ['zoom'], 14, 14, 17, 30],
				'text-field': ['get', 'sa1_code']
			},
			paint: {
				'text-halo-width': 2,
				'text-halo-color': '#ffffff'
			}
		});

		this.map.addLayer(
			{
				id: EMapLayers.Sa1HoverOutline,
				type: 'line',
				source: 'sa1',
				'source-layer': Config.MapTilesets.AustraliaSa1.SourceLayer,
				layout: {
					'line-cap': 'round',
					'line-join': 'round',
					'line-round-limit': 1.05
				},
				paint: {
					'line-color': this.state.mapColors['sales']['sa1Outline'],
					'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.3, 0],
					'line-offset': 2,
					'line-width': ['interpolate', ['exponential', 1], ['zoom'], 14, 4, 16, 8]
				}
			},
			'road-label'
		);

		//CDAR
		this.map.addLayer(
			{
				id: EMapLayers.CdarFill,
				type: 'fill',
				source: 'cdar',
				'source-layer': Config.MapTilesets.AustraliaCdar.SourceLayer,
				filter: ['match', ['get', 'cdar_id'], [''], true, false],
				paint: {
					'fill-color': [
						'case',
						['boolean', ['feature-state', 'isSelected'], false],
						this.state.mapColors['sales']['selectedArea'],
						['boolean', ['feature-state', 'isSold'], false],
						this.state.mapColors['sales']['sold'],
						['boolean', ['feature-state', 'isReserved'], false],
						this.state.mapColors['sales']['reserved'],
						Config.MapColors.available
					],
					'fill-outline-color': Config.MapColors.available,
					'fill-opacity': 0.3
				}
			},
			'road-label'
		);

		this.map.addLayer(
			{
				id: EMapLayers.CdarHoverOutline,
				type: 'line',
				source: 'cdar',
				'source-layer': Config.MapTilesets.AustraliaCdar.SourceLayer,
				layout: {
					'line-cap': 'round',
					'line-join': 'round',
					'line-round-limit': 1.05
				},
				paint: {
					'line-color': this.state.mapColors['sales']['cdarOutline'],
					'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.3, 0],
					'line-offset': 2,
					'line-width': ['interpolate', ['exponential', 1], ['zoom'], 14, 4, 16, 8]
				}
			},
			'road-label'
		);

		this.map.addLayer({
			id: EMapLayers.CdarLabel,
			type: 'symbol',
			source: 'cdar',
			minzoom: 10,
			'source-layer': Config.MapTilesets.AustraliaCdar.SourceLayer,
			filter: ['match', ['get', 'cdar_id'], [''], true, false],
			layout: {
				'text-field': ['get', 'cdar_name'],
				'text-allow-overlap': true,
				'text-padding': 0,
				'text-size': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 10, 17, 15]
			},
			paint: {
				'text-halo-width': 2,
				'text-halo-color': '#ffffff'
			}
		});

		this.map.addLayer(
			{
				id: EMapLayers.CdarOutline,
				type: 'line',
				source: 'cdar',
				'source-layer': Config.MapTilesets.AustraliaCdar.SourceLayer,
				filter: ['match', ['get', 'cdar_id'], [''], true, false],
				layout: {
					'line-cap': 'square',
					'line-join': 'bevel',
					'line-round-limit': 1.05,
					'line-miter-limit': 2
				},
				paint: {
					'line-color': this.state.mapColors['sales']['cdarOutline'],
					'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 13, 1, 16, 3],
					//'line-width': ['case', ['boolean', ['feature-state', 'clicked'], false], 4, 2],
					'line-dasharray': [2, 1]
				}
			},
			'road-label'
		);

		// Royalty cdar pattern layer
		this.map.loadImage(
			`${process.env.PUBLIC_URL}/map_royalty_cdar.png`,
			(err: unknown, royaltyCdarImage: ImageData) => {
				// Throw an error if something went wrong
				if (err) throw err;

				// Declare the image
				this.map.addImage('royaltyCdar', royaltyCdarImage);
				//this.map.addImage('reserve', reserveImage);

				this.map.addLayer(
					{
						id: EMapLayers.RoyaltyCdarFillPattern,
						type: 'fill',
						source: 'cdar',
						'source-layer': Config.MapTilesets.AustraliaCdar.SourceLayer,
						filter: ['match', ['get', 'cdar_id'], [''], true, false],
						paint: {
							'fill-pattern': 'royaltyCdar',
							//'fill-color': Config.MapColors.sales.sold,
							'fill-opacity': 0.1
						}
					},
					EMapLayers.CdarLabel
				);
			}
		);
	};

	getSystemMapColors = async () => {
		const apiRes: any = await CommonApiService.getSystemSettings('map_colors');
		if (!apiRes?.success) {
			return;
		}
		this.setState({
			mapColors: apiRes.data[0].json.MapColors,
			isShowMap: true
		});
	};

	getMapLegend = () => {
		return [
			{
				label: 'SOLD',
				color: this.state.mapColors['sales']['sold'],
				type: 'area'
			},
			{
				type: 'area',
				label: 'RESERVED',
				color: this.state.mapColors['sales']['reserved']
			},
			{
				type: 'pattern',
				label: 'Royalty',
				color: '',
				patternImageUrl: `${process.env.PUBLIC_URL}/map_royalty_cdar.png`
			}
		];
	};
	getAustralianStates = async () => {
		try {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.ShowAreaLoader
			});
			const states = await CommonApiService.getAustraliaStates();
			this.setState({
				ausStates: states
			});
		} finally {
			this.setState({
				loadingIndicator: ELoadingIndicatorStatus.None
			});
		}
	};
	onChangeAusStateDropdown = (ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
		const stateId = ev.target.value as number;
		this.setState({
			filters: {
				...this.state.filters,
				state_id: stateId
			}
		});
		/* this.setState({
			selectedAgency: null
		}); */
		//this.selectState(stateId);
	};
}
export default AppraisalEnquiry;
