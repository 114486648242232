const STORAGE_KEY_PREFIX = `__rev_sa1_dashboard__`;

function putState<T = Record<string, unknown>>(key: string, storeState: T): boolean {
	if (!localStorage) {
		return false;
	}

	try {
		const serializedState = JSON.stringify(storeState);
		localStorage.setItem(STORAGE_KEY_PREFIX + key, serializedState);
		return true;
	} catch (error) {
		throw new Error('store serialization failed');
	}
}

function getState<T = Record<string, unknown>>(key: string): T | undefined {
	if (!localStorage) {
		return;
	}

	try {
		const serializedState = localStorage.getItem(STORAGE_KEY_PREFIX + key);
		if (serializedState == null) {
			return;
		}
		return JSON.parse(serializedState);
	} catch (error) {
		throw new Error('store deserialization failed');
	}
}

function removeKey(key: string) {
	localStorage.removeItem(STORAGE_KEY_PREFIX + key);
}

export const LocalStorageService = {
	putState,
	getState,
	removeKey
};
