import React from 'react';
import ReportIcon from '@material-ui/icons/InsertChartOutlined';
import AdminIcon from '@material-ui/icons/PersonOutlineOutlined';
import ManageIcon from '@material-ui/icons/MapOutlined';
import StaffIcon from '@material-ui/icons/PersonPinOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Grid, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { INavMenu, INavSubMenu } from 'shared/types/Types';
import { useLocation, Link } from 'react-router-dom';

interface IProps {
	menu?: INavMenu[];
	onClick?: (route: string) => void;
	icon: string;
	label: string;
	route?: string;
	menuId: number;
}

const NavMenu: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const [open, setOpen] = React.useState(false);
	const [isMouseOver, setMouseover] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);
	const classes = useClasses();
	let icon: any;
	const urlPathName = useLocation().pathname;

	const onMouseEnter = () => {
		setMouseover(true);
	};
	const onMouseLeave = () => {
		setMouseover(false);
	};

	const getMouseOverColor = () => {
		return isMouseOver || open ? '#0073CF' : isActiveRoute() ? '#0073CF' : '#767676';
	};

	const isActiveRoute = () => {
		if (props.route) {
			return urlPathName === props.route;
		}
		if (!props.menu) {
			return false;
		}
		const record = props.menu.find((m: any) => m.route === urlPathName);
		return record?.parent_id === props.menuId;
	};

	const onClickLevel2Menu = (m: INavSubMenu) => {
		//props.onClick && props.onClick(m.route);
		setOpen((prevOpen) => !prevOpen);
	};

	const onClickLevel1Menu = () => {
		if (props.menu) {
			setOpen((prevOpen) => !prevOpen);
		}
		props.onClick && props.route && props.onClick(props.route);
	};

	if (props.icon === 'report') {
		icon = <ReportIcon style={{ color: getMouseOverColor() }} />;
	} else if (props.icon === 'manage-area') {
		icon = <ManageIcon style={{ color: getMouseOverColor() }} />;
	} else if (props.icon === 'admin-sale') {
		icon = <AdminIcon style={{ color: getMouseOverColor() }} />;
	} else if (props.icon === 'staff-sale') {
		icon = <StaffIcon style={{ color: getMouseOverColor() }} />;
	}

	const onCloseLevel2Menu = (e: React.MouseEvent<Document, MouseEvent>) => {
		if (anchorRef.current && anchorRef.current.contains(e.target as Node)) {
			return;
		}

		setOpen(false);
	};

	const renderLevel2Menus = () => {
		if (!props.menu) {
			return null;
		}

		return (
			<Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={onCloseLevel2Menu}>
								<MenuList className="menu-list">
									{props.menu &&
										props.menu.map((m: any) => {
											return (
												<MenuItem key={m.label} onClick={() => onClickLevel2Menu(m)}>
													<Link to={m.route}>{m.label}</Link>
												</MenuItem>
											);
										})}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		);
	};

	const renderParentMenu = () => {
		return (
			<>
				{icon}
				<span className={classes.label} style={{ color: getMouseOverColor() }}>
					{props.label}
				</span>
			</>
		);
	};

	return (
		<>
			<Grid item className="nav-menu-item menu-list">
				<Grid
					container
					alignItems="center"
					justify="center"
					onClick={!props.route ? onClickLevel1Menu : undefined}
					ref={anchorRef}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
				>
					{props.route ? <Link to={props.route}>{renderParentMenu()}</Link> : renderParentMenu()}
				</Grid>
			</Grid>
			{renderLevel2Menus()}
		</>
	);
};

const useClasses = makeStyles(() => ({
	label: {
		marginLeft: 3
	}
}));

export default NavMenu;
