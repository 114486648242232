import {
	ICda,
	ICdaSearchResponse,
	ICdaWithStateName,
	IDirtyCda,
	IGetCdaChangelogHttpResponse,
	IHttpServiceResponse,
	IMapServiceSyncStatus,
	ISuburb
} from 'shared/types/Types';
import HttpService from './HttpService';
import UtilityService from './UtilityService';

interface ISuburbStatusResponse {
	is_sold: boolean;
}

interface IGetCdaResponse {
	cda: ICda[];
	not_assigned_suburbs: ISuburb[];
}

interface IGetCdaQueryParams {
	stateId: number;
	includeTemp?: boolean;
	includingSuburbs?: boolean;
	includingSaleStats?: boolean;
}

const getCda = async (params: IGetCdaQueryParams) => {
	const res = (await HttpService.get('cda', {
		state_id: params.stateId,
		including_tmp: params.includeTemp || false,
		including_suburbs: params.includingSuburbs || false,
		including_sold_stats: params.includingSaleStats || false
	})) as IHttpServiceResponse<IGetCdaResponse>;

	//this is required because tmp_cda_id is required field in ICda interface but is not always returned by API
	//hence we add tmp_cda_id if we do not recieve it from backend
	const cda = res.data.cda.map((cda) => {
		const tmpCda = { ...cda };
		if (!tmpCda.tmp_cda_id) {
			tmpCda.tmp_cda_id = 0;
		}
		return tmpCda;
	});
	res.data.cda = cda;

	return res.data;
};

const createTmpCda = async (cda: ICda) => {
	const sanitizedPayload = UtilityService.replaceEmptyAttributeValuesByNull(cda);
	const res = (await HttpService.post('cda/tmp', sanitizedPayload)) as IHttpServiceResponse<ICda>;
	return res.data;
};

const updateTmpCda = async (cda: ICda) => {
	const sanitizedPayload = UtilityService.replaceEmptyAttributeValuesByNull(cda);
	const res = (await HttpService.put(`cda/tmp/${cda.tmp_cda_id}`, sanitizedPayload)) as IHttpServiceResponse<ICda>;
	return res.data;
};

const deleteTmpCda = async (tmpCdaId: number) => {
	await HttpService.remove(`cda/tmp/${tmpCdaId}`);
};

const deleteCda = async (cdaId: number) => {
	await HttpService.remove(`cda/${cdaId}`);
};

const getSuburbStatus = async (suburbId: number) => {
	return (await HttpService.get(`suburb/${suburbId}/status`)) as IHttpServiceResponse<ISuburbStatusResponse>;
};

const getDirtyCda = async (stateId: number) => {
	const res = (await HttpService.get(`cda/dirty`, { state_id: stateId })) as IHttpServiceResponse<IDirtyCda[]>;
	return res.data;
};

const submitToMapServiceForSync = async (data: { state_id: number }) => {
	//const res = (await HttpService.get(`transaction/submit`, data)) as IHttpServiceResponse<IMapServiceSyncStatus>;
	const res = (await HttpService.get(`transaction/cda/submit`, data)) as IHttpServiceResponse<unknown>;
	return res;
};

const searchTmpCda = async (searchTerm: string): Promise<ICdaSearchResponse[]> => {
	await UtilityService.sleep();
	return Promise.resolve([
		{
			name: 'SOUTH GIPPSLAND SHIRE',
			center: {
				latitude: -38.4758,
				longitude: 145.9451
			},
			id: 268
		},
		{
			name: 'GLEN EIRA CITY',
			center: {
				latitude: -37.8804,
				longitude: 145.0224
			},
			id: 269
		},
		{
			name: 'SWAN HILL RURAL CITY',
			center: {
				latitude: -35.34,
				longitude: 143.5571
			},
			id: 270
		},
		{
			name: 'WARRNAMBOOL CITY',
			center: {
				latitude: -38.3687,
				longitude: 142.4982
			},
			id: 272
		},
		{
			name: 'MELBOURNE CITY',
			center: {
				latitude: -37.815630308792464,
				longitude: 144.95376835038266
			},
			id: 277
		}
	]);
};

const getCdaChangeLog = async (params: { state_id: number }) => {
	const res = (await HttpService.get(`cda/change-log`, {
		state_id: params.state_id
	})) as IHttpServiceResponse<IGetCdaChangelogHttpResponse>;
	return res.data;
};

const getCdaByAgency = async (agencyId: number) => {
	const res = (await HttpService.get(`agency/${agencyId}/sold-cda`)) as IHttpServiceResponse<ICdaWithStateName[]>;
	return res.data;
};

const cleanTmpCda = async () => {
	const res = (await HttpService.get(`clean/tmp/cda`)) as IHttpServiceResponse<unknown>;
	return res.success;
};

const CdaService = {
	getCda,
	createTmpCda,
	updateTmpCda,
	deleteTmpCda,
	getSuburbStatus,
	submitToMapServiceForSync,
	searchTmpCda,
	deleteCda,
	getCdaChangeLog,
	getDirtyCda,
	getCdaByAgency,
	cleanTmpCda
};

export default CdaService;
