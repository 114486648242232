import {
	Button,
	Grid,
	makeStyles,
	Paper,
	Typography,
	CircularProgress,
	FormControlLabel,
	Switch,
	FormHelperText
} from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import ConfirmModalWithMessage from 'shared/components/ConfirmModalWithMessage';
import ToggleContent from 'shared/components/ToggleContent';
import UseNotification from 'shared/hooks/UseNotification';
import CdarService from 'shared/services/CdarService';
import CdaService from 'shared/services/CdaService';
import CommonApiService from 'shared/services/CommonApiService';
import { ENotificationTypes, IMapSyncJob, IMapColor, IMapSystemSetting, ISettingApiParams } from 'shared/types/Types';
import ConfigureColorModal from './ConfigureColorModal';
import ConfigureSystemModal from './ConfigureSystemModal';
import { LoggedInUserContext } from 'shared/providers/LoggedInUserProvider';

enum EActivityStatus {
	ClearingTmpCda = 'clearing-tmp-cda',
	ClearingTmpCdar = 'clearing-tmp-cdar',
	ColorConfiguration = 'configure-color',
	SystemConfiguration = 'configure-system',
	None = 'none'
}

enum ELoadingAction {
	ColorLoading = 'color-loading',
	SystemLoading = 'system-loading',
	None = 'none'
}

const Settings: React.FunctionComponent = () => {
	const classes = useStyles();
	const { addNotification } = UseNotification();
	const [activityStatus, setActivityStatus] = useState<EActivityStatus>(EActivityStatus.None);
	const [mapColors, setMapColors] = useState(null);
	const [systemSettings, setSystemSettings] = useState(null);
	const [loaderStatus, setLoaderStatus] = React.useState<ELoadingAction>(ELoadingAction.None);
	const [isCustomerOnSaleScreen, setIsCustomerOnSaleScreen] = React.useState<boolean>(true);
	const { loggedInUser } = useContext(LoggedInUserContext);

	useEffect(() => {
		if (loggedInUser?.role === 'Sales' && localStorage.getItem('isShowCustomerOnSaleScreen')) {
			const isCustomerOnSaleScreen: boolean = localStorage.getItem('isShowCustomerOnSaleScreen') === 'true';
			setIsCustomerOnSaleScreen(isCustomerOnSaleScreen);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClickClearTmpCda = async (hideConfirmationModal: () => void) => {
		setActivityStatus(EActivityStatus.ClearingTmpCda);
		const res = await CdaService.cleanTmpCda();
		if (res) {
			addNotification({
				type: ENotificationTypes.Success,
				message: 'Tmp CDA changes cleared successfully'
			});
			hideConfirmationModal();
		} else {
			addNotification({
				type: ENotificationTypes.Error,
				message: 'Error clearing Tmp CDA changes'
			});
		}
		setActivityStatus(EActivityStatus.None);
	};
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsCustomerOnSaleScreen(event.target.checked);
		localStorage.setItem('isShowCustomerOnSaleScreen', event.target.checked.toString());
	};

	const onClickClearTmpCdar = async (hideConfirmationModal: () => void) => {
		setActivityStatus(EActivityStatus.ClearingTmpCdar);
		const res = await CdarService.cleanTmpCdar();
		if (res) {
			addNotification({
				type: ENotificationTypes.Success,
				message: 'Tmp CDAR changes cleared successfully'
			});
			hideConfirmationModal();
		} else {
			addNotification({
				type: ENotificationTypes.Error,
				message: 'Error Tmp clearing CDAR changes'
			});
		}
		setActivityStatus(EActivityStatus.None);
	};

	const onClickConfigureColorButton = async () => {
		setLoaderStatus(ELoadingAction.ColorLoading);
		const apiRes: any = await CommonApiService.getSystemSettings('map_colors');
		setLoaderStatus(ELoadingAction.None);
		if (!apiRes?.success) {
			return;
		}
		setMapColors(apiRes.data[0].json.MapColors);
		setActivityStatus(EActivityStatus.ColorConfiguration);
	};
	const onClickConfigureSystemButton = async () => {
		setLoaderStatus(ELoadingAction.SystemLoading);
		const apiRes: any = await CommonApiService.getSystemSettings('system');
		setLoaderStatus(ELoadingAction.None);
		if (!apiRes?.success) {
			return;
		}
		setSystemSettings(apiRes.data[0].json.System);
		setActivityStatus(EActivityStatus.SystemConfiguration);
	};

	const onClickCancelConfigureMapModal = () => {
		setActivityStatus(EActivityStatus.None);
	};

	const onClickSubmitMapColor = async (colorObj: IMapColor) => {
		const apiBodyParams = getColorObjForSaveApi(colorObj);
		const apiRes = await CommonApiService.saveSystemConfiguration(apiBodyParams);
		if (!apiRes?.success) {
			return;
		}
		addNotification({
			type: ENotificationTypes.Success,
			message: 'Map Colors updated successfully'
		});
		setActivityStatus(EActivityStatus.None);
	};
	const getColorObjForSaveApi = (colorObj: IMapColor): ISettingApiParams => {
		return {
			type: 'map_colors',
			jsonData: {
				MapColors: colorObj,
				System: null
			}
		};
	};
	const onClickSubmitMapSystem = async (systemSettingObj: IMapSystemSetting) => {
		const apiBodyParams = getSystemConfigObjForSaveApi(systemSettingObj);
		const apiRes = await CommonApiService.saveSystemConfiguration(apiBodyParams);
		if (!apiRes?.success) {
			return;
		}
		addNotification({
			type: ENotificationTypes.Success,
			message: 'System configuration updated successfully'
		});
		setActivityStatus(EActivityStatus.None);
	};
	const getSystemConfigObjForSaveApi = (systemObj: IMapSystemSetting): ISettingApiParams => {
		return {
			type: 'system',
			jsonData: {
				MapColors: null,
				System: systemObj
			}
		};
	};

	return (
		<Grid container direction="column" className={classes.settings}>
			<Paper className={classes.settingsPaper}>
				<Typography className="mb10" color="secondary" variant="h5" style={{ marginBottom: 20 }}>
					Settings
				</Typography>
				<Grid container spacing={2} direction="column">
					{loggedInUser?.role === 'Administrator' ? (
						<>
							<Grid item container justify="space-between">
								<Typography className="mb10" color="secondary" variant="h6">
									Clear all CDA tmp changes
								</Typography>
								<ToggleContent
									toggle={(show) => (
										<Button onClick={show} color="primary" variant="outlined">
											clear
										</Button>
									)}
									content={(hide) => (
										<ConfirmModalWithMessage
											isLoading={activityStatus === EActivityStatus.ClearingTmpCda}
											onClickConfirm={() => {
												onClickClearTmpCda(hide);
											}}
											onClickCancel={hide}
											message={'Are you sure you want to clear all Tmp CDA changes across entire Australia ?'}
											title="Clear Tmp CDA changes ?"
										/>
									)}
								/>
							</Grid>
							<Grid item container justify="space-between">
								<Typography className="mb10" color="secondary" variant="h6">
									Clear all CDAR tmp changes
								</Typography>
								<ToggleContent
									toggle={(show) => (
										<Button onClick={show} color="primary" variant="outlined">
											clear
										</Button>
									)}
									content={(hide) => (
										<ConfirmModalWithMessage
											isLoading={activityStatus === EActivityStatus.ClearingTmpCdar}
											onClickConfirm={() => {
												onClickClearTmpCdar(hide);
											}}
											onClickCancel={hide}
											message={'Are you sure you want to clear all Tmp CDAR changes across entire Australia ?'}
											title="Clear Tmp CDAR changes ?"
										/>
									)}
								/>
							</Grid>
							<Grid item container justify="space-between">
								<Typography className="mb10" color="secondary" variant="h6">
									System configuration
								</Typography>
								<div className={classes.wrapper}>
									<Button
										disabled={loaderStatus === ELoadingAction.SystemLoading}
										variant="outlined"
										color="primary"
										onClick={onClickConfigureSystemButton}
									>
										Configure
									</Button>
									{loaderStatus === ELoadingAction.SystemLoading && (
										<CircularProgress size={24} className={classes.buttonProgress} />
									)}
								</div>
							</Grid>
							<Grid item container justify="space-between">
								<Typography className="mb10" color="secondary" variant="h6">
									Map Colors
								</Typography>

								<div className={classes.wrapper}>
									<Button
										disabled={loaderStatus === ELoadingAction.ColorLoading}
										variant="outlined"
										color="primary"
										onClick={onClickConfigureColorButton}
									>
										Configure
									</Button>
									{loaderStatus === ELoadingAction.ColorLoading && (
										<CircularProgress size={24} className={classes.buttonProgress} />
									)}
								</div>
							</Grid>
						</>
					) : (
						<Grid item container direction="column">
							<FormControlLabel
								control={
									<Switch checked={isCustomerOnSaleScreen} onChange={handleChange} name="checkedB" color="primary" />
								}
								label="Show customer on sales screen"
							/>
							<FormHelperText>Depend on this flag Customer name will display on hover of SA1 OR Cdar.</FormHelperText>
						</Grid>
					)}
					{activityStatus === EActivityStatus.ColorConfiguration && (
						<ConfigureColorModal
							onClickSubmitMapColor={onClickSubmitMapColor}
							onClickClose={onClickCancelConfigureMapModal}
							mapColors={mapColors}
						/>
					)}
					{activityStatus === EActivityStatus.SystemConfiguration && (
						<ConfigureSystemModal
							onClickSubmitMapSystem={onClickSubmitMapSystem}
							onClickClose={onClickCancelConfigureMapModal}
							systemSettings={systemSettings}
						/>
					)}
				</Grid>
			</Paper>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	settings: {
		margin: 10
	},
	settingsPaper: {
		padding: 10,
		margin: '0 auto',
		width: '70%'
	},
	table: {
		borderCollapse: 'collapse'
	},
	tableHeader: {
		border: '1px solid #ccc',
		padding: 8
	},
	tableCell: {
		border: '1px solid #ccc',
		padding: 8
	},
	tableRow: {
		'&hover': {
			background: '#eee'
		}
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	}
}));

export default Settings;
