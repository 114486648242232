import { ICdar, IGetCdarChangelogHttpResponse, IHttpServiceResponse, ISa1, ICdarWithAgency } from 'shared/types/Types';
import HttpService from './HttpService';
import UtilityService from './UtilityService';

interface IGetCdarResponse {
	cdar: ICdar[];
	not_assigned_sa1s: ISa1[];
}
/**
 *
 * @param includeTemp setting this to true will return temp Cdar as well as stable Cdar's with
 * temp cdar's overriting same records in stable cdar's
 */
const getCdar = async (params: { cdaId: number; includeTemp?: boolean; including_sold_stats?: boolean }) => {
	const res = (await HttpService.get('cdar', {
		cda_id: params.cdaId,
		including_tmp: params.includeTemp || false,
		including_sold_stats: params.including_sold_stats || false
	})) as IHttpServiceResponse<IGetCdarResponse>;

	/**
	 * Frontend needs few fields extra in Cdar type which are not need on backend.
	 * These fields are marked as compulsary in Cdar type in frontend so we are adding them below.
	 * Todo - there may be a better approach to handle this. Will revisit in future.
	 */
	const typeSafeCdars = res.data.cdar.map((cdar) => {
		const tmpCdar = { ...cdar };
		if (!tmpCdar.cdar_id) {
			tmpCdar.cdar_id = 0;
		}

		if (!tmpCdar.tmp_cdar_id) {
			tmpCdar.tmp_cdar_id = 0;
		}
		return tmpCdar;
	});

	res.data.cdar = typeSafeCdars;

	return res.data;
};

const createTmpCdar = async (cdar: ICdar) => {
	const sanitizedPayload = UtilityService.replaceEmptyAttributeValuesByNull(cdar);
	const res = (await HttpService.post('cdar/tmp', sanitizedPayload)) as IHttpServiceResponse<ICdar>;
	return res.data;
};

const updateTmpCdar = async (cdar: ICdar) => {
	const sanitizedPayload = UtilityService.replaceEmptyAttributeValuesByNull(cdar);
	const res = (await HttpService.put(`cdar/tmp/${cdar.tmp_cdar_id}`, sanitizedPayload)) as IHttpServiceResponse<ICdar>;
	return res.data;
};

const deleteTmpCdar = async (tmpCdarId: number) => {
	await HttpService.remove(`cdar/tmp/${tmpCdarId}`);
};

const deleteCdar = async (cdarId: number) => {
	await HttpService.remove(`cdar/${cdarId}`);
};

const cleanTmpCdar = async () => {
	const res = (await HttpService.get(`clean/tmp/cdar`)) as IHttpServiceResponse<unknown>;
	return res.success;
};

const submitToMapServiceForSync = async (data: { state_id: number }) => {
	const res = (await HttpService.get(`transaction/cdar/submit`, data)) as IHttpServiceResponse<unknown>;
	return res;
};

const getCdarChangeLog = async (params: { state_id: number }) => {
	const res = (await HttpService.get(`cdar/change-log`, {
		state_id: params.state_id
	})) as IHttpServiceResponse<IGetCdarChangelogHttpResponse>;
	return res.data;
};

const getCdarSa1sByStateId = async (stateId: number) => {
	const res = (await HttpService.get(`state/${stateId}/sa1-cdar`)) as IHttpServiceResponse<ICdarWithAgency[]>;
	return res.data;
};

const CdarService = {
	getCdar,
	updateTmpCdar,
	deleteTmpCdar,
	createTmpCdar,
	deleteCdar,
	cleanTmpCdar,
	submitToMapServiceForSync,
	getCdarChangeLog,
	getCdarSa1sByStateId
};

export default CdarService;
