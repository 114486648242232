/**
 * Author - Vinay
 * Source of this component - https://medium.com/yld-blog/handling-global-notifications-with-reacts-context-api-7d8135510d50
 */

import { Slide, Snackbar, SnackbarCloseReason } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import UseNotification from 'shared/hooks/UseNotification';
import { ENotificationTypes, INotificationContent } from 'shared/types/Types';

const NotificationToast: React.FunctionComponent = (): JSX.Element => {
	const { notifications } = UseNotification();
	const [notificationStack, setNotifications] = React.useState<INotificationContent[]>([]);
	const [open, setOpen] = useState(false);
	const [activeNotification, setActiveNotification] = useState<INotificationContent | undefined>(undefined);

	useEffect(() => {
		if (!activeNotification && notificationStack.length) {
			// Set a new Notification when we don't have an active one
			setActiveNotification({ ...notificationStack[0] });
			setNotifications((prev) => prev.slice(1));
			setOpen(true);
		} else if (notificationStack.length && open && activeNotification) {
			// Close an active Notification when a new one is added
			setOpen(false);
		}
	}, [notificationStack, open, activeNotification]);

	useEffect(() => {
		if (notifications.length) {
			// Add Notification in state when got a new one
			setNotifications((prev) => [...prev, notifications[notifications.length - 1]]);
		}
	}, [notifications]);

	const onRemoveToast = (event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const getSeverity = () => {
		return activeNotification?.type || ENotificationTypes.Info;
	};

	const handleExited = () => {
		if (!activeNotification) {
			throw new Error('Notification toast exit event called without active notification');
		}
		setActiveNotification(undefined);
	};

	return (
		<Snackbar
			open={open}
			onClose={onRemoveToast}
			autoHideDuration={4000}
			anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
			onExited={handleExited}
			TransitionComponent={Slide}
		>
			<Alert variant="filled" severity={getSeverity()}>
				{activeNotification?.message}
			</Alert>
		</Snackbar>
	);
};

export default NotificationToast;
