import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import NotificationToast from './NotificationToast';

export default function DefaultLayout(props: { children: React.ReactNode }): JSX.Element {
	return (
		<React.Fragment>
			<AppBar style={{ backgroundColor: '#fff' }} position="relative">
				<Toolbar variant="dense">
					<Typography style={{ minWidth: 155 }} variant="h6" color="primary" noWrap>
						SA1 Dashboard
					</Typography>
				</Toolbar>
			</AppBar>
			<Grid container direction="row" style={{ height: 'calc(100vh - 48px)' }}>
				{props.children}
			</Grid>
			<NotificationToast />
		</React.Fragment>
	);
}
