import { CircularProgress, makeStyles, Divider, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface IProps {
	title?: string;
	children: React.ReactNode;
	isLoading?: boolean;
	cancelBtnText?: string;
	confirmBtnText?: string;
	size?: 'md' | 'xs' | 'sm' | 'lg' | 'xl';
	onClickCancel: () => void;
	onClickConfirm: () => void;
}

const useStyles = makeStyles((theme) => ({
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
		color: '#fff'
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	}
}));

interface DialogTitleProps extends WithStyles<typeof styles> {
	children: React.ReactNode;
	onClose: () => void;
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: 12
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: 0,
			color: theme.palette.grey[500]
		}
	});
const ConfirmModalWithContent: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const classes = useStyles();
	const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
		const { children, classes, onClose, ...other } = props;
		return (
			<MuiDialogTitle disableTypography className={classes.root} {...other}>
				<Typography variant="h6" className="Modal-title">
					{children}
				</Typography>
				{onClose ? (
					<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
						<CloseIcon />
					</IconButton>
				) : null}
			</MuiDialogTitle>
		);
	});
	return (
		<Dialog open onClose={props.onClickCancel} disableBackdropClick fullWidth maxWidth={props.size || 'sm'}>
			<DialogTitle onClose={props.onClickCancel}>{props.title}</DialogTitle>
			<Divider />
			<DialogContent>{props.children}</DialogContent>
			<DialogActions>
				<Button onClick={props.onClickCancel} variant="contained" className="cancel-btn" disabled={props.isLoading}>
					{props.cancelBtnText}
				</Button>
				<div className={classes.wrapper}>
					<Button onClick={props.onClickConfirm} variant="contained" className="submit-btn" disabled={props.isLoading}>
						{props.confirmBtnText}
					</Button>
					{props.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
				</div>
			</DialogActions>
		</Dialog>
	);
};

ConfirmModalWithContent.defaultProps = {
	title: 'Confirmation',
	cancelBtnText: 'No',
	confirmBtnText: 'Submit',
	isLoading: false
};

export default ConfirmModalWithContent;
