import React, { useEffect } from 'react';
import 'date-fns';
import {
	Grid,
	TextField,
	CircularProgress,
	FormControl,
	Button,
	Typography,
	Select,
	InputLabel,
	MenuItem,
	Paper
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab';
import CommonApiService from 'shared/services/CommonApiService';
import { IAgency, ISalesPerson, IAustraliaState, IProspectiveCustomerOption } from 'shared/types/Types';
import { ISa1AutoComplete, Sa1HistoricalReportFilterParams, IAgencyAutoComplete, ISalesPersonFilter } from '../Report';
import UtilityService from 'shared/services/UtilityService';
import { TransactionTypeOptions } from 'shared/constants/AppData';

interface IProps {
	error: string | null;
	filterUrlParams: any | null;
	reportType: string;
	onClickApplyFilter: (filterParams: Sa1HistoricalReportFilterParams, type: string) => void;
	onReset: () => void;
}
enum EAutoCompleteLoader {
	AgencyLoader = 'agency-loader',
	ProspectiveCustomerLoader = 'prospective-customer-loader',
	SA1Loader = 'sa1-loader',
	SalesPersonLoader = 'sales-person',
	None = 'none'
}

const Sa1HistoricalReportFilter: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const [agencyOptions, setAgencyOptions] = React.useState<IAgencyAutoComplete[]>([]);
	const [agencyId, setAgencyId] = React.useState<IAgencyAutoComplete | null>(null);
	const [sa1Options, setSa1Options] = React.useState<ISa1AutoComplete[]>([]);
	const [salesPersonOptions, setSalesPersonOptions] = React.useState<ISalesPersonFilter[]>([]);
	const [sa1, setSa1] = React.useState<ISa1AutoComplete | null>(null);
	const [transactionType, setTransactionType] = React.useState<string | null>(null);
	const [salesPersonId, setSalesPersonId] = React.useState<ISalesPersonFilter | null>(null);
	const [fromDate, setFromDate] = React.useState<Date | null>(null);
	const [toDate, setToDate] = React.useState<Date | null>(null);
	const [prospectAgencyName, setProspectAgencyName] = React.useState<string | null>(null);
	const [prospectAgencyOptions, setProspectAgencyOptions] = React.useState<string[]>([]);
	const [stateId, setStateId] = React.useState<number | null>();
	const [ausStates, setAusStates] = React.useState<IAustraliaState[]>([]);
	const [loading, setLoading] = React.useState<EAutoCompleteLoader>(EAutoCompleteLoader.None);

	useEffect(() => {
		const getAustralianStates = async () => {
			try {
				const defaultStateOptions = {
					id: 9999,
					name: 'None',
					code: 'all',
					bounds: {
						east: 9999,
						north: 9999,
						west: 9999,
						south: 9999
					},
					latitude: 9999,
					longitude: 9999
				};
				const states = await CommonApiService.getAustraliaStates();
				states.splice(0, 0, defaultStateOptions);
				setAusStates(states);
			} catch (error) {
				throw new Error(error);
			}
		};
		const setInitFromToDate = () => {
			const todayDate = new Date();
			const fromDate = todayDate.getDate() - 30;
			setFromDate(new Date(todayDate.setDate(fromDate)));
			setToDate(new Date());
		};
		getAustralianStates();
		setInitFromToDate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		setFilteredParams();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.filterUrlParams]);
	useEffect(() => {
		if (props.reportType === 'cdar') {
			setSa1(null);
			setSa1Options([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.reportType]);

	const setFilteredParams = () => {
		const filterUrlParams = props.filterUrlParams;
		if (!filterUrlParams || (Object.keys(filterUrlParams).length === 0 && filterUrlParams.constructor !== Object)) {
			return;
		}
		if (filterUrlParams['fromDate']) {
			setFromDate(new Date(filterUrlParams['fromDate']));
		}
		if (filterUrlParams['toDate']) {
			setToDate(new Date(filterUrlParams['toDate']));
		}
		if (filterUrlParams['sa1']) {
			setSa1(getSA1ForAutoComplete(filterUrlParams['sa1']));
			setSa1Options([getSA1ForAutoComplete(filterUrlParams['sa1'])]);
		}
		if (filterUrlParams['stateId']) {
			setStateId(filterUrlParams['stateId']);
		}
		if (filterUrlParams['agencyId'] && filterUrlParams['agencyName']) {
			const agency = getAgencyForAutoComplete(filterUrlParams['agencyId'], decodeURI(filterUrlParams['agencyName']));
			setAgencyId(agency);
			setAgencyOptions([agency]);
		}
		if (filterUrlParams['salesPersonId'] && filterUrlParams['salesPersonName']) {
			const salesPerson = getSalesPersonForAutoComplete(
				filterUrlParams['salesPersonId'],
				decodeURI(filterUrlParams['salesPersonName'])
			);
			setSalesPersonId(salesPerson);
			setSalesPersonOptions([salesPerson]);
		}
		if (filterUrlParams['prospectAgencyName']) {
			const customer = decodeURI(filterUrlParams['prospectAgencyName']);
			setProspectAgencyName(customer);
			setProspectAgencyOptions([customer]);
		}
		if (filterUrlParams['transactionType']) {
			setTransactionType(filterUrlParams['transactionType']);
		}
	};

	const getSA1ForAutoComplete = (urlSa1s: number) => {
		return {
			sa1_code: urlSa1s
		};
	};

	const getAgencyForAutoComplete = (agencyId: number, agencyName: string) => {
		return {
			agency_id: agencyId,
			agency_name: agencyName
		};
	};
	const getSalesPersonForAutoComplete = (salesPersonId: number, salesPersonName: string) => {
		return {
			id: salesPersonId,
			name: salesPersonName
		};
	};

	const onChangeSalesPersonAutocompleteInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		if (reason === 'clear') {
			setSalesPersonId(null);
			return;
		}
		if (reason !== 'input' || value.trim().length < 3) {
			return;
		}
		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (value.trim().length < 3) {
			return;
		}
		getSalesPersonBySearchText(value);
	};

	const onChangeAgencyAutocompleteInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		if (reason === 'clear') {
			setAgencyId(null);
			return;
		}
		if (reason !== 'input' || value.trim().length < 3) {
			return;
		}
		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (value.trim().length < 3) {
			return;
		}
		getAgencyBySearchText(value);
	};
	const onChangeProspectAgencyInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		if (reason === 'clear') {
			setProspectAgencyName(null);
			return;
		}
		if (reason !== 'input' || value.trim().length < 3) {
			return;
		}
		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (value.trim().length < 3) {
			return;
		}
		getProspectAgencyBySearchText(value);
	};
	const onChangeSa1AutocompleteInput = (
		event: React.ChangeEvent<Record<string, unknown>>,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		if (reason === 'clear') {
			setSa1(null);
			return;
		}
		if (reason !== 'input' || value.trim().length < 3) {
			return;
		}
		//autocomplete does not kick in till atleast 3 letters are entered by user
		if (value.trim().length < 3) {
			return;
		}
		getSa1BySearchText(value);
	};

	let getSalesPersonBySearchText = async (searchText: string) => {
		try {
			setLoading(EAutoCompleteLoader.SalesPersonLoader);
			const res = await CommonApiService.getSalesPersonBySearchText({ searchText });
			const salesPersons = getFormattedSalesPersonRecord(res);
			setSalesPersonOptions(salesPersons);
		} catch (err) {
			console.log(
				'🚀 ~ file: Sa1HistoricalReportFilter.tsx ~ line 212 ~ Sa1HistoricalReportFilter ~ getSalesPersonBySearchText= ~ err',
				err
			);
		} finally {
			setLoading(EAutoCompleteLoader.None);
		}
		return;
	};
	getSalesPersonBySearchText = UtilityService.debounce(getSalesPersonBySearchText);
	let getAgencyBySearchText = async (searchText: string) => {
		try {
			setLoading(EAutoCompleteLoader.AgencyLoader);
			const res = await CommonApiService.getAgencyBySearchText({ searchText });
			const agencies = getFormattedAgencyRecord(res);
			setAgencyOptions(agencies);
		} catch (err) {
			console.log(
				'🚀 ~ file: Sa1HistoricalReportFilter.tsx ~ line 170 ~ Sa1HistoricalReportFilter ~ getAgencyBySearchText= ~ err',
				err
			);
		} finally {
			setLoading(EAutoCompleteLoader.None);
		}
		return;
	};
	getAgencyBySearchText = UtilityService.debounce(getAgencyBySearchText);

	let getProspectAgencyBySearchText = async (searchText: string) => {
		try {
			setLoading(EAutoCompleteLoader.ProspectiveCustomerLoader);
			const res = await CommonApiService.getProspectiveCustomerBySearchText({ searchText });
			const customers = getFormattedProspectiveCustomerRecord(res);
			setProspectAgencyOptions(customers);
		} catch (err) {
			console.log(
				'🚀 ~ file: Sa1HistoricalReportFilter.tsx ~ line 258 ~ Sa1HistoricalReportFilter ~ getProspectAgencyBySearchText= ~ err',
				err
			);
		} finally {
			setLoading(EAutoCompleteLoader.None);
		}
		return;
	};
	getProspectAgencyBySearchText = UtilityService.debounce(getProspectAgencyBySearchText);

	const getFormattedAgencyRecord = (res: IAgency[]) => {
		return res.map((agency: IAgency) => {
			return {
				agency_id: agency.agency_id,
				agency_name: agency.name
			};
		});
	};

	const getFormattedProspectiveCustomerRecord = (res: IProspectiveCustomerOption[]) => {
		return res.map((prospectiveCustomer: IProspectiveCustomerOption) => {
			return prospectiveCustomer.prospect_agency;
		});
	};

	const getFormattedSalesPersonRecord = (res: ISalesPerson[]) => {
		return res.map((person: ISalesPerson) => {
			return {
				id: person.id,
				name: `${person.firstname} ${person.lastname}`
			};
		});
	};
	let getSa1BySearchText = async (searchText: string) => {
		try {
			setLoading(EAutoCompleteLoader.SA1Loader);
			const res = await CommonApiService.getSa1BySearchText({ searchText });
			const sa1 = res;
			setSa1Options(sa1);
		} catch (err) {
			console.log(
				'🚀 ~ file: Sa1HistoricalReportFilter.tsx ~ line 95 ~ Sa1HistoricalReportFilter ~ getSa1BySearchText= ~ err',
				err
			);
		} finally {
			setLoading(EAutoCompleteLoader.None);
		}
		return;
	};
	getSa1BySearchText = UtilityService.debounce(getSa1BySearchText);

	const onChangeAgencyAutocomplete = (
		ev: unknown,
		value: IAgencyAutoComplete | null,
		reason: AutocompleteChangeReason
	) => {
		if (!value) {
			return;
		}
		setAgencyId(value);
	};
	const onChangeSalesPersonAutoComplete = async (
		ev: unknown,
		value: ISalesPersonFilter | null,
		reason: AutocompleteChangeReason
	) => {
		if (!value) {
			return;
		}
		setSalesPersonId(value);
	};

	const onChangeSa1Autocomplete = async (
		ev: unknown,
		value: ISa1AutoComplete | null,
		reason: AutocompleteChangeReason
	) => {
		if (!value) {
			return;
		}
		setSa1(value);
	};
	const handleDateChange = (date: Date | null, type: string) => {
		if (!date) {
			return;
		}

		if (type === 'fromDate') {
			setFromDate(date);
			return;
		}
		setToDate(date);
	};

	const getAgencyNameById = (agencyId: number) => {
		if (!agencyId || agencyOptions.length === 0) {
			return;
		}
		const record = agencyOptions.find((agency) => agency.agency_id === agencyId);
		if (!record) {
			return;
		}
		return encodeURI(record.agency_name);
	};
	const getSalesPersonNameById = (salesPersonId: number) => {
		if (!salesPersonId || salesPersonOptions.length === 0) {
			return;
		}
		const record = salesPersonOptions.find((salesPerson) => salesPerson.id === salesPersonId);
		if (!record) {
			return;
		}
		return encodeURI(record.name);
	};

	const onChangeTransactionTypeDropdown = (ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
		const transactionType = ev.target.value as string;
		setTransactionType(transactionType);
	};

	const onClickApplyFilter = () => {
		const filterParams = {
			fromDate: fromDate && new Date(fromDate).getTime(),
			toDate: toDate && new Date(toDate).getTime(),
			sa1: sa1?.sa1_code,
			agencyId: agencyId?.agency_id,
			stateId,
			agencyName: getAgencyNameById(agencyId?.agency_id as number),
			salesPersonId: salesPersonId?.id,
			salesPersonName: getSalesPersonNameById(salesPersonId?.id as number),
			transactionType,
			prospectAgencyName,
			limit: 10,
			offset: 0
		};

		props.onClickApplyFilter(filterParams as any, 'search');
	};

	const onClickReset = () => {
		/* setFromDate(null);
		setToDate(null); */
		setTransactionType(null);
		setSa1(null);
		setAgencyId(null);
		setSalesPersonId(null);
		setAgencyOptions([]);
		setStateId(null);
		setSalesPersonOptions([]);
		setSa1Options([]);
		setProspectAgencyName(null);
		setProspectAgencyOptions([]);

		props.onReset();
	};
	const onChangeAusStateDropdown = (ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
		const stateId = ev.target.value as number;
		setStateId(stateId);
	};

	const onChangeProspectiveAgencyComplete = (ev: unknown, value: string | null, reason: AutocompleteChangeReason) => {
		if (!value) {
			setProspectAgencyName(null);
		}
		setProspectAgencyName(value);
	};

	const renderFilters = () => {
		return (
			<>
				<Grid item container alignItems="center" className="sa1-report admin-sales-sidebar">
					<Grid item style={{ width: 115 }} className="mr20 date-filter">
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								variant="inline"
								autoOk
								style={{ maxWidth: 145 }}
								format="dd/MM/yyyy"
								id="from-date-picker-inline"
								label="From Date"
								value={fromDate}
								onChange={(date: Date | null) => {
									handleDateChange(date, 'fromDate');
								}}
								KeyboardButtonProps={{
									'aria-label': 'change date'
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>

					<Grid item style={{ width: 115 }} className="mr20 date-filter">
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Grid container justify="space-around">
								<KeyboardDatePicker
									variant="inline"
									autoOk
									style={{ maxWidth: 145 }}
									format="dd/MM/yyyy"
									id="to-date-picker-inline"
									label="To Date"
									value={toDate}
									minDate={fromDate}
									maxDate={new Date()}
									onChange={(date: Date | null) => {
										handleDateChange(date, 'toDate');
									}}
									KeyboardButtonProps={{
										'aria-label': 'change date'
									}}
								/>
							</Grid>
						</MuiPickersUtilsProvider>
					</Grid>

					{props.reportType === 'sa1' && (
						<Grid item style={{ width: 115 }} className="mr20">
							<FormControl style={{ width: '100%' }}>
								<Autocomplete
									value={sa1 as ISa1AutoComplete}
									onInputChange={onChangeSa1AutocompleteInput}
									size="small"
									onChange={onChangeSa1Autocomplete}
									options={sa1Options}
									getOptionLabel={(option) => `${option.sa1_code}`}
									renderOption={(option) => <Typography style={{ fontSize: '0.80rem' }}>{option.sa1_code}</Typography>}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Search by SA1"
											variant="standard"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{loading === EAutoCompleteLoader.SA1Loader && (
															<CircularProgress color="secondary" size={20} />
														)}
														{params.InputProps.endAdornment}
													</React.Fragment>
												)
											}}
										/>
									)}
								/>
							</FormControl>
						</Grid>
					)}
					<Grid item style={{ width: 170 }} className="mr20">
						<FormControl style={{ width: '100%' }}>
							<InputLabel htmlFor="manage-cda-state-filter">Select a State</InputLabel>
							<Select
								value={stateId || ''}
								labelId="manage-cda-state-filter"
								onChange={onChangeAusStateDropdown}
								className="stateFilter"
							>
								{ausStates.map((ausState) => {
									return (
										<MenuItem key={ausState.name} value={ausState.id} className="stateFilterOptions">
											{ausState.name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>

					<Grid item style={{ width: 160 }} className="mr20">
						<FormControl style={{ width: '100%' }}>
							<InputLabel htmlFor="transaction-type-filter">Transaction Type</InputLabel>
							<Select
								value={transactionType || ''}
								labelId="transaction-type-filter"
								onChange={onChangeTransactionTypeDropdown}
								className="stateFilter"
							>
								{TransactionTypeOptions.map((transaction) => {
									return (
										<MenuItem key={transaction.name} value={transaction.value} className="stateFilterOptions">
											{transaction.name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
					<Grid item style={{ width: 190 }} className="mr20">
						<FormControl style={{ width: '100%' }}>
							<Autocomplete
								value={salesPersonId as ISalesPersonFilter}
								onInputChange={onChangeSalesPersonAutocompleteInput}
								size="small"
								onChange={onChangeSalesPersonAutoComplete}
								options={salesPersonOptions}
								getOptionLabel={(option) => `${option.name}`}
								renderOption={(option) => <Typography style={{ fontSize: '0.80rem' }}>{option.name}</Typography>}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Search by Account Manager"
										variant="standard"
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{loading === EAutoCompleteLoader.SalesPersonLoader && (
														<CircularProgress color="secondary" size={20} />
													)}
													{params.InputProps.endAdornment}
												</React.Fragment>
											)
										}}
									/>
								)}
							/>
						</FormControl>
					</Grid>

					<Grid item style={{ width: 150 }} className="mr20">
						<FormControl style={{ width: '100%' }}>
							<Autocomplete
								value={agencyId as IAgencyAutoComplete}
								onInputChange={onChangeAgencyAutocompleteInput}
								size="small"
								onChange={onChangeAgencyAutocomplete}
								options={agencyOptions}
								getOptionLabel={(option) => `(${option.agency_id}) ${option.agency_name}`}
								renderOption={(option) => (
									<Typography style={{ fontSize: '0.80rem' }}>
										({option.agency_id}) {option.agency_name}
									</Typography>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Search by Agency"
										variant="standard"
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{loading === EAutoCompleteLoader.AgencyLoader && (
														<CircularProgress color="secondary" size={20} />
													)}
													{params.InputProps.endAdornment}
												</React.Fragment>
											)
										}}
									/>
								)}
							/>
						</FormControl>
					</Grid>

					<Grid item style={{ width: 160 }} className="mr20">
						<FormControl style={{ width: '100%' }}>
							<Autocomplete
								value={prospectAgencyName as string}
								onInputChange={onChangeProspectAgencyInput}
								size="small"
								onChange={onChangeProspectiveAgencyComplete}
								options={prospectAgencyOptions}
								getOptionLabel={(option) => option}
								renderOption={(option) => <Typography style={{ fontSize: '0.80rem' }}>{option}</Typography>}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Prospective Customer"
										variant="standard"
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{loading === EAutoCompleteLoader.ProspectiveCustomerLoader && (
														<CircularProgress color="secondary" size={20} />
													)}
													{params.InputProps.endAdornment}
												</React.Fragment>
											)
										}}
									/>
								)}
							/>
						</FormControl>
					</Grid>

					<Grid item style={{ marginTop: 10 }}>
						<Button color="secondary" variant="outlined" onClick={onClickReset}>
							Reset
						</Button>
						<Button
							color="primary"
							variant="outlined"
							onClick={onClickApplyFilter}
							style={{ marginLeft: 10, marginRight: 15 }}
						>
							Apply
						</Button>
					</Grid>
				</Grid>
				{props.error && (
					<Grid>
						<small
							style={{
								color: '#F44336',
								marginTop: 10,
								fontSize: '80%'
							}}
						>
							{`* ${props.error}`}
						</small>
					</Grid>
				)}
			</>
		);
	};

	return (
		<Paper className="paper-container" style={{ padding: 10 }}>
			{renderFilters()}
		</Paper>
	);
};

export default Sa1HistoricalReportFilter;
