import { CircularProgress, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import React from 'react';

interface IProps {
	title?: string;
	message?: string;
	isLoading?: boolean;
	cancelBtnText?: string;
	confirmBtnText?: string;
	onClickCancel: () => void;
	onClickConfirm: () => void;
}

const useStyles = makeStyles((theme) => ({
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	}
}));

const ConfirmModalWithMessage: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const classes = useStyles();
	return (
		<Dialog open onClose={props.onClickCancel} disableBackdropClick>
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent>
				<Typography variant="body2">{props.message}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClickCancel} color="secondary" disabled={props.isLoading}>
					{props.cancelBtnText}
				</Button>
				<div className={classes.wrapper}>
					<Button onClick={props.onClickConfirm} color="primary" disabled={props.isLoading}>
						{props.confirmBtnText}
					</Button>
					{props.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
				</div>
			</DialogActions>
		</Dialog>
	);
};

ConfirmModalWithMessage.defaultProps = {
	title: 'Confirmation',
	message: 'Are you sure ?',
	cancelBtnText: 'No',
	confirmBtnText: 'Yes',
	isLoading: false
};

export default ConfirmModalWithMessage;
