import { Grid, ListItemText, Typography, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import RevAreaList from 'shared/components/RevAreaList';
import { ENotificationTypes, ERevAreaSaleStatus, ICdar, ISa1, ISa1InfoView } from 'shared/types/Types';

interface IProps {
	cdar: ICdar;
	onSa1ListItemHoverStart?: (sa1: ISa1) => void;
	onSa1ListItemHoverEnd?: (sa1: ISa1) => void;
	showNotification: (message: string, type: ENotificationTypes) => void;
}

interface Sa1AreaList extends ISa1 {
	name: string;
	key: string;
}

const CdarInfoView: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const [sa1ListState, setSa1ListState] = React.useState<Sa1AreaList[]>([]);

	useEffect(() => {
		const sa1List = props.cdar.sa1s.map((sa1) => {
			return {
				...sa1,
				//name attribute is required by RevAreaList
				name: sa1.sa1_code.toString(),
				key: sa1.sa1_code.toString()
			};
		});
		setSa1ListState(sa1List);
	}, [props.cdar.sa1s]);

	const sa1ListRowMouseEnter = (sa1: ISa1) => {
		if (props.onSa1ListItemHoverStart) {
			props.onSa1ListItemHoverStart(sa1);
		}
	};

	const sa1ListRowMouseLeave = (sa1: ISa1) => {
		if (props.onSa1ListItemHoverEnd) {
			props.onSa1ListItemHoverEnd(sa1);
		}
	};

	const onClickSortCdarInfo = () => {
		const statusOrder = [ERevAreaSaleStatus.Sold, ERevAreaSaleStatus.Reserved, null];
		const sortedList = JSON.parse(JSON.stringify(sa1ListState));
		sortedList?.sort((a: ISa1InfoView, b: ISa1InfoView) => {
			return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
		});
		setSa1ListState(sortedList);
	};

	const onClickResetCdarInfo = () => {
		const defaultList = props.cdar.sa1s.map((sa1) => {
			return {
				...sa1,
				name: sa1.sa1_code.toString(),
				key: sa1.sa1_code.toString()
			};
		});

		setSa1ListState(defaultList);
	};

	const getAreaText = (area: ISa1InfoView) => {
		if (area.status) {
			return `${area.name} - ${area.status}`;
		}
		return area.name;
	};

	const onClickCopySoldSa1 = () => {
		const selectedAreas = sa1ListState.filter((area) => area.status === 'sold');
		if (selectedAreas.length === 0) {
			return;
		}
		const allSa1s = getAreaSa1CodeFromSelectedArea(selectedAreas);

		navigator.clipboard
			.writeText(allSa1s.join(', '))
			.then(() => props.showNotification(`Sold SA1 copied successfully`, ENotificationTypes.Success));
	};
	const getAreaSa1CodeFromSelectedArea = (selectedAreas: ISa1InfoView[]) => {
		const selSA1: number[] = [];
		selectedAreas.forEach((selAreaItem: ISa1InfoView) => {
			selSA1.push(parseInt(selAreaItem.name));
		});
		return selSA1.flat();
	};

	return (
		<Grid container item xs direction="column">
			<Typography variant="body2">{props.cdar.name}</Typography>
			{!props.cdar.is_default && (
				<Grid container justify="space-between">
					<Typography color="secondary" variant="body2">
						Is Royalty ?
					</Typography>
					<Typography variant="body2" color="secondary">
						{props.cdar.is_royalty ? 'YES' : 'NO'}
					</Typography>
					{/* <Switch readOnly color="primary" size="small" checked={props.cdar.is_royalty} /> */}
				</Grid>
			)}
			{/* <Typography color="secondary" variant="body2">
				SA1 in {props.cdar.name}
			</Typography> */}
			<Grid container alignItems="center">
				<Grid item xs>
					<Typography color="secondary" variant="body2">
						SA1 in {props.cdar.name}
					</Typography>
				</Grid>
				<Button
					size="small"
					color="secondary"
					variant="outlined"
					onClick={onClickSortCdarInfo}
					style={{
						marginRight: 5
					}}
				>
					Sort
				</Button>
				<Button
					size="small"
					color="secondary"
					variant="outlined"
					onClick={onClickResetCdarInfo}
					style={{
						marginRight: 5
					}}
				>
					Reset
				</Button>
				<Button
					size="small"
					color="secondary"
					variant="outlined"
					onClick={onClickCopySoldSa1}
					style={{
						marginRight: 5
					}}
				>
					Copy Sold SA1
				</Button>
			</Grid>

			{sa1ListState.length > 0 ? (
				<RevAreaList
					xs
					areaList={sa1ListState}
					onHoverListStart={sa1ListRowMouseEnter}
					onHoverListEnd={sa1ListRowMouseLeave}
					rowContent={(area) => {
						return <ListItemText primary={getAreaText(area)} />;
					}}
				/>
			) : (
				<Grid container justify="center" direction="column" style={{ height: 50 }}>
					<Typography color="secondary" variant="body2" style={{ textAlign: 'center' }}>
						No Sa1 found.
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

export default CdarInfoView;
