import {
	DialogContent,
	DialogTitle,
	ListItemText,
	List,
	ListItem,
	makeStyles,
	Grid,
	Divider,
	TextField,
	Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { ISa1 } from 'shared/types/Types';
import ClearIcon from '@material-ui/icons/ClearOutlined';

const useStyles = makeStyles((theme) => ({
	table: {
		borderCollapse: 'collapse',
		marginTop: 10,
		width: '100%'
	},
	tableHeader: {
		border: '1px solid #ccc',
		padding: 3,
		fontSize: '0.700rem'
	},
	tableCell: {
		border: '1px solid #ccc',
		padding: 5,
		fontSize: '0.750rem',
		textAlign: 'center'
	},
	tableRow: {
		'&hover': {
			background: '#eee'
		}
	},
	uppercase: {
		textTransform: 'uppercase'
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	},
	container: {
		maxHeight: 440
	},
	suburbsListContainer: {
		marginTop: 10
	},
	suburbsList: {
		//maxHeight: '100%',
		backgroundColor: '#efefef'
	},
	suburbsListItemDeleteBtn: {
		cursor: 'pointer'
	},
	suburbListRow: {
		/* border: '1px solid transparent', */
		'&:hover': {
			cursor: 'pointer',
			color: theme.palette.primary.main,
			border: '1px solid black'
		}
	},
	sa1ListItemDeleteBtn: {
		cursor: 'pointer'
	}
}));

interface IProps {
	allSa1s: ISa1[];
	onClickSubmitSa1: (sa1: ISa1[]) => void;
	onClickClose: () => void;
}

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

const ProspectiveCustomerListModal: React.FunctionComponent<IProps> = (props): JSX.Element => {
	const classes = useStyles();
	const [allSa1s, setAllSa1s] = React.useState<ISa1[]>([]);
	const [selSa1s, setSelSa1s] = React.useState<ISa1[]>([]);

	useEffect(() => {
		setAllSa1s(props.allSa1s);
	}, [props.allSa1s]);

	const onClickSubmit = () => {
		props.onClickSubmitSa1(selSa1s);
	};

	const onListItemClick = (sa1: ISa1) => {
		const sa1s: ISa1[] = [...selSa1s];
		sa1s.push(sa1);
		setSelSa1s(sa1s);

		const allS1List = allSa1s.filter((s) => {
			return s.sa1_code !== sa1.sa1_code;
		});
		setAllSa1s(allS1List);
	};

	const onClickRemoveSa1FromSelList = (sa1_code: number) => {
		const selSa1List = selSa1s.filter((s) => {
			return s.sa1_code !== sa1_code;
		});
		setSelSa1s(selSa1List);
		const sa1 = props.allSa1s.find((s) => s.sa1_code === sa1_code) as ISa1;
		const allSa1List = [...allSa1s];
		allSa1List.push(sa1);
		setAllSa1s(allSa1List);
	};

	const filterSa1s = (event: any) => {
		const searchKey = event.target.value;
		if (!searchKey) {
			return;
		}

		const filterRecord = props.allSa1s.filter(({ sa1_code: id1 }) => !selSa1s.some(({ sa1_code: id2 }) => id2 === id1));

		const filterSa1s = filterRecord.filter((sa1) => {
			return sa1.sa1_code.toString().trim().toLowerCase().includes(searchKey.trim().toLowerCase());
		});
		setAllSa1s(filterSa1s);
	};

	return (
		<Dialog aria-labelledby="customized-dialog-title" open={true} onClose={props.onClickClose} maxWidth={false}>
			<DialogTitle style={{ color: '#FF6400' }}>Select Sa1(s) to add in CDAR</DialogTitle>
			<Divider />
			<DialogContent style={{ padding: 5, width: '100%', overflow: 'hidden', height: 450 }}>
				<Grid container style={{ width: 550 }}>
					<Grid item xs style={{ padding: 10 }}>
						<TextField
							id="standard-basic"
							label="Search Sa1"
							style={{ width: '100%' }}
							onKeyUp={(e) => {
								filterSa1s(e);
							}}
						/>
						<Grid className={classes.suburbsListContainer} style={{ height: 350, overflow: 'auto', width: '100%' }}>
							{allSa1s.length > 0 && (
								<List dense className={classes.suburbsList}>
									{allSa1s.map((sa1: ISa1) => (
										<ListItem
											dense
											divider
											key={sa1.id}
											className={classes.suburbListRow}
											onClick={() => onListItemClick(sa1)}
										>
											<Grid container>
												<ListItemText primary={sa1.sa1_code} />
											</Grid>
										</ListItem>
									))}
								</List>
							)}
						</Grid>
					</Grid>
					<Divider style={{ margin: '5px 0' }} flexItem orientation="vertical" />
					<Grid item xs style={{ padding: 10 }}>
						<Typography variant="body1" color="secondary" style={{ textAlign: 'center', marginTop: 10, height: 37 }}>
							Selected Sa1s
						</Typography>
						<Grid className={classes.suburbsListContainer} style={{ maxHeight: 400, overflow: 'auto', width: '100%' }}>
							{selSa1s.length > 0 ? (
								<List dense className={classes.suburbsList}>
									{selSa1s.map((sa1: ISa1) => (
										<ListItem dense divider key={sa1.id} className={classes.suburbListRow}>
											<Grid container>
												<ListItemText primary={sa1.sa1_code} />
												<ClearIcon
													color="secondary"
													className={classes.sa1ListItemDeleteBtn}
													onClick={() => {
														onClickRemoveSa1FromSelList(sa1.sa1_code);
													}}
												/>
											</Grid>
										</ListItem>
									))}
								</List>
							) : (
								<Typography variant="body2" color="secondary" style={{ textAlign: 'center' }}>
									To add sa1 , Click on Sa1 from left side list
								</Typography>
							)}
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button onClick={props.onClickClose} variant="contained" className="cancel-btn">
					CLOSE
				</Button>
				<div className={classes.wrapper}>
					<Button onClick={onClickSubmit} variant="contained" className="submit-btn">
						SUBMIT
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default ProspectiveCustomerListModal;
