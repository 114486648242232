import AppBar from '@material-ui/core/AppBar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import NavMenu from 'shared/components/NavMenu';
import { INavMenu, INavSubMenu } from 'shared/types/Types';
import NotificationToast from './NotificationToast';
import appInfo from '../../../package.json';
import { Divider } from '@material-ui/core';
import { LoggedInUserContext } from 'shared/providers/LoggedInUserProvider';
import CommonApiService from 'shared/services/CommonApiService';

/* const ManageAreaMenu: INavMenu[] = [
	{
		label: 'Manage CDA',
		route: '/ui/manage/cda'
	},
	{
		label: 'Manage CDAR',
		route: '/ui/manage/cdar'
	}
]; */

export default function Layout(props: { children: React.ReactNode }): JSX.Element {
	const [open, setOpen] = React.useState(false);
	const history = useHistory();
	const anchorRef = React.useRef<HTMLButtonElement>(null);
	const { loggedInUser } = useContext(LoggedInUserContext);
	const [menu, setMenu] = React.useState<INavMenu[]>([]);
	const onClickUser = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (e: React.MouseEvent<Document, MouseEvent>) => {
		if (anchorRef.current && anchorRef.current.contains(e.target as Node)) {
			return;
		}
		setOpen(false);
	};

	const onClickMenu = (route: string) => {
		history.push(route);
		setOpen(false);
	};

	useEffect(() => {
		const getMenu = async () => {
			const apiRes = await CommonApiService.getMenu();
			if (!apiRes?.success) {
				return;
			}
			setMenu(apiRes.data);
		};

		getMenu();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSubMenu = (menus: INavMenu[]): INavSubMenu[] => {
		const subMenu: INavSubMenu[] = [];
		menus.forEach((menu: INavMenu) => {
			subMenu.push({
				label: menu.name,
				route: menu.ui_state as string,
				parent_id: menu.parent_id as number
			});
		});
		return subMenu;
	};

	const renderMenu = (menus: INavMenu[]) => {
		return menus.map((menu) => {
			if (menu.sub_menu) {
				return (
					<NavMenu
						key={menu.id}
						menuId={menu.id}
						onClick={onClickMenu}
						icon={menu.icon}
						label={menu.name}
						menu={getSubMenu(menu.sub_menu) as any}
					/>
				);
			}
			return (
				<NavMenu
					key={menu.id}
					menuId={menu.id}
					icon={menu.icon}
					label={menu.name}
					route={menu.ui_state as string}
					onClick={onClickMenu}
				/>
			);
		});
	};

	return (
		<React.Fragment>
			<AppBar style={{ backgroundColor: '#fff' }} position="relative">
				<Toolbar variant="dense">
					<Grid container>
						<Grid item style={{ minWidth: 155 }}>
							<Grid alignItems="center" justify="center" container className="stretch">
								<div style={{ width: 103, height: 30 }}>
									<img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="" width="100%" height="100%" />
								</div>

								<Typography
									variant="h6"
									color="primary"
									noWrap
									style={{ fontSize: 17, fontWeight: 400, marginLeft: '10px' }}
								>
									Agency Branding
								</Typography>
							</Grid>
						</Grid>
						<Grid item container alignItems="center" justify="center" xs>
							{menu.length > 0 && renderMenu(menu)}
						</Grid>
						<Grid item>
							<Grid container>
								{loggedInUser && (
									<Grid style={{ marginRight: 5 }} item>
										<Grid container direction="column" className="stretch" justify="center">
											<Typography variant="overline" style={{ lineHeight: 1 }}>
												{loggedInUser.firstName} {loggedInUser.lastName}
											</Typography>
											<Typography variant="caption" color="secondary">
												{loggedInUser.role}
											</Typography>
										</Grid>
									</Grid>
								)}
								<IconButton ref={anchorRef} onClick={onClickUser}>
									<AccountCircleIcon />
								</IconButton>
							</Grid>
							<Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
										}}
									>
										<Paper>
											<ClickAwayListener onClickAway={handleClose}>
												<>
													<MenuList autoFocusItem={open} id="menu-list-grow">
														<MenuItem onClick={() => onClickMenu('/ui/settings')}>Settings</MenuItem>
														{/* <MenuItem>Logout</MenuItem> */}
													</MenuList>
													<Divider light />
													<Grid container alignItems="center" justify="center">
														<Typography variant="caption"> Version - {appInfo.version}</Typography>
													</Grid>
												</>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Grid container direction="row" style={{ height: 'calc(100vh - 48px)' }}>
				{props.children}
			</Grid>
			<NotificationToast />
		</React.Fragment>
	);
}
