const AusStates = [
	{
		id: 1,
		name: 'New South Wales',
		code: 'NSW',
		bounds: {
			east: -33.980718,
			north: 149.976306,
			west: -39.13674,
			south: 140.961356
		},
		center: {
			latitude: 144.30079,
			longitude: -36.84639
		}
	},
	{
		id: 2,
		name: 'Victoria',
		code: 'VIC',
		center: {
			latitude: 144.30079,
			longitude: -36.84639
		},
		bounds: {
			east: -33.980718,
			north: 149.976306,
			west: -39.13674,
			south: 140.961356
		}
	},
	{
		id: 3,
		name: 'Queensland',
		code: 'QLD',
		bounds: {
			north: 153.5552415,
			west: -29.1778977,
			south: 137.99595728,
			east: -9.141203
		},
		center: {
			latitude: 144.30079,
			longitude: -36.84639
		}
	},
	{
		id: 8,
		name: 'Australian Capital Territory',
		code: 'ACT',
		bounds: {
			north: 149.399287042,
			west: -35.920530073,
			south: 148.762790106,
			east: -35.124415803
		},
		center: {
			latitude: 144.30079,
			longitude: -36.84639
		}
	},
	{
		id: 6,
		name: 'Tasmania',
		code: 'TAS',
		bounds: {
			north: -33.980718,
			east: 149.976306,
			south: -39.13674,
			west: 140.961356
		},
		center: {
			latitude: 144.30079,
			longitude: -36.84639
		}
	},
	{
		id: 4,
		name: 'South Australia',
		code: 'SA',
		bounds: {
			north: -33.980718,
			east: 149.976306,
			south: -39.13674,
			west: 140.961356
		},
		center: {
			latitude: 144.30079,
			longitude: -36.84639
		}
	},
	{
		id: 5,
		name: 'Western Australia',
		code: 'WA',
		bounds: {
			north: -33.980718,
			east: 149.976306,
			south: -39.13674,
			west: 140.961356
		},
		center: {
			latitude: 144.30079,
			longitude: -36.84639
		}
	},
	{
		id: 7,
		name: 'Northern Territory',
		code: 'NT',
		bounds: {
			north: -33.980718,
			east: 149.976306,
			south: -39.13674,
			west: 140.961356
		},
		center: {
			latitude: 144.30079,
			longitude: -36.84639
		}
	}
];

const TransactionTypeOptions = [
	{
		name: 'None',
		value: 'none'
	},
	{
		name: 'Sold',
		value: 'sold'
	},
	{
		name: 'Released',
		value: 'released'
	},
	{
		name: 'Reserved',
		value: 'reserved'
	},
	{
		name: 'Reservation Expired',
		value: 'reservation_expired'
	},
	{
		name: 'Reservation Extension',
		value: 'reservation_extension'
	}
];

const StatusOptions = [
	{
		name: 'None',
		value: 'none'
	},
	{
		name: 'Available',
		value: 'available'
	},
	{
		name: 'Sold',
		value: 'sold'
	},
	{
		name: 'Reserved',
		value: 'reserved'
	}
];

export { AusStates, TransactionTypeOptions, StatusOptions };
