import { Grid, Typography } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import DefaultLayout from 'shared/components/PublicLayout';
import React, { Component } from 'react';
import CommonApiService from 'shared/services/CommonApiService';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import { NotificationContext } from 'shared/providers/NotificationProvider';
import { ENotificationTypes } from 'shared/types/Types';

interface IMatchParams {
	id: string;
}
type IProps = RouteComponentProps<IMatchParams>;
interface IState {
	isLoading: boolean;
}
class Auth extends Component<IProps, IState> {
	state: Readonly<IState> = {
		isLoading: true
	};
	context!: React.ContextType<typeof NotificationContext>;
	componentDidMount = () => {
		this.validateAuthToken();
	};
	render(): JSX.Element {
		return (
			<DefaultLayout>
				<Grid container className="stretch" alignItems="center" justify="center">
					{this.state.isLoading ? (
						<LoadingSpinner message="Please wait" />
					) : (
						<Grid item xs>
							<Grid direction="column" container style={{ height: '100%', width: '100%' }}>
								<Grid xs item container direction="column" alignItems="center" justify="center" alignContent="center">
									<Typography className="mb10" color="secondary" variant="h5">
										You are not authorized to access this page.
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					)}
				</Grid>
			</DefaultLayout>
		);
	}
	validateAuthToken = async () => {
		const tokenParam = this.isValidToken();
		if (!tokenParam) {
			return;
		}
		try {
			const isValid = await CommonApiService.validateAuthToken({ token: tokenParam });
			if (!isValid.success) {
				this.handleValidateAuthTokenFailure(isValid.message as string);
				return;
			}
			this.props.history.push('/ui');
		} catch (error) {
			const msg = error.message || 'Failed to validate token';
			this.handleValidateAuthTokenFailure(msg as string);
		}
	};

	handleValidateAuthTokenFailure = (errMsg: string) => {
		this.setState({
			isLoading: false
		});
		this.context.addNotification({
			message: errMsg,
			type: ENotificationTypes.Error
		});
	};

	isValidToken = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const tokenParam = urlParams.get('token');
		if (!window.location.search || !tokenParam) {
			this.setState({
				isLoading: false
			});
			return false;
		}
		return tokenParam;
	};
}
Auth.contextType = NotificationContext;
export default Auth;
