import React from 'react';
import { Typography, Paper } from '@material-ui/core';

interface IProps {
	emptyText: string;
}

const EmptyScreen: React.SFC<IProps> = (props): JSX.Element => {
	const emptyScreen = () => {
		return (
			<Paper className="emptyScreenView">
				<Typography variant="h6" color="textSecondary">
					{props.emptyText}
				</Typography>
			</Paper>
		);
	};

	return emptyScreen();
};
export default EmptyScreen;
